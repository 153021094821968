<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M236.002 56v64a4 4 0 0 1-8 0V65.657l-89.171 89.172a4 4 0 0 1-5.657 0l-37.172-37.172l-69.171 69.172a4 4 0 0 1-5.657-5.658l72-72a4 4 0 0 1 5.657 0l37.171 37.172L222.346 60h-54.344a4 4 0 0 1 0-8h64c.017 0 .032.005.048.005a3.994 3.994 0 0 1 .735.074c.12.024.23.068.346.102a2.29 2.29 0 0 1 .775.321c.105.058.215.104.316.172a4.014 4.014 0 0 1 .603.493l.006.004a4.007 4.007 0 0 1 .498.61c.066.099.11.205.167.307a2.33 2.33 0 0 1 .327.788c.033.114.077.223.1.34a3.99 3.99 0 0 1 .08.784z"
      fill="currentColor"
    ></path>
  </svg>
</template>