<template>
  <div class="mt-2 ml-4">
    <div class="flex justify-between mb-2 mr-4">
      <div class="flex justify-start">
        <span class="font-semibold">
          閲覧ワード
        </span>
        <span class="ml-1">(最大5件)</span>
      </div>
      <div class="flex justify-end mb-2 mr-4">
        <button
          @click="cleanHistory"
          class="font-semibold"
        >
          クリア
        </button>
      </div>
    </div>
    <div
      v-for="word in state.words"
      :key="word"
      class="ml-4"
    >
      <div class="mb-3">
        <search-history-icon class="inline-block" />
        <button
          @click="$emit('searchFromHistory', word)"
          class="ml-11"
        >
          {{ word }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import { useRoute } from "vue-router"
import SearchHistoryIcon from "../../icons/SearchHistoryIcon.vue";

export default {
  components: {
    SearchHistoryIcon
  },
  setup() {
    const route = useRoute();
    const name = computed(() => route.name);
    const path = computed(() => route.path);
    const state = reactive({
      words: [],
    });

    const cleanHistory = () => {
      localStorage.clear();
      state.words = [];
    };

    const keys = Object.keys(localStorage).filter(k => k.includes("word"));
    keys.sort((a, b) => {
      return (a < b ? 1: -1);
    });
    keys.forEach(key => state.words.push(localStorage[key]));

    return { 
      name,
      path,
      state,
      cleanHistory
    };
  },
};
</script>

<style lang="postcss" scoped>
.active {
  @apply text-gray-900 border-gray-900;
}
</style>