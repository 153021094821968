import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
export default {
  checkSlackAuthorization(payload) {
    return Repository.post(`${version}/slack/check`, { json: payload });
  },
  fetchChannelsList(payload) {
    return Repository.post(`${version}/slack/get_channels_list`, { json: payload });
  },
  updateSlackChannel(payload) {
    return Repository.post(`${version}/slack/update_channel`, { json: payload });
  },
  revokeSlackToken(payload) {
    return Repository.post(`${version}/slack/revoke`, { json: payload });
  }
};