<template>
  <transition name="alart">
    <div v-if="show" class="fixed flex justify-start w-full left-4 top-32">
      <div
        class="px-4 py-3 text-yellow-900 bg-white border-t-4 border-yellow-500 rounded shadow-lg"
        role="alert"
      >
        <div class="flex px-4">
          <div class="py-1">
            <svg
              class="w-6 h-6 mr-4 text-yellow-500 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
              />
            </svg>
          </div>
          <div>
            <p class="font-bold">{{ title }}</p>
            <p class="text-sm">{{ body }}</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    body: String
  }
};
</script>

<style scoped>
  .alart-enter-from,
  .alart-leave-to {
    transform: translate(-100%);
  }
  .alart-enter-active,
  .alart-leave-active {
    transition: transform 0.1s ease;
  }
  .alart-enter-to,
  .alart-leave {
    transform: translate(0);
  }
</style>