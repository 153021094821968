import { computed, reactive } from "vue";
import store from "../../../store"
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepositoryForGae = RepositoryFactoryForGae.get("board");

export default function (board = {}) {
  const addPin = async (pinId, pinType, boardId = null) => {
    const payload = {
      pins: [{ pin_id: pinId, pin_type: pinType }],
      board_id: boardId ? boardId : board.id,
    };
    return await BoardRepositoryForGae.addPin(payload);
  }

  const removePin = async (pinId, pinType, boardId = null) => {
    const payload = {
      pins: [{ pin_id: pinId, pin_type: pinType }],
      board_id: boardId ? boardId : board.id,
    };
    return await BoardRepositoryForGae.removePin(payload);
  }

  const getMyBoard = async (data) => {
    return await store.dispatch("board/getMyBoard", data);
  }

  const getMyBoardToSideBar = async (data) => {
    return await store.dispatch("board/getMyBoardToSideBar", data);
  }

  const getPublicBoard = async (data) => {
    return await store.dispatch("board/getPublicBoard", data);
  }

  const addMembers = async (payload) => {
    return await BoardRepositoryForGae.attachUser(payload).json();
  }

  const removeMembers = async (payload) => {
    return await BoardRepositoryForGae.detachUser(payload).json();
  }

  const getBoardInfo = async (data) => {
    return await store.dispatch("board/getBoardInfo", data);
  }

  const mergeBoardAndMember = (boards, users) => {
    return boards.map(board => {
      const members = [
        ...board.owners.map(x => { return { id: x, permission: 'owner' } }), 
        ...board.members.map(x => { return { id: x, permission: 'member' } }),
      ].map(member => {
        const memberInfo = { ...users.find(user => user.id === member.id) || {} };
        return Object.assign(memberInfo, member);
      });

      return Object.assign(board, { participants: members });
    });
  }

  const updatePrivateBoardOrder = (newBoardOrder) => {
    return store.dispatch("board/updatePrivateBoardOrder", newBoardOrder);
  }

  const updatePublicBoardOrder = (newBoardOrder) => {
    return store.dispatch("board/updatePublicBoardOrder", newBoardOrder);
  }

  const boardRepositoryState = reactive({
    pins: [],
    limit: 12
  });

  const getPinPayload = { target: "", cursor: 0 };

  const showPinsLoading = computed(() => {
    return boardRepositoryState.pins.length >= boardRepositoryState.limit;
  });

  const fetchPins = async (payload) => {
    const boardInfo = await store.dispatch("board/getBoardInfo", payload);
    return boardInfo.pins;
  };

  const infiniteHandler = async ($state) => {
    let result;
    result = await fetchPins(getPinPayload);

    boardRepositoryState.pins = [...boardRepositoryState.pins, ...result];
    await store.dispatch("board/addPinsToBoard", boardRepositoryState.pins);
    if (result.length == boardRepositoryState.limit) {
      $state.loaded();
    } else {
      $state.complete();
    }
  };

  return {
    addPin,
    removePin,
    getMyBoard,
    getMyBoardToSideBar,
    getPublicBoard,
    addMembers,
    removeMembers,
    getBoardInfo,
    mergeBoardAndMember,
    updatePrivateBoardOrder,
    updatePublicBoardOrder,
    boardRepositoryState,
    getPinPayload,
    showPinsLoading,
    fetchPins,
    infiniteHandler
  }
}