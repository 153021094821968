<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M83.171 84.817a4 4 0 0 1 0-5.657l42-41.99a4.002 4.002 0 0 1 5.657 0l42 41.99a4 4 0 0 1-5.656 5.658L132 49.655V152a4 4 0 0 1-8 0V49.655L88.828 84.818a4 4 0 0 1-5.657 0zM216 148a4 4 0 0 0-4 4v56a4.004 4.004 0 0 1-4 4H48a4.004 4.004 0 0 1-4-4v-56a4 4 0 0 0-8 0v56a12.013 12.013 0 0 0 12 12h160a12.013 12.013 0 0 0 12-12v-56a4 4 0 0 0-4-4z"
      fill="currentColor"
    ></path>
  </svg>
</template>