<template>
  <top-needs-template />
</template>

<script>
import TopNeedsTemplate from "../templates/TopNeedsTemplate.vue"

export default {
  components: { 
    TopNeedsTemplate
  },
};
</script>