<template>
  <mail-verify-signin-template
    :resend="resendVerifyMail"
  />
</template>

<script>
import { reactive } from "vue"
import firebase from "firebase/app";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const UserRepository = RepositoryFactoryForGae.get("user");
import MailVerifySigninTemplate from "../templates/MailVerifySigninTemplate.vue";

export default {
  components: {
    MailVerifySigninTemplate,
  },

  setup() {
    const currentUser = firebase.auth().currentUser;

    const state = reactive({
      email: currentUser.email
    })

    const resendVerifyMail = async () => {
      const redirectUrl = location.origin;
      await UserRepository.sendVerificationEmail({
        email: state.email,
        redirect_url: redirectUrl
      })
      .then(() => {
        firebase.auth().signOut();
      })
      .catch((e) => {
        console.error(e.message)
      });
    }

    return {
      resendVerifyMail
    }
  },
};
</script>