<template>
  <div class="flex flex-col w-screen max-mobile-height">
    <header-top />
    <Suspense>
      <template #default>
        <profile-info
          :profileInfo="profileInfo"
        />
      </template>
      <template #fallback>
        <loader-profile />
      </template>
    </Suspense>
    <Footer />
  </div>
</template>

<script>
import ProfileInfo from "../organisms/ProfileInfo.vue"
import LoaderProfile from "../organisms/loader/LoaderProfile.vue";
import HeaderTop from "../organisms/HeaderTop.vue";
import Footer from "../organisms/Footer.vue"
export default {
  components: {
    HeaderTop,
    Footer,
    ProfileInfo,
    LoaderProfile
  },
  props: {
    profileInfo: {
      type: Object,
      required: true
    }
  },
  setup () {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
};
</script>

<style scoped>
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>