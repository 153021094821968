<template>
  <tag-template />
</template>

<script>
import TagTemplate from "../templates/TopTagTemplate.vue"

export default {
  components: { 
    TagTemplate
  },
};
</script>