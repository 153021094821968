<template>
  <div class="grid grid-cols-6">
    <div class="col-span-3">
      <h1 class="col-span-5 font-mono text-4xl font-bold">
        メール・通知設定
      </h1>
      <h2 class="mt-4 mb-6 border-b">
        <div class="flex justify-between">
          <div>アクティビティ</div>
          <div
            class="text-base text-blue-600 cursor-pointer"
            @click="checkAllTransactionalMail"
          >
            全てチェック
          </div>
        </div>
      </h2>
      <div class="flex justify-between mb-4">
        <div>自分の資料がいいねされたらメールを受け取る</div>
        <input
          type="checkbox"
          value="like"
          class="h-5 w-5 border border-gray-500 rounded-sm cursor-pointer checked:bg-blue-600 checked:border-blue-600"
          :checked="state.checkedAllTransaction ? true : false"
          v-model="state.checkedTransactionValues"
        />
      </div>
      <div class="flex justify-between mb-4">
        <div>ボードに追加されたらメールを受け取る</div>
        <input
          type="checkbox"
          value="attachBoard"
          class="h-5 w-5 border border-gray-500 rounded-sm cursor-pointer checked:bg-blue-600 checked:border-blue-600"
          :checked="state.checkedAllTransaction ? true : false"
          v-model="state.checkedTransactionValues"
        />
      </div>
      <div class="flex justify-between mb-4">
        <div>ワークスペースに資料がアップロードされたらメールを受け取る</div>
        <input
          type="checkbox"
          value="upload"
          class="h-5 w-5 border border-gray-500 rounded-sm cursor-pointer checked:bg-blue-600 checked:border-blue-600"
          :checked="state.checkedAllTransaction ? true : false"
          v-model="state.checkedTransactionValues"
        />
      </div>
      <!-- <div class="flex justify-between">
        <div>リリース情報などシステムからのメールを受け取る</div>
        <input
          type="checkbox"
          value="systemInfo"
          class="h-5 w-5 border border-gray-500 rounded-sm cursor-pointer checked:bg-blue-600 checked:border-blue-600"
          :checked="state.checkedAllTransaction ? true : false"
          v-model="state.checkedTransactionValues"
        />
      </div> -->
      <h2 class="mt-8 mb-6 border-b">
        <div class="flex justify-between">
          <div>コメント</div>
          <div
            class="text-base text-blue-600 cursor-pointer"
            @click="checkAllCommentMail"
          >
            全てチェック
          </div>
        </div>
      </h2>
      <div class="flex justify-between mb-4">
        <div>自分の資料にコメントされたらメールを受け取る</div>
        <input
          type="checkbox"
          value="comment"
          class="h-5 w-5 border border-gray-500 rounded-sm cursor-pointer checked:bg-blue-600 checked:border-blue-600"
          :checked="state.checkedAllComment ? true : false"
          v-model="state.checkedCommentValues"
        />
      </div>
      <div class="flex justify-between mb-4">
        <div>資料へのコメントでメンションされたらメールを受け取る</div>
        <input
          type="checkbox"
          value="mention"
          class="h-5 w-5 border border-gray-500 rounded-sm cursor-pointer checked:bg-blue-600 checked:border-blue-600"
          :checked="state.checkedAllComment ? true : false"
          v-model="state.checkedCommentValues"
        />
      </div>
      <h2 class="mt-8 mb-6 border-b">
        <div class="flex justify-between">
          <div>アナリティクス</div>
          <div
            class="text-base text-blue-600 cursor-pointer"
            @click="checkAllAnalyticsMail"
          >
            全てチェック
          </div>
        </div>
      </h2>
      <div class="flex justify-between mb-4">
        <div>週次アナリティクスを受け取る</div>
        <input
          type="checkbox"
          value="weeklyAnalytics"
          class="h-5 w-5 border border-gray-500 rounded-sm cursor-pointer checked:bg-blue-600 checked:border-blue-600"
          :checked="state.checkedAllAnalytics ? true : false"
          v-model="state.checkedAnalyticsValues"
        />
      </div>
      <div class="flex justify-end mt-8">
        <button
          @click="updateMailSettings"
          class="ml-2 p-1.5 text-white bg-indigo-600 button rounded-md hover:bg-indigo-700 disabled:bg-gray-300"
          :disabled="state.isUpdating"
        >
          更新する
        </button>
      </div>
    </div>
    <div class="col-span-3"></div>
    <success-alert
      :show="state.showAlert"
      :title="state.alertTitle"
      :body="state.alertBody"
    />
  </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const UserRepository = RepositoryFactoryForGae.get("user");
import SuccessAlert from "../alarts/SuccessAlart.vue";

export default {
  components: {
    SuccessAlert
  },
  setup() {
    const store = useStore();
    const state = reactive({
      userInfo: store.state.user.userInfo,
      checkedTransactionValues: [],
      checkedAnalyticsValues: [],
      checkedCommentValues: [],
      convertedValues: [],
      checkedAllTransaction: false,
      checkedAllAnalytics: false,
      checkedAllComment: false,
      isUpdating: false,
      showAlert: false,
      alertTitle: "設定を更新しました！",
      alertBody: "反映されない場合はリロードしてください"
    });

    state.userInfo.deliverable_mail_type.forEach((type) => {
      switch (type) {
        case 2:
          state.checkedTransactionValues.push("like", "attachBoard", "systemInfo");
          state.checkedAllTransaction = true;
          break;
        case 4:
          state.checkedTransactionValues.push("like");
          break;
        case 5:
          state.checkedTransactionValues.push("attachBoard");
          break;
        case 6:
          state.checkedTransactionValues.push("upload");
          break;
        case 7:
          state.checkedTransactionValues.push("systemInfo");
          break;
        case 8:
          state.checkedAnalyticsValues.push("weeklyAnalytics");
          break;
        case 10:
          state.checkedCommentValues.push("comment");
          break;
        case 11:
          state.checkedCommentValues.push("mention");
          break;
      }
    });

    const checkAllTransactionalMail = () => {
      state.checkedAllTransaction = !state.checkedAllTransaction;
      if (state.checkedAllTransaction)
        state.checkedTransactionValues.push("like", "attachBoard", "upload", "systemInfo");
      else
        state.checkedTransactionValues = [];
    };

    const checkAllAnalyticsMail = () => {
      state.checkedAllAnalytics = !state.checkedAllAnalytics;
      if (state.checkedAllAnalytics)
        state.checkedAnalyticsValues.push("weeklyAnalytics");
      else
        state.checkedAnalyticsValues = [];
    };

    const checkAllCommentMail = () => {
      state.checkedAllComment = !state.checkedAllComment;
      if (state.checkedAllComment)
        state.checkedCommentValues.push("comment", "mention");
      else
        state.checkedCommentValues = [];
    };

    const convertCheckedValue = async (values) => {
      state.convertedValues = values.map((val) => {
        if (val.length == 0)
          return 0;
        else if (val == "like")
          return 4;
        else if (val == "attachBoard")
          return 5;
        else if (val == "upload")
          return 6;
        else if (val == "systemInfo")
          return 7;
        else if (val == "weeklyAnalytics")
          return 8;
        else if (val == "comment")
          return 10;
        else if (val == "mention")
          return 11;
      });
    };

    const showAlert = () => {
      state.showAlert = true;
      setTimeout(() => (state.showAlert = false), 4000);
    };

    const updateMailSettings = async () => {
      state.isUpdating = true;
      
      await convertCheckedValue(Array.from([
        ...state.checkedTransactionValues,
        ...state.checkedAnalyticsValues,
        ...state.checkedCommentValues
      ]));
      const payload = {
        notification_settings_type: Array.from(new Set(state.convertedValues))
      };
      await UserRepository.updateNotificationSettings(payload)
        .then(() => {
          state.isUpdating = false;
          showAlert();
        });
    };

    return {
      state,
      checkAllTransactionalMail,
      checkAllAnalyticsMail,
      checkAllCommentMail,
      updateMailSettings
    };
  }
}
</script>