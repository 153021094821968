<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M116.543 158.46a58 58 0 1 0-57.085 0a94.227 94.227 0 0 0-48.368 35.482a6 6 0 1 0 9.812 6.909a82.018 82.018 0 0 1 134.193-.005a6 6 0 1 0 9.811-6.91a94.228 94.228 0 0 0-48.363-35.476zM42 108a46 46 0 1 1 46 46a46.052 46.052 0 0 1-46-46zm202.977 94.297a5.999 5.999 0 0 1-8.36-1.451A82.171 82.171 0 0 0 169.522 166a6 6 0 0 1 0-12a46 46 0 1 0-12.484-90.287a6 6 0 0 1-3.25-11.552a57.991 57.991 0 0 1 44.277 106.3a94.23 94.23 0 0 1 48.363 35.476a6 6 0 0 1-1.451 8.36z"
      fill="currentColor"
    ></path>
  </svg>
</template>