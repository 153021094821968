<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M160 58H64a14.016 14.016 0 0 0-14 14v152a6 6 0 0 0 9.488 4.882l52.505-37.51l52.52 37.51A6 6 0 0 0 174 224V72a14.016 14.016 0 0 0-14-14zm2 154.342l-46.52-33.225a6 6 0 0 0-6.975.001L62 212.34V72a2.003 2.003 0 0 1 2-2h96a2.003 2.003 0 0 1 2 2zM206 40v152a6 6 0 0 1-12 0V40a2.003 2.003 0 0 0-2-2H88a6 6 0 1 1 0-12h104a14.016 14.016 0 0 1 14 14z"
      fill="currentColor"
    ></path>
  </svg>
</template>