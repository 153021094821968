import ky from "ky";
import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
const resource = "file";
export default {
  uploadFile(url, options) {
    return ky.put(url, options);
  },

  fetchSlideImageFile(payload) {
    return Repository.post(`${version}-${resource}-fetchSlideImageFile`, { json: payload });
  },

  getUploadUrl(payload) {
    return Repository.post(`${version}-${resource}-getUploadUrl`, { json: payload });
  },

  getPptxUploadUrl(payload) {
    return Repository.post(`${version}-${resource}-getPptxUploadUrl`, { json: payload });
  },

  getDownloadUrl(payload) {
    return Repository.post(`${version}-${resource}-getDownloadUrl`, { json: payload });
  },

  archivePackage(payload) {
    return Repository.post(`${version}-${resource}-archivePackage`, { json: payload });
  },

  deletePackage(payload) {
    return Repository.post(`${version}-${resource}-deletePackage`, { json: payload });
  },

  deleteSlide(payload) {
    return Repository.post(`${version}-${resource}-deleteSlide`, { json: payload });
  },

  eliminatePackage(payload) {
    return Repository.post(`${version}-${resource}-eliminatePackage`, { json: payload });
  },

  eliminateSlide(payload) {
    return Repository.post(`${version}-${resource}-eliminateSlide`, { json: payload });
  },

  favoriteSlide(payload) {
    return Repository.post(`${version}-${resource}-favoriteSlide`, { json: payload });
  },

  favoritePackage(payload) {
    return Repository.post(`${version}-${resource}-favoritePackage`, { json: payload });
  },

  unfavoritePackage(payload) {
    return Repository.post(`${version}-${resource}-unfavoritePackage`, { json: payload });
  },

  unfavoriteSlide(payload) {
    return Repository.post(`${version}-${resource}-unfavoriteSlide`, { json: payload });
  },

  getSlideInfo(payload) {
    return Repository.post(`${version}-${resource}-getSlideInfo`, { json: payload });
  },

  getPackageInfo(payload) {
    return Repository.post(`${version}-${resource}-getPackageInfo`, { json: payload });
  },

  incrementPageview(payload) {
    return Repository.post(`${version}-${resource}-incrementPageview`, { json: payload });
  },

  updatePackage(payload) {
    return Repository.post(`${version}-${resource}-updatePackage`, { json: payload });
  },

  updatePackageHeader(payload) {
    return Repository.put(`${version}-${resource}-updatePackageHeader`, { json: payload });
  },
};