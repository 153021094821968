<template>
  <div
    v-if="deviceType == 'pc'"
    class="flex bg-gray-100"
  >
    <div class="flex flex-col w-screen h-screen">
      <header-top />
      <div class="wrapper">
        <the-sidebar @show-alart="showAlart" class="sidebar" />
        <main class="h-full px-8 py-4 overflow-x-hidden overflow-y-scroll main">
          <h1 class="mb-4 text-xl font-bold text-gray-600">
            {{ pageTitle }}
          </h1>
          <toolbox />
          <the-loader v-if="state.loading" />
          <router-view
            v-show="!state.loading"
            @start-loading="startLoading"
            @end-loading="endLoading"
            :key="$route.fullPath"
          />
        </main>
      </div>
    </div>
    <success-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'success'"
      :title="alartState.alartMsg.success.title || 'ボードにファイルを追加しました！'"
      :body="alartState.alartMsg.success.body || '詳細はマイボードから確認してください。'"
    />
    <failure-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'failure'"
      :title="alartState.alartMsg.failure.title || 'ファイルを追加できませんでした！'"
      :body="alartState.alartMsg.failure.body || '詳細はマイボードから確認してください。'"
    />
    <warning-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'warning'"
      :title="alartState.alartMsg.warning.title || '注意してください！'"
      :body="alartState.alartMsg.warning.body || '非公開ボードに属しているユーザが閲覧できるようになります。'"
    />
  </div>
  <div
    v-else-if="deviceType == 'mobile'"
    class="flex bg-gray-100"
  >
    <div class="flex flex-col w-screen max-mobile-height">
      <mobile-header-top v-show="scroll.visible" />
      <main class="h-screen py-4 overflow-x-hidden overflow-y-scroll bg-gray-100">
        <the-sidebar-board
          @show-alart="showAlart"
          class="fixed left-0 bg-white rounded shadow-lg top-32 h-4/5"
        />
        <h1 class="relative mx-4 mb-4 font-mono text-xl font-bold text-gray-600">
          {{ pageTitle }}
        </h1>
        <mobile-toolbox />
        <the-loader v-if="state.loading" />
        <router-view
          v-show="!state.loading"
          @start-loading="startLoading"
          @end-loading="endLoading"
          :key="$route.fullPath"
        />
      </main>
      <Footer v-show="scroll.visible" />
    </div>
    <success-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'success'"
      :title="alartState.alartMsg.success.title || 'ボードにファイルを追加しました！'"
      :body="alartState.alartMsg.success.body || '詳細はマイボードから確認してください。'"
    />
    <failure-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'failure'"
      :title="alartState.alartMsg.failure.title || 'ファイルを追加できませんでした！'"
      :body="alartState.alartMsg.failure.body || '詳細はマイボードから確認してください。'"
    />
    <warning-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'warning'"
      :title="alartState.alartMsg.warning.title || '注意してください！'"
      :body="alartState.alartMsg.warning.body || '非公開ボードに属しているユーザが閲覧できるようになります。'"
    />
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import HeaderTop from "../organisms/TheHeaderTop.vue";
import MobileHeaderTop from "../mobile/organisms/HeaderTop.vue";
import TheLoader from "../organisms/utilities/LoaderFiles.vue";
import TheSidebarBoard from "../organisms/TheSidebarBoard.vue";
import TheSidebar from "../organisms/TheNewSidebar.vue";
import SuccessAlart from "../organisms/alarts/SuccessAlart.vue";
import FailureAlart from "../organisms/alarts/FailureAlart.vue";
import WarningAlart from "../organisms/alarts/WarningAlart.vue";
import alart from "../organisms/composables/alart";
import Toolbox from "../organisms/utilities/Toolbox.vue";
import MobileToolbox from "../mobile/atoms/Toolbox.vue";
import Footer from "../mobile/organisms/Footer.vue";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    HeaderTop,
    MobileHeaderTop,
    TheLoader,
    TheSidebarBoard,
    TheSidebar,
    SuccessAlart,
    FailureAlart,
    WarningAlart,
    Toolbox,
    MobileToolbox,
    Footer
  },
  setup() {
    const deviceType = calcBreakpoint(window.innerWidth);

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    const state = reactive({
      loading: true
    });

    const scroll = reactive({
      visible: true,
      prevScrollY: 0,
      currentScrollY: 0
    });

    const handleScroll = () => {
      if (scroll.currentScrollY > 0) {
        scroll.prevScrollY = scroll.currentScrollY;
      }
      scroll.currentScrollY = window.scrollY;
      if (scroll.prevScrollY < scroll.currentScrollY) {
        scroll.visible = false;
      } else if (scroll.prevScrollY > scroll.currentScrollY) {
        scroll.visible = true;
      }
    };
    onMounted (() => {
      window.addEventListener("scroll", handleScroll());
    });

    const pageTitle = computed(() => {
      const route = useRoute();
      const type = route.params?.type
      const fileType = type && type == "slides" ? "スライド" : "パッケージ"
      let title = "";
      switch (route.name) {
        case "home":
          title = "📜 ホーム" + fileType;
          break;
        case "boards":
          title = "ボード" + fileType;
          break;
        case "trend":
          title = "トレンド" + fileType;
          break;
        case "ranking":
          title = "ランク" + fileType;
          break;
        case "needs":
          title = "ニーズ" + fileType;
          break;
        case "search":
          title = "🔎 " + fileType + "検索";
          break;
      }
      return title;
    });

    const startLoading = () => {
      state.loading = true;
    };

    const endLoading = () => {
      state.loading = false;
    };

    const { alartState, showAlart } = alart(); 

    return {
      deviceType,
      state,
      scroll,
      alartState,
      startLoading,
      endLoading,
      showAlart,
      pageTitle
    };
  }
};
</script>


<style scoped>
.scrollbar::-webkit-scrollbar-track {
    display:none;
}
.scrollbar::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 13px;
  background-color: #777;
  box-shadow: inset 0 0 10px 10px #909090;
  border: solid 4px transparent;
}
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: "areaA areaB";
  height: calc(100% - 116px);
}

.sidebar {
  grid-area: areaA;
}

.main {
  grid-area: areaB;
}
</style>