<template>
  <div class="flex flex-col w-screen h-screen">
    <header-top />
    <Suspense>
      <template #default>
        <user-profile-contents />
      </template>
      <template #fallback>
        <loading
          v-model:active="loading"
        />
      </template>
    </Suspense>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import UserProfileContents from "../organisms/UserProfileContents.vue"
import HeaderTop from "../organisms/TheHeaderTop.vue";
export default {
  components: {
    Loading,
    HeaderTop,
    UserProfileContents,
  },
  setup () {
    return { 
      loading: true 
    };
  }
};
</script>
