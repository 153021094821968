<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M224 50h-64a37.994 37.994 0 0 0-32 17.546A37.994 37.994 0 0 0 96 50H32a14.016 14.016 0 0 0-14 14v128a14.016 14.016 0 0 0 14 14h64a26.03 26.03 0 0 1 26 26a6 6 0 0 0 12 0a26.03 26.03 0 0 1 26-26h64a14.016 14.016 0 0 0 14-14V64a14.016 14.016 0 0 0-14-14zM96 194H32a2.003 2.003 0 0 1-2-2V64a2.003 2.003 0 0 1 2-2h64a26.03 26.03 0 0 1 26 26v116.312A37.86 37.86 0 0 0 96 194zm130-2a2.003 2.003 0 0 1-2 2h-64a37.852 37.852 0 0 0-26 10.325V88a26.03 26.03 0 0 1 26-26h64a2.003 2.003 0 0 1 2 2z"
      fill="currentColor"
    ></path>
  </svg>
</template>