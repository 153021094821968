<template>
  <div>
    <!-- ドラッグ＆ドロップによるファイルアップロード対応 -->
    <div
      v-if="isDraggableRoute(routeName)"
      @dragstart="state.draggable = false"
      @dragend="state.draggable = true"
      @dragenter.prevent="onDragEnter"
      @dragleave.prevent="onDragLeave"
      @dragover.prevent
      @drop.prevent="onDrop"
    >
      <div v-if="!state.isReadInfo && state.isExternalWS" class="flex justify-center items-center bg-blue-500 text-white font-bold px-4 py-2 relative text-right" role="alert">
        <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
        <a href="https://extns.notion.site/Spitch-848e915d091e4d45982a10e4150ad29c?pvs=4" class="hover:underline" target="_blank">スライド管理サービス「Spitch」終了のお知らせ（終了日：2024年12月1日）</a>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-2">
          <svg class="fill-current h-6 w-6" role="button" @click="readInfo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
      <router-view :key="$route.path" />

      <transition name="scale">
        <div
          v-if="state.showDropArea"
          class="absolute inset-0 flex items-center justify-center w-2/3 m-auto bg-gray-600 bg-opacity-50 h-60 rounded-xl"
          @dragenter.self="state.active = true"
          @dragleave.self="state.active = false"
          @dragover.prevent
        >
          <div
            class="flex items-center justify-center px-8 py-4 text-3xl font-bold border-2 border-dashed pointer-events-none text-gray-50 border-gray-50"
          >
            <ri-drag-drop-line class="w-10 h-10"/>
            <p class="ml-2">Drop files here</p>
          </div>
        </div>
      </transition>

      <file-upload-modal
        v-if="state.showUploadModal"
        :dropped-files="state.files"
        :board-ids="state.boardIds"
        @close-modal="state.showUploadModal = false"
        @open-success-modal="state.showSuccessModal = true"
        @toggle-loading='state.loading = !state.loading'
      />

      <file-upload-success-modal
        :show-modal="state.showSuccessModal"
        @close-modal="state.showSuccessModal = false"
      />

      <loading
        v-model:active="state.loading"
        color="#818589"
        blur=""
      />
    </div>

    <!-- ドラッグ＆ドロップによるファイルアップロード非対応 -->
    <div v-else>
      <div v-if="state.isExternalWS" class="flex justify-center items-center bg-blue-500 text-white font-bold px-4 py-2 relative text-right" role="alert">
        <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
        <a href="https://extns.notion.site/Spitch-848e915d091e4d45982a10e4150ad29c?pvs=4" class="hover:underline" target="_blank">スライド管理サービス「Spitch」終了のお知らせ（終了日：2024年12月1日）</a>
      </div>
      <router-view :key="$route.path" />
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import FileUploadModal from "./components/organisms/modals/FileUploadModal.vue";
import FileUploadSuccessModal from "./components/organisms/modals/FileUploadSuccessModal.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import RiDragDropLine from "./components/icons/RiDragDropLine.vue"
import calcBreakpoint from "./components/organisms/composables/calcBreakpoint";

export default {
  components: {
    FileUploadModal,
    FileUploadSuccessModal,
    Loading,
    RiDragDropLine
  },

  setup() {
    const state = reactive({
      width: window.innerWidth,
      height: window.innerHeight,
      files: [],
      showDropArea: false,
      showUploadModal: false,
      showSuccessModal: false,
      loading: false,
      active: false,
      draggable: true,
      boardIds: [],
      isReadInfo: Boolean(localStorage.getItem("isReadInfo")),
      isExternalWS: !location.origin.includes("northsand"),
      showInfo: true
    });

    const isOverWidth = computed(() => {
      const threshold = 1024;
      return state.width >= threshold;
      // return true;
    });

    const handleResize = () => {
      state.width = window.innerWidth;
    };

    const route = useRoute();
    const routeName = computed(() => route.name);

    const isDraggableRoute = name => {
      const excludeNames = [
        "Signin",
        "Signup",
        "afterSignUp",
        "VerifyMail",
        "ResetPassword",
        "upload",
        'notFound'
      ];
      return !excludeNames.includes(name);
    };

    /**
     * This is about onDragEnter and onDragLeave method.
     *
     * Need to keep a reference counter to controle drag and drop events.
     * Increment it when you get a dragenter, decrement when you get a dragleave.
     * When the counter is at 0 - set showDropArea false.
     *
     * This is why that dragenter fired when hovering child element before dragleave from parent element.
     * But no more dragenter fired when get out of window, so the counter would be 0 as always.
     *
     * Note: In the drop event, reset counter to zero and set showDropArea false.
     * https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element?page=1&tab=votes#tab-top
     */
    const store = useStore();
    const showSidebar = computed(() => store.state.board.showSidebar);
    const showUploadModal = computed(() => store.state.upload.showModal);
    let counter = 0;

    const onDrop = evt => {
      if (showSidebar.value || !state.showDropArea) return;

      if (routeName.value === 'boardDetail') {
        state.boardIds = [route.params.id];
      }

      const files = Array.from(evt.dataTransfer.files).filter(
        file => file.name.endsWith(".pdf") || file.name.endsWith(".pptx")
      );
      state.files = files;

      counter = 0;
      state.showDropArea = false;
      state.showUploadModal = true;
    };

    const onDragEnter = (e) => {
      /**
       * ドラッギング要素によってアップロードモーダルの表示/非表示を切り替える
       * 
       * [仕様]
       * - ウィンドウ内の要素をドラッグした場合はモーダルを表示させない
       * - ウィンドウ外からドラッグした場合はモーダルを表示させる
       * 
       * [処理]
       * 'state.draggable'を判別の変数とする
       * 
       * 1. デフォルトの'state.draggable'は'true'
       * 2. dragstartイベントで'state.draggable'を'false'に変更
       * 3. dragendイベントで'state.draggable'を'true'に初期化
       * 
       */
      if (!state.draggable) return;
      if (showUploadModal.value) return;
      if (showSidebar.value) return;
      counter++;
      state.showDropArea = true;
      // console.log('enter', counter)
    };

    const onDragLeave = (e) => {
      if (showUploadModal.value) return;
      if (showSidebar.value) return;
      if (counter < 1) return;
      counter--;
      if (counter == 0) {
        state.showDropArea = false;
      }
      // console.log('leave', counter)
    };

    const onDragStart = (e) => {
      return state.draggable = false;
    }

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onBeforeMount(() => {
      window.removeEventListener("resize", handleResize);
    });

    // 横幅を基準に表示形式を変更するため下記は使用しない
    // const isMobile = () => {
    //   const pattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    //   return pattern.test(navigator.userAgent);
    // };

    // Crisp chat
    // const deviceType = calcBreakpoint(window.innerWidth);
    // const crisp = () => {
    //   window.$crisp=[];
    //   window.CRISP_WEBSITE_ID = "e652b4de-cd9d-4001-a646-ff7e6014f157";
    //   const d = document;
    //   const s = d.createElement("script");
    //   s.src = "https://client.crisp.chat/l.js";
    //   s.async = 1;
    //   d.getElementsByTagName("head")[0].appendChild(s);
    // };
    // if (deviceType == "pc")
    //   crisp();

    const readInfo = (() => {
      localStorage.setItem("isReadInfo", true);
      const isReadInfo = localStorage.getItem("isReadInfo");
      state.isReadInfo = isReadInfo;
    })

    return {
      state,
      routeName,
      isOverWidth,
      isDraggableRoute,
      onDrop,
      onDragEnter,
      onDragLeave,
      onDragStart,
      readInfo
    };
  }
};
</script>

<style scoped>
.scale-enter-active {
  animation: scale-up 0.5s;
}

@keyframes scale-up {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>