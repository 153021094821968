<template>
  <operation-modal
    :showModal="true"
    @toggleModal="toggleBoardModal('')"
  >
    <template v-slot:header>ボードに招待する</template>

    <template v-slot:body>
      <div>
        <div class="mb-8" style="">
          <p class="mb-1 text-gray-500">ユーザー選択</p>
          <div class="flex p-1 border">
            <Multiselect
              class="border-none multiselect-gray"
              v-model="state.inviteUsers"
              mode="tags"
              :searchable="true"
              :options="options"
              :canClear="false"
              :caret="false"
              :closeOnSelect="false"
            />
            <select
              id="permission"
              v-model="state.userPermission"
              class="h-8 px-1 py-1 my-1 text-gray-400 bg-transparent rounded right-20 focus:outline-none"
            >
              <option value="member">🔍 メンバー</option>
              <option value="owner">⚙️ 管理者</option>
            </select>
            <button @click="attachUser" class="w-24 h-8 px-4 py-1 mx-2 my-1 text-white bg-gray-400 rounded shadow hover:bg-gray-500 focus:outline-none">追加</button>
          </div>
        </div>
        <div>
          <p class="block mb-1 text-gray-500">参加者</p>
          <div
            v-for="user in boardInfo.participants"
            :key="'A' + user.id"
            class="mb-2 last:mb-0"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <div
                  class="flex items-center justify-center w-8 h-8 border rounded-full "
                >
                  <img
                    v-if="user.photoURL"
                    class="object-cover w-full h-full border-none rounded-full"
                    :src="user.photoURL"
                  />
                  <div
                    v-else
                    class="flex items-center justify-center w-full h-full text-lg rounded-full bg-gray-50"
                  >
                    <span>{{ user?.firstName ? user.firstName.slice(0, 1) : '?' }}</span>
                  </div>
                </div>
                <div class="ml-2">
                  <span>{{ `${user.lastName} ${user.firstName}` }}</span>
                </div>
              </div>
              <div class="flex items-center justify-center w-60">
                <Multiselect
                  class="w-full text-gray-500 h-7"
                  style="min-height: 0px; width: 320px; --ms-option-font-size: text-sm;--ms-border-width: 0px;"
                  v-model="user.permission"
                  :options="[{ label: '⚙️ 管理者', value: 'owner' },{ label: '🔍 メンバー', value: 'member' }]"
                  :canClear="false"
                  :caret="true"
                  @change="changeMemberPermission(user)"
                />
                <button @click="detachUser(user)" class="w-32 p-1 ml-1 text-sm text-white bg-red-500 border-none rounded hover:bg-red-400">削除する</button>
              </div>
            </div>
          </div>
        </div>
        <loading
          v-model:active="state.loading"
          :is-full-page="false"
          color="#818589"
          blur=""
        />
      </div>
    </template>

    <template v-slot:footer>
      <div class="mx-10">
        <div class="w-full mb-4 border"></div>
        <div class="flex justify-end mb-6">
          <button
            @click="cancel"
            class="text-black bg-gray-200 button hover:bg-gray-300"
          >
            キャンセル
          </button>
        </div>
      </div>
    </template>
  </operation-modal>
</template>

<script>
import firebase from "firebase/app";
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import useBoardRepository from "../composables/useBoardRepository";
import OperationModal from "./BaseModal.vue";
import Multiselect from "@vueform/multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    OperationModal,
    Multiselect,
    Loading
  },

  props: {
    toggleBoardModal: {
      type: Function,
      required: true
    },
    changeOperationModalFlag: {
      type: Function,
      required: true
    },
    boardInfo: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      allUsersInWorkspace: [],
      members: [],
      inviteUsers: [],
      permission: "",
      userPermission: "member",
      myUserId: firebase.auth().currentUser.uid,
      loading: false
    });

    const options = computed(() => {
      const memberIds = props.boardInfo.members.map(member => member.id);
      const excludedMembers = store.state.user.users.filter(user =>  { 
        const result = user.id && !memberIds.includes(user.id) && !user.is_deleted;
        if (result) console.debug(user);
        return result;
      }).map(user => {
        return {
          value: user.id,
          label: `${user.lastName} ${user.firstName}`
        };
      });
      return excludedMembers;
    });

    const { addMembers, removeMembers } = useBoardRepository();

    const attachUser = async () => {
      state.loading = true;
      const users = state.inviteUsers.map(userId => {
        return { user_id: userId, permission: state.userPermission };
      });

      const payload = {
        users,
        board_id: props.boardInfo.id,
        url_domain: location.origin
      };
      const result = await addMembers(payload);
      if (result.status === "200") {
        state.inviteUsers = [];  // Should be initialized, or Multiselect output error log.
        emit("board-update", 'invite');
      }
      state.loading = false;
    };

    const detachUser = async user => {
      state.loading = true;
      const users = [{ user_id: user.id, permission: user.permission }];

      const payload = { users, board_id: props.boardInfo.id };
      const result = await removeMembers(payload);
      if (result.status === "200") {
        emit("board-update", 'invite');
      }
      state.loading = false;
    };

    const changeMemberPermission = async (user) => {
      // Need some time for applying data.
      await new Promise(resolve => setTimeout(resolve, 100));
      const users = [{ user_id: user.id, permission: user.permission }];
      const payload = { users, board_id: props.boardInfo.id };
      await addMembers(payload);
    }

    const cancel = () => {
      props.changeOperationModalFlag("edit");
    };

    return {
      state,
      options,
      attachUser,
      detachUser,
      changeMemberPermission,
      cancel
    };
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-gray {
  --ms-tag-bg: #cecdca80;
  --ms-tag-color: #37352f;
  --ms-tag-font-weight: 400;
  --ms-ring-color: #ffffff00;
}
</style>

<style lang="postcss" scoped>
.button {
  @apply shadow border rounded px-3 py-1.5 focus:outline-none;
}
</style>