<template>
  <div class="flex flex-col w-screen h-screen animate-pulse">
    <div class="flex flex-col items-center">
      <div class="bg-gray-300 rounded-full mt-5 mb-2" style="width: 150px; height: 150px"></div>
      <div class="w-28 h-6 mb-3 bg-gray-300 rounded-full title"></div>
      <div class="w-20 h-4 bg-gray-300 rounded-full description"></div>
    </div>
    <!-- <div class="flex flex-col items-center">
      <div class="tabs mb-2">
        <span class="tab-item mr-2 p-2 rounded font-semibold">
          プロフィール
        </span>
        <span class="tab-item mr-2 p-2 rounded font-semibold">
          ボード
        </span>
        <span class="tab-item mr-2 p-2 rounded font-semibold">
          ファイル
        </span>
      </div>
    </div>
    <hr>
    <div>
      <div class="relative flex justify-between mt-4 ml-4 mr-4 font-bold">
        名前
      </div>
      <hr>
      <div class="relative mt-4 ml-4 mr-4 font-bold rounded">
        自己紹介
      </div>
    </div> -->
  </div>
</template>
