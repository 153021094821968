<template>
  <top-analytics-template />
</template>

<script>
import TopAnalyticsTemplate from "../templates/TopAnalyticsTemplate.vue"

export default {
  components: { 
    TopAnalyticsTemplate
  },
};
</script>