<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-show="showUpdateMenu"
      @click.stop
      class="absolute z-20 mx-4 my-2 ml-4 overflow-hidden origin-top-right bg-white border rounded-md shadow-lg w-72 top-4 left-14"
    >
      <!-- This is my own package -->
      <div
        v-if="userId == packageInfo.user.id"
        class="bg-white rounded-md shadow-xs"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <Form
          @submit="updatePackage"
          :initial-values="initialValues"
          :validation-schema="schema"
        >
          <div>
            <h1 class="px-4 py-2 text-base font-bold bg-gray-100 rounded-md">
              ℹ️ パッケージ情報
            </h1>
            <div class="px-4 mt-2">
              <p class="text-gray-600 truncate">作成者: <span class="font-semibold">{{ packageInfo.user.displayName }}</span></p>
              <div class="w-full py-2">
                <label
                  class="block"
                  name="packageName"
                  for="packageName"
                  >パッケージ名:</label
                >
                <Field
                  class="w-full p-1 text-base text-gray-600 break-words border"
                  id="packageName"
                  name="packageName"
                  @focus="onFocus"
                  @blur="onBlur"
                />
                <ErrorMessage class="text-red-600" name="packageName" />
              </div>
              <div class="w-full py-2">
                <label
                  class="block"
                  name="description"
                  for="description"
                  >パッケージの説明:</label
                >
                <Field
                  as="textarea"
                  class="w-full p-1 text-base text-gray-600 break-words border"
                  id="description"
                  name="description"
                  @focus="onFocus"
                  @blur="onBlur"
                />
                <ErrorMessage class="text-red-600" name="description" />
              </div>
              <div class="w-full py-2">
                <p>公開権限</p>
                <Field
                  name="permission"
                  type="radio"
                  id="public"
                  value="public"
                ></Field>
                <label for="public" class="ml-1">
                  <span class="">公開</span>
                </label>
                <Field
                  class="ml-3"
                  name="permission"
                  type="radio"
                  id="limited"
                  value="limited"
                ></Field>
                <label for="limited" class="ml-1">
                  <span class="">限定</span>
                </label>
              </div>
              <div class="w-full py-2">
                <p>ページ数</p>
                <p class="py-1 text-gray-600 break-words">
                  {{ packageInfo.children_ids.length }}
                </p>
              </div>
              <div class="w-full py-1">
                <button class="w-full py-1 text-white bg-gray-500 rounded">
                  <span>変更を保存する</span>
                </button>
                <p
                  v-if="state.modState == 'success'"
                  class="pt-1 text-sm text-green-600"
                >
                  パッケージ情報を変更しました。
                </p>
                <p
                  v-if="state.modState == 'failed'"
                  class="pt-1 text-sm text-red-600"
                >
                  パッケージ情報の変更に失敗しました。
                </p>
              </div>
            </div>
          </div>
        </Form>
        <div class="border-t border-gray-100"></div>
        <div class="py-1">
          <div class="px-4 py-1">
            <button
              class="w-full py-1 text-white bg-red-500 rounded"
              @click="deletePackage"
            >
              <span>パッケージを削除する</span>
            </button>
            <p
              v-if="state.delState == 'failed'"
              class="pt-1 text-sm text-red-600"
            >
              パッケージの削除に失敗しました。
            </p>
          </div>
        </div>
      </div>

      <!-- This is not my own package -->
      <div
        v-else
        class="bg-white rounded-md shadow-xs"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div>
          <div class="px-4 py-2 bg-gray-100">
            <div class="w-full">
              <p class="font-bold">作成者</p>
              <p class="py-1 text-base font-semibold text-gray-600 truncate">
                {{ packageInfo.user.displayName }}
              </p>
            </div>
          </div>
          <div class="px-4 py-2">
            <div class="w-full py-2">
              <p class="font-bold">パッケージ名</p>
              <p class="py-1 text-gray-600 break-words">
                {{ packageInfo.fileTitle }}
              </p>
            </div>
            <div class="w-full py-2">
              <p class="font-bold">説明</p>
              <p v-html="linkify(packageInfo.description)" class="py-1 text-gray-600 break-words"></p>
            </div>
            <div class="w-full py-2">
              <p class="font-bold">権限</p>
              <p class="py-1 text-gray-600 break-words">
                {{ packageInfo.permission }}
              </p>
            </div>
            <div class="w-full py-2">
              <p class="font-bold">ページ数</p>
              <p class="py-1 text-gray-600 break-words">
                {{ packageInfo.children_ids.length }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <loading
        v-model:active="state.loading"
        :is-full-page="false"
        color="#818589"
      />
    </div>
  </transition>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { Field, Form, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
const FileRepository = RepositoryFactoryForGae.get("file");
import Loading from "vue-loading-overlay";
import utility from "../composables/utility";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading
  },

  props: {
    showUpdateMenu: {
      type: Boolean,
      required: true
    },
    packageInfo: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const state = reactive({
      isMenuOpen: false,
      modState: "",
      delState: "",
      loading: false,
      packageInfo: props.packageInfo,
    });

    const store = useStore();
    const userId = computed(() => store.state.auth.userUid);

    const initialValues = {
      packageName: props.packageInfo.fileTitle,
      description: props.packageInfo.description,
      permission: props.packageInfo.permission
    };

    const schema = yup.object({
      packageName: yup
        .string()
        .required()
        .label("パッケージ名"),
      description: yup.string().label("説明"),
      permission: yup
        .string()
        .required()
        .label("公開権限")
    });

    const updatePackage = async values => {
      // Before update
      state.loading = true;

      const payload = {
        packageId: props.packageInfo.packageId,
        name: values.packageName,
        description: values.description,
        permission: values.permission
      };
      await FileRepository.updatePackage(payload)
        .json()
        .then(() => {
          state.modState = "success";
        })
        .catch(error => {
          console.error(error);
          state.modState = "failed";
        });

      // After update
      const newValue = Object.assign(payload, { fileTitle: payload.name });
      emit("updatePackageInfo", newValue);
      state.loading = false;
    };

    const deletePackage = async () => {
      // Before delete
      state.loading = true;

      const packageId = props.packageInfo.packageId;
      const payload = { target: packageId };
      await FileRepository.deletePackage(payload).json()
        .catch(error => {
          console.error(error);
          state.delState = "failed";
        });

      const boardsArray = state.packageInfo.boards;
      if (boardsArray) {
        for (const board of boardsArray) {
          const target = board.pins.filter(p => p.pin_id === packageId);
          if (target.length > 1) console.error(`packageId ${packageId} is duplicated.`);
          const removeTarget = {
            pins: [{ pin_id: packageId, pin_type: target[0].pin_type }],
            board_id: board.id
          };
          await BoardRepository.removePin(removeTarget).json()
            .catch(error => {
              console.error(error);
            });
        }
      }

      // After delete
      state.loading = false;
      emit("deletePackageInfo", packageId);
    };
  
    const onFocus = () => {
      emit("disableKeydown")
    }

    const onBlur = () => {
      emit("enableKeydown")
    }

    const { linkify } = utility();

    return {
      state,
      userId,
      initialValues,
      schema,
      updatePackage,
      deletePackage,
      onFocus,
      onBlur,
      linkify
    };
  }
};
</script>