<template>
  <header class="z-30 w-full bg-gray-600 global-nav">
    <div
      v-if="!displaySearchFlg"
      class="header-top h-14 px-5 py-3 flex items-center"
    >
      <div class="flex h-10 ml-4 mr-3 items-center flex-none transform header-logo hover:-translate-y-px">
        <router-link to="/">
          <logo-horizon width="90" height="20" class="" />
        </router-link>
      </div>
      <!-- <div class="flex flex-none ml-10">
        <div class="text-center">
          <router-link
            :to="{ name: 'upload' }"
            class="flex items-center p-1 mx-1 text-lg text-gray-100 transition-colors duration-150 border-b-2 border-gray-600 font-extralight hover:text-white hover:border-white"
          >
            <file-upload-icon class="w-7 h-7" />
            <span class="mx-1">Upload</span>
          </router-link>
        </div>
      </div> -->
      <div class="flex-auto header-middle">
        <slot name="middle"></slot>
      </div>
      <div class="relative flex items-center">
        <slot name="right"></slot>
      </div>
    </div>
    <div
      v-else-if="displaySearchFlg"
      class="flex header-top h-14 px-2 py-3 justify-between"
    >
      <div
        class="flex w-5/6 ml-4 mr-3 items-center flex-none transform header-logo hover:-translate-y-px"
      >
        <mobile-search-form
          :search-history="state.searchWord"
          @focused="display"
          @get-text="pushText"
        />
      </div>
      <div class="relative flex items-center">
        <slot name="right"></slot>
      </div>
    </div>
  </header>
  <search-history
    v-show="state.displayHistory"
    @search-from-history="searchFromHistory"
  />
</template>

<script>
import LogoHorizon from "../../icons/LogoHorizon.vue";
import MobileSearchForm from "../atoms/MobileSearchForm.vue";
import SearchHistory from "./SearchHistory.vue";

import { reactive, computed } from "vue"
import { useRoute, useRouter } from "vue-router"

export default {
  components: {
    LogoHorizon,
    MobileSearchForm,
    SearchHistory
  },
  emits: [
    "displayHistory",
    "getText"
  ],
  setup(_props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const name = computed(() => route.name);
    const path = computed(() => route.path);
    const state = reactive({
      displayHistory: false,
      words: [],
      searchWord: "",
      inputtedText: ""
    });

    const display = () => {
      if (state.inputtedText) return;
      state.displayHistory = true;
      emit("displayHistory", "searchTop");
    };
    const pushText = text => {
      state.inputtedText = text;
      emit("getText", state.inputtedText);
      if (!state.inputtedText) {
        // 検索ボックスがブランクの場合、履歴表示 & 子に渡している履歴ワードも初期化
        state.searchWord = "";
        setTimeout(() => {
          state.displayHistory = true;
        }, 310);
      } else {
        state.displayHistory = false;
      }
    };
    const searchFromHistory = word => {
      state.searchWord = word;
    };

    const keys = Object.keys(localStorage).filter(
      k => k.includes("word")
    );
    keys.forEach(key => state.words.push(localStorage[key]));

    const pattern = /search/;
    const displaySearchFlg = pattern.test(path.value);
    const cancelSearch = () => {
      router.push({ path: "/" })
    };

    return { 
      name,
      path,
      state,
      displaySearchFlg,
      cancelSearch,
      searchFromHistory,
      display,
      pushText,
    };
  },
};
</script>

<style lang="postcss" scoped>
.active {
  @apply text-gray-900 border-gray-900;
}
</style>