<template>
  <div>
    <div
      v-if="showModal"
      @click.self="callParentMethod"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div class="w-auto max-w-6xl max-h-screen mx-auto my-6">
        <div class="w-full bg-white rounded-lg">
          <div class="p-8">
            <div class="mb-4 header">
              <h1 class="font-bold text-gray-700">メールアドレス変更</h1>
            </div>
            <div class="mb-4 body">

              <div>
                <p class="mb-2 text-base text-gray-700">
                  ログインパスワードを入力してください。<br />認証が完了するとアドレス変更用フォームが表示されます。
                </p>
                <Form
                  @submit="authenticate"
                  :initial-values="authInitialValues"
                  :validation-schema="authSchema"
                >
                  <Field
                    class="field-item"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    autocomplete
                  />
                  <ErrorMessage class="text-red-600" name="password" />
                  <button
                    v-show="!isAuthenticated"
                    class="block w-32 p-2 mt-4 text-center text-white bg-red-500 border rounded  hover:bg-red-600 focus:outline-none"
                  >
                    OK
                  </button>
                </Form>
              </div>

              <div v-show="isAuthenticated" class="my-4">
                <p class="mb-2 text-base text-gray-700">
                  新しいメールアドレスを入力してください。<br />新しいメールアドレスに認証用メールが送信されます。
                </p>
                <Form
                  @submit="send"
                  :initial-values="changeMailInitialValues"
                  :validation-schema="changeMailSchema"
                >
                  <Field
                    class="field-item"
                    id="email"
                    name="email"
                    placeholder="Enter your new email"
                  />
                  <ErrorMessage class="text-red-600" name="email" />
                  <button
                    class="w-32 p-2 mt-4 text-center text-white bg-red-500 border rounded  hover:bg-red-600 focus:outline-none"
                  >
                    OK
                  </button>
                </Form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-70"></div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import firebase from "firebase/app";
import { Form, Field, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja";

export default {
  components: { Form, Field, ErrorMessage },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const email = firebase.auth().currentUser.email;
    const callParentMethod = () => {
      emit("closeModal");
    };

    const changeMailSchema = yup.object({
      email: yup.string().label("メールアドレス").email(),
    });

    const changeMailInitialValues = reactive({
      email: "",
    });

    const authSchema = yup.object({
      password: yup.string().required().min(8).label("パスワード"),
    });

    const authInitialValues = reactive({
      password: "",
    });

    const isAuthenticated = ref(false)
    const authenticate = (values) => {
      const password = values.password
      const credential = firebase.auth.EmailAuthProvider.credential(email, password)
      const user = firebase.auth().currentUser;
      user.reauthenticateWithCredential(credential)
      .then(() => {
        isAuthenticated.value = true;
      })
    }

    const send = (values) => {
      // emit("showSuccessModal");
      const user = firebase.auth().currentUser;
      user.updateEmail(values.email)
      .then(() => {
        user.sendEmailVerification()
        emit("showSuccessModal");
      })
      .catch((error) => {
        console.error(error.message)
      })
    };

    return {
      changeMailSchema,
      changeMailInitialValues,
      authSchema,
      authInitialValues,
      isAuthenticated,
      email,
      authenticate,
      send,
      callParentMethod,
    };
  },
};
</script>

<style lang="postcss" scoped>
.field-item {
  @apply block w-96 px-4 py-2 text-gray-700 shadow border rounded;
}
</style>