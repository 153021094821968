import { computed, reactive } from "vue";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const NotificationRepository = RepositoryFactoryForGae.get("notification");

export default function () {
  const repositoryState = reactive({
    notifications: [],
  });

  const payload = reactive({ limit: 5, cursor: '' });

  const showNotificationLoading = computed(() => {
    return repositoryState.notifications.length >= payload.limit;
  });

  const fetchNotification = async (payload) => {
    const res = await NotificationRepository.fetch(payload).json();
    return res.data;
  };

  const infiniteHandler = async ($state) => {
    let result;
    result = await fetchNotification(payload);

    repositoryState.notifications = [...repositoryState.notifications, ...result]
    if (result.length == payload.limit) {
      $state.loaded();
    } else {
      $state.complete();
    }
  };

  return {
    repositoryState,
    payload,
    showNotificationLoading,
    fetchNotification,
    infiniteHandler,
  }
}