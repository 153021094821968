<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="showAnalytics"
      @click.stop
      class="absolute z-20 text-gray-700 bg-white rounded-md shadow-lg left-20 bottom-4"
      style="min-width: 240px;"
    >
      <div class="relative">
        <h1 class="px-4 py-2 text-base font-bold bg-gray-100 rounded-md">
          📊 アナリティクス
        </h1>
        <div class="relative px-4 py-2 overflow-y-scroll" style="max-height: 240px;">
          <ul>
            <li>インプレッション: <span class="font-semibold">{{ packageInfo.pageview }}</span></li>
            <li>ダウンロード数: <span class="font-semibold">{{ packageInfo.downloaded_count }}</span></li>
            <li>保存されているボード数: <span class="font-semibold">{{ packageInfo.boards?.length ?? 0 }}</span></li>
            <li>いいねしたユーザー(<span class="font-semibold">{{ state.likeList.length }}人</span>):
            <span class="font-semibold">
              <div
                v-if="state.likeList.length != 0"
                class="relative px-4 py-2 overflow-y-scroll"
                style="max-height: 180px;"
              >
                <div
                  v-for="user in state.likeList"
                  :key="user"
                  class="flex items-center justify-between mb-1"
                >
                  <router-link
                    :to="{
                      name: 'user',
                      path: `/users/${user.userId}/profile`,
                      params: {
                        username: user.userId,
                        workspace: state.userInfo.workSpace,
                        id: state.userInfo.id
                      }
                    }"
                    class="flex items-center transition"
                  >
                    <img
                      v-if="user.photoUrl"
                      :src="user.photoUrl"
                      class="object-cover w-6 h-6 border rounded-full"
                    />
                    <div
                      v-else
                      class="flex items-center justify-center object-cover w-6 h-6 border rounded-full"
                    >
                      <span class="text-base">
                        {{ user.userName.slice(0, 1) }}
                      </span>
                    </div>
                    <p class="ml-2 hover:underline">
                      {{ user.userName }}
                    </p>
                  </router-link>
                </div>
              </div>
              <div
                v-else
                class="relative px-4 py-2 overflow-y-scroll" style="max-height: 320px;"
              >
                なし
              </div>
            </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { RepositoryFactoryForGae } from "../../../api/gae/RepositoryFactory";
const UserRepository = RepositoryFactoryForGae.get("user");

export default {
  props: {
    showAnalytics: {
      type: Boolean,
      required: true
    },
    packageInfo: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore();
    const state = reactive({
      userInfo: store.state.user.userInfo,
      packageInfo: props.packageInfo,
      likeList: []
    });

    state.packageInfo.liked_users.filter(async userId => {
      const payload = {
        workspace: state.userInfo.workSpace,
        user_id: userId
      };
      const { data } = await UserRepository.getUserInfoById(payload).json();
      const photoUrl = data[0]?.photoURL;
      const userName = data[0]?.id === state.userInfo.id
        ? (data[0]?.displayName != ""
          ? data[0]?.displayName
          : data[0]?.lastName + " " + data[0]?.firstName) + "(自分)"
        : (data[0]?.displayName != ""
          ? data[0]?.displayName
          : data[0]?.lastName + " " + data[0]?.firstName);
      return state.likeList.push({ userName, userId, photoUrl });
    });
    
    return {
      state
    };
  }
};
</script>

<style lang="postcss" scoped>
.bottom {
  @apply mt-4 top-full left-0;
}

.right {
  @apply ml-4 top-4 left-14;
}
</style>