<template>
  <top-template />
</template>

<script>
import firebase from "firebase/app";
import { useStore } from "vuex";
import useBoardRepository from "../organisms/composables/useBoardRepository";
import TopTemplate from '../templates/TopTemplate'

export default {
  components: { 
    TopTemplate 
  },

  setup() {
    const user = firebase.auth().currentUser;
    const store = useStore();

    // Set board data in global state(vuex).
    const { getMyBoard, getPublicBoard } = useBoardRepository();
    const userInfo = store.state.user.userInfo;
    getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid });
    getPublicBoard({ workSpace: userInfo.workSpace });
  }
};
</script>

<style>
html::-webkit-scrollbar-track,html::-webkit-scrollbar,html::-webkit-scrollbar-thumb  {
    display:none;
}

html {
    overflow: hidden;
}

</style>