<template>
  <div class="animate-pulse">
    <div class="flex justify-between mb-4">
      <div class="w-1/5 h-6 bg-gray-300 rounded"></div>
      <div class="flex items-center justify-center">
        <p>📍</p>
        <div class="w-12 h-6 bg-gray-300 rounded"></div>
      </div>
    </div>
    <div class="flex justify-between mb-4">
      <div class="w-2/5 h-6 bg-gray-300 rounded"></div>
      <div class="w-1/5 h-6 bg-gray-300 rounded"></div>
    </div>

    <div class="grid gap-6 row lg:grid-cols-4 md:grid-cols-3">
      <div class="animate-pulse" v-for="item in [1, 2, 3, 4, 5, 6, 7]" :key="item">
        <div class="relative overflow-hidden rounded">
          <div
            class="bg-gray-300 aspect-w-16 aspect-h-9"
          >
          </div>
        </div>

        <div class="py-2">
          <div class="mb-1">
            <div class="w-3/4 h-4 bg-gray-300 rounded"></div>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center meta-user">
              <div
                class="w-6 h-6 bg-gray-300 border rounded-full"
              ></div>
              <div class="h-4 ml-2 bg-gray-300 rounded w-28"></div>
            </div>
            <div class="flex items-center text-gray-400">
              <span class="w-10 h-4 ml-2 bg-gray-300 rounded"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>