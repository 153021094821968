import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Lazyload } from '@vant/lazyload';
import VTooltip from 'v-tooltip';
import 'floating-vue/dist/style.css'
// import 'v-tooltip/dist/v-tooltip.css'
import '@/assets/index.css'

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app'

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
// import "firebase/functions";
// import "firebase/messaging";
import "firebase/storage";
// import "firebase/performance";
// import "firebase/database";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase JavaScript SDK
const filebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Create application instance
const app = createApp(App)
  .use(router)
  .use(store)
  .use(Lazyload, {
    preLoad: 5,
    error: require('./assets/image-error.svg'),
    loading: require('./assets/image-loading.svg'),
    atempt: 1
  })
  .use(VTooltip)
  .directive('focus', {
    mounted(el) {
      el.focus();
    }
  })

// Error handling
app.config.errorHandler = (err, vm, info) => {
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in
  console.error(err);
};
// Warning handling
app.config.warnHandler = (msg, vm, trace) => {
  // `trace` is the component hierarchy trace
  console.warn(`Warn: ${msg}\nTrace: ${trace}`);
};

app.mount('#app')