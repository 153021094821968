<template>
  <div v-if="deviceType == 'pc'">
    <settings-template />
  </div>
  <div v-if="deviceType == 'mobile'">
    <mobile-settings-template />
  </div>
</template>

<script>
import SettingsTemplate from '../templates/SettingsTemplate.vue'
import MobileSettingsTemplate from '../mobile/templates/Settings.vue'
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    SettingsTemplate,
    MobileSettingsTemplate
  },

  setup() {
    const deviceType = calcBreakpoint(window.innerWidth);

    return {
      deviceType
    }
  },
}
</script>