<template>
  <div
    v-if="showDropdown"
    class="absolute right-0 z-20 mt-2 bg-white rounded-md shadow-xl dropdown-menu top-12 w-80 max-h-80 overflow-y-auto"
  >
    <div v-if="notificationList.length != 0">
      <div
        v-for="notification of notificationList"
        :key="notification.id"
      >
        <router-link
          v-if="notification.url != ''
            && (notification.event_type == 'like' || notification.event_type == 'attachBoard' || notification.event_type == 'addPin')"
          :to="{ path: notification.url }"
        >
          <div
            :class="notification.is_read == true ? 'p-2' : 'bg-yellow-200 p-2'"
            class="hover:bg-gray-300 border-b"
          >
            <div class="grid grid-cols-10 m-1">
              <div
                v-if="notification.event_type == 'like'"
                class="col-span-1"
              >
                <liked-icon class="text-xl" />
              </div>
              <div
                v-else-if="notification.event_type == 'attachBoard'"
                class="col-span-1"
              >
                <attach-board-icon class="text-xl" />
              </div>
              <div
                v-else-if="notification.event_type == 'addPin'"
                class="col-span-1"
              >
                <add-pin-to-board-icon class="text-xl" />
              </div>
              <div class="col-span-9 text-sm">
                {{ notification.message }}
              </div>
            </div>
            <div class="text-xs m-1 mt-2 right-10">
              {{ formatTime(new Date(notification.created_at).toISOString()) }}
            </div>
          </div>
        </router-link>
        <router-link
          v-else-if="notification.url.includes('/package')"
          :to="{ path: notification.url, query: { comment_id: notification.url.split('?comment=')[1] } }"
        >
          <div
            :class="notification.is_read == true ? 'p-2' : 'bg-yellow-200 p-2'"
            class="hover:bg-gray-300 border-b"
          >
            <div class="grid grid-cols-10 m-1">
              <div
                v-if="notification.event_type == 'comment'"
                class="col-span-1"
              >
                <comment-icon class="text-xl" />
              </div>
              <div
                v-else-if="notification.event_type == 'mention'"
                class="col-span-1"
              >
                <mention-icon class="text-xl" />
              </div>
              <div class="col-span-9 text-sm">
                {{ notification.message }}
              </div>
            </div>
            <div class="text-xs m-1 mt-2 right-10">
              {{ formatTime(new Date(notification.created_at).toISOString()) }}
            </div>
          </div>
        </router-link>
        <div
          v-else
          :class="notification.is_read == true ? 'p-2' : 'bg-yellow-200 p-2'"
          class="border-b"
        >
          <div class="grid grid-cols-10 m-1">
            <div
              v-if="notification.event_type == 'release'"
              class="col-span-1"
            >
              <new-release-icon class="text-xl" />
            </div>
            <div
              v-if="notification.event_type == 'like'"
              class="col-span-1"
            >
              <liked-icon class="text-xl" />
            </div>
            <div class="col-span-9 text-sm">
              {{ notification.message }}
            </div>
          </div>
          <div class="text-xs m-1 mt-2 right-10">
            {{ formatTime(new Date(notification.created_at).toISOString()) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- loading -->
    </div>
    <infinite-loading
      v-if="showNotificationLoading"
      spinner="circles"
      @infinite="infiniteHandler"
    >
      <template #no-more>他にお知らせはありません。</template>
      <template #no-results>他にお知らせはありません。</template>
    </infinite-loading>
  </div>
</template>

<script>
import { computed, watchEffect } from "vue";
import infiniteLoading from "vue-infinite-loading";
import NewReleaseIcon from "../../icons/NewReleaseIcon.vue";
import LikedIcon from "../../icons/LikedIcon.vue";
import AttachBoardIcon from "../../icons/AttachBoardIcon.vue";
import AddPinToBoardIcon from "../../icons/AddPinToBoardIcon.vue";
import CommentIcon from "../../icons/CommentProcessingIcon.vue";
import MentionIcon from "../../icons/MentionIcon.vue";
import utility from "../../organisms/composables/utility";

import useNotificationRepository from "../../organisms/composables/useNotificationRepository";

export default {
  components: {
    NewReleaseIcon,
    LikedIcon,
    AttachBoardIcon,
    AddPinToBoardIcon,
    CommentIcon,
    MentionIcon,
    infiniteLoading
  },
  props: {
    showDropdown: {
      type: Boolean,
      required: true
    },
  },
  setup(_props) {
    const {
      repositoryState,
      payload,
      showNotificationLoading,
      fetchNotification,
      infiniteHandler
    } = useNotificationRepository();

    const { formatTime } = utility();

    const notificationList = computed(() => {
      return repositoryState.notifications;
    });

    watchEffect(() => {
      const numNotifications = repositoryState.notifications.length;
      if (numNotifications > 0)
        payload.cursor = repositoryState.notifications[numNotifications - 1].id;
    });

    const fetchNotifications = async () => {
      await fetchNotification(payload).then(result => {
        repositoryState.notifications = result;
      });
      return repositoryState.notifications;
    };
    fetchNotifications();

    return {
      notificationList,
      showNotificationLoading,
      infiniteHandler,
      formatTime,
    };
  }
}
</script>