<template>
  <div class="w-full px-8 pt-6 mb-4 bg-white sm:w-2/3">
    <div class="field">
      <failure-alart
        :show="state.showAlart"
        :title="state.alartTitle"
        :body="state.alartBody"
      />
    </div>
    <Form
      v-slot="{ meta }"
      @submit="signin"
      :initial-values="initialValues"
      :validation-schema="schema"
    >
      <div class="field">
        <label class="field-label" for="email">メールアドレス</label>
        <Field
          class="block field-item"
          id="email"
          name="email"
          placeholder="info@example.com"
        />
        <ErrorMessage class="text-red-600" name="email" />
        <span v-if="!state.isValidEmail" class="text-red-600"
          >許可されていないメールアドレスです。<br />URLを確認してください。</span
        >
      </div>
      <div class="field">
        <label class="field-label" for="password">パスワード</label>
        <Field
          class="field-item"
          id="password"
          name="password"
          type="password"
          placeholder="********"
          autocomplete="on"
          :validateOnInput="true"
        />
        <ErrorMessage class="text-red-600" name="password" />
      </div>
      <div>
        <button
          :disabled="!meta.valid"
          class="w-full p-4 font-bold text-white bg-blue-500 rounded shadow hover:bg-blue-700 focus:outline-none disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          ログイン
        </button>
      </div>
      <div class="text-center">
        <router-link
          :to="{ name: 'ResetPassword' }"
          class="text-sm text-gray-500 hover:text-gray-700"
          >パスワードを忘れた方はこちら</router-link
        >
      </div>
    </Form>
    <loading
      v-model:active="state.loading"
      :is-full-page="false"
      color="#818589"
      blur=""
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Field, Form, ErrorMessage } from "vee-validate";
import yup from "../../locale/custom-ja";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FailureAlart from "./alarts/FailureAlartBase.vue";
import useUserRepository from "./composables/useUserRepository"

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    FailureAlart,
    Loading,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      loading: false,
      showAlart: false,
      alartTitle: "ログインに失敗しました",
      alartBody: "",
      isValidEmail: true
    });

    const initialValues = reactive({
      email: "",
      password: "",
    });

    const schema = yup.object({
      email: yup.string().required().email().label("メールアドレス"),
      password: yup.string().required().min(6).label("パスワード"),
    });

    const { checkEmail } = useUserRepository();

    const signin = async (values) => {
      state.loading = true;

      state.isValidEmail = await checkEmail({ email: values.email })
        .catch(error => {
          state.loading = false
          state.alartBody = "存在しないアカウントです。";
          showAlart();
          throw new Error(error);
        })

      if (!state.isValidEmail) { 
        state.loading = false;
        return;
      }

      firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then(async (userCredential) => {
          // Check if email is verified
          const isVerified = userCredential.user.emailVerified;

          if (!isVerified) {
            router.push({ name: "VerifyMail" });
          } else {
            const redirect = route.query.redirectTo || ""
            if (redirect) {
              return router.push({ path: redirect });
            }
            router.push({ name: "home" });
          }
        })
        .catch((error) => {
          /**
           * Error Handling for signInWithEmailAndPassword method.
           * https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
           */
          switch (error.code) {
            case "auth/invalid-email":
              state.alartBody = "無効なメールアドレスの形式です";
              break;
            case "auth/user-disabled":
              state.alartBody = "無効なアカウントです";
              break;
            case "auth/user-not-found":
              state.alartBody = "存在しないアカウントです";
              break;
            case "auth/wrong-password":
              state.alartBody = "パスワードが異なります";
              break;
            default:
              state.alartBody = "メールアドレスまたはパスワードが異なります";
              break;
          }
          state.loading = false;
          showAlart();
        });
    };

    const showAlart = () => {
      state.showAlart = true;
      setTimeout(() => (state.showAlart = false), 5000);
    };

    return {
      state,
      initialValues,
      schema,
      signin,
    };
  },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply shadow appearance-none border rounded w-full p-4 bg-blue-500 hover:bg-blue-700 text-white font-bold focus:outline-none;
}

.field {
  @apply mb-6;
}

.field-label {
  @apply block mb-1 text-sm text-gray-500;
}

.field-item {
  @apply w-full p-3 text-gray-700 shadow border rounded;
}
</style>