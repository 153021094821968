<template>
  <base-filter-modal
    :showModal="true"
    @filterChannels="filterChannels"
    @toggleModal="closeModal"
  >
    <template v-slot:header>チャンネルを選択する</template>

    <template v-slot:body>
      <div
        style="height: 40vh;"
        class="overflow-y-scroll"
      >
        <div
          v-for="channel in state.filteredChannels"
          :key="channel.id"
          class="flex justify-start ml-6"
        >
          <input
            type="radio"
            name="channel_name"
            :id="channel.id"
            :value="channel.id"
            @change="selectChannel"
            :checked="state.selectedChannels.find((id) => id == channel.id) ? true : false"
          >
          <label
            :for="channel.id"
            class="p-2"
          >
            {{ channel.is_private ? `🔒${channel.name}` : `#${channel.name}` }}
          </label>
        </div>
      </div>
      <loading
        v-model:active="state.loading"
        :is-full-page="false"
        color="#818589"
        blur=""
      />
    </template>

    <template v-slot:footer>
      <div class="mx-10">
        <div class="w-full mt-2 mb-4 border"></div>
        <div class="flex justify-end mb-6">
          <button
            @click="closeModal"
            class="p-2 rounded mr-4 text-black bg-gray-200 button hover:bg-gray-300"
          >
            キャンセル
          </button>
          <button
            @click="saveChannel"
            class="p-2 text-white bg-blue-600 rounded hover:bg-blue-800 disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-not-allowed"
          >
            保存する
          </button>
        </div>
      </div>
    </template>
  </base-filter-modal>
</template>

<script>
import { reactive, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import utility from "../../organisms/composables/utility";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const externalRepository = RepositoryFactoryForGae.get("external");
import BaseFilterModal from "./BaseFilterModal.vue";

export default {
  components: {
    BaseFilterModal,
    Loading
  },
  emits: [
    "closeModal"
  ],
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    },
  },
  setup(_props, { emit }) {
    const store = useStore();

    onBeforeMount(() => {
      getChannelsList();
    });

    const state = reactive({
      filterKeyword: "",
      authUser: store.state.user.userInfo,
      loading: true,
      allChannels: [],
      filteredChannels: [],
      selectedChannels: [],
      selectedSlackChannelId: ""
    });

    const { filterSlackChannelsByKeyword } = utility();

    const getChannelsList = async () => {
      const token = localStorage.getItem("slack_token");
      const { data } = await externalRepository.fetchChannelsList({
          token,
          workspace: state.authUser.workSpace
        }).json();
      state.allChannels = data.all_channels;
      state.filteredChannels = data.all_channels;
      state.selectedChannels.push(data.selected_channels?.upload);
      state.loading = false;
    };

    const selectChannel = async (e) => {
      state.selectedSlackChannelId = e.target.value;
    };

    const saveChannel = async () => {
      state.loading = true;
      const params = {
        token: localStorage.getItem("slack_token"),
        workspace: state.authUser.workSpace,
        target_operation: "upload",
        channel_id: state.selectedSlackChannelId
      };
      await externalRepository.updateSlackChannel(params).json();
      state.loading = false;
    };

    const filterChannels = async (text) => {
      state.filteredChannels = filterSlackChannelsByKeyword(state.allChannels, text);
    };

    return {
      state,
      getChannelsList,
      selectChannel,
      saveChannel,
      filterChannels
    }
  }
};
</script>