<template>
  <div class="max-mobile-height bg-black bg-clip-padding backdrop-filter backdrop-blur bg-opacity-80">
    <div
      class="relative flex flex-col items-center max-h-screen"
      id="modal-content"
    >
      <!-- ヘッダー -->
      <div
        class="absolute top-0 z-50 flex items-center justify-center w-full h-12 px-5 bg-gray-100 vh-heiht filter drop-shadow-xl"
      >
        <div class="absolute left-3">
          <div v-if="packageInfo.extension.pptx" class="inline-flex items-center px-3 py-1 mx-1 text-xs font-bold text-gray-700 bg-gray-200 rounded-lg">PPTX</div>
          <div v-if="packageInfo.extension.pdf" class="inline-flex items-center px-3 py-1 mx-1 text-xs font-bold text-gray-700 bg-gray-200 rounded-lg">PDF</div>
        </div>

        <div class="w-full text-center truncate" style="max-width: 195px;">
          <span class="text-lg font-bold text-gray-900"> {{ packageInfo.fileTitle }} </span>
        </div>

        <div class="absolute right-3">
          <router-link :to="{ name: 'home' }">
            <div class="font-semibold">ホームへ</div>
          </router-link>
        </div>
      </div>

      <!-- パッケージアクション -->
      <div class="absolute top-0 left-0 flex flex-col items-center justify-center w-24 h-screen z-10">

        <!-- パッケージメニュ -->
        <div class="relative flex text-left text-gray-800">
          <button
            @click.stop="
              state.mode == 'update'
                ? (state.mode = '')
                : (state.mode = 'update')
            "
            class="my-4 transition transform bg-white rounded-full shadow-md h-14 w-14 hover:scale-110 hover:bg-gray-100 focus:outline-none"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <img
              v-if="packageInfo.user.photoURL"
              :src="packageInfo.user.photoURL"
              alt="user-icon"
              class="object-cover w-full h-full rounded-full"
            />
            <div
              v-else
              class="flex items-center justify-center w-full h-full text-4xl rounded-full"
            >
              <span>{{
                packageInfo.user?.firstName.slice(0, 1)
              }}</span>
            </div>
          </button>
          <package-update-dropdown
            :show-update-menu="state.mode == 'update'"
            :package-info="packageInfo"
            @update-package-info="updatePackageInfo"
            @enable-keydown="enableKeydown"
            @disable-keydown="disableKeydown"
            @delete-package-info="jumpToHome"
          />
        </div>

        <!-- ボード追加 -->
        <button
          @click.stop="state.pinInfo.target === 'slide' ? state.mode = 'favorite' : state.mode == 'favorite' ? (state.mode = '') : (state.mode = 'favorite'); setPinInfo(packageInfo.id, 'package')"
          class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900 disabled:opacity-50"
          :disabled="!hasAddPinPermission"
        >
          <add-icon class="w-8 h-8" />
        </button>
        <pin-add-dropdown 
          :show-download-menu="state.mode === 'favorite' && state.pinInfo.target === 'package'"
          :pin-info="state.pinInfo"
        />

        <!-- コメント -->
        <button
          @click.stop=""
          class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
        >
          <comment-icon class="w-8 h-8" />
        </button>

        <!-- ダウンロード -->
        <div class="relative flex text-left text-gray-800">
          <button
            @click.stop="state.mode == 'download' ? (state.mode = '') : (state.mode = 'download')"
            class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
          >
            <download-icon class="w-10 h-10" />
          </button>
          <package-download-dropdown
            :show-download-menu="state.mode == 'download'"
            :package-info="packageInfo"
            @update-package-info="updatePackageInfo"
          />
        </div>

        <!-- アナリティクス -->
        <div class="relative flex text-left text-gray-800">
          <button
            @click.stop="state.mode == 'analytics' ? (state.mode = '') : (state.mode = 'analytics')"
            class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
          >
            <chart-icon class="w-10 h-10" />
          </button>
        </div>
        <package-analytics-dropdown 
          :show-analytics="state.mode == 'analytics'"
          :package-info="packageInfo"
        />
      </div>

      <!-- スライド画像 -->
      <div id="scrollable" class="w-screen overflow-y-auto vh-margin-top">
        <div class="mx-2">
          <div
            class="border-b-2 border-l-2 border-r-2 rounded-b vh-heiht"
          ></div>

          <div
            class="relative mt-12 preview-image"
            v-for="(children_id, index) in packageInfo.sorted_children_ids"
            :key="children_id"
            :id="children_id"
            @mouseenter="state.showSlideMenu = true; state.slideMenuIndex = index"
            @mouseleave="!(state.mode === 'favorite' && state.pinInfo.target === 'slide') ? state.showSlideMenu = false : state.showSlideMenu = true"
          >
            <div class="relative">
              <img
                id="modal-slide-image"
                class="object-contain mx-auto border border-opacity-25"
                width="1"
                height="1"
                v-lazy="`${state.baseUrl}${children_id}.jpg`"
                alt="slide"
                style="width: 100%; height: auto; max-height: 85vh;"
              />
              <div class="absolute top-0 right-0" v-show="state.showSlideMenu && state.slideMenuIndex === index">
                <div class="flex items-center justify-center text-white bg-black bg-opacity-20 backdrop-filter backdrop-blur">
                  <!-- サムネイル設定 -->
                  <button 
                    @click.stop="setPackageHeader(children_id)"
                    :disabled="!isCreatedUser"
                    class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <pin-icon class="w-8 h-8" />
                  </button>

                  <!-- ボードに追加 -->
                  <button 
                    @click.stop="state.pinInfo.target === 'package' ? state.mode = 'favorite' : state.mode == 'favorite' ? (state.mode = '') : (state.mode = 'favorite'); setPinInfo(children_id, 'slide')"
                    :disabled="!hasAddPinPermission"
                    class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:opacity-50"
                  >
                    <add-icon class="w-8 h-8" />
                  </button>
                  <pin-add-dropdown 
                    :show-download-menu="state.mode === 'favorite' && state.pinInfo.target === 'slide'"
                    :pin-info="state.pinInfo"
                    placement="bottom"
                  />

                  <!-- スライドダウンロード -->
                  <button 
                    @click.stop="downloadSlide(children_id)" 
                    class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:cursor-not-allowed" 
                    :disabled="!canDownload"
                  >
                    <download-icon class="w-8 h-8" />
                  </button>

                  <!-- URLコピー -->
                  <button @click.stop="copyToClipboard(children_id)" class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white">
                    <clip-icon class="w-8 h-8" />
                  </button>
                </div>
              </div>

              <!-- スタック -->
              <div v-show="state.showSlideMenu && state.slideMenuIndex === index" class="absolute bottom-2 right-2">
                <button class="px-2 py-2 text-white bg-gray-600 rounded cursor-not-allowed">スタックに追加</button>
              </div>

              <!-- ラベル -->
              <div class="absolute bottom-0 ml-2 left-full" v-if="hasShowLabelPermission">
                <div v-for="(label, index) in state.slideLables[children_id]" :key="`${children_id}#${index}`" class="mt-2">
                  <router-link 
                    :to="{ path: '/search/slides', query: { sort: 'created_timestamp', labels: label }}" 
                    class="px-2 py-1 text-sm bg-white rounded"
                  >
                    {{ label }}
                  </router-link>
                </div>
            </div>

            </div>

            <!-- 紐づくボード -->
            <div class="flex items-center justify-start mt-1">
              <div v-for="board in attachedBoardsWithSlide[children_id]" :key="board.id" class="mr-2">
                <router-link :to="{ path: `/boards/${board.id}` }" class="px-2 py-1 text-xs bg-white rounded-full">
                  <span v-if="board.visibility === 'limited'">🔒 </span>#{{ board.name }}
                </router-link>
              </div>
            </div>

            <loading
              v-model:active="state.showLoading"
              :is-full-page="false"
              color="#818589"
            />
          </div>
          <div
            class="border-t-2 border-l-2 border-r-2 rounded-t vh-margin-top vh-heiht"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { reactive, onBeforeMount, onBeforeUnmount, onMounted, computed } from "vue";
import useFileRepository from "../../organisms/composables/useFileRepository";
import PackageUpdateDropdown from "../../organisms/dropdowns/PackageMenuDropdown.vue";
import PackageDownloadDropdown from "../../organisms/dropdowns/PackageDownloadDropdown.vue";
import PackageAnalyticsDropdown from "../../organisms/dropdowns/PackageAnalytics.vue";
import PinAddDropdown from "../../organisms/dropdowns/PinAddDropdown.vue";
import PinIcon from "../../icons/MdiPinOutline.vue";
import ClipIcon from "../../icons/PhPaperclip.vue";
import AddIcon from "../../icons/AddIcon.vue";
import ChartIcon from "../../icons/ChartIcon.vue";
import CommentIcon from "../../icons/CommentIcon.vue";
import DownloadIcon from "../../icons/DownloadIcon.vue";
import utility from "../../organisms/composables/utility";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    PackageUpdateDropdown,
    PackageDownloadDropdown,
    PackageAnalyticsDropdown,
    PinAddDropdown,
    AddIcon,
    ChartIcon,
    CommentIcon,
    DownloadIcon,
    PinIcon,
    ClipIcon,
    Loading
  },

  props: {
    packageInfo: {
      type: Object,
      required: true,
    },
  },

  emits: ["updatePackageInfo"],

  setup(props, { emit }) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const user = firebase.auth().currentUser;

    const state = reactive({
      actEl: null,
      baseUrl: location.origin + "/img/",
      loadedCount: 0,
      showDownloadMenu: false,
      mode: "", // 'download', 'package', 'chart', 'commend', 'favorite',
      showLoading: false,
      showSlideMenu: false,
      slideMenuIndex: null,
      pinInfo: { id: '', permission: props.packageInfo.permission, target: '' },
      slideLables: {}
    });

    const attachedBoardsWithSlide = computed(() => {
      const boards = store.state.board.myBoard.filter(board => {
        const memberIds = [...board.owners, ...board.members]
        return board.visibility === 'public' || (board.visibility === 'limited' && memberIds.includes(user.uid));
      });

      const data = props.packageInfo.children_ids.reduce((a, c) => {
        if (!(c in a)) {
          a[c] = boards.filter(board => board.pins.some(pin => pin.pin_id === c && pin.pin_type === 'slide'));
        }
        return a;
      }, {});

      return data;
    })

    const isCreatedUser = computed(() => {
      return props.packageInfo.created_by === user.uid;
    })

    const hasAddPinPermission = computed(() => { 
      return !(props.packageInfo.permission === 'limited' && props.packageInfo.created_by !== user.uid)
    });    

    const hasShowLabelPermission = computed(() => {
      const isCreatedUser = props.packageInfo.created_by === user.uid;
      const isVisibleUser = props.packageInfo.visible_user.includes(user.uid);
      const isLimitedPackage = props.packageInfo.permission === 'limited';
      return !(!isCreatedUser && !isVisibleUser && isLimitedPackage);
    })

    // Shrotcut trigger
    let scFlag = true;
    const handleKeydown = (event) => {
      scFlag = false;
      switch (event.key) {
        case "ArrowUp":
          if (state.actEl.previousElementSibling.id) {
            state.actEl = state.actEl.previousElementSibling;
            scrollToAnchorPoint(state.actEl);
          }
          break;
        case "ArrowDown":
          if (state.actEl.nextElementSibling.id) {
            state.actEl = state.actEl.nextElementSibling;
            scrollToAnchorPoint(state.actEl);
          }
          break;
      }
      setTimeout(() => { scFlag = true }, 1000);
    };

    /**
     * スクロール終了時に処理を実行する
     * 
     * スクロールイベントが連続して発火しても
     * 最後以外はクリアする。
     * 
     * Credits: https://lab.syncer.jp/Web/JavaScript/Snippet/46/
     */
    let timeoutId;
    const handleScroll = () => {
      if (scFlag) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          const st = document.getElementById('scrollable').scrollTop;
          const targets = Array.from(document.querySelectorAll('.preview-image'));
          state.actEl = targets.find(e => e.offsetTop > st).previousElementSibling;
        }, 500);
      }
    } 

    const scrollToAnchorPoint = (el) => {
      el.scrollIntoView({ block: "center", behavior: "auto" });
    };

    const handlePopstate = (e) => {
      emit('toggle');
    }

    onBeforeMount(() => {
      window.addEventListener("keydown", handleKeydown);
      window.addEventListener("popstate", handlePopstate);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("popstate", handlePopstate);
      window.removeEventListener("scroll", handleScroll);
    });

    const enableKeydown = () => {
      window.addEventListener("keydown", handleKeydown);
    }

    const disableKeydown = () => {
      window.removeEventListener("keydown", handleKeydown);
    }

    onMounted(() => {
      const targetId = route.hash.slice(1)
      if (targetId) {
        state.actEl = document.getElementById(targetId);
      } else {
        state.actEl = document.getElementById(props.packageInfo.sorted_children_ids[0]);
      }

      setTimeout(() => {
        scrollToAnchorPoint(state.actEl);
      }, 10);

      setTimeout(() => {
        document.getElementById('scrollable').addEventListener("scroll", handleScroll);
      }, 30);
    });

    const getSlideLabels = async (slideId) => {
      const slideInfo = await getSlideInfo({ target: slideId });
      state.slideLables[slideId] = slideInfo.labels;
    };

    const updatePackageInfo = (newValue) => {
      emit("updatePackageInfo", newValue);
    };

    const jumpToHome = () => {
      router.push({ name: 'home' });
    }

    const { getSlideInfo, downloadFile, updatePackageHeader } = useFileRepository();

    const canDownload = computed(() => {
      const permission = props.packageInfo?.permission;
      // return true if the permission is public.
      if (permission === "public") return true; 

      // permission is any other 'public' and the user is one of visible users.
      return props.packageInfo?.visible_user.includes(user.uid);
    })

    /// Slide Section ///
    const downloadSlide = async (slideId) => {
      // Before download
      state.showLoading = true;
      try {
        const slideInfo = await getSlideInfo({ target: slideId });
        const target = slideInfo.extension.pdf ? slideInfo.pdf_path : slideInfo.extension.pptx ? slideInfo.pptx_path : '';
        await downloadFile({ target, type: 'slide' });
      } finally {
        // After download
        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;
      }
    };

    const setPackageHeader = async (slideId) => {
      // Before update package header
      state.showLoading = true;
      try {
        await updatePackageHeader({ packageId: props.packageInfo.id, headerId: slideId });
        // After update package header
        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;

        await new Promise(resolve => setTimeout(resolve, 500));
        alert('パッケージのサムネイルを更新しました。')
      } catch(error) {
        console.error(error);

        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;
      }
    }

    const { copyToClipboard } = utility();

    const setPinInfo = (id, target) => {
      state.pinInfo.id = id;
      state.pinInfo.target = target;
    }

    return {
      state,
      attachedBoardsWithSlide,
      isCreatedUser,
      hasAddPinPermission,
      hasShowLabelPermission,
      getSlideLabels,
      updatePackageInfo,
      enableKeydown,
      disableKeydown,
      jumpToHome,
      canDownload,
      downloadSlide,
      setPackageHeader,
      copyToClipboard,
      setPinInfo
    };
  },
};
</script>

<style scoped>
*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 13px;
  background-color: #fff;
  box-shadow: inset 0 0 10px 10px #909090;
  border: solid 4px transparent;
}

.vh-margin-top {
  margin-top: 4vh;
}

.vh-heiht {
  height: 4vh;
}

.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>