import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION;
const resource = "search";
export default {
  package(payload) {
    return Repository.post(`${version}/${resource}/package`, { json: payload });
  },

  slide(payload) {
    return Repository.post(`${version}/${resource}/slide`, { json: payload });
  },

  board(payload) {
    return Repository.post(`${version}/${resource}/board`, { json: payload });
  },

  boardWithMembers(payload) {
    return Repository.post(`${version}/${resource}/boardWithMembers`, { json: payload });
  },

  tokenize(payload) {
    return Repository.post(`${version}/${resource}/tokenize`, { json: payload });
  },

  saveSearchAuditLog(payload) {
    return Repository.post(`${version}/${resource}/save_search_auditlog`, { json: payload });
  },
};