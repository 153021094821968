<template>
  <div class="mb-6">
    <h1 class="font-mono text-4xl font-bold">連携設定</h1>
  </div>
  <div
    v-if="state.authUser.role != 'admin'"
  >
    表示できる権限がありません。ワークスペースの管理者の方にお問い合せください。
  </div>
  <div
    style="width: 80vh;"
    v-else-if="state.authUser.role == 'admin'"
    class="border rounded-lg p-4"
  >
    <div class="flex justify-between">
      <div class="flex justify-center items-center">
        <img src="../../../../public/slack-logo.png" class="h-6" alt="logo" />
        <div class="font-bold text-2xl ml-2">
          Slack
        </div>
      </div>
      <div class="flex">
        <div v-if="state.slack.authCheckStatus">
          <button
            v-if="!state.slack.isAuthorized"
            class="p-1 text-white bg-indigo-600 rounded"
            @click="startSlackAuthorization"
          >
            連携する
          </button>
          <button
            v-if="state.slack.isAuthorized"
            class="p-1 text-white bg-red-600 rounded"
            @click="revoke"
          >
            解除する
          </button>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <div class="flex justify-center items-center">
        アップロード通知
      </div>
      <button
        :disabled="!state.slack.isAuthorized"
        class="p-1 text-white rounded"
        :class="state.slack.isAuthorized ? 'bg-gray-500' : 'bg-gray-200'"
        @click="showChannels"
      >
        チャンネルを選択
      </button>
    </div>
  </div>
  <slack-channels-list-modal
    v-if="state.slack.showChannelsListModal"
    :showModal="state.slack.showChannelsListModal ? true : false"
    :closeModal="closeSlackChannelsListModal"
  />
</template>

<script>
import { reactive, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const externalRepository = RepositoryFactoryForGae.get("external");
import SlackChannelsListModal from "../modals/SlackChannelsListModal.vue";
const SLACK_AUTHZ_URL = "https://slack.com/oauth/v2/authorize";
const GAE_SLACK_AUTHZ_URL = `${process.env.VUE_APP_GAE_DOMAIN}/api/v1/slack/authorization`;

export default {
  components: {
    SlackChannelsListModal
  },
  setup() {
    onBeforeMount(() => {
      checkSlackToken();
    });

    const checkSlackToken = async () => {
      const { data } = await externalRepository.checkSlackAuthorization({
        workspace: state.authUser.workSpace
      }).json();
      state.slack.isAuthorized = data.is_authorized;
      if (state.slack.isAuthorized) {
        localStorage.setItem("slack_token", data.access_token);
      } else {
        state.slack.clientId = data.client_id;
        state.slack.state = data.state;
        state.slack.scopes = data.scopes;
      }
      state.slack.authCheckStatus = true;
    };

    const store = useStore();
    const state = reactive({
      authUser: store.state.user.userInfo,
      slack: {
        isAuthorized: false,
        clientId: "",
        clientSecret: "",
        scopes: "",
        state: "",
        authCheckStatus: false,
        showChannelsListModal: false
      }
    });

    const startSlackAuthorization = () => {
      const GAE_SLACK_AUTHZ_URI = `${GAE_SLACK_AUTHZ_URL}?workspace=${state.authUser.workSpace}`;
      const url = `${SLACK_AUTHZ_URL}?scope=${state.slack.scopes}&client_id=${state.slack.clientId}&redirect_uri=${GAE_SLACK_AUTHZ_URI}&state=${state.slack.state}`
      window.location.href = url;
    };

    const revoke = async () => {
      await externalRepository.revokeSlackToken({
          workspace: state.authUser.workSpace
        })
        .json();
      state.slack.isAuthorized = false;
      localStorage.removeItem("slack_token");
      state.slack.authCheckStatus = true;
    };

    const showChannels = async () => {
      state.slack.showChannelsListModal = true;
    }

    const closeSlackChannelsListModal = async () => {
      state.slack.showChannelsListModal = false;
    }

    return {
      state,
      startSlackAuthorization,
      revoke,
      showChannels,
      closeSlackChannelsListModal
    }
  }
}
</script>
