import firebase from "firebase/app";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const UserRepository = RepositoryFactoryForGae.get("user");

export default {
  namespaced: true,
  state: {
    userInfo: {},
    users: [],
  },
  mutations: {
    setUser(state, userInfo) {
      state.userInfo = userInfo;
    },
    setAllUser(state, users) {
      state.users = users;
    },
  },
  actions: {
    async setUser({ commit }, payload) {
      const userInfo = await UserRepository.getUserInfo(payload).json();
      commit("setUser", userInfo);
    },
    async getUserInfo({ commit }, data) {
      const usersRef = firebase
        .firestore()
        .collection("dataStore")
        .doc(data.workSpace)
        .collection("users")
        .doc(data.userId);
      const userInfo = await usersRef.get().then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          throw new Error(`No such a userInfo: ${data.userId}`);
        }
      });
      commit("setUser", userInfo);
      return userInfo;
    },
    async getUsers({ commit }, data) {
      const userRef = firebase
        .firestore()
        .collection("dataStore")
        .doc(data.workSpace)
        .collection("users");
      const users = await userRef
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.data()));
      commit("setAllUser", users);
      return users;
    },
    async getSignupParamsDecryptionKey({ commit }, data) {
      const email = data.email;
      const mailRef = firebase.firestore().collection("mail");
      const results = await mailRef
        .where("type", "==", "invite-user")
        .where("to", "==", email)
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.data()));
      results.sort((a, b) => b.created_timestamp - a.created_timestamp);
      return results[0].salt;
    },
  },
};
