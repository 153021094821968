<template>
  <div class="flex sticky border bg-gray-600">
    <nav class="w-full flex flex-wrap content-center py-0.5 px-2 mx-2 header-nav h-15 text-white">
      <router-link
        to="/"
        class="nav-item w-1/3"
      >
        <div class="flex justify-center">
          <home-icon class="nav-icon"/>
        </div>
        <div class="nav-label text-xs text-center">HOME</div>
        <hr v-if="$route.fullPath == '/'">
      </router-link>
      <router-link
        to="/search"
        class="nav-item w-1/3"
      >
        <div class="flex justify-center">
          <footer-search-icon class="nav-icon text-center" />
        </div>
        <div class="nav-label text-xs text-center">SEARCH</div>
        <hr v-if="$route.fullPath.includes('/search')">
      </router-link>
      <router-link
        to="/boards"
        class="nav-item w-1/3"
      >
        <div class="flex justify-center">
          <bookmark-icon class="nav-icon"/>
        </div>
        <div class="nav-label text-xs text-center">BOARD</div>
        <hr v-if="$route.fullPath.includes('/boards')">
      </router-link>
    </nav>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import HomeIcon from "../../icons/HomeIcon.vue";
import FooterSearchIcon from "../../icons/FooterSearchIcon.vue";
import BookmarkIcon from "../../icons/BookmarkIcon.vue";

export default {
  components: {
    HomeIcon,
    FooterSearchIcon,
    BookmarkIcon
  },
  setup() {
    const route = useRoute();
    const path = route.name;
    const showSearch = () => {
      const showComponents = [
        "home",
        "search",
        "notice",
        "board"
      ];
      return showComponents.includes(path);
    };
    return { path, showSearch };
  },
};
</script>

<style lang="postcss" scoped>
.link {
  @apply rounded p-2 text-xl text-gray-600 hover:text-gray-700 hover:bg-gray-200 transition-colors duration-300 md:mx-2 lg:px-4;
}
/* .tabbar {
  @apply w-320px
} */
</style>