<template>
  <transition name="hamburger" appear>
    <button
      v-if="!showSidebar"
      @click="toggleSidebar"
      class="fixed left-0 z-40 px-3 py-2 my-1 rounded top-2 focus:outline-none"
    >
      <hamburger-icon />
    </button>
  </transition>
</template>

<script>
import HamburgerIcon from "../../icons/HamburgerIcon.vue"

export default {
  components: {
    HamburgerIcon,
  },

  props: {
    showSidebar: Boolean,
  },

  setup(props, { emit }) {
    const toggleSidebar = () => {
      emit("toggle-sidebar");
    };

    return {
      toggleSidebar,
    }
  },
}
</script>