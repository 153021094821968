<template>
  <div class="mx-2">
    <!-- tab section -->
    <div class="mb-4">
      <ul class="list-reset flex border-b">
        <li
          @click="changeTab('packages')"
          class="-mb-px mr-1"
        >
          <div
            :class="state.selectedTab == 'packages' ?
              'bg-white border-l border-t border-r text-black' : 'bg-gray-200'
            "
            class="inline-block rounded-t py-1.5 px-2 text-gray-400 font-semibold"
          >
            Packages
          </div>
        </li>
        <li
          @click="changeTab('slides')"
          class="-mb-px mr-1"
        >
          <div
            :class="state.selectedTab == 'slides' ?
              'bg-white border-l border-t border-r text-black' : 'bg-gray-200'
            "
            class="inline-block rounded-t py-1.5 px-2 text-gray-400 font-semibold"
          >
            Slides
          </div>
        </li>
        <li
          @click="changeTab('boards')"
          class="-mb-px mr-1"
        >
          <div
            :class="state.selectedTab == 'boards' ?
              'bg-white border-l border-t border-r text-black' : 'bg-gray-200'
            "
            class="inline-block rounded-t py-1.5 px-2 text-gray-400 font-semibold"
          >
            Boards
          </div>
        </li>
        <li
          @click="changeTab('users')"
          class="-mb-px mr-1"
        >
          <div
            :class="state.selectedTab == 'users' ?
              'bg-white border-l border-t border-r text-black' : 'bg-gray-200'
            "
            class="inline-block rounded-t py-1.5 px-2 text-gray-400 font-semibold"
          >
            Users
          </div>
        </li>
      </ul>
    </div>
    <div class="font-mono mx-2 mb-4 text-md text-gray-600">
      <span class="font-bold">
        {{ state.searchText }}
      </span>
      <span>
        での検索結果 ({{ state.nbHits }}件)
      </span>
    </div>
    <div
      v-if="files.length == 0 && state.nbHits == 0"
      class="mx-2 mt-4 font-mono"
    >
      検索結果が見つかりませんでした :(
    </div>
    <div
      v-if="state.fileType != 'boards' && state.fileType != 'users'"
      class="grid gap-4 mx-2 row grid-cols-1"
    >
      <div
        v-for="(file, index) in files"
        :key="`ga${file.id}`"
        class="bg-white rounded shadow-md"
        draggable="true"
        @dragstart="onDragStartFromTop($event, file)"
        @dragend="onDragEnd"
      >
        <div
          @click.prevent="getContents(file)"
          :class="!file.user ? 'pointer-events-none cursor-wait' : 'cursor-pointer'"
        >
          <div class="relative overflow-hidden rounded-t preview bg-gray-50">
            <div
              v-if="!file.slideId"
              class="absolute inset-0 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9 filter blur-xs contrast-50">
            </div>
            <div
              v-bind:style="{
                backgroundImage: 'url(' + state.baseUrl + file.slideId + '.jpg)'
              }"
              class="absolute inset-0 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9 filter blur-xs contrast-50"
            ></div>
            <div
              v-bind:style="{
                backgroundImage: 'url(' + state.baseUrl + file.slideId + '.jpg)'
              }"
              class="bg-center bg-no-repeat bg-contain preview-thumbnail aspect-w-16 aspect-h-9"
              v-on:mouseover.self="
                state.hoverFlag = true;
                state.hoverIndex = index;
              "
            ></div>
            <div
              v-show="state.hoverFlag && index === state.hoverIndex"
              class="absolute inset-0 transition hover:bg-black hover:bg-opacity-30 "
              v-on:mouseleave.self="state.hoverFlag = false"
            >
              <div
                v-if="boards.length > 0 && hasAddPinPermission(file)"
                class="relative"
              >
                <div class="absolute top-0 flex justify-end w-full p-2">
                  <button
                    @click.stop="toggleOperationModal(file)"
                    class="flex items-center justify-center my-1 text-sm text-white hover:text-gray-200 focus:outline-none "
                    type="button"
                  >
                    <span>他のボードに保存</span>
                    <round-keyboard-arrow-down />
                  </button>
                  <button
                    v-if="!hasFavPin(file)"
                    @click.stop="addPin(file)"
                    class="px-2 text-sm text-white transition-all duration-150 ease-linear border border-gray-200 rounded-full outline-none bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 hover:bg-gray-700 focus:outline-none"
                  >
                    <favorite-icon />
                  </button>
                  <button
                    v-else
                    @click.prevent
                    class="px-2 text-sm text-white transition-all duration-150 ease-linear bg-gray-300 rounded-full outline-none focus:outline-none"
                  >
                    お気に入り保存済
                  </button>
                </div>
              </div>
            </div>
            <div
              class="absolute outline-none top-3 left-3 filter drop-shadow-xl focus:outline-none"
              v-show="file.permission === 'limited'"
            >
              <lock-icon
                class="w-5 h-5 text-yellow-500 stroke-current stroke-2"
              />
            </div>
          </div>
        </div>
        <div class="px-2 py-1 text-gray-800 meta">
          <div class="flex items-center justify-between mb-1 meta-top">
            <p class="text-xs font-medium truncate" style="max-width: 120px">
              {{
                state.fileType === "slides"
                  ? `[${file.slide_number}]` + file.parent_name
                  : file.fileTitle
              }}
            </p>
          </div>

          <div
            class="flex items-center justify-between mb-1.5 text-gray-400 meta-buttom"
          >
            <router-link
              v-if="file.user"
              class="flex items-center transition meta-user hover:text-gray-600 group"
              tabindex="-1"
              :to="{ name: 'user', path: `/users/${file.user.username}/profile`, params: { username: file.user.username, workspace: file.user.workSpace, id: file.user.id } }"
            >
              <img
                v-if="file.user.photoURL"
                :src="file.user.photoURL"
                alt=""
                class="object-cover w-5 h-5 border rounded-full"
              />
              <div
                v-else
                class="flex items-center justify-center object-cover w-5 h-5 border rounded-full"
              >
                <span class="text-xs">{{
                  file.user.firstName.slice(0, 1)
                }}</span>
              </div>

              <p
                v-if="file.user.displayName"
                class="ml-1 text-xs truncate group-hover:underline"
              >
                {{ file.user.displayName }}
              </p>
              <p v-else class="ml-2 text-xs truncate">
                {{ `${file.user.lastName} ${file.user.firstName}` }}
              </p>
              <!-- <p class="ml-2 text-xs truncate">
                {{ formatTime(file.created_timestamp) }}
              </p> -->
            </router-link>
            <div
              v-else
              class="flex items-center transition meta-user hover:text-gray-600 group"
            >
              <div
                class="flex items-center justify-center bg-gray-200 object-cover w-5 h-5 border rounded-full"
              ></div>
              <div
                class="w-12 h-3 ml-1 truncate bg-gray-200 rounded-full"
              ></div>
            </div>
            <div class="flex items-center">
              <div class="flex">
                <page-number-icon class="w-4 h-4" />
                <span class="ml-1 text-xs">{{ file.children_ids?.length }}</span>
              </div>
              <div class="flex">
                <eye-icon class="w-4 h-4 ml-2" />
                <span class="ml-1 text-xs">{{ file.pageview }}</span>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-0.5">
            <button
              v-if="boards.length > 0 && hasAddPinPermission(file)"
              @click.stop="toggleOperationModal(file)"
              class="flex mr-1.5 border border-gray-400 rounded transition hover:text-white hover:border-blue-400 hover:bg-blue-400 focus:outline-none "
              type="button"
            >
              <plus class="w-4 h-4 p-0.5" />
            </button>
            <button
              v-if="!(boards.length > 0 && hasAddPinPermission(file))"
              class="flex mr-1.5 border border-gray-300 bg-gray-200 rounded cursor-not-allowed "
            >
              <plus class="w-4 h-4 p-0.5" />
            </button>
            <div v-if="file.user" class="flex overflow-x-scroll">
              <div
                v-for="board in file.boards"
                :key="board.id"
                class="flex-none mr-1.5 text-xs text-blue-400 truncate transition hover:text-blue-600 hover:underline"
                style="max-width: 170px"
              >
                <router-link
                  v-if="board.visibility === 'public'"
                  :to="{ path: `/boards/${board.id}` }"
                >
                  #{{ board.name }}
                </router-link>
                <router-link
                  v-if="
                    board.visibility === 'limited' &&
                      [
                        ...board.owners,
                        ...board.members
                      ].includes(state.user.uid)
                  "
                  :to="{ path: `/boards/${board.id}` }"
                >
                  🔒{{ board.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="state.fileType == 'boards'"
      class="mx-2"
    >
      <p
        v-if="files.length != 0"
        class="text-sm text-gray-500 mb-4"
      >
        参加可能なパブリックボードを表示しています。
      </p>
      <div
        class="grid grid-cols-1 gap-4"
      >
        <div
          v-for="(board) in files"
          :key="`ga${board.id}`"
          class="transition transform bg-white border rounded shadow-md hover:shadow-xl hover:-translate-y-px"
        >
          <router-link
            :to="{
              name: 'boardDetail',
              params: { id: board.id }
            }"
          >
            <div
              v-on:mouseleave="onMouseLeaveMyBoard"
              class="flex overflow-hidden rounded bg-gray-50 preview"
            >
              <div v-if="board.coverURL">
                <div
                  v-bind:style="{ backgroundImage: 'url(' + board.coverURL + ')'}"
                  class="w-40 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9"
                ></div>
              </div>
              <div v-else>
                <div
                  v-bind:style="{ backgroundImage: board.pins?.length > 0 ? 'url(' + state.baseUrl + board.thumbnail + `.jpg)`: 'url(' + require('@/assets/board-thumbnail.png') + ')'}"
                  class="inset-0 w-40 bg-center bg-no-repeat bg-cover border-r preview-background aspect-w-16 aspect-h-9"
                ></div>
              </div>
              <div class="flex justify-between w-full px-2 py-1">
                <div>
                  <p class="mb-1 font-bold text-gray-600 text">
                    {{ board.visibility === "limited" ? "🔒" : "#"}}{{ board.name }}
                  </p>
                  <p class="text-xs text-gray-500">
                    {{ board.description }}
                  </p>
                </div>
                <div class="text-sm">
                  <div class="flex text-gray-400">
                    <pins-icon class="w-5 h-5 current-color" />
                    <span>{{board.pins?.length}}</span>
                  </div>
                  <div
                    class="flex text-gray-400"
                  >
                    <users-icon class="w-5 h-5 current-color" />
                    <span>
                      {{
                        calcMembersNumber(board.owners, board.members)  ?? 0
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div
      v-if="state.fileType == 'users'"
      class="grid gap-4 mx-2"
    >
      <div
        v-for="(user) in files"
        :key="`ga${user.id}`"
        class="grid grid-cols-3 bg-white rounded-xl shadow-md p-3"
      >
        <div class="col-span-1 ml-2">
          <img
            v-if="user.photoURL"
            :src="user.photoURL"
            class="object-cover w-20 h-20 border rounded-full"
          />
          <div
            v-else
            class="flex items-center justify-center object-cover w-20 h-20 border rounded-full"
          >
            <span class="text-5xl text-gray-500">
              {{ user.firstName?.slice(0, 1) }}
            </span>
          </div>
        </div>
        <div class="grid grid-rows-2 col-span-2 ml-2">
          <div class="row-span-2">
            <div class="font-bold text-lg">
              {{ user.lastName + " " + user.firstName }}
            </div>
            <div class="mb-2">
              {{ "@" + user.username }}
            </div>
            <div class="text-gray-500">
              {{ user.department }}
            </div>
            <div class="text-gray-500">
              {{ user.position }}
            </div>
          </div>
          <div class="row-span-1 flex justify-end">
            <button
              @click="transitProfilePage(user.username)"
              class="p-1.5 mt-4 bg-indigo-500 text-white rounded-lg"
            >
              プロフィールへ
            </button>
          </div>
        </div>
      </div>
    </div>

    <operation-add-pin-modal
      :show-pin-modal="state.addPinModalFlag"
      :close-pin-modal="toggleOperationModal"
      :file="state.selectedPin"
      @update-boards="updateBoard"
    />

    <div v-for="file in files" :key="file.sildeId">
      <preview-modal
        v-if="file.slideId && state.previewId == file.slideId"
        :anchor_point="state.anchorPoint"
        :package-info="state.packageInfo"
        :fade-mode="state.fadeMode"
        @update-package-info="updatePackageInfo"
        @delete-package-info="deletePackageInfo"
        @toggle="closePreviewModal"
        @switch-package="switchPackage"
      ></preview-modal>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { reactive, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepositoryForGae = RepositoryFactoryForGae.get("file");
const BoardRepository = RepositoryFactoryForGae.get("board");
import useBoardRepository from "../../organisms/composables/useBoardRepository";
import utility from "../../organisms/composables/utility";
import PreviewModal from "../../mobile/modals/PreviewModal.vue";
import OperationAddPinModal from "../../organisms/modals/PinModalAdd.vue";

import RoundKeyboardArrowDown from "../../icons/ArrowDown.vue";
import EyeIcon from "../../icons/EyeIcon.vue";
import Plus from "../../icons/Plus.vue";
import PageNumberIcon from "../../icons/PageNumberIcon.vue";
import FavoriteIcon from "../../icons/FavoriteIcon.vue";
import LockIcon from "../../icons/LockIcon.vue";
import UsersIcon from "../../icons/UsersIcon.vue";
import PinsIcon from "../../icons/PinsIcon.vue";

export default {
  components: {
    PreviewModal,
    OperationAddPinModal,
    RoundKeyboardArrowDown,
    EyeIcon,
    Plus,
    LockIcon,
    FavoriteIcon,
    PageNumberIcon,
    UsersIcon,
    PinsIcon
  },

  props: {
    files: Array,
    fileType: String,
    searchText: String,
    nbHits: [String, Number],
  },

  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const user = firebase.auth().currentUser;

    const state = reactive({
      fileType: props.fileType,
      searchText: props.searchText,
      nbHits: props.nbHits,
      baseUrl: `${location.origin}/img/`,
      selectedTab: "packages",
      hoverFlag: false,
      hoverIndex: null,
      addPinModalFlag: false,
      selectedPin: {},
      anchorPoint: "",
      previewModalFlag: false,
      packageInfo: {},
      previewId: null,
      fadeMode: "center",
      boards: [],
      user: user,
      allHistory: []
    });

    const boards = computed(() => store.state.board.myBoard);
    const favBoard = computed(() =>
      store.getters["board/defaultBoards"].find(x => x.name === "お気に入り")
    );
    const calcMembersNumber = (owners, members) => {
      const membersNumber = owners?.length + members?.length;
      return membersNumber;
    };
    watchEffect(() => {
      state.searchText = props.searchText;
      state.nbHits = props.nbHits;
      state.fileType = props.fileType;
    });

    const { getMyBoard } = useBoardRepository();
    const userInfo = store.state.user.userInfo;
    const updateBoard = async () => {
      await getMyBoard({
        workSpace: userInfo.workSpace, userId: user.uid
      });
    };

    const closePreviewModal = () => {
      state.previewModalFlag = false;
      state.previewId = null;
      state.fadeMode = "center";
      state.packageInfo = {};
    };

    const hasAddPinPermission = file => {
      if (file.permission === "limited") {
        return file.created_by === user.uid;
      } else {
        return true;
      }
    };

    const toggleOperationModal = (file = {}) => {
      state.selectedPin = file;
      state.addPinModalFlag = !state.addPinModalFlag;
    };

    const hasFavPin = file => {
      const pins = favBoard.value.pins;
      const field = file.type === "slide" ? "slideId" : "packageId";
      return pins.some(pin => pin.pin_id === file[field]);
    };

    const incrementPageview = (fileId, fileType) => {
      const payload = { target: fileId, type: fileType };
      return FileRepositoryForGae.incrementPageview(payload).json();
    };

    const getContents = async packageInfo => {
      const sorted_children_ids = packageInfo.children_ids_number?.sort()
        .map(packageId => packageId.slice(5));
      Object.assign(packageInfo, { sorted_children_ids });

      state.anchorPoint = packageInfo.type === "slide" ? packageInfo.slideId : sorted_children_ids[0];
      state.packageInfo = packageInfo;
      state.previewModalFlag = true;
      state.previewId = packageInfo.slideId;
      const url = `/package/${packageInfo.packageId}#${state.anchorPoint}`;
      history.pushState(history.state, "", url);
      incrementPageview(packageInfo.packageId, "package");

      saveHistory();
    };
    const getContentsReplaceUrl = async packageInfo => {
      const sorted_children_ids = packageInfo.children_ids_number
        .sort()
        .map(packageId => packageId.slice(5));
      Object.assign(packageInfo, { sorted_children_ids });

      state.anchorPoint = packageInfo.type === "slide" ? packageInfo.slideId : sorted_children_ids[0];
      state.packageInfo = packageInfo;
      state.previewModalFlag = true;
      state.previewId = packageInfo.slideId;
      const url = `/package/${packageInfo.packageId}#${state.anchorPoint}`;
      // history.pushState(history.state, '', url);
      history.replaceState(history.state, "", url);
      incrementPageview(packageInfo.packageId, "package");
    };

    const switchPackage = target => {
      const currentIndex = props.files.findIndex(
        file => file.slideId == state.previewId
      );
      const nextIndex = target == "next" ? currentIndex + 1 : currentIndex - 1;
      const numFiles = props.files.length;

      const isFirst = nextIndex < 0;
      const isLast = nextIndex > numFiles - 1;

      if (!(isFirst || isLast)) {
        const nextPackage = props.files[nextIndex];
        closePreviewModal();
        state.fadeMode = target == "next" ? "left" : "right";
        getContentsReplaceUrl(nextPackage);
      }
    };

    /** Should be in composables file coz this func can be used several files. */
    const addPin = async file => {
      const fileType = file.type;
      const boardId = favBoard.value.id;
      const pin_id = fileType == "slide" ? file.slideId : file.packageId;
      const pin_type = fileType == "slide" ? "slide" : "package";
      const payload = { pins: [{ pin_id, pin_type }], board_id: boardId };
      await BoardRepository.addPin(payload);
      await updateBoard();
    };
    
    const saveHistory = async () => {
      if (!state.searchText) return;
      state.keys = Object.keys(localStorage).filter(k => k.includes("word"));
      state.keys.forEach(key => state.allHistory.push(localStorage[key]));
      const currentWordsLength = state.keys.length;
      const sameWord = state.allHistory.filter(word => word == state.searchText);
      // 検索履歴保存
      if (sameWord.length == 0) {
        if (currentWordsLength < 5) {
          localStorage.setItem("word" + (currentWordsLength + 1), state.searchText);
        } else {
          const word5 = localStorage.getItem("word5");
          const word4 = localStorage.getItem("word4");
          const word3 = localStorage.getItem("word3");
          const word2 = localStorage.getItem("word2");
          localStorage.setItem("word5", state.searchText);
          localStorage.setItem("word4", word5);
          localStorage.setItem("word3", word4);
          localStorage.setItem("word2", word3);
          localStorage.setItem("word1", word2);
        }
      }
    };

    const changeTab = type => {
      state.selectedTab = type;
      emit("changeType", state.searchText, type);
    };

    const transitProfilePage = username => {
      router.push({
        name: "user",
        path: `/users${username}/profile`,
        params: { username }
      });
    };

    const { formatTime, onDragStart, onDragEnd } = utility();

    const onDragStartFromTop = (evt, file) => {
      const fileType = file.type === "slide" ? "slide" : "package";
      const params = {
        fileType: fileType,
        fileId: fileType === "package" ? file.packageId : file.slideId,
        permission: file.permission,
        createdBy: file.created_by,
        headerId: file.slideId,
        sortable: false
      };

      onDragStart(evt, params);
    };

    // Update packageInfo which is changed some fields in child components.
    const updatePackageInfo = data => {
      emit("updatePackageInfo", data);
    };

    const deletePackageInfo = packageId => {
      emit("deletePackageInfo", packageId);
    };

    return {
      state,
      boards,
      updateBoard,
      closePreviewModal,
      hasAddPinPermission,
      toggleOperationModal,
      hasFavPin,
      getContents,
      switchPackage,
      addPin,
      onDragStartFromTop,
      onDragEnd,
      formatTime,
      updatePackageInfo,
      deletePackageInfo,
      calcMembersNumber,
      changeTab,
      transitProfilePage
    };
  }
};
</script>


<style scoped>
::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}


</style>