<template>
  <header-base>
    <template v-slot:middle>
      <div class="flex items-center justify-center mx-10">
        <search-form
          v-if="showSearch"
          @get-text="pushText"
        />
      </div>
    </template>

    <template v-slot:right>
      <user-dropdown />
    </template>
  </header-base>
</template>

<script>
import { useRoute } from "vue-router";
import HeaderBase from "./TheHeaderBase.vue";
import SearchForm from "./SearchForm.vue";
import UserDropdown from "./dropdowns/UserSettingDropdown.vue";

export default {
  components: {
    HeaderBase,
    SearchForm,
    UserDropdown,
  },

  setup(_, { emit }) {
    const route = useRoute();
    const path = route.name;
    const showSearch = () => {
      const showComponents = [
        "home",
        "latest",
        "popular",
        "trend",
        "tag",
        "ranking",
      ];
      return showComponents.includes(path);
    };
    const pushText = (text, type) => {
      emit("get-text", text, type);
    };
    return { path, showSearch, pushText };
  },
};
</script>

<style lang="postcss" scoped>
.link {
  @apply rounded p-2 text-xl text-gray-600 hover:text-gray-700 hover:bg-gray-200 transition-colors duration-300 md:mx-2 lg:px-4;
}
</style>