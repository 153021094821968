import { RepositoryFactoryForGae } from '@/api/gae/RepositoryFactory'
const SearchRepository = RepositoryFactoryForGae.get('search')

export default {
  namespaced: true,
  state: {
    keyPressWord: "",
    result: [],
    showLoading: false,
    showMyBoardLoading: false,
    showPublicBoardLoading: false
  },
  mutations: {
    setKeyPressWord(state, data) {
      state.keyPressWord = data;
    },
    setLoading(state, data) {
      state.showLoading = data;
    },
    setResult(state, data) {
      state.result = data;
    },
    clearResult(state) {
      state.result = [];
    }
  },
  actions: {
    async setKeyPressWord({ commit }, word) {
      commit("setKeyPressWord", word);
    },
    async package({ commit }, payload) {
      commit("setLoading", true)
      const result = await SearchRepository.package(payload).json()
      commit("setResult", result.data);
      commit("setLoading", false)
      return result.data;
    },
    async slide({ commit }, payload) {
      commit("setLoading", true)
      const result = await SearchRepository.slide(payload).json()
      commit("setResult", result.data);
      commit("setLoading", false)
      return result.data;
    }
  }
};