<template>
  <div>
    <div
      v-if="showModal"
      @click.self="callParentMethod"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div
        class="w-auto max-w-6xl max-h-screen mx-auto my-6"
      >
        <div class="w-full bg-white rounded-lg">
          <div class="p-8">
            <div class="mb-4 header">
              <h1 class="font-bold text-gray-700">
                パスワード変更
              </h1>
            </div>
            <div class="mb-4 body">
              <p class="mb-2 text-base text-gray-700">
                下記のメールアドレス宛にパスワード変更用のリンクを送信します。
              </p>
              <p class="mb-2 text-lg font-bold">
                {{ email }}
              </p>
            </div>
            <div class="footer">
              <button
                @click="sendResetMail"
                class="w-32 p-2 text-center text-white bg-red-500 border rounded hover:bg-red-600 focus:outline-none"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-70"></div>
  </div>
</template>

<script>
import firebase from 'firebase/app'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      required: true
    },
  },

  setup(props, { emit }) {
    const email = firebase.auth().currentUser.email;
    const callParentMethod = () => {
      emit("closeModal");
    }

    const sendResetMail = () => {
      // emit("showSuccessModal");
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          emit("showSuccessModal");
        })
        .catch((error) => {
          console.error(error.message);
        });
    }

    return {
      email,
      sendResetMail,
      callParentMethod
    }

  },

};
</script>