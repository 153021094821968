
<template>
  <svg width="1em" height="1em" viewBox="0 0 48 48">
    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M15 8C8.925 8 4 12.925 4 19c0 11 13 21 20 23.326C31 40 44 30 44 19c0-6.075-4.925-11-11-11c-3.72 0-7.01 1.847-9 4.674A10.987 10.987 0 0 0 15 8Z">
    </path>
  </svg>
</template>

<script>
export default {
  name: 'IconParkOutlineLike'
}
</script>