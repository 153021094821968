import { reactive } from "vue";

export default function () {
  const alartState = reactive({
    showAlart: false,
    alartMode: '',
    alartMsg: {
      success: {
        title: '',
        body: ''
      },
      failure: {
        title: '',
        body: ''
      },
      warning: {
        title: '',
        body: ''
      }
    }
  })

  const showAlart = (mode, msg={title:'', body:''}) => {
    if (mode === 'success') {
      alartState.alartMsg.success.title = msg.title;
      alartState.alartMsg.success.body = msg.body;
    } else if (mode === 'failure') {
      alartState.alartMsg.failure.title = msg.title;
      alartState.alartMsg.failure.body = msg.body;
    } else if (mode === 'warning') {
      alartState.alartMsg.warning.title = msg.title;
      alartState.alartMsg.warning.body = msg.body;
    }

    alartState.alartMode = mode;
    alartState.showAlart = true;

    setTimeout(() => (alartState.showAlart = false), 5000);
  }

  return {
    alartState, showAlart
  }
}