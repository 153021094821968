<template>
  <div>
    <div class="grid gap-6 mx-4 row lg:grid-cols-3 md:grid-cols-2">
      <div
        v-for="(file, index) in files"
        :key="`ga${file.slideId}`"
        class="bg-white rounded shadow-md"
        draggable="true"
        @dragstart="onDragStartFromTop($event, file)"
        @dragend="onDragEnd"
      >
        <div @click.prevent="getContents(file)" class="cursor-pointer">
          <div class="relative overflow-hidden rounded-t preview bg-gray-50">
            <div
              v-bind:style="{
                backgroundImage: 'url(' + state.baseUrl + file.slideId + '.jpg)'
              }"
              class="absolute inset-0 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9 filter blur-xs contrast-50"
            ></div>
            <div
              v-bind:style="{
                backgroundImage: 'url(' + state.baseUrl + file.slideId + '.jpg)'
              }"
              class="bg-center bg-no-repeat bg-contain preview-thumbnail aspect-w-16 aspect-h-9"
              v-on:mouseover.self="
                state.hoverFlag = true;
                state.hoverIndex = index;
              "
            ></div>
            <div
              v-show="state.hoverFlag && index === state.hoverIndex"
              class="absolute inset-0 transition hover:bg-black hover:bg-opacity-30 "
              v-on:mouseleave.self="state.hoverFlag = false"
            >
              <div
                v-if="boards.length > 0 && hasAddPinPermission(file)"
                class="relative"
              >
                <div class="absolute top-0 flex justify-end w-full p-2">
                  <button
                    @click.stop="toggleOperationModal(file)"
                    class="flex items-center justify-center my-1 text-sm text-white hover:text-gray-200 focus:outline-none "
                    type="button"
                  >
                    <span>他のボードに保存</span>
                    <round-keyboard-arrow-down />
                  </button>
                  <button
                    v-if="!hasFavPin(file)"
                    @click.stop="addPin(file)"
                    class="px-2 text-sm text-white transition-all duration-150 ease-linear border border-gray-200 rounded-full outline-none bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 hover:bg-gray-700 focus:outline-none"
                  >
                    <favorite-icon />
                  </button>
                  <button
                    v-else
                    @click.prevent
                    class="px-2 text-sm text-white transition-all duration-150 ease-linear bg-gray-300 rounded-full outline-none focus:outline-none"
                  >
                    お気に入り保存済
                  </button>
                </div>
              </div>
            </div>
            <div
              class="absolute outline-none top-3 left-3 filter drop-shadow-xl focus:outline-none"
              v-show="file.permission === 'limited'"
            >
              <lock-icon
                class="w-5 h-5 text-yellow-500 stroke-current stroke-2"
              />
            </div>
          </div>
        </div>
        <div class="px-2 py-1 text-gray-600 meta">
          <div class="flex items-center justify-between mb-1 meta-top">
            <p class="text-sm truncate" style="max-width: 520px">
              {{
                $route.params?.type === "slides"
                  ? `[${file.slide_number}]` + file.fileTitle
                  : file.fileTitle
              }}
            </p>
          </div>

          <div
            class="flex items-center justify-between mb-1.5 text-gray-400 meta-buttom"
          >
            <router-link
              class="flex items-center transition meta-user hover:text-gray-600 group"
              tabindex="-1"
              :to="{ name: 'user', path: `/users/${file.user.username}/profile`, params: { username: file.user.username, workspace: file.user.workSpace, id: file.user.id } }"
            >
              <img
                v-if="file.user.photoURL"
                :src="file.user.photoURL"
                alt=""
                class="object-cover w-5 h-5 border rounded-full"
              />
              <div
                v-else
                class="flex items-center justify-center object-cover w-5 h-5 border rounded-full"
              >
                <span class="text-xs">{{
                  file.user.firstName.slice(0, 1)
                }}</span>
              </div>

              <p
                v-if="file.user.displayName"
                class="ml-1 text-xs truncate group-hover:underline"
              >
                {{ file.user.displayName }}
              </p>
              <p v-else class="ml-2 text-xs truncate">
                {{ `${file.user.lastName} ${file.user.firstName}` }}
              </p>
              <p class="ml-2 text-xs truncate">
                {{ formatTime(file.created_timestamp) }}
              </p>
            </router-link>
            <div class="flex items-center">
              <tooltip-text text="ページ数" placement="buttom">
                <div class="flex">
                  <page-number-icon class="w-4 h-4" />
                  <span class="ml-1 text-xs">{{ file.children_ids?.length }}</span>
                </div>
              </tooltip-text>
              <tooltip-text text="閲覧数" placement="buttom">
                <div class="flex">
                  <eye-icon class="w-4 h-4 ml-2" />
                  <span class="ml-1 text-xs">{{ file.pageview }}</span>
                </div>
              </tooltip-text>
            </div>
          </div>
          <div class="flex items-center mb-0.5">
            <tooltip-text text="ボードに追加" placement="right">
              <button
                v-if="boards.length > 0 && hasAddPinPermission(file)"
                @click.stop="toggleOperationModal(file)"
                class="flex mr-1.5 border border-gray-400 rounded transition hover:text-white hover:border-blue-400 hover:bg-blue-400 focus:outline-none "
                type="button"
              >
                <plus class="w-4 h-4 p-0.5" />
              </button>
            </tooltip-text>
            <tooltip-text text="他人の非公開資料はボードに追加できません" placement="right">
              <button
                v-if="!(boards.length > 0 && hasAddPinPermission(file))"
                class="flex mr-1.5 border border-gray-300 bg-gray-200 rounded cursor-not-allowed "
              >
                <plus class="w-4 h-4 p-0.5" />
              </button>
            </tooltip-text>
            <div class="flex overflow-x-scroll">
              <div
                v-for="board in file.boards"
                :key="board.id"
                class="flex-none mr-1.5 text-xs text-blue-400 truncate transition hover:text-blue-600 hover:underline"
                style="max-width: 170px"
              >
                <router-link
                  v-if="board.visibility === 'public'"
                  :to="{ path: `/boards/${board.id}` }"
                >
                  #{{ board.name }}
                </router-link>
                <router-link
                  v-if="
                    board.visibility === 'limited' &&
                      [
                        ...board.owners,
                        ...board.members
                      ].includes(state.user.uid)
                  "
                  :to="{ path: `/boards/${board.id}` }"
                >
                  🔒{{ board.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <operation-add-pin-modal
      :show-pin-modal="state.addPinModalFlag"
      :close-pin-modal="toggleOperationModal"
      :file="state.selectedPin"
      @update-boards="updateBoard"
    />

    <div v-for="file in files" :key="file.sildeId">
      <preview-modal
        v-if="state.previewId == file.slideId"
        :anchor_point="state.anchorPoint"
        :package-info="state.packageInfo"
        :fade-mode="state.fadeMode"
        @update-package-info="updatePackageInfo"
        @delete-package-info="deletePackageInfo"
        @toggle="closePreviewModal"
        @switch-package="switchPackage"
      ></preview-modal>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepositoryForGae = RepositoryFactoryForGae.get("file");
const BoardRepository = RepositoryFactoryForGae.get("board");
import useBoardRepository from "./composables/useBoardRepository";
import PreviewModal from "./modals/PreviewModal.vue";
import OperationAddPinModal from "./modals/PinModalAdd.vue";
import TooltipText from "./utilities/TooltipContent.vue";
import utility from "./composables/utility";

import RoundKeyboardArrowDown from "../icons/ArrowDown.vue";
import EyeIcon from "../icons/EyeIcon.vue";
import Plus from "../icons/Plus.vue";
import PageNumberIcon from "../icons/PageNumberIcon.vue";
import FavoriteIcon from "../icons/FavoriteIcon.vue";
import LockIcon from "../icons/LockIcon.vue";

export default {
  components: {
    PreviewModal,
    OperationAddPinModal,
    TooltipText,
    RoundKeyboardArrowDown,
    EyeIcon,
    Plus,
    LockIcon,
    FavoriteIcon,
    PageNumberIcon
  },

  props: {
    files: Array
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const user = firebase.auth().currentUser;

    const state = reactive({
      baseUrl: `${location.origin}/img/`,
      hoverFlag: false,
      hoverIndex: null,
      addPinModalFlag: false,
      selectedPin: {},
      anchorPoint: "",
      previewModalFlag: false,
      packageInfo: {},
      previewId: null,
      fadeMode: "center",
      boards: [],
      user: user
    });

    const boards = computed(() => store.state.board.myBoard);
    const favBoard = computed(() =>
      store.getters["board/defaultBoards"].find(x => x.name === "お気に入り")
    );

    const { getMyBoard } = useBoardRepository();
    const userInfo = store.state.user.userInfo;
    const updateBoard = async () => {
      await getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid })
    };

    const closePreviewModal = () => {
      state.previewModalFlag = false;
      state.previewId = null;
      state.fadeMode = "center";
      state.packageInfo = {};
    };

    const hasAddPinPermission = file => {
      if (file.permission === "limited") {
        return file.created_by === user.uid;
      } else {
        return true;
      }
    };

    const toggleOperationModal = (file = {}) => {
      state.selectedPin = file;
      state.addPinModalFlag = !state.addPinModalFlag;
    };

    const hasFavPin = file => {
      const pins = favBoard.value?.pins;
      const field = route.params.type === "slides" ? "slideId" : "packageId";
      return pins?.some(pin => pin.pin_id === file[field]);
    };

    const incrementPageview = (fileId, fileType) => {
      const payload = { target: fileId, type: fileType };
      return FileRepositoryForGae.incrementPageview(payload).json();
    };

    const getContents = async packageInfo => {
      const sorted_children_ids = packageInfo.children_ids_number
        .sort()
        .map(packageId => packageId.slice(5));
      Object.assign(packageInfo, { sorted_children_ids });

      state.anchorPoint = sorted_children_ids[0];
      state.packageInfo = packageInfo;
      state.previewModalFlag = true;
      state.previewId = packageInfo.slideId;
      const url = `/package/${packageInfo.packageId}`;
      history.pushState(history.state, "", url);
      incrementPageview(packageInfo.packageId, "package");
    };
    const getContentsReplaceUrl = async packageInfo => {
      const sorted_children_ids = packageInfo.children_ids_number
        .sort()
        .map(packageId => packageId.slice(5));
      Object.assign(packageInfo, { sorted_children_ids });

      state.anchorPoint = sorted_children_ids[0];
      state.packageInfo = packageInfo;
      state.previewModalFlag = true;
      state.previewId = packageInfo.slideId;
      const url = `/package/${packageInfo.packageId}`;
      history.replaceState(history.state, "", url);
      incrementPageview(packageInfo.packageId, "package");
    };

    const switchPackage = target => {
      const currentIndex = props.files.findIndex(
        file => file.slideId == state.previewId
      );
      const nextIndex = target == "next" ? currentIndex + 1 : currentIndex - 1;
      const numFiles = props.files.length;

      const isFirst = nextIndex < 0;
      const isLast = nextIndex > numFiles - 1;

      if (!(isFirst || isLast)) {
        const nextPackage = props.files[nextIndex];
        closePreviewModal();
        state.fadeMode = target == "next" ? "left" : "right";
        getContentsReplaceUrl(nextPackage);
      }
    };

    /** Should be in composables file coz this func can be used several files. */
    const fileType = router.currentRoute.value.params.type;
    const addPin = async file => {
      const boardId = favBoard.value.id;
      const pin_id = fileType == "slides" ? file.slideId : file.packageId;
      const pin_type = fileType == "slides" ? "slide" : "package";
      const payload = { pins: [{ pin_id, pin_type }], board_id: boardId };
      await BoardRepository.addPin(payload);
      await updateBoard();
    };

    const { formatTime, onDragStart, onDragEnd } = utility();

    const onDragStartFromTop = (evt, file) => {
      const fileType = route.params.type === "slides" ? "slide" : "package";
      const params = {
        fileType: fileType,
        fileId: fileType === "package" ? file.packageId : file.slideId,
        permission: file.permission,
        createdBy: file.created_by,
        headerId: file.slideId,
        sortable: false
      };

      onDragStart(evt, params);
    };

    // Update packageInfo which is changed some fields in child components.
    const updatePackageInfo = data => {
      emit("updatePackageInfo", data);
    };

    const deletePackageInfo = packageId => {
      emit("deletePackageInfo", packageId);
    };

    return {
      state,
      boards,
      updateBoard,
      closePreviewModal,
      hasAddPinPermission,
      toggleOperationModal,
      hasFavPin,
      getContents,
      switchPackage,
      addPin,
      onDragStartFromTop,
      onDragEnd,
      formatTime,
      updatePackageInfo,
      deletePackageInfo
    };
  }
};
</script>


<style scoped>
::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}


</style>