<template>
  <div
    v-if="deviceType == 'pc'"
    class="bg-gray-100"
  >
    <search-top-template />
  </div>
  <div v-if="deviceType == 'mobile'">
    <mobile-search-top-template />
  </div>
</template>

<script>
import SearchTopTemplate from "../templates/SearchTopTemplate.vue";
import MobileSearchTopTemplate from "../mobile/templates/SearchTop.vue";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    SearchTopTemplate,
    MobileSearchTopTemplate
  },
  setup () {
    const deviceType = calcBreakpoint(window.innerWidth);

    return {
      deviceType
    }
  }
};
</script>