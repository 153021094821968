import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
const resource = "board";
export default {
  create(payload) {
    return Repository.post(`${version}/${resource}/create`, { json: payload });
  },

  getPin(payload) {
    return Repository.post(`${version}/${resource}/get_pin`, { json: payload });
  },

  getAll() {
    return Repository.get(`${version}/${resource}/get_all`);
  },

  fetchBoardById (payload) {
    return Repository.post(`${version}/${resource}/fetch_by_id`, { json: payload });
  },

  update(payload) {
    return Repository.post(`${version}/${resource}/update`, { json: payload });
  },

  delete(payload) {
    return Repository.post(`${version}/${resource}/delete`, { json: payload });
  },

  addPin(payload) {
    return Repository.post(`${version}/${resource}/add_pin`, { json: payload });
  },

  removePin(payload) {
    return Repository.post(`${version}/${resource}/remove_pin`, { json: payload });
  },

  attachUser(payload) {
    return Repository.post(`${version}/${resource}/attach_user`, { json: payload });
  },

  detachUser(payload) {
    return Repository.post(`${version}/${resource}/detach_user`, { json: payload });
  },

  createNote(payload) {
    return Repository.post(`${version}/${resource}/create_note`, { json: payload });
  },

  getNote(payload) {
    return Repository.post(`${version}/${resource}/get_note`, { json: payload });
  },

  updateNote(payload) {
    return Repository.post(`${version}/${resource}/update_note`, { json: payload });
  },

  deleteNote(payload) {
    return Repository.post(`${version}/${resource}/delete_note`, { json: payload });
  },

  updatePinOrder(payload) {
    return Repository.post(`${version}/${resource}/update_pin_order`, { json: payload });
  },

  updateBoardOrder(payload) {
    return Repository.post(`${version}/${resource}/update_board_order`, { json: payload });
  },

};