<template>
  <base-modal :showModal="true" @toggleModal="$emit('closeModal')">
    <template v-slot:header>ボードを作成する</template>
    <template v-slot:body>
      <div class="vld-parent">
        <Form :validation-schema="schema" @submit="createBoard" :initial-values="formValues">
          <div class="relative field">
            <div class="flex items-center justify-center">
              <div class="text-4xl text-center bg-gray-100 border" style="width: 42px; height: 42px;">{{state.emoji}}</div>
              <div class="w-full p-2 text-left text-gray-400 border border-l-0 cursor-pointer hover:bg-gray-50" @click="state.showEmoji = !state.showEmoji" style="height: 42px;">アイキャッチの絵文字を選択する</div>
            </div>
            <EmojiPicker class="absolute top-0 z-20 left-20" v-if="state.showEmoji" :native="true" @select="setEmoji" :disable-skin-tones="true" :hide-group-names="false" />
            <div v-show="state.showEmoji" @click="state.showEmoji = false" class="fixed inset-0 z-10 w-full h-full"></div>
          </div>
          <div class="field">
            <label class="field-label" for="boardName">ボード名</label>
            <Field
              class="field-item"
              name="boardName"
              id="boardName"
              placeholder="プロジェクト計画集"
            />
            <ErrorMessage name="boardName" class="field-error" />
            <p class="mt-1 text-sm text-gray-400">
              参考になるスライド、部内共有、プロジェクト名...など、わかりやすい名前で作成するのがおすすめです。
            </p>
          </div>
          <div class="field">
            <label class="field-label" for="description"
              >説明(オプション)</label
            >
            <Field
              class="field-item"
              name="description"
              id="description"
              rows="2"
              placeholder="誰にでもボードの目的が分かるように説明を加えます。"
              as="textarea"
            />
          </div>

          <div class="field">
            <p class="field-label">ボード公開</p>
            <Field
              id="create-board-permission-limited"
              class="w-4 h-4"
              value="limited"
              name="visibility"
              type="radio"
            ></Field>
            <label for="create-board-permission-limited" class="mb-1 ml-1 text-base text-gray-500 cursor-pointer">プライベート</label>
            <p class="my-1 text-sm text-gray-400">
              自身とボード参加者にしか表示されません。このボードは招待制で、非公開の資料でもボードメンバーは閲覧することができるようになります。
            </p>
            <p class="my-1 font-bold text-sm text-gray-600">
              ※プライベートボードはあとから公開に変更することができません。
            </p>
            <Field
              id="create-board-permission-public"
              class="w-4 h-4"
              value="public"
              name="visibility"
              type="radio"
            ></Field>
            <label for="create-board-permission-public" class="mb-1 ml-1 text-base text-gray-500 cursor-pointer">パブリック</label>
            <p class="my-1 text-sm text-gray-400">
              このボードは検索可能になり、誰でも自分から参加できるように公開されます。
            </p>
          </div>
          <div class="field">
            <p class="field-label">ボードメンバー</p>
            <div class="flex items-center justify-center border rounded bg-gray-50">
              <Multiselect
                class="border-0 border-r border-gray-300 multiselect-gray"
                v-model="state.userIds"
                mode="tags"
                :searchable="true"
                :options="options"
                :closeOnSelect="false"
              />
              <select v-model="state.permission" class="px-1 mr-2 text-gray-500 bg-gray-50 focus:outline-none">
                <option value="member">メンバー</option>
                <option value="owner">管理者</option>
              </select>
            </div>
          </div>
          <div class="w-full my-2 border"></div>
          <div class="flex justify-end">
            <button class="px-4 py-2 text-white transition bg-indigo-700 rounded button hover:bg-indigo-600">
              ボードを作成する
            </button>
          </div>
        </Form>
        <loading
          v-model:active="state.loading"
          color="#818589"
          :is-full-page="false"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
import { Field, Form, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja";
import Multiselect from "@vueform/multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import BaseModal from "./BaseModal.vue";
import EmojiPicker from "vue3-emoji-picker";
import "../../../../node_modules/vue3-emoji-picker/dist/style.css";
import { list as emoji } from '../../../assets/emoji.json'

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect,
    Loading,
    BaseModal,
    EmojiPicker
  },

  props: {
    visibility: {
      type: String,
      default: 'limited'
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const userInfo = store.state.user.userInfo;

    const schema = yup.object({
      boardName: yup
        .string()
        .required()
        .label("ボード名"),
      description: yup.string().label("説明"),
      visibility: yup.string().label("権限")
    });

    const formValues = {
      boardName: "",
      description: "",
      visibility: props.visibility,
      emoji: "",
      coverURL: ""
    };

    const state = reactive({ 
      loading: false,
      permission: 'member',
      userIds: [],
      emoji: '',
      showEmoji: false
    });

    const options = computed(() => {
      return store.state.user.users.filter(user => user.id != userInfo.id).map(user => {
        return { value: user.id, label: `${user.lastName} ${user.firstName}` };
      })
    })

    const createBoard = async values => {
      state.loading = true;
      const description = values.description ? values.description : ""
      const users = state.userIds.map(userId => {
        return { userId, permission: state.permission }
      });
      console.log("users", users)
      const payload = {
        boards: [
          {
            name: values.boardName,
            description: description,
            visibility: values.visibility,
            emoji: state.emoji,
            coverURL: values.coverURL,
            users: users,
          }
        ],
        url_domain: location.origin
      };
      const result = await BoardRepository.create(payload).json();

      emit("updateBoard");
      state.loading = false;

      if (result.status === "200") {
        await new Promise(resolve => setTimeout(resolve, 100));
        emit("closeModal");
      }
    };

    const setEmoji = (emoji) => {
      state.emoji = emoji.i
    }

    state.emoji = emoji[(Math.floor(Math.random() * (emoji.length - 1)))].emoji;

    return {
      createBoard,
      schema,
      formValues,
      state,
      options,
      setEmoji
    };
  }
};
</script>

<style lang="postcss" scoped>
.button {
  @apply text-sm border rounded px-3 py-1.5 focus:outline-none;
}

.field {
  @apply mb-6;
}

.field-label {
  @apply block mb-1 text-gray-800 font-bold text-sm;
}

.field-item {
  @apply w-full p-2 text-gray-700 border rounded;
}

.field-error {
  @apply text-red-500 text-sm;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-gray {
  --ms-tag-bg: #cecdca80;
  --ms-tag-color: #37352f;
  --ms-tag-font-weight: 400;
  --ms-ring-color: #ffffff00;
  --ms-radius: 0px;
}
</style>