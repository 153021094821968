<template>
  <base-modal
    :showModal="true"
    @toggleModal="closeModal"
  >
    <template v-slot:header>{{ userFullName }} さんを削除しますか？</template>

    <template v-slot:body>
      <div
        v-if="state.validationType == 'error'"
        class="mb-2 text-red-600"
      >
        ユーザーの削除に失敗しました。
      </div>
      <div>
        <p class="block mb-4 text-gray-500">
          一度削除されたユーザーは元に戻すことができません。
        </p>
      </div>
      <loading
        v-model:active="state.loading"
        :is-full-page="false"
        color="#818589"
        blur=""
      />
    </template>

    <template v-slot:footer>
      <div class="mt-4 mx-10">
        <div class="flex justify-end mb-6">
          <button
            @click="closeModal"
            class="p-2 rounded mr-4 text-black bg-gray-200 button hover:bg-gray-300"
          >
            キャンセル
          </button>
          <button
            @click="deleteUser"
            class="p-2 text-white bg-red-600 rounded hover:bg-red-800 disabled:bg-gray-300"
          >
            削除する
          </button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { reactive } from "vue";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const userRepository = RepositoryFactoryForGae.get("user");
import BaseModal from "./BaseModal.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    BaseModal,
    Loading
  },
  emits: [
    "closeModal"
  ],
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    userFullName: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      userId: props.userId,
      validationType: "",
      loading: false
    });

    const deleteUser = async () => {
      state.loading = true;
      const payload = {
        userId: state.userId
      };
      await userRepository.deleteUser(payload)
        .json()
        .then(({ status }) => {
          if (status) {
            state.loading = false;
            emit("succeededDeleteUser");
          }
        })
        .catch(() => {
          state.loading = false;
          state.validationType = "error";
        });
    };

    return {
      state,
      deleteUser
    }
  }
};
</script>