<template>
  <operation-modal :showModal="modalVisibleFlag" @toggleModal="togglePinModal">
    <template v-slot:header>ピンを編集する</template>

    <template v-slot:body>
      <div v-if="'slideInfo' in selectedPin">
        <p class="block mb-2 font-bold text-gray-700 text-md">
          ファイル名: {{ selectedPin.slideInfo.name }}
        </p>
        <div
          v-bind:style="{
            backgroundImage:
              'url(' + baseUrl + selectedPin.slideInfo.slideId + '.jpg)',
          }"
          class="bg-gray-100 bg-center bg-no-repeat bg-contain border rounded preview-thumbnail aspect-w-16 aspect-h-9"
        ></div>
      </div>
      <div v-else>
        <p class="block mb-2 font-bold text-gray-700 text-md">
          ファイル名: {{ selectedPin.packageInfo.fileTitle }}
        </p>
        <div
          v-bind:style="{
            backgroundImage: 'url(' + baseUrl + selectedPin.packageInfo.headerId + '.jpg)',
          }"
          class="bg-gray-100 bg-center bg-no-repeat bg-contain border rounded preview-thumbnail aspect-w-16 aspect-h-9"
        ></div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="mx-10">
        <div class="w-full my-4 border"></div>
        <div class="flex justify-end mb-6">
          <button
            @click="togglePinModal"
            class="text-black bg-gray-200 button hover:bg-gray-300"
          >
            キャンセル
          </button>
          <button
            @click="changeOperationModalFlag('delete')"
            class="ml-4 text-white bg-indigo-600 button hover:bg-indigo-700"
          >
            ボードから削除する
          </button>
        </div>
      </div>
    </template>
  </operation-modal>
</template>

<script>
import OperationModal from "./BaseModal.vue";

export default {
  components: {
    OperationModal,
  },

  props: {
    modalVisibleFlag: {
      type: Boolean,
      required: true,
    },
    togglePinModal: {
      type: Function,
      required: true,
    },
    selectedPin: {
      type: Object,
      required: true,
    },
    changeOperationModalFlag: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      baseUrl: location.origin + "/img/",
    };
  },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply border rounded px-3 py-1.5 focus:outline-none;
}
</style>