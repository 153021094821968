<template>
  <board-detail-template />
</template>

<script>
import firebase from "firebase/app";
import { useStore } from "vuex";
import BoardDetailTemplate from '@/components/templates/BoardDetailTemplate';
import useBoardRepository from "../organisms/composables/useBoardRepository";

export default {
  components: {
    BoardDetailTemplate
  },

  setup() {
    const store = useStore();
    const user = firebase.auth().currentUser;

    const userInfo = store.state.user.userInfo;

    const { getMyBoard } = useBoardRepository();
    getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid });
  }
};
</script>