<template>
<div class="col-span-5 pt-3 ml-4 overflow-y-scroll">
  <div class="text-gray-700">
    <div class="mb-10 infomation">
      <h2 class="font-semibold">アカウント情報</h2>
      <div class="w-full py-4 bg-white">
        <div class="mb-8">
          <label class="block mb-2" for="email">
            <p>メールアドレス</p>
            <span class="text-sm text-gray-400"
              >ログインメールアドレスをこちらから変更できます</span
            >
          </label>
          <div class="flex items-center mb-1">
            <p class="text-lg text-gray-600">{{ email }}</p>
          </div>
          <button
            @click="showChangeEmailModal = true"
            class="btn border"
          >
            メールアドレス変更
          </button>
        </div>

        <div class="mb-3">
          <label class="block mb-2" for="email">
            <p>パスワード</p>
            <span class="text-sm text-gray-400"
              >ログインパスワードをこちらから変更できます</span
            >
          </label>
          <button
            @click="showResetPasswordModal = true"
            class="btn border"
          >
            パスワード変更
          </button>
        </div>
      </div>
    </div>
    <div class="danger-zone mb-2">
      <button
        @click="showDeleteAccountModal = true"
        class="px-2 py-2 text-white bg-red-500 rounded shadow focus:outline-none hover:bg-red-600"
      >
        アカウントを削除
      </button>
    </div>
    <change-email-modal
      :showModal="showChangeEmailModal"
      @showSuccessModal="
        showChangeEmailModal = false;
        showSuccessModal = true;
      "
      @closeModal="showChangeEmailModal = false"
    />
    <reset-password-modal
      :showModal="showResetPasswordModal"
      @showSuccessModal="
        showResetPasswordModal = false;
        showSuccessModal = true;
      "
      @closeModal="showResetPasswordModal = false"
    />
    <success-modal
      :showModal="showSuccessModal"
      @closeModal="showSuccessModal = false"
    />
    <account-delete-modal
      :showModal="showDeleteAccountModal"
      :deleteAccount="deleteAccount"
      @closeModal="showDeleteAccountModal = false"
    />
  </div>
</div>
</template>

<script>
import { ref } from "vue";
import firebase from "firebase/app";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import ChangeEmailModal from "../modals/ChangeEmailModal.vue";
import ResetPasswordModal from "../modals/ResetPasswordModal.vue";
import SuccessModal from "../modals/SendMailSuccessModal.vue";
import AccountDeleteModal from "../modals/AccountDeleteModal.vue";

import { RepositoryFactoryForGae } from "../../../api/gae/RepositoryFactory.js";
const UserRepository = RepositoryFactoryForGae.get("user");

export default {
  components: {
    ChangeEmailModal,
    AccountDeleteModal,
    SuccessModal,
    ResetPasswordModal,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const showChangeEmailModal = ref(false);
    const showResetPasswordModal = ref(false);
    const showSuccessModal = ref(false);
    const showDeleteAccountModal = ref(false);
    const email = ref(firebase.auth().currentUser.email);

    const userId = store.state.auth.userUid;
    const signout = () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push("/signin");
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const deleteAccount = async () => {
      const payload = { userId: userId };
      await UserRepository.deletableUser(payload).json();
      signout();
    };

    return {
      email,
      showChangeEmailModal,
      showResetPasswordModal,
      showSuccessModal,
      showDeleteAccountModal,
      deleteAccount,
    };
  },
};
</script>

<style lang="postcss" scoped>
.field-item {
  @apply w-96 px-4 py-2 text-gray-700 shadow border rounded;
}

.btn {
  @apply rounded shadow text-sm text-gray-500 hover:bg-gray-100 p-2 focus:outline-none;
  transition: background 20ms ease-in 0s;
}
</style>