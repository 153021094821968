<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M80.344 115.668a8 8 0 1 1 11.312-11.314L120 132.69V40a8 8 0 0 1 16 0v92.69l28.344-28.336a8 8 0 1 1 11.312 11.314l-42 41.99a8 8 0 0 1-11.312 0zM216 144a8 8 0 0 0-8 8v56H48v-56a8 8 0 0 0-16 0v56a16.018 16.018 0 0 0 16 16h160a16.018 16.018 0 0 0 16-16v-56a8 8 0 0 0-8-8z"
      fill="currentColor"
    ></path>
  </svg>
</template>