<template>
  <div>
    <top-main-content
      v-if="deviceType == 'pc'"
      :files="files"
      @delete-package-info="deletePackageInfo"
      @update-package-info="updatePackageInfo"
    />
    <mobile-top-main-content
      v-else-if="deviceType == 'mobile'"
      :files="files"
      @delete-package-info="deletePackageInfo"
      @update-package-info="updatePackageInfo"
    />
    <infinite-loading
      v-if="showInfiniteLoading"
      spinner="circles"
      @infinite="infiniteHandler"
    />
  </div>
</template>

<script>
import { onBeforeMount, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import TopMainContent from "./TopMainContent.vue";
import MobileTopMainContent from "../mobile/templates/Home.vue";
import infiniteLoading from "vue-infinite-loading";
import useSearchRepository from "./composables/useSearchRepository";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    TopMainContent,
    MobileTopMainContent,
    infiniteLoading
  },

  setup(_, { emit }) {
    const deviceType = calcBreakpoint(window.innerWidth);
    const route = useRoute();
    const store = useStore();

    const {
      state,
      payload,
      showInfiniteLoading,
      searchPackagePayload,
      searchSlidePayload,
      tokenize,
      searchPackage,
      deletePackageInfo,
      updatePackageInfo,
      searchSlide,
      infiniteHandler
    } = useSearchRepository();

    const files = computed(() => {
      return state.files.map(file => {
        const boards = store.state.board.myBoard.filter(board => board.pins.some(pin => pin.pin_id === file.id));
        return Object.assign(file, { boards });
      })
    })

    const fileType = route.params.type;
    const searchFiles = async () => {
      emit("start-loading");
      const query = route.query.q || "";
      if (fileType === "packages") {
        Object.assign(payload, { ...searchPackagePayload, ...route.query });

        if (query) { 
          payload.text = query;
          payload.tokens = await tokenize({ text: query });
        }

        state.files = await searchPackage(payload);
      } else if (fileType === "slides") {
        Object.assign(payload, { ...searchSlidePayload, ...route.query });

        if (query) { 
          payload.text = query;
          payload.tokens = await tokenize({ text: query });
        }

        state.files = await searchSlide(payload);
      }
      emit("end-loading");
    };

    onBeforeMount(() => {
      searchFiles();
    });

    watchEffect(() => {
      const numFiles = state.files.length;
      if (numFiles > 0) {
        switch (route.params.type) {
          case "packages":
            payload.cursor = state.files[numFiles - 1].packageId;
            break;
          case "slides":
            state.fileType = "slide";
            payload.cursor = state.files[numFiles - 1].slideId;
            break;
          default:
            payload.cursor = state.files[numFiles - 1].packageId;
        }
      }
    });

    return {
      state,
      files,
      deviceType,
      showInfiniteLoading,
      deletePackageInfo,
      updatePackageInfo,
      infiniteHandler
    };
  }
};
</script>