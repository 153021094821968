<template>
  <div class="h-screen bg-black bg-clip-padding backdrop-filter backdrop-blur bg-opacity-80">
    <div class="relative flex flex-col items-center max-h-screen animate-pulse">
      <!-- Header -->
      <div class="absolute top-0 z-10 flex items-center justify-center w-full h-8 px-5 bg-gray-300 vh-heiht filter drop-shadow-xl"></div>

      <!-- Navigation -->
      <div class="absolute top-0 left-0 flex flex-col items-center justify-center w-24 h-screen">
        <!-- Package Menu -->
        <div class="relative flex text-left text-gray-800">
          <button
            class="my-4 transition transform bg-gray-300 rounded-full shadow-md h-14 w-14 hover:scale-110"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
          </button>
        </div>

        <!-- Favorite Menu -->
        <div class="relative flex text-left text-gray-800">
          <button
            class="my-4 transition transform bg-gray-300 rounded-full shadow-md h-14 w-14 hover:scale-110"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
          </button>
        </div>

        <!-- Comment Menu -->
        <div class="relative flex text-left text-gray-800">
          <button
            class="my-4 transition transform bg-gray-300 rounded-full shadow-md h-14 w-14 hover:scale-110"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
          </button>
        </div>

        <!-- Download Menu -->
        <div class="relative flex text-left text-gray-800">
          <button
            class="my-4 transition transform bg-gray-300 rounded-full shadow-md h-14 w-14 hover:scale-110"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
          </button>
        </div>

        <!-- Chart Menu -->
        <div class="relative flex text-left text-gray-800">
          <button
            class="my-4 transition transform bg-gray-300 rounded-full shadow-md h-14 w-14 hover:scale-110"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
          </button>
        </div>
      </div>

      <!-- Slides -->
      <div class="flex items-center justify-center w-screen h-screen mt-8">
        <div class="w-4/5 mx-40 bg-gray-300 rounded h-4/5"></div>
      </div>
    </div>
  </div>
</template>