<template>
  <file-upload-template />
</template>

<script>
import firebase from "firebase/app";
import { useStore } from "vuex";
import useBoardRepository from "../organisms/composables/useBoardRepository";
import FileUploadTemplate from "../templates/FileUploadTemplate.vue";

export default {
  components: {
    FileUploadTemplate
  },

  setup() {
    const store = useStore();
    const user = firebase.auth().currentUser;

    const userInfo = store.state.user.userInfo;
    const { getMyBoard } = useBoardRepository();
    getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid });
  }
};
</script>