import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
const resource = "board";
export default {
  create(payload) {
    return Repository.post(`${version}-${resource}-create`, { json: payload });
  },

  get(payload) {
    return Repository.post(`${version}-${resource}-get`, { json: payload });
  },

  getAll() {
    return Repository.get(`${version}-${resource}-getAll`);
  },

  fetchBoardById (payload) {
    return Repository.post(`${version}-${resource}-fetchBoardById`, { json: payload });
  },

  update(payload) {
    return Repository.post(`${version}-${resource}-update`, { json: payload });
  },

  delete(payload) {
    return Repository.post(`${version}-${resource}-delete`, { json: payload });
  },

  addPin(payload) {
    return Repository.post(`${version}-${resource}-addPin`, { json: payload });
  },

  removePin(payload) {
    return Repository.post(`${version}-${resource}-removePin`, { json: payload });
  },

  attachUser(payload) {
    return Repository.post(`${version}-${resource}-attachUser`, { json: payload });
  },

  detachUser(payload) {
    return Repository.post(`${version}-${resource}-detachUser`, { json: payload });
  },

  createNote(payload) {
    return Repository.post(`${version}-${resource}-createNote`, { json: payload });
  },

  getNote(payload) {
    return Repository.post(`${version}-${resource}-getNote`, { json: payload });
  },

  updateNote(payload) {
    return Repository.post(`${version}-${resource}-updateNote`, { json: payload });
  },

  deleteNote(payload) {
    return Repository.post(`${version}-${resource}-deleteNote`, { json: payload });
  },

  updatePinOrder(payload) {
    return Repository.post(`${version}-${resource}-updatePinOrder`, { json: payload });
  },

};