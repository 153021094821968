<template>
  <div>
    <div
      v-if="showModal"
      @click.self="callParentMethod"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div class="w-80 max-w-4xl max-h-screen mx-auto my-6">
        <div class="w-full bg-white rounded-lg">
          <div
            v-if="!isAuthenticated"
            class="p-5"
          >
            <div class="mb-4 header">
              <h1 class="font-bold text-gray-700">
                パスワード入力
              </h1>
            </div>
            <div class="body">
              <div>
                <p class="mb-6 text-base text-gray-700">
                  ログインパスワードを入力してください。<br />
                  認証が完了するとアドレス変更用フォームが表示されます。
                </p>
                <Form
                  @submit="authenticate"
                  :initial-values="authInitialValues"
                  :validation-schema="authSchema"
                >
                  <Field
                    class="field-item"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="パスワードを入力"
                    autocomplete
                  />
                  <ErrorMessage
                    v-if="!errorState.invalidPassword && !errorState.tooManyFailed"
                    class="text-red-600"
                    name="password"
                  />
                  <div
                    v-else-if="errorState.invalidPassword"
                    class="text-red-600"
                  >
                    パスワードが間違っています
                  </div>
                  <div
                    v-else-if="errorState.tooManyFailed"
                    class="text-red-600"
                  >
                    時間を空けてから再度お試しください
                  </div>
                  <div class="flex flex-cols-4 justify-between">
                    <button
                      @click="$emit('closeModal')"
                      class="w-32 p-2 mt-4 text-center text-white bg-gray-500 border rounded hover:bg-red-600 focus:outline-none"
                    >
                      キャンセル
                    </button>
                    <button
                      @click="initErrorState"
                      v-show="!isAuthenticated"
                      class="block w-32 p-2 mt-4 text-center text-white bg-red-500 border rounded  hover:bg-red-600 focus:outline-none"
                    >
                      OK
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div
            v-if="isAuthenticated"
            class="p-5"
          >
            <div class="mb-4 header">
              <h1 class="font-bold text-gray-700">
                新規メールアドレス入力
              </h1>
            </div>
            <div class="body">
              <span class="text-gray-400">
                ※パスワード認証済み
              </span>
              <p class="mb-6 text-base text-gray-700">
                新しいメールアドレスを入力してください。<br />
                新しいメールアドレスに認証用メールが送信されます。
              </p>
              <Form
                @submit="send"
                :initial-values="changeMailInitialValues"
                :validation-schema="changeMailSchema"
              >
                <Field
                  class="field-item"
                  id="email"
                  name="email"
                  placeholder="メールアドレスを入力"
                />
                <ErrorMessage class="text-red-600" name="email" />
                <div class="flex flex-cols-4 justify-between">
                  <button
                    @click="$emit('closeModal')"
                    class="w-32 p-2 mt-4 text-center text-white bg-gray-500 border rounded hover:bg-red-600 focus:outline-none"
                  >
                    キャンセル
                  </button>
                  <button
                    class="w-32 p-2 mt-4 text-center text-white bg-red-500 border rounded  hover:bg-red-600 focus:outline-none"
                  >
                    OK
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="fixed inset-0 z-40 bg-black opacity-70"
    ></div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import firebase from "firebase/app";
import { Form, Field, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja";

export default {
  components: { Form, Field, ErrorMessage },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  setup(_props, { emit }) {
    const email = firebase.auth().currentUser.email;
    const errorState = reactive({
      invalidPassword: false,
      tooManyFailed: false
    });
    const authErrorList = {
      invalidPassword: "auth/wrong-password",
      tooManyFailed: "auth/tooManyRequests"
    };

    const callParentMethod = () => {
      emit("closeModal");
    };

    const changeMailSchema = yup.object({
      email: yup.string().label("メールアドレス").email(),
    });

    const changeMailInitialValues = reactive({
      email: "",
    });

    const authSchema = yup.object({
      password: yup.string().required().min(8).label("パスワード"),
    });

    const authInitialValues = reactive({
      password: "",
    });

    const isAuthenticated = ref(false)
    const authenticate = (values) => {
      const password = values.password
      const credential = firebase.auth.EmailAuthProvider.credential(email, password)
      const user = firebase.auth().currentUser;
      user.reauthenticateWithCredential(credential)
        .then(() => {
          isAuthenticated.value = true;
        }).catch(e => {
          if (e.code == authErrorList.invalidPassword) {
            errorState.invalidPassword = true;
          } else if (e.code == authErrorList.tooManyFailed) {
            errorState.tooManyFailed = true;
          }
        });
    };

    const initErrorState = () => {
      errorState.invalidPassword = false;
      errorState.tooManyFailed = false;
    };

    const send = (values) => {
      // emit("showSuccessModal");
      const user = firebase.auth().currentUser;
      user.updateEmail(values.email)
      .then(() => {
        user.sendEmailVerification()
        emit("showSuccessModal");
      })
      .catch((error) => {
        console.error(error.message)
      })
    };

    return {
      errorState,
      changeMailSchema,
      changeMailInitialValues,
      authSchema,
      authInitialValues,
      isAuthenticated,
      email,
      authenticate,
      send,
      initErrorState,
      callParentMethod
    };
  },
};
</script>

<style lang="postcss" scoped>
.field-item {
  @apply flex block w-72 px-4 py-2 mb-4 text-gray-700 shadow border rounded;
}
</style>