<template>
  <div>
    <div
      v-if="showModal"
      @click.self="callParentToggleModal"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div
        class="w-80 max-w-6xl max-h-screen mx-auto my-6"
      >
        <div class="w-full bg-white rounded-lg">
          <div class="flex flex-col items-center justify-center p-5">
            <div class="my-8">
              <img
                src="../../../assets/businessman.png"
                alt="success"
                width="100"
                height="100"
              />
            </div>
            <h1 class="mb-8 font-bold text-gray-700">
              アカウントを削除してよろしいですか？
            </h1>
            <p class="mb-2 text-base text-gray-700">
              アカウントを削除するとピンやボードを利用できなくなり、<br>
              Spitchアカウントのデータはすべて削除されます。
            </p>
          </div>
          <div class="p-4 flex justify-between">
            <button
              @click="$emit('closeModal')"
              class="w-32 p-2 mt-1 text-center text-white bg-gray-500 border rounded md:mx-2 hover:bg-red-600 focus:outline-none"
            >
              キャンセル
            </button>
            <button
              @click="deleteAccount"
              class="w-32 p-2 mt-1 text-center text-white bg-red-500 border rounded md:mx-2 hover:bg-red-600 focus:outline-none"
            >
              削除する
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-70"></div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    deleteAccount: {
      type: Function,
      required: true
    },
  },

  methods: {
    callParentToggleModal() {
      this.$emit("closeModal");
    },
  },
};
</script>