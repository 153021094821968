<template>
  <div
    v-if="showModal"
    @click.self="closeModal"
    class="fixed inset-0 z-50 flex items-center justify-center"
  >
    <div class="w-auto max-w-6xl max-h-screen mx-auto my-6">
      <div class="w-full bg-white rounded-lg">
        <div class="p-8">
          <div class="mb-4 header">
            <h1 class="font-bold text-gray-700">
              <slot name="header"></slot>
            </h1>
          </div>
          <div class="mb-4 body">
            <p class="mb-2 text-base text-gray-700">
              <slot name="body"></slot>
            </p>
          </div>
          <div class="footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-70"></div>
</template>

<script>
export default {
  props: {
    showModal: Boolean
  },

  emits: [
    'closeModal'
  ],

  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    };

    return { closeModal };
  }
};
</script>