<template>
  <operation-modal :showModal="showPinModal" @toggleModal="closePinModal" class="">
    <!-- Header -->
    <template v-slot:header>ボードに保存する</template>

    <!-- Body -->
    <template v-slot:body>
      <div class="mb-4">
        <div class="flex items-center justify-center w-full h-8 bg-white bg-gray-100 border border-gray-200 rounded-lg focus:outline-none">
          <search-icon
            class="w-6 h-6 ml-2 text-gray-400 transition fill-current rounded-l-md "
          />
          <input
            class="w-full h-full pl-2 text-sm bg-gray-100 rounded-r-md focus:outline-none"
            type="search"
            placeholder="ボードの絞り込み..."
            v-model="state.filterKeyword"
            v-focus
          />
        </div>
      </div>

      <div v-for="board in boards" :key="board.id" class="mb-2">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <div
              class="flex items-center justify-center w-8 h-8"
            >
              <span class="text-xl">{{ board.emoji ? board.emoji : "🐟" }}‍</span>
            </div>
            <div class="flex-grow flex-shrink">
              <p v-if="board.visibility==='public'" class="text-gray-600 truncate text-md">#{{ board.name }}</p>
              <p v-if="board.visibility==='limited'" class="text-gray-600 truncate text-md">🔒{{ board.name }}</p>
            </div>
          </div>
          <button
            v-if="isPinOfBoard(board)"
            @click="removePinFromBoard(board)"
            class="text-xs text-white transition bg-gray-300 border  border-gray-300 button hover:bg-gray-400 hover:border-gray-400 hover:text-white shadow rounded px-2 py-1.5 focus:outline-none"
          >
            <span>✓ 保存済み</span>
          </button>
          <button
            v-else-if="
              !(board.visibility === 'public' && file.permission === 'limited')
            "
            @click="addPinIntoBoard(board)"
            class="text-xs text-gray-700 transition border border-gray-700 button hover:bg-gray-700 hover:text-white shadow rounded px-2 py-1.5 focus:outline-none disabled:opacity-30 disabled:cursor-not-allowed"
          >
            <span>＋ 保存する</span>
          </button>
          <span
            v-if="
              board.visibility === 'public' && file.permission === 'limited'
            "
            class="text-xs text-gray-300 px-2 py-1.5 focus:outline-none cursor-not-allowed"
          >
            <span>公開ボードに保存できません</span>
          </span>
        </div>
        <hr class="my-2 border-gray-100" />
      </div>
      <pin-modal-add-warning
        v-if="state.showWarning"
        @close-modal="state.showWarning = false"
        @add-pin="addPinIntoBoard"
      />
      <loading v-model:active="state.loading" color="#818589" />
    </template>
  </operation-modal>
</template>

<script>
import firebase from "firebase/app";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import OperationModal from "./BaseModal.vue";
import useBoardRepository from "../composables/useBoardRepository";
import Loading from "vue-loading-overlay";
import SearchIcon from "../../icons/SearchIcon.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import PinModalAddWarning from "./PinModalAddWarning.vue";
import utility from "../composables/utility"

export default {
  components: {
    OperationModal,
    PinModalAddWarning,
    Loading,
    SearchIcon
  },

  props: {
    showPinModal: {
      type: Boolean,
      required: true
    },
    closePinModal: {
      type: Function,
      required: true
    },
    file: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const route = useRoute();
    const store = useStore();
    const user = firebase.auth().currentUser;
    const fileType = route.params?.type === "slides" ? "slide" : "package";

    const state = reactive({
      showWarning: false,
      loading: false,
      tmpBoard: null,
      filterKeyword: "",
    });

    const { getMyBoard, getMyBoardToSideBar, getPublicBoard } = useBoardRepository();
    const userInfo = store.state.user.userInfo;
    const updateBoards = async () => {
      await getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid });
      await getMyBoardToSideBar({ workSpace: userInfo.workSpace, userId: user.uid });
      await getPublicBoard({ workSpace: userInfo.workSpace });
    };

    const { filterBoardByKeyword } = utility();
    const boards = computed(() => { 
      const myBoard = [...store.getters['board/myPrivateBoards'], ...store.getters['board/publicBoards']];
      return filterBoardByKeyword(myBoard, state.filterKeyword);
    });

    const isPinOfBoard = (board) => {
      return board.pins.some(x => x.pin_id === (fileType === 'slide' ? props.file.slideId : props.file.packageId));
    }

    const addPinIntoBoard = async (board = null) => {
      if (
        board &&
        board.visibility === "limited" &&
        props.file.permission === "limited"
      ) {
        state.tmpBoard = board;
        return (state.showWarning = true);
      }

      if (!board) {
        state.showWarning = false;
        board = state.tmpBoard;
      }

      state.loading = true;
      const { addPin } = useBoardRepository(board);
      const fileId =
        fileType === "package" ? props.file.packageId : props.file.slideId;
      await addPin(fileId, fileType).catch(error =>
        console.error("failed to add pin", error)
      );
      updateBoards();
      state.loading = false;
    };

    const removePinFromBoard = async board => {
      state.loading = true;
      const { removePin } = useBoardRepository(board);
      const fileId =
        fileType === "package" ? props.file.packageId : props.file.slideId;
      await removePin(fileId, fileType).catch(error =>
        console.error("failed to add pin", error)
      );
      updateBoards();
      state.loading = false;
    };

    return {
      state,
      boards,
      isPinOfBoard,
      addPinIntoBoard,
      removePinFromBoard
    };
  }
};
</script>

<style lang="postcss" scoped>
.button {
  @apply px-2 py-1 text-white rounded focus:outline-none;
}

.button-add {
  @apply bg-indigo-500 hover:bg-indigo-600;
}
</style>