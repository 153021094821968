<template>
  <div class="h-screen sm:grid sm:grid-cols-2">
    <aside
      class="bg-indigo-50 sm:flex sm:flex-col sm:justify-center sm:items-center sm:px-20"
    >
      <header class="my-4">
        <img
          class="mx-auto"
          src="../../assets/spitch-logo.svg"
          height="40"
          width="200"
          alt=""
        />
      </header>
      <p
        class="w-full mb-8 font-serif font-bold text-center text-md sm:text-5xl"
      >
        {{ subdomain }}に参加する
      </p>
      <img src="../../assets/signup-image.png" width="1024" height="1024" class="hidden sm:block" />
    </aside>
    <main class="flex flex-col items-center justify-center h-4/5 sm:h-auto">
      <signup-form />
      <router-link
        to="/signin"
        class="inline-block text-sm text-blue-600 align-baseline"
      >
        すでにアカウントをお持ちの方はこちら
      </router-link>
    </main>
  </div>
</template>

<script>
import SignupForm from "@/components/organisms/SignupForm";
import { computed } from "vue";

export default {
  components: {
    SignupForm,
  },

  setup() {
    const subdomain = computed(() => {
      const subdomain = location.host.split('.')[0];
      return subdomain;
    });

    return { subdomain };
  }
};
</script>