<template>
  <div>
    <div
      v-if="showMenu" @click.stop
      class="absolute z-50 w-48 p-1 origin-top-right bg-white border rounded-md shadow-lg max-h-60 left-48"
      :style="{ top: calcMenuTopPosition }"
    >
      <div class="py-1">
        <p @click="toggleModal('edit')" class="px-2 rounded cursor-pointer leading-12 hover:bg-gray-200" :class="{limited: !isBoardEditable}">🖋 編集</p>
        <p @click="toggleModal('invite')" class="px-2 rounded cursor-pointer leading-12 hover:bg-gray-200" :class="{limited: !isBoardEditable}">👥 メンバーを追加</p>
        <p @click="copyUrl" class="px-2 rounded cursor-pointer leading-12 hover:bg-gray-200">🔗 リンクをコピー</p>
        <p @click="leaveBoard" class="px-2 rounded cursor-pointer leading-12 hover:bg-gray-200">👋 ボードから退出</p>
        <p @click="toggleModal('delete')" class="px-2 rounded cursor-pointer leading-12 hover:bg-gray-200" :class="{limited: !isBoardEditable}">🗑 削除</p>
      </div>
      <hr>
      <div class="py-1">
        <p class="px-2 text-xs text-gray-400">最終更新者：北砂 太郎<br>2022/07/12</p>
      </div>
      <hr>
    </div>

    <div v-show="showMenu" @click="$emit('closeMenu')" class="fixed inset-0 z-10 w-full h-full"></div>

    <board-edit 
      v-if="state.mode == 'edit'"
      :toggle-board-modal="toggleModal"
      :change-operation-modal-flag="changeMode"
      :board-info="boardInfo"
      @update-board="updateBoard"
    />

    <user-invite
      v-else-if="state.mode == 'invite'"
      :toggle-board-modal="toggleModal"
      :change-operation-modal-flag="changeMode"
      :board-info="boardInfo"
      @board-update="updateBoard"
    />

    <board-delete
      v-else-if="state.mode == 'delete'"
      :toggle-modal="toggleModal"
      :change-operation-modal-flag="changeMode"
      :board-id="boardInfo.id"
      target='board'
      @update-board="updateBoard"
    />
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router";
import BoardEdit from "../modals/BoardModalEdit.vue";
import BoardDelete from "../modals/BoardModalDelete.vue";
import UserInvite from "../modals/BoardModalInviteMember.vue";
import useBoardRepository from "../composables/useBoardRepository";

export default {
  components: {
    BoardEdit,
    BoardDelete,
    UserInvite
  },

  props: {
    showMenu: {
      type: Boolean,
      required: true
    },
    boardInfo: {
      type: Object,
      required: true
    },
    top: {
      type: String,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      mode: '',
      showModal: false
    });

    const calcMenuTopPosition = computed(() => {
      return props.top;
    });

    const userInfo = store.state.user.userInfo;

    const isBoardEditable = computed(() => {
      const board = props.boardInfo;
      const isBoardOwner = board?.owners.includes(userInfo.id);
      return isBoardOwner;
    });

    const toggleModal = (mode) => {
      emit('closeMenu');
      state.mode = mode;
      state.showModal = !state.showModal;
    };

    const changeMode = (flag = "edit") => {
      state.mode = flag;
    };

    const { getMyBoard, removeMembers, getBoardInfo } = useBoardRepository();
    const updateBoard = async (mode='') => {
      if (mode == 'edit' && route.name == 'boardDetail' && props.boardInfo.id == route.params.id) { 
        await getBoardInfo({ target: props.boardInfo.id }) 
      }
      if (mode == 'delete' && route.name == 'boardDetail' && props.boardInfo.id == route.params.id) { 
        return router.push({ name: 'home' });
      }
      await getMyBoard({ workSpace: userInfo.workSpace, userId: userInfo.id });
      if (mode == 'invite') {
        emit('update-board');
      }
    }

    const copyUrl = async () => {
      const url = `${location.origin}/boards/${props.boardInfo.id}`;
      await navigator.clipboard.writeText(url);
      alert('リンクがクリップボードにコピーされました。');
    }

    const leaveBoard = async () => {
      const users = [{ user_id: userInfo.id, permission: '' }];
      const payload = { users, board_id: props.boardInfo.id };
      await removeMembers(payload);
      await updateBoard()
    };
  
    return {
      state,
      isBoardEditable,
      calcMenuTopPosition,
      toggleModal,
      changeMode,
      updateBoard,
      copyUrl,
      leaveBoard
    };
  }
};
</script>

<style lang="postcss" scoped>
.limited {
  @apply text-gray-400 pointer-events-none
}
/* .menu {
  top: v-bind(top);
} */
</style>