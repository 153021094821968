<template>
  <base-simple-modal
    :show-modal="true"
    @close-modal="closeModal"
  >
    <template v-slot:content>
      <div class="mx-3 my-2 title">
        <div class="flex items-center justify-between">
          <h2 class="font-bold ">ファイルアップロード</h2>
          <button @click="closeModal"><close-icon /></button>
        </div>
      </div>
      <div
        class="p-4 mb-1 overflow-y-auto rounded shadow-2xl vld-parent"
        style="height: 480px; width: 300px;"
      >
        <div class="mb-3">
          <p class="text-sm text-gray-400">
            ファイルフォーマットは「PPTX,PDF」のみに対応しています。
          </p>
        </div>
        <div class="content">
          <file-upload-form 
            :dropped-files="droppedFiles"
            :board-ids="boardIds"
            @before-upload="beforeUpload"
            @after-upload="afterUpload"
          />
        </div>
      </div>
    </template>
  </base-simple-modal>
  <upload-success-modal
    :show-modal="state.showSuccessModal"
    @close-modal="closeModal"
  />
</template>

<script>
import { useStore } from "vuex";
import { reactive } from "vue";
import BaseSimpleModal from "./BaseSimpleModal.vue";
import UploadSuccessModal from "../modals/FileUploadSuccessModal.vue";
import FileUploadForm from "../molecules/FileUploadForm.vue";
import CloseIcon from "../../icons/CloseIcon.vue";

export default {
  components: {
    BaseSimpleModal,
    UploadSuccessModal,
    FileUploadForm,
    CloseIcon
  },

  props: {
    droppedFiles: {
      type: Array,
      required: false
    },
    boardIds: {
      type: Array,
      default() { return [] },
      required: false
    }
  },

  emits: [
    'closeModal',
    'openSuccessModal',
    'toggleLoading'
  ],

  setup(_, { emit }) {
    const store = useStore();

    const state = reactive({
      showSuccessModal: false,
    });

    store.dispatch('upload/openModal');

    const beforeUpload = () => {
      emit('toggleLoading');
    };

    const afterUpload = () => {
      emit('toggleLoading');
      state.showSuccessModal = true;
    };

    const closeModal = () => {
      state.showSuccessModal = false;
      store.dispatch('upload/closeModal');
      emit('closeModal');
    };

    return {
      state,
      beforeUpload,
      afterUpload,
      closeModal
    };
  }
};
</script>