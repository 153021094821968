import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
const resource = "user";
export default {
  login(payload) {
    return Repository.post(`${version}-${resource}-login`, { json: payload });
  },

  signup(payload) {
    return Repository.post(`${version}-${resource}-signup`, { json: payload });
  },

  deletableUser(payload) {
    return Repository.post(`${version}-${resource}-deletableUser`, { json: payload });
  },

  deleteUser(payload) {
    return Repository.post(`${version}-${resource}-deleteUser`, { json: payload });
  },

  deleteUsers(payload) {
    return Repository.post(`${version}-${resource}-deleteUsers`, { json: payload });
  },

  updateProfile(payload) {
    return Repository.post(`${version}-${resource}-updateProfile`, { json: payload });
  },

  createProfile(payload) {
    return Repository.post(`${version}-${resource}-createProfile`, { json: payload });
  },

  createApiKeyForAlgolia(payload) {
    return Repository.post(`${version}-${resource}-createApiKeyForAlgolia`, { json: payload });
  },

  checkSignup(payload) {
    return Repository.post(`${version}-${resource}-checkSignup`, { json: payload });
  },

  checkEmail(payload) {
    return Repository.post(`${version}-${resource}-checkEmail`, { json: payload });
  },

  checkUserId(payload) {
    return Repository.post(`${version}-${resource}-checkUserId`, { json: payload });
  },

  createUserAfterSignup(payload) {
    return Repository.post(`${version}-${resource}-createUserAfterSignup`, { json: payload });
  },

  createUserMapping() {
    return Repository.post(`${version}-${resource}-createUserMapping`, { json: {} });
  },

  getUserInfo() {
    return Repository.get(`${version}-${resource}-getUserInfo`);
  },

  getAllUsers() {
    return Repository.get(`${version}-${resource}-getAllUsers`);
  },

  getUserInfoById (payload) {
    return Repository.post(`${version}-${resource}-getUserInfoById`, { json: payload });
  }

};