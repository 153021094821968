<template>
  <div class="relative flex items-end justify-between mb-4 filter-nav">
    <!-- Sort -->
    <div class="mr-2">
      <label class="toolbox-label">並び替え</label>
      <Multiselect
        class="select-sort"
        v-model="form.sort.value"
        mode="single"
        :classes="{
          optionSelected: 'bg-white font-semibold',
          optionSelectedPointed: 'bg-white opacity-90',
          containerActive: 'ring-2 ring-indigo-500 ring-opacity-30',
        }"
        :canDeselect="false"
        :canClear="false"
        :limit="10"
        :options="form.sort.options"
        @change="onChange"
      />
    </div>

    <div class="flex items-end justify-end w-full">
      <!-- ToolBox -->
      <transition
        enter-from-class="-translate-y-4 opacity-0"
        enter-active-class="transition duration-200 ease-out transform"
        enter-to-class="translate-y-0 opacity-1"
        leave-from-class="translate-y-0 opacity-1"
        leave-active-class="transition duration-200 ease-out transform"
        leave-to-class="-translate-y-4 opacity-0"
      >
        <div
          v-if="state.showFilter"
          class="flex items-center justify-end w-full"
        >
          <!-- Package Label -->
          <!-- <div class="filter-setting">
            <label for="slide-label" class="toolbox-label"
              >パッケージラベル</label
            >
            <Multiselect
              class="flex-grow filterbox"
              :classes="{
                optionSelected: 'text-white bg-gray-500',
                optionSelectedPointed: 'text-white bg-gray-500 opacity-90',
                containerActive: 'ring-2 ring-indigo-500 ring-opacity-30',
              }"
              v-model="form.labels.value"
              mode="tags"
              placeholder="工事中🚧"
              :max="1"
              :limit="10"
              :searchable="true"
              :disabled="true"
              
            />
          </div> -->
          <!-- Slide Label -->
          <div class="filter-setting">
            <label for="slide-label" class="toolbox-label"
              >スライドラベル</label
            >
            <Multiselect
              class="filterbox"
              :classes="{
                optionSelected: 'text-white bg-gray-500',
                optionSelectedPointed: 'text-white bg-gray-500 opacity-90',
                containerActive: 'ring-2 ring-indigo-500 ring-opacity-30',
              }"
              v-model="form.labels.value"
              mode="single"
              :groups="true"
              :groupSelect="false"
              :searchable="true"
              :placeholder="route.name == 'search' && route.params.type == 'slides' ? 'ラベルを選択' : 'スライド検索時のみ'"
              :max="1"
              :limit="-1"
              :options="form.labels.options"
              @change="onChange('labels')"
            />
          </div>

          <!-- Permission -->
          <div class="filter-setting">
            <label for="permission" class="toolbox-label">パーミッション</label>
            <Multiselect
              class="filterbox"
              :classes="{
                optionSelected: 'text-white bg-gray-500',
                optionSelectedPointed: 'text-white bg-gray-500 opacity-90',
                containerActive: 'ring-2 ring-indigo-500 ring-opacity-30',
              }"
              v-model="form.permission.value"
              mode="single"
              placeholder="権限を選択"
              :limit="10"
              :searchable="true"
              :options="form.permission.options"
              @change="onChange"
            />
          </div>

          <!-- Format -->
          <div class="filter-setting">
            <label for="format" class="toolbox-label">ファイル形式</label>
            <Multiselect
              class="filterbox"
              :classes="{
                optionSelected: 'text-white bg-gray-500',
                optionSelectedPointed: 'text-white bg-gray-500 opacity-90',
                containerActive: 'ring-2 ring-indigo-500 ring-opacity-30',
              }"
              v-model="form.format.value"
              mode="single"
              placeholder="ファイル形式を選択"
              :limit="10"
              :searchable="true"
              :options="form.format.options"
              @change="onChange"
            />
          </div>

          <!-- CreatedBy -->
          <div class="filter-setting">
            <label class="toolbox-label">作成者</label>
            <Multiselect
              class="filterbox"
              :classes="{
                optionSelected: 'text-white bg-gray-500',
                optionSelectedPointed: 'text-white bg-gray-500 opacity-90',
                containerActive: 'ring-2 ring-indigo-500 ring-opacity-30',
              }"
              v-model="form.createdBy.value"
              mode="single"
              placeholder="作成者を選択"
              :limit="10"
              :searchable="true"
              :options="form.createdBy.options"
              @change="onChange"
            />
          </div>
        </div>
      </transition>

      <!-- Button for Toggle Filter -->
      <div class="ml-2">
        <button
          @click="state.showFilter = !state.showFilter"
          class="flex items-center justify-center p-2 text-gray-800 bg-gray-200 rounded w-28 hover:bg-gray-300"
        >
          <filter-icon v-if="numFilter == 0" />
          <span
            v-else
            class="w-5 h-5 text-sm text-white bg-indigo-500 rounded-full"
            >{{ numFilter }}</span
          >
          <span class="ml-2 text-base">Filters</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onBeforeMount, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
import useUserRepository from "../composables/useUserRepository";
import FilterIcon from "../../icons/IcSharpFilterList.vue";

export default {
  components: {
    Multiselect,
    FilterIcon,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      showFilter: true,
      windowWidth: 0,
      isLargeWindow: true
    });

    const form = reactive({
      sort: {
        value: route.query.sort ? route.query.sort : "created_timestamp",
        options: [
          { label: "新着", value: "created_timestamp" },
          { label: "人気", value: "pageview" },
        ],
      },
      labels: {
        value: [],
        options: [
          { 
            label: '構成',
            options: [
              { label: '表紙', value: 'title' },
              { label: '目次', value: 'index' },
              { label: '中表紙', value: 'divider' },
              { label: 'コンテンツ', value: 'content' },
              { label: '裏表紙', value: 'end' },
            ]
          },
          {
            label: 'ダイアグラム',
            options: [
              { label: 'マップ', value: 'map' },
              { label: 'プロセス', value: 'process' },
              { label: 'フロー', value: 'flow' },
              { label: 'スケジュール', value: 'schedule' },
              { label: 'ツリー', value: 'tree' },
              { label: 'サークル', value: 'circle' },
              { label: 'ピラミッド', value: 'pyramid' },
            ]
          },
          {
            label: 'グラフ',
            options: [
              { label: 'バーチャート', value: 'bar-chart' },
              { label: 'ラインチャート', value: 'line-chart' },
              { label: 'エリアチャート', value: 'area-chart' },
              { label: 'パイチャート', value: 'pie-chart' },
              { label: 'ドットチャート', value: 'dot-chart' },
              { label: 'バブルチャート', value: 'bubble-chart' },
              { label: 'ウォーターチャート', value: 'waterfall-chart' },
              { label: 'レーダーチャート', value: 'radar-chart' },
              { label: 'ヒートマップ', value: 'heatmap' }
            ]
          },
          {
            label: 'その他',
            options: [
              { label: 'メッセージ', value: 'message' },
              { label: 'マトリクス', value: 'matrix' },
              { label: 'コンセプト', value: 'concept' },
              { label: '並列', value: 'parallel' },
              { label: 'アイコン', value: 'icon'},
              { label: '写真', value: 'photo'},
              { label: 'ポップ', value: 'pop'},
              { label: 'シック', value: 'chic'}
            ]
          },
          {
            label: '色',
            options: [
              { label: '赤', value: 'red' },
              { label: '青', value: 'blue' },
              { label: '緑', value: 'green' },
              { label: '灰', value: 'gray' },
              { label: 'カラフル', value: 'colorful' }
            ]
          },
          {
            label: 'サイズ',
            options: [
              { label: 'スライド(16:9)', value: '16:9' },
              { label: 'スライド(4:3)', value: '4:3' },
              { label: 'ドキュメント', value: 'document' },
              { label: 'スプレッドシート', value: 'spreadsheet' }
            ]
          }
        ],
      },
      createdBy: {
        value: "",
        options: [],
      },
      permission: {
        value: "",
        options: [
          { label: "公開", value: "public" },
          { label: "非公開", value: "limited" },
        ],
      },
      format: {
        value: "",
        options: [
          { label: "PPTX", value: "pptx" },
          { label: "PDF", value: "pdf" },
        ],
      },
    });
    // リアクティブに変えたい
    const calcWidth = () => {
      state.windowWidth = window.innerWidth;
      return state.isLargeWindow = state.windowWidth > 1300;
    }

    const numFilter = computed(() => {
      const num = Object.keys(form).filter((key) => {
        return !(
          form[key].value == "" ||
          form[key].value == null ||
          (Array.isArray(form[key].value) && form[key].value?.length == 0)
        );
      }).length;
      return num - 1;
    });

    const buildQuery = () => {
      const keys = Object.keys(form);
      const query = {};
      for (const key of keys) {
        const value = form[key].value;
        if (value && key === "labels" && typeof value === "string") {
          query[key] = [value];
        } else if (value) {
          query[key] = value;
        }
      }
      return query;
    };

    const onChange = async () => {
      // Wait a sec while setting the data into form object with multiselect component
      await new Promise((resolve) => setTimeout(resolve, 1));
      const query = buildQuery();

      if (route.name === "search") {
        if (route.query?.q) query.q = route.query.q;

        if (route.params.type == "packages") {
          router.push({ path: "/search/packages", query: query });
        } else if (route.params.type == "slides") {
          router.push({ path: "/search/slides", query: query });
        }
      } else {
        if ("labels" in query && query.labels?.length > 0) {
          router.push({ path: "/search/slides", query: query });
        } else {
          router.push({ name: "home", query: query });
        }
      }
    };

    const { getUsers } = useUserRepository();

    const getAllUsers = async () => {
      const userInfo = store.state.user.userInfo;
      const users = await getUsers({ workSpace: userInfo.workSpace });
      form.createdBy.options = users.map((user) => {
        return { label: `${user.lastName} ${user.firstName}`, value: user.id };
      });
    };

    onBeforeMount(() => {
      getAllUsers();

      // Initialize toolbox
      const query = router.currentRoute.value.query;
      const keys = Object.keys(query);

      for (const key of keys) {
        if (!(key in form)) return;
        if (key == "labels" && typeof query[key] == "string") {
          form[key].value = [query[key]];
        } else {
          form[key].value = query[key];
        }
      }
    });

    onMounted(() => {
      // Toolbox is opened when filter condition exists.
      // state.showFilter = numFilter.value > 0;  // Diabled default of toolbox is opend.
      window.addEventListener("resize", calcWidth);
    });

    return {
      state,
      form,
      onChange,
      numFilter,
      route,
    };
  },
};
</script>

<style lang="postcss" scoped>
.filter-setting {
  @apply mx-2 flex-grow flex-shrink;
  min-width: 192px;
  max-width: 240px;
}

.filterbox {
  --ms-tag-bg: #cecdca80;
  --ms-tag-color: #37352f;
  --ms-tag-font-weight: 400;
  --ms-option-font-size: 0.875rem;
  @apply text-sm;
}

.toolbox-label {
  @apply block text-xs text-gray-600;
}

.select-sort {
  --ms-option-font-size: 0.875rem;
  --ms-ring-color: #ffffff00;
  @apply w-28;
}
</style>