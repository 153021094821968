<template>
  <div class="flex">
    <div class="flex flex-col w-screen h-screen bg-gray-100">
      <header-top />
      <main class="flex flex-col items-center justify-center h-full py-4 overflow-y-scroll" style="">
        <file-uploader />
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import HeaderTop from "../organisms/TheHeaderTop.vue";
import FileUploader from "../organisms/FileUploader.vue"

export default {
  components: {
    HeaderTop,
    FileUploader,
  },

  setup() {
    let showSidebar = ref(true);

    const toggleSidebar = () => {
      showSidebar.value = !showSidebar.value;
    };

    return {
      showSidebar,
      toggleSidebar,
    };
  }
};
</script>