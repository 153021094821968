import firebase from "firebase/app";
import "firebase/auth";

export default {
  namespaced: true,
  state: {
    userUid: "",
    loggedIn: false,
    photoURL: "",
    displayName: ""
  },

  mutations: {
    loginStatusChange(state, status) {
      state.loggedIn = status;
    },
    setUserUid(state, userUid) {
      state.userUid = userUid;
    },
    setLoginUser(state, user) {
      state.userUid = user.uid;
      state.loggedIn = user?.uid ? true : false;
      state.photoURL = user.photoURL;
      state.displayName = user.displayName;
    }
  },

  actions: {
    onAuth({ commit }) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          commit("setLoginUser", user);
        }
      });
    },
  }
};
