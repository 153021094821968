<template>
  <div class="flex-auto search-form">
    <div
      class="flex h-10 bg-white border hover:shadow-lg rounded-full"
      v-bind:class="{ 'search-focus': state.focus }"
    >
      <div
        class="flex items-center h-full overflow-hidden group"
      >
        <div class="h-full mx-3">
          <search-icon class="fill-current w-6 h-6 mt-2" />
        </div>
      </div>
      <div class="flex items-center w-full">
        <input
          class="w-full h-full pl-1 pr-3 bg-white focus:outline-none rounded-full"
          type="text"
          placeholder="検索する"
          v-model="state.text"
          v-on:focus="focus"
          @input="inputText"
        />
        <button
          v-if="state.text"
          @click="clearText"
          class="flex items-center justify-center mr-1"
        >
          <clear-text-icon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import { useRoute } from "vue-router";
import SearchIcon from "../../icons/SearchIcon.vue";
import ClearTextIcon from "../../icons/ClearTextIcon.vue";

export default {
  components: {
    SearchIcon,
    ClearTextIcon
  },
  props: {
    searchHistory: {
      type: String
    }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const state = reactive({
      type: route.path.includes("/slides") ? "slides" : "packages",
      text: route.query?.q ?? "",
      focus: false,
      allWords: []
    });
    const keys = Object.keys(localStorage).filter(
      k => k.includes("word")
    );
    keys.forEach(key => state.allWords.push(localStorage[key]));

    watch(() => props.searchHistory, () => {
      state.text = props.searchHistory;
      emit("get-text", state.text);
    });

    const focus = () => {
      state.focus = true;
      if (route.path != "/search") return;
      emit("focused");
    };
    const inputText = target => {
      // IME対応
      if (target.inputType == "insertCompositionText") {
        state.text = target.data;
        return;
      }
      emit("get-text", state.text);
    };
    const clearText = () => {
      state.text = "";
      emit("get-text", state.text);
    };

    return {
      state,
      focus,
      blur,
      inputText,
      clearText
    };
  },
};
</script>

<style lang="postcss" scoped>
.search-focus {
  @apply ring-2 ring-indigo-400;
}
</style>