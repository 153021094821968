<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="showDownloadMenu"
      @click.stop
      class="absolute z-20 text-gray-700 bg-white rounded-md shadow-lg"
      :class="state.customClass"
      style="min-width: 240px;"
    >
      <div class="relative">
        <h1 class="px-4 py-2 text-base font-bold bg-gray-100 rounded-md">
          📌 ボードに保存
        </h1>
        <div class="relative px-4 py-2 overflow-y-scroll" style="max-height: 320px;">
          <div class="flex items-center justify-center w-full h-6 mt-1 mb-2 bg-white bg-gray-100 border border-gray-200 rounded-lg focus:outline-none">
            <search-icon
              class="w-6 h-6 ml-2 text-gray-400 transition fill-current rounded-l-md "
            />
            <input
              class="w-full h-full pl-2 text-sm bg-gray-100 rounded-r-md focus:outline-none"
              type="search"
              placeholder="ボードの絞り込み..."
              v-model="state.filterKeyword"
              v-focus
            />
          </div>
          <div v-for="board in boards" :key="board">
            <div class="flex items-center justify-between">
              <p class="w-32 text-sm leading-7 truncate">
                <span class="mr-1">{{ board.emoji ? board.emoji : "🐟" }}</span
                >{{ board.name }}
              </p>
              <button
                v-if="!pinExists(board)"
                class="border-solid px-2 py-0.5 text-xs text-gray-700 bg-white rounded border border-gray-500 hover:text-white hover:bg-gray-500 disabled:opacity-30 disabled:cursor-not-allowed"
                @click="storePin(pinInfo, board)"
                :disabled="board.visibility === 'public' && pinInfo.permission === 'limited'"
              >
                保存する
              </button>
              <button
                v-else
                @click="deletePin(pinInfo, board.id)"
                class="border-solid px-2 py-0.5 text-xs text-red-500 border-red-500 rounded border hover:text-white hover:bg-red-500"
              >
                取り消す
              </button>
            </div>
          </div>
        </div>
        <div v-if="state.showWarning" class="absolute inset-0 flex flex-col items-center justify-center w-full h-32 px-2 m-auto bg-gray-600 rounded bg-opacity-90">
          <button class="absolute top-0 right-2" @click="state.showWarning = false">x</button>
          <p class="pt-4 text-sm text-center text-white">非公開ボードに属しているユーザが閲覧できるようになります。</p>
          <div class="flex items-center pt-4">
            <button @click="storePin(pinInfo, null, ignoreWarning=true)" class="rounded mx-2 px-1 bg-indigo-500 py-0.5 text-white w-16 hover:bg-indigo-600">はい</button>
            <button @click="state.showWarning = false" class="rounded mx-2 px-1 bg-red-500 py-0.5 text-white w-16 hover:bg-red-600">いいえ</button>
          </div>
        </div>
      </div>
      <loading
        v-model:active="state.showLoading"
        :is-full-page="false"
        color="#818589"
      />
    </div>
  </transition>
</template>

<script>
import firebase from "firebase/app";
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import utility from "../composables/utility";
import useBoardRepository from "../composables/useBoardRepository";
import SearchIcon from "../../icons/SearchIcon.vue";

export default {
  components: {
    Loading,
    SearchIcon
  },

  props: {
    pinInfo: {
      type: Object,
      required: true
    },
    showDownloadMenu: {
      type: Boolean
    },
    placement: {
      type: String,
      default: 'right'
    }
  },

  setup(props) {
    const store = useStore();
    const user = firebase.auth().currentUser;

    const state = reactive({
      showLoading: false,
      showWarning: false,
      boardId: '',
      customClass: { "bottom": props.placement === 'bottom', "right": props.placement === 'right' },
      filterKeyword: "",
    });

    const { filterBoardByKeyword } = utility();
    const boards = computed(() => {
      const myBoard = [...store.getters['board/defaultBoards'], ...store.getters['board/customBoards']];
      return filterBoardByKeyword(myBoard, state.filterKeyword);
    });

    const { getMyBoard, getMyBoardToSideBar, getPublicBoard } = useBoardRepository();
    const userInfo = store.state.user.userInfo;
    const updateBoard = async () => {
      await getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid });
      await getMyBoardToSideBar({ workSpace: userInfo.workSpace, userId: user.uid });
      await getPublicBoard({ workSpace: userInfo.workSpace });
    };

    const { addPin, removePin } = useBoardRepository();

    const showWarning = (visibility, permission) => {
      return visibility === 'limited' && permission === 'limited' 
    }

    const storePin = async (pinInfo, boardInfo, ignoreWarning=false) => {
      const boardId = boardInfo ? boardInfo.id : state.boardId;
      state.boardId = boardId;

      if (!ignoreWarning && showWarning(boardInfo.visibility, pinInfo.permission)) {
        return state.showWarning = true;
      } else {
        state.showWarning = false;
      }

      state.showLoading = true;
      await addPin(pinInfo.id, pinInfo.target, boardId);
      await updateBoard();

      state.showLoading = false;
    };

    const deletePin = async (pinInfo, boardId) => {
      state.showLoading = true;
      await removePin(pinInfo.id, pinInfo.target, boardId);
      await updateBoard();
      state.showLoading = false;
    };

    const pinExists = (board) => {
      const { target, id } = props.pinInfo;
      if (target === 'package') {
        return board.pins.some(pin =>pin.pin_type === 'package' && pin.pin_id === id);
      } else if (target === 'slide') {
        return board.pins.some(pin =>pin.pin_type === 'slide' && pin.pin_id === id);
      }
    }

    return {
      state,
      boards,
      storePin,
      deletePin,
      pinExists
    };
  }
};
</script>

<style lang="postcss" scoped>
.bottom {
  @apply mt-4 top-full left-0;
}

.right {
  @apply ml-4 top-4 left-14;
}
</style>