<template>
  <header class="z-30 w-full bg-gray-600 global-nav">
    <div class="header-top h-15 px-10 py-1.5 flex justify-between items-center">
      <div class="flex items-center flex-none transform header-logo hover:-translate-y-px">
        <router-link to="/">
          <logo-horizon width="150" height="25" class="" />
        </router-link>
      </div>

      <div class="flex flex-none ml-10">
        <div class="text-center">
          <!-- <router-link
            :to="{ name: 'upload' }"
            class="flex items-center p-1 mx-1 text-lg text-gray-100 transition-colors duration-150 border-b-2 border-gray-600 font-extralight hover:text-white hover:border-white"
          >
            <file-upload-icon class="w-7 h-7" />
            <span class="mx-1">Upload</span>
          </router-link> -->
          <router-link
            :to="{ name: 'upload' }"
            class="flex items-center px-2 py-1 mx-1 font-bold text-gray-600 bg-gray-100 rounded hover:bg-gray-50"
          >
            <file-upload-icon class="w-7 h-7" />
            <span class="mx-1">アップロード</span>
          </router-link>
        </div>
      </div>

      <div class="flex-auto header-middle">
        <slot name="middle"></slot>
      </div>

      <div class="relative flex items-center">
        <slot name="right"></slot>
      </div>
    </div>

    <nav class="flex items-center px-10 mt-1 shadow header-nav h-11 bg-gray-50">
      <router-link to="/" class="nav-item" :class="{active: name=='home'}">
        <home-icon class="nav-icon"/>
        <span class="nav-label">ホーム</span>
      </router-link>
      <div class="w-px bg-gray-200 h-4/6"></div> 
      <router-link to="/boards" class="nav-item" :class="{active: path.includes('/boards')}">
        <bookmark-icon class="nav-icon"/>
        <span class="nav-label">ボード</span>
      </router-link>
      <div class="w-px bg-gray-200 h-4/6"></div> 
      <router-link :to="{ name: 'trend' }" class="nav-item" :class="{active: name=='trend'}">
        <trending-up-icon class="nav-icon" />
        <span class="nav-label">トレンド</span>
      </router-link>
      <div class="w-px bg-gray-200 h-4/6"></div> 
      <TooltipText
        text="作成予定のページ"
        placement="top"
      >
        <router-link :to="{ name: 'ranking' }" class="pointer-events-none nav-item" :class="{active: name=='ranking'}">
          <person-icon class="nav-icon" />
          <span class="nav-label">ランク</span>
        </router-link>
      </TooltipText>
      <div class="w-px bg-gray-200 h-4/6"></div> 
      <TooltipText
        text="作成予定のページ"
        placement="top"
      >
        <router-link :to="{ name: 'needs' }" class="pointer-events-none nav-item" :class="{active: name=='needs'}">
          <request-icon class="nav-icon"/>
          <span class="nav-label">ニーズ</span>
        </router-link>
      </TooltipText>
      <div class="w-px bg-gray-200 h-4/6"></div> 
      <TooltipText
        text="作成予定のページ"
        placement="top"
      >
        <router-link :to="{ name: 'analytics' }" class="pointer-events-none nav-item" :class="{active: name=='analytics'}">
          <today-icon class="nav-icon"/>
          <span class="nav-label">分析</span>
        </router-link>
      </TooltipText>
    </nav>
  </header>
</template>

<script>
import TooltipText from "./utilities/TooltipContent.vue";
import BookmarkIcon from "../icons/BookmarkIcon.vue";
import PersonIcon from "../icons/PersonIcon.vue";
import TodayIcon from "../icons/TodayIcon.vue";
import TrendingUpIcon from "../icons/TrendingUpIcon.vue";
import FileUploadIcon from "../icons/FileUploadIcon.vue";
import HomeIcon from "../icons/HomeIcon.vue";
import RequestIcon from "../icons/RequestIcon.vue";
import LogoHorizon from "../icons/LogoHorizon.vue";

import { reactive, computed } from "vue"
import { useRoute, useRouter } from "vue-router"

export default {
  components: {
    TooltipText,
    BookmarkIcon,
    PersonIcon,
    TodayIcon,
    TrendingUpIcon,
    FileUploadIcon,
    HomeIcon,
    RequestIcon,
    LogoHorizon
  },
  setup() {
    const route = useRoute();
    const name = computed(() => route.name);
    const path = computed(() => route.path);

    return { name, path };
  },
};
</script>


<style lang="postcss" scoped>
.nav-icon{
    @apply h-6 w-6
}
.nav-label{
    @apply pl-1
}
.nav-item {
  @apply h-full flex items-center justify-center flex-none tracking-tight  text-gray-400 py-1 px-2 w-28 border-b-2 border-white transition-colors duration-150 hover:text-gray-900 hover:border-gray-900
}

.active {
  @apply  text-gray-900 border-gray-900;
}
</style>