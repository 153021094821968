<template>
  <div
    v-if="showModal"
    @click.self="cancel"
    class="fixed inset-0 z-50 flex items-center justify-center outline-none focus:outline-none"
  >
    <div
      class="flex my-8 rounded"
      style="width: 90%; max-height: 75%"
    >
      <div
        class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
      >
        <div
          class="flex items-center justify-between p-3 text-gray-700 bg-gray-100 border-b border-solid rounded-t border-blueGray-200"
        >
          <h3 class="text-xl font-semibold">
            ボードを削除しますか？
          </h3>
          <button
            @click="cancel"
            class="transition duration-100 transform shadow-2xl w-7 h-7 hover:scale-105 hover:rotate-90"
          >
            <close-icon class="w-7 h-7" />
          </button>
        </div>

        <div class="relative flex-auto py-2 mx-4 overflow-y-auto">
          <p class="mb-8">
            ボードおよびボード内に保存したピンを削除すると復元できません
          </p>
          <form-button
            title="完全に削除する"
            className="button button-red"
            v-on="target=='board' ? { click: deleteBoard } : { click: removePin }"
          />
          <form-button
            title="キャンセル"
            className="button button-gray"
            @click="cancel"
          />
          <loading
            v-model:active="state.loading"
            color="#818589"
            :is-full-page="false"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-70"></div>
</template>

<script>
import { reactive } from "vue"
import { useRoute } from "vue-router"
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
import FormButton from "../../atoms/FormButton.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    FormButton,
    Loading
  },

  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    changeOperationModalFlag: {
      type: Function,
      required: true,
    },
    boardId: {
      type: String,
      required: false,
    },
    pinInfo: {
      type: Object,
      required: false,
    },
    target: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      loading: false
    })

    const cancel = () => {
      props.changeOperationModalFlag('edit')
    }

    const deleteBoard = async () => {
      state.loading = true;
      const payload = { targets: [props.boardId] };
      const result = await BoardRepository.delete(payload).json();

      emit("updateBoard");
      state.loading = false;

      if (result.status === "200") {
        await new Promise(resolve => setTimeout(resolve, 100));
        emit("closeModal");
      }
    }

    const route = useRoute()

    const removePin = async () => {
      state.loading = true;
      const payload = {
        pins: [
          {
            pin_id: props.pinInfo.pin_id,
            pin_type: props.pinInfo.pin_type,
          },
        ],
        board_id: route.params.id,
      };
      const result = await BoardRepository.removePin(payload).json();
      if (result.status === "200") {
        props.toggleModal();
        emit("pin-update");
      }
      state.loading = false;
    }

    return {
      state,
      cancel,
      deleteBoard,
      removePin
    }
  }
};
</script>

<style lang="postcss" scoped>
.button {
  @apply w-full border-none font-semibold px-6 py-3 rounded outline-none focus:outline-none mb-2 ease-linear transition-all duration-150;
}

.button-red {
  @apply bg-red-500 hover:bg-red-600 text-white
}

.button-gray {
  @apply bg-gray-100 hover:bg-gray-200
}
</style>