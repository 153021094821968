<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M208 80h-36V52a44 44 0 0 0-88 0v28H48a16.018 16.018 0 0 0-16 16v112a16.018 16.018 0 0 0 16 16h160a16.018 16.018 0 0 0 16-16V96a16.018 16.018 0 0 0-16-16zM100 52a28 28 0 0 1 56 0v28h-56zm108 156H48V96h160l.01 112zm-68-56a12 12 0 1 1-12-12a12.014 12.014 0 0 1 12 12z"
      fill="currentColor"
    ></path>
  </svg>
</template>