<template>
  <div class="tooltip-box group">
    <slot />
    <div
      :id="state.elementId"
      class="tooltip group-hover:opacity-100"
      :class="customClass.tooltip"
      :style="{ marginTop: style.marginTop }"
    >
      <span class="arrow text" :class="customClass.arrow">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { reactive, onMounted, toRefs } from "vue";

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: "top"
    },
    tooltipClasses: {
      type: String,
      default: ""
    },
    arrowClasses: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    const state = reactive({
      elementId: nanoid(),
      tooltipWidth: "",
      tooltipHeight: ""
    });

    const style = reactive({
      marginLeft: "",
      marginTop: ""
    });

    const customClass = reactive({
      tooltip: {},
      arrow: {}
    });

    const initializeClass = () => {
      const { placement, tooltipClasses, arrowClasses } = toRefs(props);
      const parseClasses = (x, y) => {
        x[y] = true;
        return x;
      };

      const placementClass = {
        "tooltip-top": placement.value == "top",
        "tooltip-right": placement.value == "right",
        "tooltip-bottom": placement.value == "bottom",
        "tooltip-left": placement.value == "left"
      };
      const tooltipClass = tooltipClasses.value
        .split(" ")
        .reduce(parseClasses, {});
      customClass.tooltip = Object.assign(tooltipClass, placementClass);

      const arrowDirectionClass = {
        "arrow-top": placement.value == "bottom",
        "arrow-right": placement.value == "left",
        "arrow-bottom": placement.value == "top",
        "arrow-left": placement.value == "right"
      };
      const arrowClass = arrowClasses.value.split(" ").reduce(parseClasses, {});
      customClass.arrow = Object.assign(arrowClass, arrowDirectionClass);
    };

    initializeClass();

    onMounted(() => {
      state.tooltipWidth = document.getElementById(
        `${state.elementId}`
      ).clientWidth;

      state.tooltipHeight = document.getElementById(
        `${state.elementId}`
      ).clientHeight;

      style.marginLeft = `-${state.tooltipWidth / 2}px`;
      style.marginTop =
        props.placement === "right" || props.placement === "left"
          ? `-${state.tooltipHeight / 2}px`
          : "0px";
    });

    return { state, style, customClass };
  }
};
</script>

<style scoped>
/* .arrow::after {
  content: " ";
  position: absolute;
  border-width: 7px;
  border-style: solid;
}

.arrow-bottom::after {
  top: 100%;
  left: 50%;
  margin-left: -7px;
  border-color: rgba(55, 65, 81) transparent transparent transparent;
}

.arrow-top::after {
  bottom: 100%;
  left: 50%;
  margin-left: -7px;
  border-color: transparent transparent rgba(55, 65, 81) transparent;
}

.arrow-left::after {
  right: 100%;
  bottom: 50%;
  margin-bottom: -7px;
  border-color: transparent rgba(55, 65, 81) transparent transparent;
}

.arrow-right::after {
  left: 100%;
  bottom: 50%;
  margin-bottom: -7px;
  border-color: transparent transparent transparent rgba(55, 65, 81);
} */
</style>

<style lang="postcss" scoped>
.tooltip-box {
  @apply relative inline-block;
}

.tooltip {
  @apply absolute z-10 w-40 py-1 text-white transition duration-300 opacity-0 pointer-events-none;
}

.tooltip-top {
  /* @apply -top-full; */
  @apply bottom-full;
}

.tooltip-bottom {
  /* @apply -bottom-full; */
  @apply top-full;
}

.tooltip-right {
  @apply left-full top-1/2;
}

.tooltip-left {
  @apply right-full top-1/2;
}

.text {
  @apply bg-gray-900 inline-block text-xs px-2 py-1 rounded;
}
</style>