import { createWebHistory, createRouter } from 'vue-router'

import firebase from 'firebase/app'
import 'firebase/auth'
import store from '@/store/index'

import TopPage from '../components/pages/TopPage'
import SigninPage from '@/components/pages/SigninPage'
import SignupPage from '@/components/pages/SignupPage'
import MailVerifySignupPage from '@/components/pages/MailVerifySignupPage'
import MailVerifySigninPage from '@/components/pages/MailVerifySigninPage'
import PasswordResetPage from '@/components/pages/PasswordResetPage'
import BoardTopPage from '@/components/pages/BoardTopPage'
import BoardDetailPage from '@/components/pages/BoardDetailPage'
import SettingsPage from '../components/pages/SettingsPage.vue'
import Profile from '../components/organisms/settings/TheProfile.vue'
import Account from '../components/organisms/settings/TheAccount.vue'
import HomeCustom from '../components/organisms/settings/TheHomeCustom.vue'
import Member from '../components/organisms/settings/TheMember.vue'
import Notification from '../components/organisms/settings/TheNotification.vue'
import Payment from '../components/organisms/settings/ThePayment.vue'
import Introduce from '../components/organisms/settings/TheIntroduce.vue'
import Theme from '../components/organisms/settings/TheTheme.vue'
import Workspace from '../components/organisms/settings/TheWorkspace.vue'
import Connect from '../components/organisms/settings/TheConnect.vue'
import UserProfilePage from '../components/pages/UserProfilePage.vue'
import FileUploadPage from '../components/pages/FileUploadPage.vue'
import PackageDetailPage from '../components/pages/PackageDetailPage.vue'
import TagPage from '../components/pages/TopTagPage.vue'
import NotFound from '../components/pages/NotFoundPage.vue'
import TopHome from '../components/organisms/TopHome.vue'
import TopTrend from '../components/pages/TopTrend.vue'
import TopNeeds from '../components/pages/TopNeeds.vue'
import TopRanking from '../components/pages/TopRanking.vue'
import TopAnalytics from '../components/pages/TopAnalytics.vue'
import TopSearch from '../components/organisms/TopSearch.vue'
import SearchTop from '../components/pages/SearchPage.vue'
import SettingsTop from '../components/mobile/templates/SettingsTop.vue'
import MobileAccount from '../components/mobile/templates/AccountSetting.vue'
import MobileHomeCustom from '../components/mobile/templates/HomeCustom.vue'
import MobileIntroduce from '../components/mobile/templates/Introduce.vue'
import MobileMember from '../components/mobile/templates/Member.vue'
import MobileNotification from '../components/mobile/templates/Notification.vue'
import MobilePayment from '../components/mobile/templates/Payment.vue'
import MobileTheme from '../components/mobile/templates/Theme.vue'
import MobileWorkspace from '../components/mobile/templates/Workspace.vue'

const routes = [
  {
    path: "/",
    name: "top",
    component: TopPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'home',
        component: TopHome,
      },
      {
        path: 'search/:type',
        name: 'search',
        component: TopSearch,
      },
    ]
  },
  {
    path: "/signin",
    name: "Signin",
    component: SigninPage
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupPage
  },
  {
    path: "/signup/register",
    name: "afterSignUp",
    component: MailVerifySignupPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/signin/verify",
    name: "VerifyMail",
    component: MailVerifySigninPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/signin/reset/passowrd",
    name: "ResetPassword",
    component: PasswordResetPage,
  },
  {
    path: "/settings",
    component: SettingsPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Settings',
        component: Profile,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'account',
        name: 'Account',
        component: Account,
      },
      {
        path: 'home-custom',
        name: 'HomeCustom',
        component: HomeCustom,
      },
      {
        path: 'introduce',
        name: 'Introduce',
        component: Introduce,
      },
      {
        path: 'member',
        name: 'Member',
        component: Member,
      },
      {
        path: 'notification',
        name: 'Notification',
        component: Notification,
      },
      {
        path: 'payment',
        name: 'Payment',
        component: Payment,
      },
      {
        path: 'theme',
        name: 'Theme',
        component: Theme,
      },
      {
        path: 'workspace',
        name: 'Workspace',
        component: Workspace,
      },
      {
        path: 'connect',
        name: 'Connect',
        component: Connect,
      },
    ]
  },
  {
    path: "/setting",
    component: SettingsPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MobileSettings',
        component: SettingsTop,
      },
      {
        path: 'account',
        name: 'MobileAccount',
        component: MobileAccount,
      },
      {
        path: 'home-custom',
        name: 'MobileHomeCustom',
        component: MobileHomeCustom,
      },
      {
        path: 'introduce',
        name: 'MobileIntroduce',
        component: MobileIntroduce,
      },
      {
        path: 'Member',
        name: 'MobileMember',
        component: MobileMember,
      },
      {
        path: 'notification',
        name: 'MobileNotification',
        component: MobileNotification,
      },
      {
        path: 'payment',
        name: 'MobilePayment',
        component: MobilePayment,
      },
      {
        path: 'theme',
        name: 'MobileTheme',
        component: MobileTheme,
      },
      {
        path: 'workspace',
        name: 'MobileWorkspace',
        component: MobileWorkspace,
      },
    ]
  },
  {
    path: '/search',
    name: 'SearchTop',
    component: SearchTop,
    meta: { requiresAuth: true }
  },
  {
    path: "/package/:id",
    name: "package-detail",
    component: PackageDetailPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/boards",
    name: "boardtop",
    component: BoardTopPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/boards/:id",
    name: "boardDetail",
    component: BoardDetailPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:username/profile",
    name: "user",
    component: UserProfilePage,
    meta: { requiresAuth: true }
  },
  {
    path: "/upload",
    name: "upload",
    component: FileUploadPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/tags",
    name: "tags",
    component: TagPage,
    meta: { requiresAuth: true }
  },      
  {
    path: '/trend',
    name: 'trend',
    component: TopTrend,
    meta: { requiresAuth: true },
  },
  {
    path: "/ranking",
    name: "ranking",
    component: TopRanking,
    meta: { requiresAuth: true },
  },
  {
    path: '/needs',
    name: 'needs',
    component: TopNeeds,
    meta: { requiresAuth: true },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: TopAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }
  if (to.hash) {
    const element = document.getElementById(to.hash.slice(1));
    if (element) return { el: to.hash };
  }
  return {x: 0, y: 0};
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior
})

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      try { resolve(user); }
      catch (error) { reject(error) }
    });
  });
};


/**
 * Allow navigation guards to return the value or a Promise of it instead of calling next()
 * https://github.com/vuejs/rfcs/blob/master/active-rfcs/0037-router-return-guards.md
 */
router.beforeEach(async (to, from) => {
  /**
   * Turn off fire hosting's default domain and redirect to 'spitch.jp'
   * https://stackoverflow.com/questions/61396081/how-to-turn-off-default-domain-in-firebase-hosting-firebase
   */
  if (
    window.location.hostname == process.env.VUE_APP_HOSTING_DEFAULT_DOMAIN1
    || window.location.hostname == process.env.VUE_APP_HOSTING_DEFAULT_DOMAIN2
  ) {
    window.location.href = process.env.VUE_APP_REDIRECT_URL;
    return false;
  }

  const currentUser = await getCurrentUser()
  const isAuthenticated = currentUser ? true : false

  if (to.meta.requiresAuth) {
    // 認証していなければサインインページにリダイレクト
    if (!isAuthenticated) {
      return {
        name: 'Signin',
        query: { redirectTo: to.fullPath }
      }
    }

    // メール認証していなければ再送ページにリダイレクト
    await firebase.auth().currentUser.reload()
    const isVerifiedEmail = currentUser.emailVerified
    if (!isVerifiedEmail) {
      if (to.name == 'afterSignUp' || to.name == 'VerifyMail') return true;
      return {
        name: 'VerifyMail',
        query: { redirectTo: to.fullPath }
      }
    }

    // アカウント削除フラグがtrueの場合はサインインページにリダイレクト
    const deletable = store.state.user.userInfo.deletable;
    if (deletable) {
      return {
        name: 'Signin',
        query: { redirectTo: to.fullPath }
      }
    }

    store.dispatch("auth/onAuth");
    const db = firebase.firestore();
    const workSpace = await db.collection("userMapping").doc(currentUser.uid).get()
      .then(doc => {
        if (doc.exists) {
          return doc.data().workSpace;
        } else {
          throw new Error(`No such a userMapping: ${currentUser.uid}`);
        }
      })
    await store.dispatch("user/getUserInfo", { workSpace, userId: currentUser.uid });

    // Set token in Cookies
    firebase.auth().currentUser.getIdToken().then((token) => {
      document.cookie = `token=${token};path=/`;
    });
  } else {
    if (isAuthenticated) {
      return { name: 'home' };
    }
  }
})

export default router;