<template>
  <div class="flex flex-col items-center justify-center w-screen min-h-screen bg-gray-100">
    <div class="flex items-center justify-center py-10 bg-white rounded-md shadow-xl">
      <div class="flex items-center justify-center pl-12 pr-6">
        <img src="../../assets/spitch-icon.png" alt="logo" width="50">
      </div>

      <div class="pl-6 pr-12 my-4 border-l-2">
        <p class="mb-2 font-semibold text-center text-indigo-500">404 ERROR</p>

        <h1 class="mb-2 text-4xl font-bold text-center text-gray-800">Page not found.</h1>

        <p class="mb-4 text-center text-gray-500 md:text-lg">
          Sorry, we clouldn't find the page you're looking for.
        </p>

        <router-link :to="{ name: 'home'}">
          <p class="font-semibold text-center text-indigo-500 hover:underline">Go back home →</p>
        </router-link>
      </div>
    </div>
  </div>
</template>