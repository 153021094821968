<template>
  <div class="flex flex-col w-screen h-screen">
    <header-top />
    <div class="grid flex-1 grid-cols-6 overflow-y-scroll wrap">
      <aside class="col-span-1 pt-8 text-gray-900 bg-gray-50">
        <div>
          <div class="menu-list">
            <router-link :to="{ name: 'Profile' }" :class="{active: path=='Profile' || path=='Settings'}">プロフィール</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Account' }" :class="{active: path=='Account'}">アカウント</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'HomeCustom' }" :class="{active: path=='HomeCustom'}">ホームカスタム</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Notification' }" :class="{active: path=='Notification'}">メール・通知</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Theme' }" :class="{active: path=='Theme'}">テーマ</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Introduce' }" :class="{active: path=='Introduce'}">紹介</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Member' }" :class="{active: path=='Member'}">メンバー</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Connect' }" :class="{active: path=='Connect'}">連携</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Workspace' }" :class="{active: path=='Workspace'}">ワークスペース</router-link>
          </div>
          <div class="menu-list">
            <router-link :to="{ name: 'Payment' }" :class="{active: path=='Payment'}">請求</router-link>
          </div>
        </div>
      </aside>
      <main class="col-span-5 pt-8 ml-16 overflow-y-scroll">
        <Suspense>
          <template #default>
            <router-view />
          </template>
          <template #fallback>
            <div class="mb-8 title">
              <h1 class="font-mono text-4xl font-bold">loading...</h1>
            </div>
          </template>
        </Suspense>
      </main>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import { useRoute } from "vue-router"
import "vue-loading-overlay/dist/vue-loading.css";
import HeaderTop from "../organisms/TheHeaderTop.vue";

export default {
  components: {
    // Loading
    HeaderTop,
  },

  setup() {
    const route = useRoute();
    const path = computed(() => route.name);
    const state = reactive({
      loading: true
    })
    return { path, state };
  },
};
</script>

<style lang="postcss" scoped>
.menu-list {
  @apply pl-6 pr-2 py-2 mb-2 text-xl text-gray-600 hover:bg-gray-100;
}
.active {
  @apply border-b-2 py-1 border-gray-400;
}
</style>