<template>
  <base-modal
    :showModal="true"
    @toggleModal="closeModal"
  >
    <template v-slot:header>
      <span class="text-green-500">ファイル更新に成功しました！</span>
    </template>

    <template v-slot:body>
      <div class="mb-2">
        反映に少し時間がかかるため、プレビュー画面を閉じて少し経ってからブラウザを更新してください。
      </div>
    </template>

    <template v-slot:footer>
      <div class="mx-10">
        <div class="w-full mt-2 mb-4 border"></div>
        <div class="flex justify-end mb-6">
          <button
            @click="closeModal"
            class="p-2 rounded mr-4 text-black bg-gray-200 button hover:bg-gray-300"
          >
            閉じる
          </button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    BaseModal,
  },
  emits: [
    "closeModal"
  ],
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    },
  }
};
</script>