<template>
  <header-base
    @get-text="getText"
  >
    <template #right>
      <user-dropdown v-if="!displaySearchFlg" />
      <button
        v-if="displaySearchFlg && state.displayCancelBtn"
        @click="$router.push('/')"
        class="mr-2 text-white"
      >
        <cancel-search-icon />
      </button>
    </template>
  </header-base>
</template>

<script>
import { reactive } from "vue";
import { useRoute } from "vue-router";
import HeaderBase from "../molecules/HeaderBase.vue";
import UserDropdown from "../atoms/UserSettingsDropdown.vue";
import CancelSearchIcon from "../../icons/CancelSearchIcon.vue";

export default {
  components: {
    HeaderBase,
    UserDropdown,
    CancelSearchIcon
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      displayCancelBtn: true
    });

    const pattern = /search/;
    const displaySearchFlg = pattern.test(route.path);
    const getText = text => {
      if (!text) state.displayCancelBtn = true;
      else state.displayCancelBtn = false;
    };

    return {
      state,
      displaySearchFlg,
      getText
    };
  },
};
</script>