<template>
  <aside
    id="board_sidebar"
    class="w-full py-4 overflow-y-scroll text-gray-600 rounded shadow-lg g-white bg-gray-50 sidebar" 
    :class="{'scroll-hidden': !state.showScroll}"
  >
    <div class="mb-4">
      <div class="px-2 mb-2 text-lg font-bold">
        <p>🗂️  マイボード</p>
      </div>
      <div class="flex items-center justify-center bg-white h-7 ml-1 mb-3 border border-gray-200 rounded-lg focus:outline-none">
        <search-icon
          class="w-6 h-6 ml-2 text-gray-400 transition fill-current"
        />
        <input
          class="w-full h-full pl-2 text-sm rounded-r-md focus:outline-none"
          type="search"
          placeholder="マイボードを検索"
          @input="filterBoard"
        />
      </div>
      <div class="flex items-center justify-between px-2 text-gray-400">
        <p class="text-xs font-bold">プライベート</p>
        <button 
          class="px-2 text-lg rounded hover:bg-gray-100" 
          @click="state.showBoardCreate = true; state.boardVisibility = 'limited'"
        >
          +
        </button>
      </div>
      <!-- ここはコンポーネントに切り出して重複をなくすべき -->
      <ul>
        <draggable
          v-model="privateBoard"
          animation="200"
          @change="onChange"
        >
          <li
            v-for="(board, index) in privateBoard"
            :key="board"
            class="text-sm leading-6 text-gray-600"
            :class="{ enter: state.hoverPrivateIndex == index }"
            :sortable="true"
            :draggable="true"
            @dragstart="onDragStart($event, board)"
            @dragend="onDragEnd"
            @drop="onDrop($event, board)"
          >
            <router-link
              draggable="true"
              :to="{ name: 'boardDetail',
              params: { id: board.id } }"
              class="block px-2 py-1 hover:bg-gray-100"
              :class="{'bg-gray-100': state.showMenu && state.showIndex == index && state.menuType == 'private'}"
            >
              <div class="flex items-center justify-between group">
                <p class="w-40 truncate">
                  <span class="pr-2 text-base">{{ board.emoji ? board.emoji : '🐟' }}</span>
                  <span :class="{selected: $route.name == 'boardDetail' && $route.params.id == board.id}">{{ board.name }}</span>
                </p>
                <button 
                  class="hidden h-full text-2xl rounded ml-1 hover:bg-gray-200 group-hover:block" 
                  @click.stop.prevent="openMenu($event, board, index, 'private');"
                >
                  <ph-dots-three-bold />
                </button>
              </div>
            </router-link>
            <board-menu 
              :show-menu="state.showMenu && state.showIndex == index && state.menuType == 'private'" 
              :board-info="state.boardInfo" 
              :top="state.menuTop"
              @closeMenu="state.showMenu = false" 
              @update-board="state.boardInfo=board" 
            />
          </li>
        </draggable>
      </ul>
    </div>

    <div>
      <div class="flex items-center justify-between px-2 text-gray-400">
        <p class="text-xs font-bold">パブリック</p>
        <button 
          class="px-2 text-lg rounded hover:bg-gray-100" 
          @click="state.showBoardCreate = true; state.boardVisibility = 'public'"
        >
          +
        </button>
      </div>
      <ul>
        <draggable
          v-model="publicBoard"
          animation="200"
          @change="onChange"
        >
          <li
            v-for="(board, index) in publicBoard"
            :key="board"
            class="text-sm leading-6 text-gray-600"
            :class="{ enter: state.hoverSharedIndex == index }"
            :sortable="true"
            :draggable="true"
            @dragstart="onDragStart($event, board)"
            @dragend="onDragEnd"
            @drop="onDrop($event, board)"
          >
            <router-link
              draggable="true"
              :to="{ name: 'boardDetail', params: { id: board.id } }"
              class="block px-2 py-1 hover:bg-gray-100"
              :class="{'bg-gray-100': state.showMenu && state.showIndex == index && state.menuType == 'public'}"
            >
              <div class="flex items-center justify-between group">
                <p class="w-40 truncate">
                  <span class="pr-2 text-base">{{ board.emoji ? board.emoji : '🐟' }}</span>
                  <span :class="{selected: $route.name == 'boardDetail' && $route.params.id == board.id}">{{ board.name }}</span>
                </p>
                <button 
                  class="hidden h-full text-xl rounded hover:bg-gray-200 group-hover:block" 
                  @click.prevent="leaveFromBoard(board)"
                >
                  <pin-off-icon />
                </button>
                <button 
                  class="hidden h-full text-2xl rounded ml-1 hover:bg-gray-200 group-hover:block" 
                  @click.prevent="openMenu($event, board, index,'public');"
                >
                  <ph-dots-three-bold />
                </button>
              </div>
            </router-link>
            <board-menu 
              :show-menu="state.showMenu && state.showIndex == index && state.menuType == 'public'" 
              :board-info="state.boardInfo" 
              :top="state.menuTop"
              @closeMenu="state.showMenu = false" 
              @update-board="state.boardInfo=board"
            />
          </li>
        </draggable>
      </ul>
    </div>

    <board-create v-if="state.showBoardCreate" :visibility="state.boardVisibility" @closeModal="state.showBoardCreate = false" @updateBoard="updateBoard" />
  </aside>
</template>

<script>
import firebase from "firebase/app";
import { computed, reactive, onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
import useBoardRepository from "./composables/useBoardRepository"
import PinOffIcon from "../icons/PinOffIcon"
import SearchIcon from "../icons/SearchIcon.vue";
import PhDotsThreeBold from "../icons/PhDotsThreeBold"
// import DragIndicatorIcon from "../icons/DragIndicatorIcon.vue"
import boardMenu from "./dropdowns/BoardMenuDropdown.vue"
import BoardCreate from './modals/BoardModalCreate.vue'
import utility from "./composables/utility";

export default {
  props: {
    showSidebar: {
      type: Boolean,
      default: true
    },
  },

  emits:['showAlart'],

  components: {
    PhDotsThreeBold,
    PinOffIcon,
    SearchIcon,
    boardMenu,
    BoardCreate,
    draggable: VueDraggableNext,
  },

  setup(_, { emit }) {
    const user = firebase.auth().currentUser;
    const store = useStore();
    const state = reactive({
      filterKeyword: "",
      showWarningModal: false,
      hoverSharedIndex: null,
      hoverPrivateIndex: null,
      showMenu: false,
      showIndex: null,
      menuType: '',
      showBoardCreate: false,
      boardVisibility: '',
      boardInfo: {},
      menuTop: '',
      showScroll: false,
      prevScrollY: sessionStorage.getItem("prevScrollY") ?? 0,
      userInfo: store.state.user.userInfo,
      leaveTargetBoardId: ""
    });
    
    const onScroll = (el) => {
      el.scrollTop = state.prevScrollY;
    }

    const {
      getMyBoard,
      getMyBoardToSideBar,
      mergeBoardAndMember,
      updatePrivateBoardOrder,
      updatePublicBoardOrder,
      removeMembers
    } = useBoardRepository();

    onBeforeMount(() => {
      getMyBoardToSideBar({ workSpace: userInfo.workSpace, userId: userInfo.id });
    });

    const { filterBoardByKeyword } = utility();
    const privateBoard = computed({
      get: () => {
        let boards = store.state.board.myPrivateBoard;
        if (state.filterKeyword) {
          boards = filterBoardByKeyword(boards, state.filterKeyword);
        }
        const users = store.state.user.users;
        return mergeBoardAndMember(boards, users);
      },
      set: (value) => {
        updatePrivateBoardOrder(value);
        const newOrder = value.map((board) => board.id);
        const payload = {
          boards: newOrder,
          permission: "private"
        };
        BoardRepository.updateBoardOrder(payload);
      }
    });

    const publicBoard = computed({
      get: () => {
        let boards = store.state.board.myPublicBoard.filter(
          (pin) => pin.id != state.leaveTargetBoardId
        );
        if (state.filterKeyword) {
          boards = filterBoardByKeyword(boards, state.filterKeyword);
        }
        const users = store.state.user.users;
        return mergeBoardAndMember(boards, users);
      },
      set: (value) => {
        updatePublicBoardOrder(value);
        const newOrder = value.map((board) => board.id);
        const payload = {
          boards: newOrder,
          permission: "public"
        };
        BoardRepository.updateBoardOrder(payload);
      }
    });

    const userInfo = store.state.user.userInfo;

    const updateBoard = async () => {
      await getMyBoard({ workSpace: userInfo.workSpace, userId: userInfo.id });
      await getMyBoardToSideBar({ workSpace: userInfo.workSpace, userId: userInfo.id });
    };
    
    const filterBoard = async (e) => {
      state.filterKeyword = e.target.value;
    };

    let counter = 0;
    const onSharedDragEnter = (index) => {
      counter++
      state.hoverSharedIndex = index;
    }

    const onPrivateDragEnter = (index) => {
      counter++
      state.hoverPrivateIndex = index;
    }

    const { onDragEnd } = utility();
    const onDragStart = (e, board) => {
      const params = {
        board_id: board.id
      };
      return params;
    };

    const onDragLeave = () => {
      counter--;
      if (counter == 0) {
        state.hoverPrivateIndex = null;
        state.hoverSharedIndex = null;
      }
    }

    const onDrop = async (evt, board) => {
      if (evt.dataTransfer.effectAllowed == "move") return;

      evt.dataTransfer.dropEffect = "copy";
      evt.dataTransfer.effectAllowed = "copy";

      const fileType = evt.dataTransfer.getData("fileType");
      const fileId = evt.dataTransfer.getData("fileId");
      const permission = evt.dataTransfer.getData("permission");
      const createdBy = evt.dataTransfer.getData("createdBy");

      /**
       * アラート表示
       * 
       * ■ ファイルが重複している場合
       * - ファイルやボードの権限に関わらず失敗アラート
       * 
       * ■ 非公開ファイルの場合
       * - 作成者が自分以外の非公開ファイルを公開/非公開ボードに追加すると失敗アラート
       * - 非公開ファイルを公開ボードに追加すると失敗アラート
       * - 作成者が自分の非公開ファイルを非公開ボードに追加すると警告アラート
       * 
       * ■ 公開ファイルの場合
       * - ボードの権限に関わらず成功アラート
       */
      if (board.pins.some(pin => pin.pin_id === fileId && pin.pin_type === fileType)) {
        emit('showAlart', 'failure', { title: '', body: 'すでにこのファイルはボードに保存されています。' });
      } else if (permission === 'limited') {
        if (createdBy !== user.uid) {
          emit('showAlart', 'failure', { title: '', body: '別ユーザが作成した非公開ファイルはボードに追加できません。' });
        } else if (board.visibility === 'public') {
          emit('showAlart', 'failure', { title: '', body: '非公開ファイルは公開ボードに追加できません。' });
        } else if (board.visibility === 'limited') {
          const result = await useBoardRepository(board).addPin(fileId, fileType);
          if (result.status == 200) {
            updateBoard();
            emit('showAlart', 'warning', { title: '', body: '非公開ボードに属しているユーザが閲覧できるようになります。' });
          }
        }
      } else {
        const result = await useBoardRepository(board).addPin(fileId, fileType);
        if (result.status == 200) {
          updateBoard();
          emit("showAlart", 'success');
        }
      }

      onDragLeave();
    };

    const onChange = (e) => {
      console.debug("event", e);
    };

    const getMenuTop = (e) => {
      const menuHeight = 180;
      const isTop = e.clientY + menuHeight > document.documentElement.clientHeight ? false : true;
      return isTop ? `${e.clientY}px` : `${e.clientY - menuHeight}px`
    }

    const openMenu = (e, board, index, type) => {
      state.menuTop = getMenuTop(e);
      state.showMenu = true; 
      state.showIndex = index;
      state.menuType = type;
      state.boardInfo = board;
    }

    const handleScroll = () => {
      const element = document.getElementById("board_sidebar");
      sessionStorage.setItem("prevScrollY", element.scrollTop);
      state.showScroll = true;
      setTimeout(() => { state.showScroll = false }, 2000);
    }

    onMounted(() => {
      const el = document.getElementById("board_sidebar");
      onScroll(el);
      document.querySelector(".sidebar").addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      document.querySelector(".sidebar").removeEventListener("scroll", handleScroll);
    });

    const leaveFromBoard = async (board) => {
      state.leaveTargetBoardId = board.id;
      const users = [{
        user_id: user.uid,
        permission: user.permission
      }];
      const payload = { users, board_id: board.id };
      await removeMembers(payload);
    };

    return {
      state,
      privateBoard,
      publicBoard,
      filterBoard,
      onSharedDragEnter,
      onPrivateDragEnter,
      onDragStart,
      onDragEnd,
      onDragLeave,
      onDrop,
      updateBoard,
      openMenu,
      onChange,
      leaveFromBoard
    };
  }
};
</script>

<style scoped>
*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 13px;
  background-color: rgb(161, 154, 154);
  box-shadow: inset 0 0 10px 10px #909090;
  border: solid 4px transparent;
}

.scroll-hidden::-webkit-scrollbar-thumb {
  display: none;
}
</style>

<style lang="postcss" scoped>
.enter {
  @apply bg-gray-100;
}
.selected {
  @apply underline
}
</style>