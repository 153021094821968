<template>
  <div class="h-screen bg-black bg-clip-padding backdrop-filter backdrop-blur bg-opacity-80">
    <div
      class="relative flex flex-col items-center max-h-screen"
      id="modal-content"
    >
      <!-- ヘッダー -->
      <div
        class="absolute top-0 z-10 flex items-center justify-center w-full h-8 px-5 bg-gray-100 vh-heiht filter drop-shadow-xl"
      >
        <div class="absolute left-8">
          <router-link :to="{ name: 'home' }"><logo-horizon width="100" class="" /></router-link>
        </div>

        <div class="w-4/5 text-center truncate">
          <span class="text-lg font-bold text-gray-900"> {{ packageInfo.fileTitle }} </span>
          <div v-if="packageInfo.extension.pptx" class="inline-flex items-center px-3 py-1 mx-1 text-xs font-bold text-gray-700 bg-gray-200 rounded-lg">PPTX</div>
          <div v-if="packageInfo.extension.pdf" class="inline-flex items-center px-3 py-1 mx-1 text-xs font-bold text-gray-700 bg-gray-200 rounded-lg">PDF</div>
        </div>

        <div class="absolute right-8">
          <button
            @click="jumpToHome"
            class="flex items-center justify-center mx-4 transition duration-100 transform bg-white border border-gray-400 rounded-full shadow-2xl w-7 h-7 hover:scale-105 hover:rotate-90"
          >
            <close-icon class="w-7 h-7" />
          </button>
        </div>
      </div>

      <!-- パッケージアクション -->
      <div
        v-show="state.pinInfo.target === 'package' && state.mode != ''"
        @click="state.mode = ''"
        class="fixed inset-0 z-10 w-full h-full"
      ></div>
      <div class="absolute top-0 left-0 flex flex-col items-center justify-center w-24 h-screen">
        <!-- パッケージメニュ -->
        <TooltipText
          text="パッケージ情報の表示/編集"
          placement="right"
        >
          <div class="relative flex text-left text-gray-800">
            <button
              @click.stop="
                state.mode == 'update'
                  ? (state.mode = '')
                  : (state.mode = 'update')
              "
              class="my-4 transition transform bg-white rounded-full shadow-md h-14 w-14 hover:scale-110 hover:bg-gray-100 focus:outline-none"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <img
                v-if="packageInfo.user.photoURL"
                :src="packageInfo.user.photoURL"
                alt="user-icon"
                class="object-cover w-full h-full rounded-full"
              />
              <div
                v-else
                class="flex items-center justify-center w-full h-full text-4xl rounded-full"
              >
                <span>{{
                  packageInfo.user?.firstName.slice(0, 1)
                }}</span>
              </div>
            </button>
            <package-update-dropdown
              :show-update-menu="state.mode == 'update'"
              :package-info="packageInfo"
              @update-package-info="updatePackageInfo"
              @enable-keydown="enableKeydown"
              @disable-keydown="disableKeydown"
              @delete-package-info="jumpToHome"
            />
          </div>
        </TooltipText>

        <!-- ボード追加 -->
        <TooltipText :text="hasAddPinPermission ? 'ボードに追加' : 'ボード追加の権限がありません'" placement="right">
          <button
            @click.stop="state.pinInfo.target === 'slide' ? state.mode = 'favorite' : state.mode == 'favorite' ? (state.mode = '') : (state.mode = 'favorite'); setPinInfo(packageInfo.id, 'package')"
            class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900 disabled:opacity-50"
            :disabled="!hasAddPinPermission"
          >
            <add-icon class="w-8 h-8" />
          </button>
          <pin-add-dropdown 
            :show-download-menu="state.mode === 'favorite' && state.pinInfo.target === 'package'"
            :pin-info="state.pinInfo"
          />
        </TooltipText>

        <!-- コメント -->
        <TooltipText text="コメントを表示" placement="right">
          <button
            @click="clickComment"
            class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
          >
            <comment-icon class="w-8 h-8" />
          </button>
        </TooltipText>

        <!-- いいね -->
        <TooltipText
          :text="isLikedPackage(packageInfo) ? 'いいねを取り消す' : 'いいねする'"
          placement="right"
        >
          <button
            v-if="isLikedPackage(packageInfo)"
            @click.stop="unlikePackage(packageInfo)"
            class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
          >
            <liked-icon class="w-8 h-8" />
          </button>
          <button
            v-else
            @click.stop="likePackage(packageInfo)"
            class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
          >
            <unlike-icon class="w-8 h-8 text-bold" />
          </button>
        </TooltipText>

        <!-- ダウンロード -->
        <TooltipText text="ファイルのダウンロード" placement="right">
          <div class="relative flex text-left text-gray-800">
            <button
              @click.stop="state.mode == 'download' ? (state.mode = '') : (state.mode = 'download')"
              class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
            >
              <download-icon class="w-10 h-10" />
            </button>
            <package-download-dropdown
              :show-download-menu="state.mode == 'download'"
              :package-info="packageInfo"
              @update-package-info="updatePackageInfo"
            />
          </div>
        </TooltipText>

        <!-- アナリティクス -->
        <TooltipText
          text="パッケージのアナリティクスを表示"
          placement="right"
        >
          <div class="relative flex text-left text-gray-800">
            <button
              @click.stop="state.mode == 'analytics' ? (state.mode = '') : (state.mode = 'analytics')"
              class="flex items-center justify-center my-4 text-white transition border border-gray-200 border-solid rounded-full shadow-2xl w-14 h-14 hover:bg-white hover:text-gray-900"
            >
              <chart-icon class="w-10 h-10" />
            </button>
          </div>
          <package-analytics-dropdown 
            :show-analytics="state.mode == 'analytics'"
            :package-info="packageInfo"
          />
        </TooltipText>
      </div>

      <!-- スライド画像 -->
      <div
        id="scrollable"
        class="w-screen overflow-y-auto mt-8 col-span-1"
        :class="state.displayComment ? 'col-span-4' : 'col-span-1'"
      >
        <div
          class="grid"
          :class="state.displayComment ? 'grid-cols-12 ml-32' : 'mx-40'"
        >
          <div
            :class="state.displayComment ? 'col-span-9' : ''"
            :style="state.displayComment ? 'width: 85%;' : ''"
          >
            <div
              class="border-b-2 border-l-2 border-r-2 rounded-b vh-heiht"
            ></div>
            <div
              class="relative mt-12 preview-image"
              v-for="(children_id, index) in packageInfo.sorted_children_ids"
              :key="children_id"
              :id="children_id"
              @mouseenter="state.showSlideMenu = true; state.slideMenuIndex = index"
              @mouseleave="!(state.mode === 'favorite' && state.pinInfo.target === 'slide') ? state.showSlideMenu = false : state.showSlideMenu = true"
            >
              <div class="relative">
                <img
                  id="modal-slide-image"
                  class="object-contain mx-auto border border-opacity-25"
                  width="1"
                  height="1"
                  v-lazy="`${state.baseUrl}${children_id}.jpg`"
                  alt="slide"
                  style="width: 100%; height: auto; max-height: 85vh;"
                />
                <div class="absolute top-0 right-0" v-show="state.showSlideMenu && state.slideMenuIndex === index">
                  <div class="flex items-center justify-center text-white bg-black bg-opacity-20 backdrop-filter backdrop-blur">
                    <!-- サムネイル設定 -->
                    <tooltip-text :text="isCreatedUser ? 'サムネイルに設定' : 'サムネイル設定の権限がありません'" placement="bottom">
                      <button 
                        @click.stop="setPackageHeader(children_id)"
                        :disabled="!isCreatedUser"
                        class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <pin-icon class="w-8 h-8" />
                      </button>
                    </tooltip-text>

                    <!-- ボードに追加 -->
                    <tooltip-text :text="hasAddPinPermission ? 'スライドをボードに追加' : 'ボード追加の権限がありません'" placement="bottom">
                      <button 
                        @click.stop="state.pinInfo.target === 'package' ? state.mode = 'favorite' : state.mode == 'favorite' ? (state.mode = '') : (state.mode = 'favorite'); setPinInfo(children_id, 'slide')"
                        :disabled="!hasAddPinPermission"
                        class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:opacity-50"
                      >
                        <add-icon class="w-8 h-8" />
                      </button>
                      <pin-add-dropdown 
                        :show-download-menu="state.mode === 'favorite' && state.pinInfo.target === 'slide'"
                        :pin-info="state.pinInfo"
                        placement="bottom"
                      />
                    </tooltip-text>

                    <!-- スライドダウンロード -->
                    <tooltip-text text="スライドダウンロード" placement="bottom">
                      <button 
                        @click.stop="downloadSlide(children_id)" 
                        class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:cursor-not-allowed" 
                        :disabled="!canDownload"
                      >
                        <download-icon class="w-8 h-8" />
                      </button>
                    </tooltip-text>

                    <!-- URLコピー -->
                    <tooltip-text text="URLをコピー" placement="bottom">
                      <button @click.stop="copyToClipboard(children_id)" class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white">
                        <clip-icon class="w-8 h-8" />
                      </button>
                    </tooltip-text>
                  </div>
                </div>

                <!-- スタック -->
                <!-- <div v-show="state.showSlideMenu && state.slideMenuIndex === index" class="absolute bottom-2 right-2">
                  <tooltip-text text="[未実装]PDFはSTACKに追加できません" placement="top">
                    <button class="px-2 py-2 text-white bg-gray-600 rounded cursor-not-allowed">スタックに追加</button>
                  </tooltip-text>
                </div> -->

                <!-- ラベル -->
                <div class="absolute bottom-0 ml-2 left-full" v-if="hasShowLabelPermission">
                  <div v-for="(label, index) in state.slideLables[children_id]" :key="`${children_id}#${index}`" class="mt-2">
                    <router-link 
                      :to="{ path: '/search/slides', query: { sort: 'created_timestamp', labels: label }}" 
                      class="px-2 py-1 text-sm bg-white rounded"
                    >
                      {{ label }}
                    </router-link>
                  </div>
              </div>

              </div>

              <!-- 紐づくボード -->
              <div class="flex items-center justify-start mt-1">
                <div v-for="board in attachedBoardsWithSlide[children_id]" :key="board.id" class="mr-2">
                  <router-link :to="{ path: `/boards/${board.id}` }" class="px-2 py-1 text-xs bg-white rounded-full">
                    <span v-if="board.visibility === 'limited'">🔒 </span>#{{ board.name }}
                  </router-link>
                </div>
              </div>

              <loading
                v-model:active="state.showLoading"
                :is-full-page="false"
                color="#818589"
              />
            </div>
            <div
              class="border-t-2 border-l-2 border-r-2 rounded-t vh-margin-top vh-heiht"
            ></div>
          </div>
          <div
            v-if="state.displayComment"
            :class="state.displayComment ? 'col-span-3' : ''"
            class="overflow-x-hidden overflow-y-scroll"
          >
            <comment
              class="fixed right-0"
              :showComment="state.displayComment"
              :packageInfo="state.packageInfo"
              :notifiedComment="state.notifiedCommentId"
              @comment-focus="commentFocusType"
              @createComment="createComment"
              @deleteComment="deleteComment"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { reactive, onBeforeMount, onBeforeUnmount, onMounted, computed } from "vue";
import useFileRepository from "../organisms/composables/useFileRepository";
import PackageUpdateDropdown from "../organisms/dropdowns/PackageMenuDropdown.vue";
import PackageDownloadDropdown from "../organisms/dropdowns/PackageDownloadDropdown.vue";
import PackageAnalyticsDropdown from "../organisms/dropdowns/PackageAnalytics.vue";
import PinAddDropdown from "../organisms/dropdowns/PinAddDropdown.vue";
import TooltipText from "../organisms/utilities/TooltipContent.vue";
import Comment from "../organisms/Comment.vue";
import PinIcon from "../icons/MdiPinOutline.vue";
import ClipIcon from "../icons/PhPaperclip.vue";
import AddIcon from "../icons/AddIcon.vue";
import ChartIcon from "../icons/ChartIcon.vue";
import CommentIcon from "../icons/CommentIcon.vue";
import DownloadIcon from "../icons/DownloadIcon.vue";
import LikedIcon from "../icons/LikedIcon.vue";
import UnlikeIcon from "../icons/UnlikeIcon.vue";
import LogoHorizon from "../icons/LogoHorizon.vue";
import CloseIcon from "../icons/CloseIcon.vue";
import utility from "../organisms/composables/utility";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepository = RepositoryFactoryForGae.get("file");

export default {
  components: {
    PackageUpdateDropdown,
    PackageDownloadDropdown,
    PackageAnalyticsDropdown,
    PinAddDropdown,
    Comment,
    AddIcon,
    ChartIcon,
    CommentIcon,
    DownloadIcon,
    PinIcon,
    ClipIcon,
    LikedIcon,
    UnlikeIcon,
    TooltipText,
    LogoHorizon,
    CloseIcon,
    Loading
  },

  props: {
    packageInfo: {
      type: Object,
      required: true,
    },
  },

  emits: ["updatePackageInfo"],

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const user = firebase.auth().currentUser;
    const userInfo = computed(() => {
      return store.state.user.userInfo;
    });

    const state = reactive({
      actEl: null,
      baseUrl: location.origin + "/img/",
      loadedCount: 0,
      showDownloadMenu: false,
      mode: "", // 'download', 'package', 'chart', 'commend', 'favorite',
      showLoading: false,
      showSlideMenu: false,
      slideMenuIndex: null,
      pinInfo: { id: '', permission: props.packageInfo.permission, target: '' },
      slideLables: {},
      packageInfo: props.packageInfo,
      displayComment: false,
      isInputtingComment: false,
      notifiedCommentId: router.currentRoute.value.query.comment_id ?? ""
    });

    const attachedBoardsWithSlide = computed(() => {
      const boards = store.state.board.myBoard.filter(board => {
        const memberIds = [...board.owners, ...board.members]
        return board.visibility === 'public' || (board.visibility === 'limited' && memberIds.includes(user.uid));
      });

      const data = props.packageInfo.children_ids.reduce((a, c) => {
        if (!(c in a)) {
          a[c] = boards.filter(board => board.pins.some(pin => pin.pin_id === c && pin.pin_type === 'slide'));
        }
        return a;
      }, {});

      return data;
    })

    const isCreatedUser = computed(() => {
      return props.packageInfo.created_by === user.uid;
    })

    const hasAddPinPermission = computed(() => { 
      return !(props.packageInfo.permission === 'limited' && props.packageInfo.created_by !== user.uid)
    });    

    const hasShowLabelPermission = computed(() => {
      const isCreatedUser = props.packageInfo.created_by === user.uid;
      const isVisibleUser = props.packageInfo.visible_user.includes(user.uid);
      const isLimitedPackage = props.packageInfo.permission === 'limited';
      return !(!isCreatedUser && !isVisibleUser && isLimitedPackage);
    })

    // Shrotcut trigger
    let scFlag = true;
    const handleKeydown = (event) => {
      scFlag = false;
      switch (event.key) {
        case "ArrowUp":
          if (state.actEl.previousElementSibling.id) {
            state.actEl = state.actEl.previousElementSibling;
            scrollToAnchorPoint(state.actEl);
          }
          break;
        case "ArrowDown":
          if (state.actEl.nextElementSibling.id) {
            state.actEl = state.actEl.nextElementSibling;
            scrollToAnchorPoint(state.actEl);
          }
          break;
      }
      setTimeout(() => { scFlag = true }, 1000);
    };

    /**
     * スクロール終了時に処理を実行する
     * 
     * スクロールイベントが連続して発火しても
     * 最後以外はクリアする。
     * 
     * Credits: https://lab.syncer.jp/Web/JavaScript/Snippet/46/
     */
    let timeoutId;
    const handleScroll = () => {
      if (scFlag) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          const st = document.getElementById('scrollable').scrollTop;
          const targets = Array.from(document.querySelectorAll('.preview-image'));
          state.actEl = targets.find(e => e.offsetTop > st).previousElementSibling;
        }, 500);
      }
    } 

    const scrollToAnchorPoint = (el) => {
      el.scrollIntoView({ block: "center", behavior: "auto" });
    };

    const handlePopstate = (e) => {
      emit('toggle');
    }

    onBeforeMount(() => {
      window.addEventListener("keydown", handleKeydown);
      window.addEventListener("popstate", handlePopstate);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("popstate", handlePopstate);
      window.removeEventListener("scroll", handleScroll);
    });

    const enableKeydown = () => {
      window.addEventListener("keydown", handleKeydown);
    }

    const disableKeydown = () => {
      window.removeEventListener("keydown", handleKeydown);
    }

    onMounted(() => {
      const targetId = route.hash.slice(1)
      if (targetId) {
        state.actEl = document.getElementById(targetId);
      } else {
        state.actEl = document.getElementById(props.packageInfo.sorted_children_ids[0]);
      }

      setTimeout(() => {
        scrollToAnchorPoint(state.actEl);
      }, 10);

      setTimeout(() => {
        document.getElementById('scrollable').addEventListener("scroll", handleScroll);
      }, 30);

      setTimeout(() => {
        if (state.notifiedCommentId) state.displayComment = true;
      }, 30);
    });

    const getSlideLabels = async (slideId) => {
      const slideInfo = await getSlideInfo({ target: slideId });
      state.slideLables[slideId] = slideInfo.labels;
    };

    const updatePackageInfo = (newValue) => {
      emit("updatePackageInfo", newValue);
    };

    const jumpToHome = () => {
      router.push({ name: 'home' });
    }

    const { getSlideInfo, downloadFile, updatePackageHeader } = useFileRepository();

    const canDownload = computed(() => {
      const permission = props.packageInfo?.permission;
      // return true if the permission is public.
      if (permission === "public") return true; 

      // permission is any other 'public' and the user is one of visible users.
      return props.packageInfo?.visible_user.includes(user.uid);
    })

    /// Slide Section ///
    const downloadSlide = async (slideId) => {
      // Before download
      state.showLoading = true;
      try {
        const slideInfo = await getSlideInfo({ target: slideId });
        const target = slideInfo.extension.pdf ? slideInfo.pdf_path : slideInfo.extension.pptx ? slideInfo.pptx_path : '';
        await downloadFile({ target, type: 'slide' });
      } finally {
        // After download
        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;
      }
    };

    const setPackageHeader = async (slideId) => {
      // Before update package header
      state.showLoading = true;
      try {
        await updatePackageHeader({ packageId: props.packageInfo.id, headerId: slideId });
        // After update package header
        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;

        await new Promise(resolve => setTimeout(resolve, 500));
        alert('パッケージのサムネイルを更新しました。')
      } catch(error) {
        console.error(error);

        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;
      }
    }

    const { copyToClipboard } = utility();

    const setPinInfo = (id, target) => {
      state.pinInfo.id = id;
      state.pinInfo.target = target;
    }

    // like
    const isLikedPackage = file => {
      const likeList = userInfo.value.like_packages_list || [];
      return likeList.some(like => like === file.id);
    };

    const likePackage = async (file) => {
      const payload = {
        target: file.packageId,
        event_type: "like",
        url: {
          domain: location.origin,
          endpoint: `/package/${file.packageId}`
        }
      };
      store.state.user.userInfo.like_packages_list.push(file.packageId);
      file.liked_users.push(store.state.user.userInfo.username);
      await FileRepository.likePackage(payload);
      await store.dispatch("user/setUser");
    };

    const unlikePackage = async (file) => {
      const payload = { target: file.packageId };
      store.state.user.userInfo.like_packages_list = store.state.user.userInfo.like_packages_list.filter(x => x != file.packageId);
      file.liked_users = file.liked_users.filter(x => x != store.state.user.userInfo.username);
      await FileRepository.unlikePackage(payload);
      await store.dispatch("user/setUser");
    };

    // comment
    const clickComment = () => {
      state.displayComment = !state.displayComment;
    };

    const commentFocusType = (type) => {
      state.isInputtingComment = type;
    };

    const createComment = (newComment) => {
      state.packageInfo.comment.map((comment) => {
        if (comment.is_temp) {
          comment.id = newComment.id;
          comment.created_at = newComment.created_at;
          comment.is_temp = newComment.is_temp;
        }
      });
    };

    const deleteComment = (commentId) => {
      // state.packageInfo = state.packageInfo.comment.filter((comment) => comment.id != commentId);
    };

    return {
      state,
      attachedBoardsWithSlide,
      isCreatedUser,
      hasAddPinPermission,
      hasShowLabelPermission,
      getSlideLabels,
      updatePackageInfo,
      enableKeydown,
      disableKeydown,
      jumpToHome,
      canDownload,
      downloadSlide,
      setPackageHeader,
      copyToClipboard,
      setPinInfo,
      isLikedPackage,
      likePackage,
      unlikePackage,
      clickComment,
      commentFocusType,
      createComment,
      deleteComment
    };
  },
};
</script>

<style scoped>
*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 13px;
  background-color: #fff;
  box-shadow: inset 0 0 10px 10px #909090;
  border: solid 4px transparent;
}

.vh-margin-top {
  margin-top: 4vh;
}

.vh-heiht {
  height: 4vh;
}
</style>