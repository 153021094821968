<template>
  <div
    v-if="showModal"
    class="fixed inset-0 z-50 flex items-center justify-center"
  >
    <div class="w-80 max-w-4xl max-h-screen mx-auto my-6">
      <div class="w-full bg-white rounded-lg p-5">
        <div class="mb-4 header">
          <h1 class="font-bold text-gray-700">
            ログアウト
          </h1>
        </div>
        <div class="body">
          <p class="mb-4 text-base text-gray-700">
            SPITCHからログアウトしてもよろしいですか？
          </p>
          <div class="flex flex-cols-4 justify-between">
            <button
              @click="signout"
              class="block w-32 p-2 mt-4 text-center text-white bg-red-500 border rounded  hover:bg-red-600 focus:outline-none"
            >
              OK
            </button>
            <button
              @click="$emit('closeModal')"
              class="w-32 p-2 mt-4 text-center text-white bg-gray-500 border rounded hover:bg-red-600 focus:outline-none"
            >
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showModal"
    class="fixed inset-0 z-40 bg-black opacity-70"
  ></div>
</template>

<script>
import { useRouter } from "vue-router";
import firebase from "firebase/app";
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  setup () {
    const router = useRouter();
    const signout = () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
        router.push("/signin");
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return {
      signout
    };
  }
}
</script>