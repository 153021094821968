<template>
<div class="overflow-y-scroll overflow-x-scroll">
  <div class="p-4">
    <div class="mb-3">
      <h2 class="font-bold">
        アカウント管理
      </h2>
      <hr />
    </div>
    <router-link
      :to="{ name: 'MobileAccount' }"
      :class="{ active: path == 'account' }"
    >
      <div class="settings-child">
        <div class="text-lg">
          アカウント設定
        </div>
        <div class="font-bold">
          ＞
        </div>
      </div>
    </router-link>
    <router-link
      :to="{ name: 'MobileHomeCustom' }"
      :class="{ active: path == 'home-custom' }"
    >
      <div class="settings-child">
        <div class="text-lg">
          ホームカスタム（工事中）
        </div>
        <div class="font-bold">
          ＞
        </div>
      </div>
    </router-link>
    <router-link
      :to="{ name: 'MobileIntroduce' }"
      :class="{ active: path == 'introduce' }"
    >
      <div class="settings-child">
        <div class="text-lg">
          招待（工事中）
        </div>
        <div class="font-bold">
          ＞
        </div>
      </div>
    </router-link>
    <router-link
      :to="{ name: 'MobilePayment' }"
      :class="{ active: path == 'payment' }"
    >
      <div class="settings-child">
        <div class="text-lg">
          請求（工事中）
        </div>
        <div class="font-bold">
          ＞
        </div>
      </div>
    </router-link>
  </div>
  
  <div class="p-4">
    <div class="mb-3">
      <h2 class="font-bold">アクティビティ</h2>
      <hr />
    </div>
    <div class="settings-child">
      <div class="text-lg">
        検索設定（工事中）
      </div>
      <div class="font-bold">
        ＞
      </div>
    </div>
    <div class="settings-child">
      <div class="text-lg">
        通知（工事中）
      </div>
      <div class="font-bold">
        ＞
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { reactive, computed } from "vue"
import { useRoute } from "vue-router"
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  setup() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const route = useRoute();
    const path = computed(() => route.name);
    const state = reactive({
      loading: true
    })
    return { path, state };
  },
};
</script>

<style lang="postcss" scoped>
.menu-list {
  @apply px-2 py-2 mb-2 text-base text-gray-600 hover:bg-gray-100;
}
.active {
  @apply border-b-2 py-1 border-gray-400;
}
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.settings-child {
  @apply flex justify-between p-1
}
</style>