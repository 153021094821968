import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
const resource = "notification";
export default {
  fetch(payload) {
    return Repository.post(`${version}/${resource}/get`, { json: payload });
  },

  updateNotificationReadFlg(payload) {
    return Repository.put(`${version}/${resource}/update`, { json: payload });
  },
};