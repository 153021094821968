import { render } from "./BoardModalCreate.vue?vue&type=template&id=21ebbaac&scoped=true"
import script from "./BoardModalCreate.vue?vue&type=script&lang=js"
export * from "./BoardModalCreate.vue?vue&type=script&lang=js"

import "./BoardModalCreate.vue?vue&type=style&index=0&id=21ebbaac&lang=postcss&scoped=true"
import "@vueform/multiselect/themes/default.css?vue&type=style&index=1&lang=css"
import "./BoardModalCreate.vue?vue&type=style&index=2&id=21ebbaac&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-21ebbaac"

export default script