<template>
  <loader-package v-if="state.loading && deviceType == 'pc'" />
  <mobile-package-detail-template
    v-else-if="deviceType == 'mobile'"
    :package-info="state.packageInfo"
    @update-package-info="updatePackageInfo"
  />
  <package-detail-template
    v-else
    :package-info="state.packageInfo"
    @update-package-info="updatePackageInfo"
  />
</template>

<script>
import { reactive, onBeforeMount, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useFileRepository from "../organisms/composables/useFileRepository";
import useBoardRepository from "../organisms/composables/useBoardRepository";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepository = RepositoryFactoryForGae.get("file");
import LoaderPackage from "../organisms/utilities/LoaderPackage.vue";
import PackageDetailTemplate from "../templates/PackageDetailTemplate.vue";
import MobilePackageDetailTemplate from "../mobile/templates/PackageDetail.vue";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    PackageDetailTemplate,
    MobilePackageDetailTemplate,
    LoaderPackage
  },

  setup() {
    const deviceType = calcBreakpoint(window.innerWidth);
    const store = useStore();
    const route = useRoute();
    const packageId = route.params.id;

    const { incrementPageview } = useFileRepository();
    incrementPageview({ target: packageId, type: 'package' }).then(_ => console.debug('Package pageview incremented!'));

    const state = reactive({
      packageInfo: {},
      loading: true
    });

    const boards = computed(() => {
      return store.state.board.myBoard.filter(board => board.pins.some(pin => pin.pin_id === packageId && pin.pin_type === 'package'));
    })

    watchEffect(() => {
      state.packageInfo.boards = boards.value;
    })

    const getPackageInfo = async () => {
      const packageInfo = await FileRepository.getPackageInfo({target: packageId}).json();
      const sorted_children_ids = packageInfo.children_ids_number.sort().map(packageId => packageId.slice(5));
      packageInfo.sorted_children_ids = sorted_children_ids;
      state.packageInfo = packageInfo;
      state.loading = false;
    };

    // Update packageInfo which is changed some fields in child components.
    const updatePackageInfo = newValue => {
      newValue.fileTitle = newValue.name;
      state.packageInfo = Object.assign(state.packageInfo, newValue);
    };

    const { getMyBoard } = useBoardRepository();

    onBeforeMount(() => {
      const userInfo = store.state.user.userInfo;
      getMyBoard({ workSpace: userInfo.workSpace, userId: userInfo.id });
      getPackageInfo();
    });

    return {
      deviceType,
      state,
      updatePackageInfo
    };
  }
};
</script>