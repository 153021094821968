<template>
  <div class="flex flex-col w-screen h-screen overflow-y-scroll">
    <main
      class="grid grid-cols-10 pt-10"
      v-if="!pageStates.loading"
    >
      <div class="col-span-2"></div>
      <div class="col-span-6 mb-4">
        <!-- profile section -->
        <div class="flex flex-wrap">
          <div class="items-center justify-center p-1 rounded">
            <img
              v-if="profileInfo.photoURL"
              :src="profileInfo.photoURL"
              alt="avater"
              class="object-cover p-1 border rounded-full"
              style="width: 185px; height: 185px"
            />
            <div
              v-else
              class="flex items-center justify-center p-2 border rounded-full"
              style="width: 185px; height: 185px"
            >
              <span class="text-gray-500 text-9xl">
                {{ profileInfo.lastName ? profileInfo.lastName.substring(0, 1) : "" }}
              </span>
            </div>
          </div>
          <div class="w-2/3 pl-6">
            <div class="mb-1">
              <span class="mr-5 text-4xl font-semibold leading-10 text-gray-600">
                {{ profileInfo.displayName ? profileInfo.displayName : profileInfo.lastName + " " + profileInfo.firstName }}
              </span>
              <span class="justify-center text-lg text-gray-600">
                {{ profileInfo.displayName ? profileInfo.lastName + " " + profileInfo.firstName : "" }}
              </span>
            </div>
            <div class="mb-4">
              <span class="text-gray-400">
                {{ "@" + profileInfo.username }}
              </span>
            </div>
            <div class="mb-4 text-base">
              <span>
                {{ profileInfo.biography }}
              </span>
            </div>
            <div class="mb-4">
              <span class="text-gray-400">
                {{ profileInfo.department + " " + profileInfo.position }}
              </span>
            </div>
            <div class="mb-8">
              <a
                :href="profileInfo.website ? profileInfo.website : ''"
                target="_blank"
                class="underline"
              >
                {{ profileInfo.website }}
              </a>
            </div>
          </div>
        </div>
        <!-- tabs section -->
        <div class="mt-4">
          <div class="mb-6 cursor-pointer tabs">
            <span
              @click="changeTab('board')"
              class="tab-item"
              :class="selectedTab == 'board' ? 'text-black border-gray-900' : ''"
            >
              ボード
            </span>
            <span
              @click="changeTab('file')"
              class="tab-item"
              :class="selectedTab == 'file' ? 'text-black border-gray-900' : ''"
            >
              ファイル
            </span>
            <span
              @click="changeTab('like')"
              class="tab-item"
              :class="selectedTab == 'like' ? 'text-black border-gray-900' : ''"
            >
              いいね
            </span>
            <hr class="mt-1 shadow">
          </div>
        </div>
        <!-- boards section -->
        <div class="contents">
          <div
            v-if="selectedTab == 'board' && pageStates.allBoards.length != 0"
          >
            <div class="grid gap-6 px-10 row lg:grid-cols-2 md:grid-cols-2">
              <div
                class="transition transform bg-white border rounded shadow-md hover:shadow-xl hover:-translate-y-px"
                v-for="(item) in pageStates.allBoards"
                :key="item.id"
              >
                <router-link
                  :to="{ name: 'boardDetail', params: { id: item.id } }"
                >
                  <div
                    class="flex overflow-hidden rounded bg-gray-50 preview"
                  >
                    <div v-if="item.coverURL">
                      <div
                        v-bind:style="{ backgroundImage: 'url(' + item.coverURL + ')' }"
                        class="w-40 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9"
                      ></div>
                    </div>
                    <div v-else>
                      <div
                        v-bind:style="{ backgroundImage: item.pins.length > 0 ? 'url(' + pageStates.baseUrl + item.thumbnail + '.jpg)': 'url(' + require('@/assets/board-thumbnail.png') + ')'}"
                        class="inset-0 w-40 bg-center bg-no-repeat bg-cover border-r preview-background aspect-w-16 aspect-h-9"
                      ></div>
                    </div>
                    <div class="flex justify-between w-full px-2 py-1">
                      <div>
                        <p class="mb-1 font-bold text-gray-600 text">
                          {{ item.visibility === "limited" ? "🔒" : "#"}}{{ item.name }}
                        </p>
                        <p class="text-xs text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                      <div class="text-sm">
                        <div class="flex text-gray-400">
                          <pins-icon class="w-5 h-5 current-color" />
                          <span>{{ item.pins.length }}</span>
                        </div>
                        <div class="flex text-gray-400">
                          <users-icon class="w-5 h-5 current-color" />
                          <span>{{ item.membersNumber }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div
            v-else-if="selectedTab == 'board' && pageStates.allBoards.length == 0"
            class="font-mono text-gray-700"
          >
            参加済みのボードがありません。
          </div>
        </div>
        <!-- files section -->
        <profile-files
          v-if="selectedTab == 'file'"
          :files="files"
        />
        <infinite-loading
          v-if="selectedTab == 'file' && showInfiniteLoading"
          spinner="circles"
          @infinite="infiniteHandler"
        />
        <!-- likes section -->
        <like-files
          v-if="selectedTab == 'like'"
          :files="likeFiles"
        />
        <infinite-loading
          v-if="selectedTab == 'like' && showInfiniteLoading"
          spinner="circles"
          @infinite="infiniteHandler"
        />
      </div>
      <div class="col-span-2"></div>
    </main>
  </div>
</template>

<script>
import { computed, ref, reactive, onBeforeMount, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { RepositoryFactoryForGae } from "../../api/gae/RepositoryFactory";
import useSearchRepository from "./composables/useSearchRepository";
import ProfileFiles from "./ProfileFiles.vue";
import LikeFiles from "./LikeFiles.vue";
import infiniteLoading from "vue-infinite-loading";
import UsersIcon from "../icons/UsersIcon.vue";
import PinsIcon from "../icons/PinsIcon.vue";

const BoardRepositoryForGae = RepositoryFactoryForGae.get("board");
const UserRepositoryForGae = RepositoryFactoryForGae.get("user");

export default {
  components: {
    UsersIcon,
    PinsIcon,
    ProfileFiles,
    LikeFiles,
    infiniteLoading
  },
  async setup(_, { emit }) {
    const route = useRoute();
    const store = useStore();
    const {
      state,
      showInfiniteLoading,
      searchPackagePayload,
      payload,
      infiniteHandler
    } = useSearchRepository();
    const pageStates = reactive({
      loading: true,
      userProfile: [],
      baseUrl: `${location.origin}/img/`,
      allBoards: [],
      createdBoards: [],
      favBoard: []
    });
    const profileInfo = reactive({});
    const setProfileInfo = async value => {
      profileInfo.id = value.id;
      profileInfo.photoURL = value.photoURL;
      profileInfo.displayName = value.displayName;
      profileInfo.firstName = value.firstName;
      profileInfo.lastName = value.lastName;
      profileInfo.username = value.username;
      profileInfo.department = value.department;
      profileInfo.position = value.position;
      profileInfo.website = value.website;
      profileInfo.biography = value.biography;
    };

    let showSidebar = ref(true);
    const toggleSidebar = () => {
      showSidebar.value = !showSidebar.value;
    };

    // Files取得
    const files = computed(() => {
      return state.files.filter((file) => {
          const boards = store.state.board.myBoard.filter(
            (board) => board.pins.some(pin => pin.pin_id === file.id)
          );
          Object.assign(file, { boards });
          return file.created_by == pageStates.userProfile.id;
        }
      );
    });
    onBeforeMount (async () => {
      emit("start-loading");
      Object.assign(payload, { ...searchPackagePayload, ...route.query });
      emit("end-loading");
    });
    watchEffect(() => {
      const numFiles = state.files.length;
      if (numFiles > 0) {
        switch (route.params.type) {
          case "packages":
            payload.cursor = state.files[numFiles - 1].packageId;
            break;
          case "slides":
            state.fileType = "slide";
            payload.cursor = state.files[numFiles - 1].slideId;
            break;
          default:
            payload.cursor = state.files[numFiles - 1].packageId;
        }
      }
    });

    const getUserInfoPayload = { 
      workspace: route.params.workspace ?? store.state.user.userInfo.workSpace,
      user_id: route.params.username
    };
    // Profile取得
    const getProfile = async (params) => {
      return await UserRepositoryForGae.getUserInfoById({
        workspace: params.workspace ?? store.state.user.userInfo.workSpace,
        user_id: params.user_id
      }).json();
    };
    const setUserInfo = async (params) => {
      const { data } = await getProfile(params);
      pageStates.userProfile = data[0];
      await setProfileInfo(pageStates.userProfile);
      pageStates.loading = false;
    };
    await setUserInfo(getUserInfoPayload);

    // Boards取得
    const getBoardPayload = {
      user_id: pageStates.userProfile.id,
      workspace: pageStates.userProfile.workSpace,
    };
    const fetchBoards = async () => {
      const { data } = await BoardRepositoryForGae.fetchBoardById(getBoardPayload).json();
      if (data.all.find(board => 
        board.name == "お気に入り" &&
        board.created_user == store.state.user.userInfo.id
      )) {
        pageStates.allBoards = data.all;
      } else {
        pageStates.allBoards = data.all.filter(board => board.name != "お気に入り");
      }
      pageStates.createdBoards = data.created_board;
      pageStates.favBoard = data.all.filter(board => board.name == "お気に入り");
    };
    await fetchBoards();

    // いいね取得
    const likeFiles = computed(() => {
      return state.files.filter(
        file => file.liked_users.some(
          user => user == pageStates.userProfile.username
        )
      );
    });

    // タブ切り替え
    const selectedTab = ref("board");
    const changeTab = type => {
      selectedTab.value = type;
    };

    return {
      pageStates,
      showSidebar,
      showInfiniteLoading,
      profileInfo,
      selectedTab,
      files,
      likeFiles,
      toggleSidebar,
      changeTab,
      infiniteHandler
    };
  }
};
</script>

<style lang="postcss" scoped>
.tab-item {
  @apply text-gray-400 font-semibold py-2 px-4 transition-all border-b-2 border-gray-100 hover:text-black hover:border-gray-900
}
</style>