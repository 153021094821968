
<template>
  <svg width="32" height="32" viewBox="0 0 256 256">
    <path
      d="M209.655 122.342a8 8 0 0 1-.008 11.313l-82.053 81.947a56 56 0 0 1-79.192-79.2L147.671 35.75a40 40 0 1 1 56.607 56.528L105.01 192.931a24 24 0 1 1-33.98-33.902l83.262-84.644a8 8 0 0 1 11.406 11.22L82.39 170.297a8 8 0 1 0 11.266 11.36l99.27-100.652a24 24 0 1 0-33.902-33.98l-99.27 100.65a40 40 0 1 0 56.53 56.61l82.057-81.95a7.999 7.999 0 0 1 11.314.007z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "PhPaperclip"
};
</script>