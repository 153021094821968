import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION;
const resource = "user";
export default {
  login(payload) {
    return Repository.post(`${version}/${resource}/login`, { json: payload });
  },

  signup(payload) {
    return Repository.post(`${version}/${resource}/signup`, { json: payload });
  },

  sendVerificationEmail(payload) {
    return Repository.post(`${version}/${resource}/verification_email`, {
      json: payload,
    });
  },

  deletableUser(payload) {
    return Repository.post(`${version}/${resource}/deletable_user`, {
      json: payload,
    });
  },

  deleteUser(payload) {
    return Repository.post(`${version}/${resource}/delete`, { json: payload });
  },

  deleteUsers(payload) {
    return Repository.post(`${version}/${resource}/delete_users`, {
      json: payload,
    });
  },

  updateProfile(payload) {
    return Repository.post(`${version}/${resource}/update_profile`, {
      json: payload,
    });
  },

  updateNotificationSettings(payload) {
    return Repository.put(`${version}/${resource}/notification_settings`, {
      json: payload,
    });
  },

  createProfile(payload) {
    return Repository.post(`${version}/${resource}/create_profile`, {
      json: payload,
    });
  },

  createSearchApiKey(payload) {
    return Repository.post(`${version}/${resource}/create_search_api_key`, {
      json: payload,
    });
  },

  checkSignup(payload) {
    return Repository.post(`${version}/${resource}/check_signup`, {
      json: payload,
    });
  },

  checkEmail(payload) {
    return Repository.post(`${version}/${resource}/check_email`, {
      json: payload,
    });
  },

  checkUserId(payload) {
    return Repository.post(`${version}/${resource}/check_user_id`, {
      json: payload,
    });
  },

  createUserAfterSignup(payload) {
    return Repository.post(`${version}/${resource}/create_user`, {
      json: payload,
    });
  },

  createUserMapping() {
    return Repository.post(`${version}/${resource}/create_user_mapping`, {
      json: {},
    });
  },

  getUserInfo() {
    return Repository.get(`${version}/${resource}/get`);
  },

  getAllUsers() {
    return Repository.get(`${version}/${resource}/get_all`);
  },

  getUserInfoById(payload) {
    return Repository.post(`${version}/${resource}/get_profile`, {
      json: payload,
    });
  },

  sendInviteMail(payload) {
    return Repository.post(`${version}/${resource}/invite`, { json: payload });
  },

  updateFirstLogin(payload) {
    return Repository.put(`${version}/${resource}/update_first_login`, {
      json: payload,
    });
  },
};
