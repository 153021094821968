<template>
  <button
    class="ml-5 flex items-center justify-center p-1 rounded hover:bg-gray-100 focus:outline-none"
    @click="dropdownOpen = !dropdownOpen"
  >
    <img
      v-if="userInfo.photoURL"
      :src="userInfo.photoURL"
      alt="user-icon"
      class="object-cover p-1 border rounded-full h-12 w-12"
    />
    <div v-else class="p-2 border rounded-full h-12 w-12">
      <span class="text-2xl text-gray-500">
        {{ userInfo?.lastName ? userInfo.lastName.substring(0, 1) : "" }}
      </span>
    </div>
    <arrow-down />
  </button>
  <div
    v-show="dropdownOpen"
    @click="dropdownOpen = false"
    class="fixed inset-0 z-10 w-full h-full"
  ></div>
  <div
    v-show="dropdownOpen"
    class="absolute right-0 z-20 py-2 mt-2 bg-white rounded-md shadow-xl dropdown-menu top-12 w-52"
  >
    <div
      @click="goProfile"
      class="flex items-center p-2 mb-4 user-item hover:bg-gray-100"
    >
      <img
        v-if="userInfo.photoURL"
        :src="userInfo.photoURL"
        alt="user-icon"
        class="object-cover p-1 bg-white border rounded-full h-14 w-14"
      />
      <div
        v-else
        class="flex items-center justify-center p-2 border rounded-full h-14 w-14"
      >
        <span class="text-4xl text-gray-500">
          {{ userInfo?.lastName ? userInfo.lastName.substring(0, 1) : "" }}
        </span>
      </div>
      <p class="mx-2 text-gray-600 truncate">
        {{
          userInfo.displayName != '' ? userInfo.displayName : userInfo.lastName + ' ' + userInfo.firstName
        }}
      </p>
    </div>
    <hr />
    <div class="my-2 menu-item">
      <router-link to="#" class="menu-list">
        通知
      </router-link>
      <router-link to="#" class="menu-list">
        ウォッチ
      </router-link>
      <router-link to="/boards" class="menu-list">
        マイボード
      </router-link>
      <router-link to="#" class="menu-list">
        ユーザ一覧
      </router-link>
      <router-link :to="{ name: 'MobileSettings' }" class="menu-list">
        設定
      </router-link>
    </div>
    <hr />
    <div class="my-2 logout">
      <button
        class="w-full text-left menu-list focus:outline-none text-red-500"
        type="button"
        @click="showModal = true"
      >
        ログアウト
      </button>
    </div>
  </div>
  <confirm-logout-modal
    v-if="showModal"
    :showModal="true"
    @closeModal="initPage"
  />
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import ArrowDown from "../../icons/ArrowDown.vue";
import ConfirmLogoutModal from "../modals/ConfirmLogoutModal.vue";

export default {
  components: {
    ArrowDown,
    ConfirmLogoutModal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    let dropdownOpen = ref(false);
    let showModal = ref(false)

    const userInfo = computed(() => {
      return store.state.user.userInfo;
    });

    const goProfile = () => {
      router.push({ path: `/users/${userInfo.value.username}/profile` });
    };

    const initPage = () => {
      dropdownOpen.value = false;
      showModal.value = false;
    };

    return {
      dropdownOpen,
      userInfo,
      showModal,
      goProfile,
      initPage
    };
  },
};
</script>

<style lang="postcss" scoped>
.menu-list {
  @apply block px-4 py-2 text-gray-600 hover:bg-gray-100;
}
</style>