<template>
  <base-modal-without-line
    :show-modal="showModal"
    @close-modal="closeModal"
  >
    <template v-slot:header>
      アップロードを開始しました！
    </template>

    <template v-slot:body>
      アップロード処理が完了次第、アプリ上でファイルが表示されます。
    </template>

    <template v-slot:footer>
      <button
        @click="closeModal"
        class="w-32 p-2 mr-auto text-center text-white bg-gray-500 border rounded hover:bg-gray-400 focus:outline-none"
      >
        閉じる
      </button>
    </template>
  </base-modal-without-line>
</template>

<script>
import BaseModalWithoutLine from "./BaseModalWithoutLine.vue";
export default {
  components: { BaseModalWithoutLine },

  props: {
    showModal: Boolean
  },

  emits: [
    'closeModal'
  ],

  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    };

    return { closeModal };
  }
};
</script>