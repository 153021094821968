<template>
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
    <path
      d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcRoundKeyboardArrowDown"
};
</script>