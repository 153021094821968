<template>
  <base-modal :showModal="true" @toggleModal="toggleBoardModal('')">
    <template v-slot:header>ボードを編集する</template>
    <template v-slot:body>
      <div class="vld-parent">
        <Form :validation-schema="schema" @submit="updateBoard" :initial-values="formValues">
          <div class="relative field">
            <div class="flex items-center justify-center">
              <div class="text-4xl text-center bg-gray-100 border" style="width: 42px; height: 42px;">{{state.emoji}}</div>
              <div class="w-full p-2 text-gray-400 border border-l-0 cursor-pointer hover:bg-gray-50" @click="state.showEmoji = !state.showEmoji" style="height: 42px;">アイキャッチの絵文字を選択する</div>
            </div>
            <EmojiPicker class="absolute top-0 z-20 left-20" v-if="state.showEmoji" :native="true" @select="setEmoji" :disable-skin-tones="true" :hide-group-names="false" />
            <div v-show="state.showEmoji" @click="state.showEmoji = false" class="fixed inset-0 z-10 w-full h-full"></div>
          </div>
          <div class="field">
            <label class="field-label" for="boardName">ボード名</label>
            <Field
              class="field-item"
              name="boardName"
              id="boardName"
              placeholder="プロジェクト計画集"
            />
            <ErrorMessage name="boardName" class="field-error" />
            <p class="my-1 text-sm text-gray-400">
              参考になるスライド、部内共有、プロジェクト名...など、わかりやすい名前で作成するのがおすすめです。
            </p>
          </div>
          <div class="field">
            <label class="field-label" for="description"
              >説明(オプション)</label
            >
            <Field
              class="field-item"
              name="description"
              id="description"
              rows="2"
              placeholder="このボードは参考にすべき資料を配置しています。"
              as="textarea"
            />
          </div>
          <div
            v-if="boardInfo.visibility == 'limited'"
            class="field"
          >
            <p class="field-label">ボード公開</p>
            <p class="my-1 text-sm text-gray-600">
              ※非公開のボードは後から公開に変更することはできません。
            </p>
          </div>
          <div
            v-if="boardInfo.visibility == 'public'"
            class="field"
          >
            <p class="field-label">ボード公開</p>
            <Field
              id="create-board-permission-limited"
              class="w-4 h-4"
              value="limited"
              name="visibility"
              type="radio"
            ></Field>
            <label
              for="create-board-permission-limited"
              class="mb-1 ml-1 text-base text-gray-500 cursor-pointer"
            >
              プライベート
            </label>
            <p class="my-1 text-sm text-gray-400">
              自身とボード参加者にしか表示されません。このボードは招待制で、非公開の資料でもボードメンバーは閲覧することができるようになります。
            </p>
            <p class="mb-1 font-bold text-sm text-gray-600">
              ※プライベートボードはあとから公開に変更することができません。
            </p>
            <Field
              id="create-board-permission-public"
              class="w-4 h-4"
              value="public"
              name="visibility"
              type="radio"
            ></Field>
            <label
              for="create-board-permission-public"
              class="mb-1 ml-1 text-base text-gray-500 cursor-pointer"
            >
              パブリック
            </label>
            <p class="my-1 text-sm text-gray-400">
              このボードは検索可能になり、誰でも自分から参加できるように公開されます。
            </p>
          </div>
          <div class="mb-8">
            <p class="field-label">
              ボードメンバー {{ boardInfo.participants?.length }}人
            </p>
            <div class="flex items-center justify-between">
              <div class="flex overflow-x-scroll w-96">
                <div
                  v-for="member in boardInfo.participants"
                  :key="member.id"
                  class="flex items-center justify-center w-10 h-10 border rounded-full"
                  style="min-width: 40px; min-height: 40px"
                >
                  <img
                    v-if="member.photoURL"
                    class="object-cover w-full h-full rounded-full"
                    :src="member.photoURL"
                    alt=""
                  />
                  <div
                    v-else
                    class="flex items-center justify-center w-full h-full text-lg rounded-full bg-gray-50"
                  >
                    <span>{{ member?.firstName ? member.firstName.slice(0, 1) : '?' }}</span>
                  </div>
                </div>
              </div>
              <button
                v-if="boardInfo.type == 'custom'"
                @click="changeOperationModalFlag('invite')"
                class="flex items-center justify-center w-10 h-10 transition bg-gray-100 rounded-full hover:bg-gray-200"
                type="button"
              >
                <Plus />
              </button>
            </div>
          </div>

          <div class="w-full my-2 border"></div>
          <div
            v-if="boardInfo.created_user == state.userInfo.id || state.userInfo.role == 'admin'"
            class="flex justify-between"
          >
            <button
              @click="changeOperationModalFlag('delete')"
              class="flex items-center justify-center w-10 h-10 text-red-400 transition border border-red-400 rounded-full hover:bg-red-400 hover:text-white"
              type="button"
            >
              <trash-icon
                class="w-6 h-6 stroke-current stroke-2"
              />
            </button>
            <button class="px-4 py-2 text-white transition bg-indigo-700 rounded button hover:bg-indigo-600">ボードを更新する</button>
          </div>
        </Form>

        <loading
          v-model:active="state.loading"
          :is-full-page="false"
          color="#818589"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { Field, Form, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
import Plus from "../../icons/Plus.vue";
import TrashIcon from "../../icons/TrashIcon.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import BaseModal from "./BaseModal.vue";
import EmojiPicker from "vue3-emoji-picker";
import "../../../../node_modules/vue3-emoji-picker/dist/style.css";
import { list as emoji } from '../../../assets/emoji.json'

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Plus,
    Loading,
    TrashIcon,
    BaseModal,
    EmojiPicker
  },

  props: {
    toggleBoardModal: {
      type: Function,
      required: true,
    },
    changeOperationModalFlag: {
      type: Function,
      required: true,
    },
    boardInfo: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      userInfo: store.state.user.userInfo,
      loading: false,
      emoji: props.boardInfo.emoji,
      showEmoji: false
    });

    const schema = yup.object({
      boardName: yup.string().required().label("ボード名"),
      description: yup.string().label("説明"),
      visibility: yup.string().label("権限"),
    });

    const formValues = reactive({
      boardName: props.boardInfo.name,
      description: props.boardInfo.description,
      visibility: props.boardInfo.visibility,
      coverURL: props.boardInfo.coverURL ? props.boardInfo.coverURL : ""
    });

    const updateBoard = async (values) => {
      state.loading = true;
      const payload = {
        name: values.boardName,
        description: values.description,
        visibility: values.visibility,
        emoji: state.emoji,
        coverURL: values.coverURL,
        target: props.boardInfo.id,
      };
      const result = await BoardRepository.update(payload).json();

      emit("updateBoard", 'edit');
      state.loading = false;

      if (result.status === "200") {
        await new Promise(resolve => setTimeout(resolve, 100));
        props.toggleBoardModal('');
      }
    };

    const setEmoji = (emoji) => {
      state.emoji = emoji.i
    }

    return {
      state,
      schema,
      formValues,
      updateBoard,
      setEmoji
    };
  },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply text-sm rounded px-3 py-1.5 focus:outline-none;
}

.field {
  @apply mb-6;
}

.field-label {
  @apply block mb-1 text-gray-800 font-bold text-sm ;
}

.field-item {
  @apply w-full p-2 text-gray-700 border rounded text-base;
}

.field-error {
  @apply text-red-500 text-sm;
}
</style>