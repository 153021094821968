<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="showComment"
      class="flex flex-col w-80 bg-gray-100 bg-white border-t border-gray-300 max-height"
    >
      <!-- ヘッダー -->
      <div class="p-2 border-b border-gray-300 font-bold bg-gray-300">
        <comment-icon class="inline-block mr-2" />コメント（{{ state.comment?.length ? state.comment.length + "件" : "0件" }}）
      </div>
      <!-- コメント表示 -->
      <div
        id="commentView"
        class="overflow-y-scroll"
      >
        <div
          v-if="!state.comment || state.comment.length == 0"
        >
        </div>
        <div
          v-else
          v-for="comment in state.comment"
          :key="comment"
          :class="comment?.id == state.notifiedCommentId ? 'transition-bg-color' : ''"
        >
          <div
            v-if="state.editMode == false || comment?.id != state.targetComment?.id"
            class="grid grid-cols-12 p-3 ml-2 mb-2 rounded"
          >
            <div class="col-span-2 ml-1">
              <img
                v-if="comment.photoURL"
                :src="comment.photoURL"
                alt=""
                class="object-cover w-11 h-11 border rounded-full bg-white"
              />
              <div
                v-else
                class="flex items-center justify-center object-cover w-11 h-11 border rounded-full bg-white"
              >
                <span class="text-xl">
                  {{ comment.firstName?.slice(0, 1) }}
                </span>
              </div>
            </div>
            <div class="col-span-8 ml-3">
              <p class="font-bold mb-1">{{ comment.displayName }}</p>
              <p class="text-gray-600">{{ comment.text }}</p>
              <p class="text-xs mt-3 flex justify-between">
                <span>{{ comment.formatted_created_at }}</span>
                <span>{{ comment.edited ? "(編集済み)" : "" }}</span>
                </p>
            </div>
            <div
              v-if="comment.user_id == state.userInfo.id"
              class="col-span-1"
            >
              <button
                @click="clickEditDropdown(comment)"
                class="rounded hover:bg-gray-300"
              >
                <ph-dots-three-bold-icon />
              </button>
              <div
                v-if="state.openEditDropdown && comment?.id == state.targetComment?.id"
              >
                <comment-edit-dropdown
                  :commentInfo="state.targetComment"
                  :fileInfo="state.packageInfo"
                  @editComment="changeToEditMode"
                  @deleteComment="deleteComment"
                />
              </div>
            </div>
          </div>
          <div
            v-if="state.editMode == true && comment?.id == state.targetComment?.id"
            class="grid grid-cols-12 p-3 ml-2 mt-2 bg-yellow-100 rounded"
          >
            <div class="col-span-2 ml-1">
              <img
                v-if="comment.photoURL"
                :src="comment.photoURL"
                alt=""
                class="object-cover w-11 h-11 border rounded-full bg-white"
              />
              <div
                v-else
                class="flex items-center justify-center object-cover w-11 h-11 border rounded-full bg-white"
              >
                <span class="text-xl">
                  {{ comment.firstName?.slice(0, 1) }}
                </span>
              </div>
            </div>
            <div class="col-span-8 ml-1">
              <mentionable
                :keys="['@', '＠']"
                :items="state.allUsers"
                offset="6"
                omit-key
                @apply="onApply"
              >
                <textarea
                  id="search-form"
                  class="col-span-11 w-full mb-2 h-8 pl-2 bg-white border border-gray-300 rounded focus:outline-none"
                  type="text"
                  autocomplete="off"
                  v-model="comment.text"
                  @focus="focusedTextarea"
                  @blur="bluredTextarea"
                  v-focus
                />
                <template #no-result>
                  <div class="p-2">
                    ユーザーが見つかりません
                  </div>
                </template>
                <template #item-@="{ item }">
                  <div class="flex flex-row cursor-pointer hover:bg-blue-600">
                    <div class="pt-2 pl-2">
                      <img
                        v-if="item.photoURL"
                        :src="item.photoURL"
                        alt=""
                        class="object-cover w-7 h-7 border rounded-full bg-white"
                      />
                      <div
                        v-else
                        class="flex items-center justify-center object-cover w-7 h-7 border rounded-full bg-white"
                      >
                        <span class="text-xl">
                          {{ item.firstName?.slice(0, 1) }}
                        </span>
                      </div>
                    </div>
                    <div class="p-2 hover:text-white">
                      {{ `${item.lastName} ${item.firstName}` }} {{ `@${item.username}` }}
                    </div>
                  </div>
                </template>
              </mentionable>
              <div class="flex justify-end">
                <button
                  class="p-1 mr-1 bg-white border rounded"
                  @click="cancelEdittingComment(comment)"
                >
                  キャンセル
                </button>
                <button
                  class="p-1 ml-2 bg-green-700 text-white border rounded disabled:bg-gray-300"
                  @click="updateComment(comment)"
                  :disabled="state.targetCommentText == comment.text"
                >
                  保存する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-24"></div><div class="mb-2"></div>
      <!-- コメント入力 -->
      <div class="border-t border-gray-900 w-full p-2 absolute bottom-12">
        <mentionable
          :keys="['@', '＠']"
          :items="state.allUsers"
          offset="6"
          omit-key
          @apply="onApply"
        >
          <div class="flex flex-row mt-2">
            <textarea
              placeholder="コメントする"
              id="comment-area"
              class="w-full h-8 pl-3 pt-0.5 border border-gray-600 bg-gray-200 rounded-full focus:outline-none col-span-11"
              type="text"
              autocomplete="off"
              v-model="state.text"
              max-height="70px"
              @focus="focusedTextarea"
              @blur="bluredTextarea"
            />
            <send-icon
              v-if="!state.text"
              class="col-span-1 mt-1 ml-1 text-2xl text-gray-400 cursor-not-allowed"
            />
            <send-icon
              v-else
              @click="sendComment"
              class="col-span-1 mt-1 ml-1 text-2xl text-blue-600 cursor-pointer"
            />
          </div>
          <template #no-result>
            <div class="p-2">
              ユーザーが見つかりません
            </div>
          </template>
          <template #item-@="{ item }">
            <div class="flex flex-row cursor-pointer hover:bg-blue-600">
              <div class="pt-2 pl-2">
                <img
                  v-if="item.photoURL"
                  :src="item.photoURL"
                  alt=""
                  class="object-cover w-7 h-7 border rounded-full bg-white"
                />
                <div
                  v-else
                  class="flex items-center justify-center object-cover w-7 h-7 border rounded-full bg-white"
                >
                  <span class="text-xl">
                    {{ item.firstName?.slice(0, 1) }}
                  </span>
                </div>
              </div>
              <div class="p-2 hover:text-white">
                {{ `${item.lastName} ${item.firstName}` }} {{ `@${item.username}` }}
              </div>
            </div>
          </template>
        </mentionable>
      </div>
    </div>
  </transition>
</template>

<script>
import { reactive, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { Mentionable } from "vue-mention";
import CommentEditDropdown from "../organisms/dropdowns/CommentEditDropdown.vue";
import SendIcon from "../icons/SendIcon.vue";
import PhDotsThreeBoldIcon from "../icons/PhDotsThreeBold.vue";
import CommentIcon from "../icons/CommentIcon.vue";
import utility from "../organisms/composables/utility";
import useUserRepository from "../organisms/composables/useUserRepository";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepository = RepositoryFactoryForGae.get("file");

export default {
  components: {
    CommentEditDropdown,
    SendIcon,
    PhDotsThreeBoldIcon,
    CommentIcon,
    Mentionable
  },
  props: {
    showComment: {
      type: Boolean,
      required: true
    },
    packageInfo: {
      type: Object,
      required: true
    },
    notifiedComment: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const store = useStore();
    const state = reactive({
      userInfo: store.state.user.userInfo,
      text: "",
      packageInfo: props.packageInfo,
      notifiedCommentId: props.notifiedComment,
      comment: props.packageInfo.comment ?? [],
      allUsers: store.state.user.users,
      openEditDropdown: false,
      targetComment: {},
      targetCommentText: "",
      editMode: false,
      mentionedUsers: []
    });

    state.allUsers.map((user) => user.value = `@${user.username}`);

    const { getUsers } = useUserRepository();
    const getAllUsers = async () => {
      const users = await getUsers({ workSpace: state.userInfo.workSpace });
      state.allUsers = users;
    };

    onBeforeMount(() => {
      if (!state.comment || state.comment.length == 0) return;
      state.comment.map(async (comment) => {
        if (state.allUsers.length == 0) {
          await getAllUsers();
        }
        const userId = comment.user_id;
        state.allUsers.map((user) => {
          if (userId == user.id) {
            comment.photoURL = user.photoURL;
            comment.displayName = user.displayName != "" ? user.displayName : `${user.lastName} ${user.firstName}`;
            comment.firstName = user.firstName;
            comment.lastName = user.lastName;
            comment.formatted_created_at = formatTime(new Date(comment.created_at).toISOString());
          }
        });
      });
    });

    onMounted(() => {
      // コメント最下部へフォーカス
      const target = document.getElementById("commentView");
      target.scrollTop = target.scrollHeight;

      /**
       * @todo
       * - Insert "v-html" tag(v-html="comment.text")
       * - Replace all the mentioned user's name
       */
      // if (state.comment.isReplacedText) return;
      // state.comment.map((comment) => {
      //   comment.text = 
      //     comment.mention_user_ids.length == 0
      //       ? `<span>${comment.text}</span>`
      //       : comment.mention_user_ids.map(
      //         (user) => comment.text?.replace(
      //           `@${user.username}`,
      //           `<span class="mention-section rounded p-0.5" style="color: rgb(70,130,180); background: rgba(0,191,255,0.2);">@${user.username}</span>`
      //         )
      //       );
      // });
      // state.comment.isReplacedText = true;
    });

    const { formatTime } = utility();

    // コメント作成
    const sendComment = async () => {
      const arrangedMentionedUsers = [];
      state.mentionedUsers.forEach((user) => {
        if (state.text.includes(user.username)) arrangedMentionedUsers.push(user);
      });
      const payload = {
        type: "packages",
        file_id: state.packageInfo.id,
        mention_user_ids: arrangedMentionedUsers,
        text: state.text,
        url: {
          domain: location.origin,
          endpoint: `/package/${state.packageInfo.id}`
        }
      };
      const tempNewComment = {
        id: "temp",
        user_id: state.userInfo.id,
        text: state.text,
        photoURL: state.userInfo.photoURL,
        displayName: state.userInfo.displayName != ""
          ? state.userInfo.displayName
          : `${state.userInfo.lastName} ${state.userInfo.firstName}`,
        firstName: state.userInfo.firstName,
        lastName: state.userInfo.lastName,
        formatted_created_at: "たった今",
        is_temp: true,
      };
      state.comment.push(tempNewComment);
      state.text = "";
      await FileRepository.createComment(payload)
        .json()
        .then((result) => {
          tempNewComment.id = result.data.id;
          tempNewComment.created_at = result.data.created_at;
          tempNewComment.formatted_created_at = formatTime(new Date(result.data.created_at).toISOString());
          tempNewComment.is_temp = false;
          emit("createComment", tempNewComment);
        });
    };

    const focusedTextarea = () => {
      emit("comment-focus", true);
    };
    const bluredTextarea = () => {
      emit("comment-focus", false);
    };

    // コメント編集
    const clickEditDropdown = (target) => {
      state.openEditDropdown = !state.openEditDropdown;
      state.targetComment = target;
      state.targetCommentText = target.text;
    };
    const changeToEditMode = () => {
      state.openEditDropdown = false;
      state.editMode = true;
    };
    const updateComment = async (comment) => {
      const arrangedMentionedUsers = [];
      state.mentionedUsers.forEach((user) => {
        if (comment.text.includes(user.username)) arrangedMentionedUsers.push(user);
      });
      state.editMode = false;
      state.comment.map((c) => {
        if (c.id == comment.id) {
          c.edited = true;
          c.text = comment.text;
        }
      });
      const payload = {
        type: "packages",
        file_id: state.packageInfo.id,
        comment_id: comment.id,
        mention_user_ids: arrangedMentionedUsers,
        text: comment.text,
        url: {
          domain: location.origin,
          endpoint: `/package/${state.packageInfo.id}`
        }
      };
      await FileRepository.editComment(payload);
    };
    const cancelEdittingComment = (currentText) => {
      state.editMode = false;
      state.openEditDropdown = false;
      currentText.text = state.targetCommentText;
    };

    // コメント削除
    const deleteComment = (commentId) => {
      state.comment = state.comment.filter((comment) => comment.id != commentId);
      emit("deleteComment", commentId);
    };

    // メンション
    const onApply = (item) => {
      state.mentionedUsers.push({ id: item.id, username: item.username });
    };

    return {
      state,
      formatTime,
      clickEditDropdown,
      focusedTextarea,
      bluredTextarea,
      sendComment,
      changeToEditMode,
      updateComment,
      cancelEdittingComment,
      deleteComment,
      onApply
    };
  },
};
</script>

<style scoped>
.max-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

@keyframes bgcolor {
  5% { background-color: rgb(254 240 138); }
  20% { background-color: rgb(254 240 138); }
}
.transition-bg-color {
  animation: bgcolor 5s;
}
</style>