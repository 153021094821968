<template>
  <signin-template />
</template>

<script>
import SigninTemplate from '@/components/templates/SigninTemplate'

export default {
  components: { 
    SigninTemplate 
  },
};
</script>