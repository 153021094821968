<template>
  <div v-if="deviceType == 'pc'">
    <user-profile-template />
  </div>
  <div v-if="deviceType == 'mobile'">
    <mobile-user-profile-template />
  </div>
</template>

<script>
import UserProfileTemplate from "../templates/UserProfileTemplate.vue";
import MobileUserProfileTemplate from "../mobile/templates/Profile.vue";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    UserProfileTemplate,
    MobileUserProfileTemplate
  },
  setup () {
    const deviceType = calcBreakpoint(window.innerWidth);

    return {
      deviceType
    }
  }
};
</script>