<template>
  <password-reset-template />
</template>

<script>
import PasswordResetTemplate from "@/components/templates/PasswordResetTemplate";

export default {
  components: {
    PasswordResetTemplate
  }
};
</script>