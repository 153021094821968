<template>
  <div class="bg-indigo-50">
    <div
      class="container flex flex-col items-center justify-center h-screen mx-auto"
    >
      <div class="px-8 py-6 bg-white rounded-lg shadow-lg" style="width: 640px;">
        <header class="my-4">
          <img
            class="mx-auto"
            src="../../assets/spitch-logo.svg"
            width="200"
            alt=""
          />
        </header>
        <div class="my-4">
          <div class="my-8">
            <h1 class="text-center text-gray-600">
              アカウントの登録を受け付けました！
            </h1>
            <img class="mx-auto my-4" src="../../assets/send-verify-mail.png" width="250" alt="">
          </div>
          <div class="mb-8 description">
            <p class="mb-2 text-gray-700 break-words">
              ご登録いただいた<span class="font-bold">「{{ email }}」</span
              >宛に登録確認用のご案内をお送りしましたので、アカウントの登録を完了してください。
            </p>
            <ul class="text-sm text-gray-400">
              <li>
                ※上記の確認作業が完了しないと、本サービスをご利用できません。
              </li>
              <li>
                ※受信箱にメールがない場合は迷惑メールをご確認ください。
              </li>
            </ul>
          </div>
          <div class="mb-8 text-center button">
            <router-link
              to="/signin"
              class="p-2 mt-1 text-center text-white transition-colors duration-300 bg-indigo-500 border border-indigo-600 border-solid rounded md:mx-2 md:p-4 hover:bg-indigo-700"
            >
              ログインページに移動する
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true
    }
  },
};
</script>