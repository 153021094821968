<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
  >
    <path
      d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z"
      fill="currentColor"
    >
    </path>
  </svg>
</template>