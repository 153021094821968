<template>
  <top-trend-template :files="state.files" :loading="state.loading" />
</template>

<script>
import { reactive, onBeforeMount, watchEffect } from 'vue';
import { useRoute } from 'vue-router'
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const TrendRepositoryForGae = RepositoryFactoryForGae.get("trend");
import TopTrendTemplate from "../templates/TopTrendTemplate.vue"

export default {
  components: {
    TopTrendTemplate
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      files: [],
      loading: true
    });

    onBeforeMount(async () => {
      if (route.query.q === 'slide') {
        const res = await TrendRepositoryForGae.slide().json();
        state.files = res.data;
      } else {
        const res = await TrendRepositoryForGae.package().json();
        state.files = res.data;
      }
      state.loading = false;
    })

    watchEffect(async () => {
      state.loading = true;
      const q = route.query?.q;
      if (q === 'package') {
        const res = await TrendRepositoryForGae.package().json();
        state.files = res.data;
      } else if (q === 'slide') {
        const res = await TrendRepositoryForGae.slide().json();
        state.files = res.data;
      }
      state.loading = false;
    })

    return {
      state
    }
  },
}
</script>