import FileRepository from "./fileRepository";
import BoardRepository from "./boardRepository";
import UserRepository from "./userRepository";
import SearchRepository from "./searchRepository";
import NotificationRepository from "./notificationRepository";
import TrendRepository from "./trendRepository";
import externalRepository from "./externalRepository";

const repositories = {
  file: FileRepository,
  board: BoardRepository,
  user: UserRepository,
  search: SearchRepository,
  notification: NotificationRepository,
  trend: TrendRepository,
  external: externalRepository
};

export const RepositoryFactoryForGae = {
  get: name => repositories[name]
};