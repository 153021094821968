<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="showDownloadMenu"
      @click.stop
      class="absolute z-20 text-gray-700 bg-white rounded-md shadow-lg right"
      style="min-width: 240px"
    >
      <h1 class="px-4 py-2 text-base font-bold bg-gray-100 rounded-md">
        📥 ダウンロード 
      </h1>
      <div class="px-4 py-2">
        <!-- PPTX -->
        <div class="flex items-center justify-between">
          <p class="font-bold">PPTX<span class="text-sm italic text-green-500" v-show="renderBy == 'pptx' ? true : false">✓</span></p>
          <div v-if="userId == packageInfo.user.id" class="w-32 text-center">
            <label
              class="text-base text-gray-500 underline cursor-pointer hover:text-gray-600"
              for="upload-pptx"
              >{{ hasPptx ? "ファイルを更新" : "アップロード" }}</label
            >
            <input id="upload-pptx" type="file" class="hidden" accept=".pptx" @change="upload"/>
          </div>
          <button
            class="flex items-center justify-center w-8 h-8 text-white transition bg-gray-500 border border-gray-200 rounded shadow-2xl hover:bg-gray-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            @click="downloadFile('pptx_path')"
            :disabled="!(hasPptx && canDownload)"
          >
            <download-icon class="w-full h-full p-1" />
          </button>
        </div>

        <hr class="my-2" />

        <!-- PDF -->
        <div class="flex items-center justify-between">
          <p class="font-bold">PDF<span class="text-sm italic text-green-500" v-show="renderBy == 'pdf' ? true : false">✓</span></p>
          <button
            class="flex items-center justify-center w-8 h-8 text-white transition bg-gray-500 border border-gray-200 rounded shadow-2xl hover:bg-gray-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            @click="downloadFile('pdf_path')"
            :disabled="!(hasPdf && canDownload)"
          >
            <download-icon class="w-full h-full p-1" />
          </button>
        </div>
      </div>

      <loading
        v-model:active="state.showLoading"
        :is-full-page="false"
        color="#818589"
      />
    </div>
  </transition>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepository = RepositoryFactoryForGae.get("file");
import DownloadIcon from "../../icons/DownloadIcon.vue";

export default {
  components: {
    DownloadIcon,
    Loading,
  },

  props: {
    packageInfo: {
      type: Object,
      required: true,
    },
    showDownloadMenu: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      showLoading: false,
    });

    const store = useStore();
    const userId = computed(() => store.state.auth.userUid);

    const hasPdf = computed(() => {
      return props.packageInfo?.pdf_path ? true : false;
    });

    const hasPptx = computed(() => {
      return props.packageInfo?.pptx_path ? true : false;
    });

    const canDownload = computed(() => {
      const permission = props.packageInfo?.permission;
      if (permission === "public" || props.packageInfo?.created_by == userId.value)
        return true; // return true if the permission is public or file creator.

      const isVisibleUser = props.packageInfo?.visible_user.includes(userId.value);
      return isVisibleUser;
    })

    const renderBy = computed(() => {
      const isPdf = props.packageInfo?.extension?.pdf;
      const isPptx = props.packageInfo?.extension?.pptx;
      return isPdf ? "pdf" : !isPptx ? "pdf" : "pptx";
    });

    /**
     * Download PPTX or PDF file based on argument.
     */
    const downloadFile = async (field) => {
      // Before download
      state.showLoading = true;

      const path = props.packageInfo[field];
      const res = await FileRepository.getDownloadUrl({ target: path, type: 'package' }).json();
      if (res.status == "success") {
        const url = res.url;
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
      } else {
        console.log("Unable to retrieve download URL.");
      }

      // After download
      await new Promise(resolve => setTimeout(resolve, 100));
      state.showLoading = false;
    };

    // Upload PPTX file to register as a download file with exist package document in firestore.
    const upload = async (e) => {
      // Before upload
      state.showLoading = true;
      const file = e.target.files[0];

      // Validate file size
      const mb = 50;
      const maxFileSize = mb * 1024 * 1024;
      if (file.size > maxFileSize) {
        return alert(`${mb}MBを超過しています。`);
      }

      // Get signed URL
      const payload = {
        fileName: file.name,
        packageId: props.packageInfo.packageId,
      };
      const res = await FileRepository.getPptxUploadUrl(payload).json();
      if (res.status == "success") console.info("Successful getPptxUploadUrl!");

      // Delete previous file data
      const deletePreviousDataPayload = { file_id: props.packageInfo.id }
      await FileRepository.replacePackageData(deletePreviousDataPayload);

      // Upload new file data
      const uploadFileOptions = { body: file };
      await FileRepository.uploadFile(res.url, uploadFileOptions);

      // After upload
      const newValue = { pptx_path: res.filePath };
      emit("updatePackageInfo", newValue);
      state.showLoading = false;
      return;
    };

    return {
      state,
      userId,
      downloadFile,
      upload,
      hasPdf,
      hasPptx,
      canDownload,
      renderBy,
    };
  },
};
</script>

<style lang="postcss" scoped>
.bottom {
  @apply mt-4 top-full left-0;
}

.right {
  @apply ml-4 top-4 left-14;
}
</style>