import { render } from "./BoardInviteMemberModal.vue?vue&type=template&id=98dd7542&scoped=true"
import script from "./BoardInviteMemberModal.vue?vue&type=script&lang=js"
export * from "./BoardInviteMemberModal.vue?vue&type=script&lang=js"

import "@vueform/multiselect/themes/default.css?vue&type=style&index=0&lang=css"
import "./BoardInviteMemberModal.vue?vue&type=style&index=1&id=98dd7542&scoped=true&lang=css"
import "./BoardInviteMemberModal.vue?vue&type=style&index=2&id=98dd7542&lang=postcss&scoped=true"
script.render = render
script.__scopeId = "data-v-98dd7542"

export default script