<template>
  <div class="flex flex-col w-screen max-mobile-height">
    <header-top />
      <div class="overflow-y-scroll wrap h-screen">
        <main class="col-span-5 pt-3 overflow-y-scroll">
          <Suspense>
            <template #default>
              <router-view />
            </template>
            <template #fallback>
              <div class="mb-8 title h-screen">
                <h1 class="font-mono text-2xl font-bold">
                  loading...
                </h1>
              </div>
            </template>
          </Suspense>
        </main>
      </div>
    <Footer />
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import { useRoute } from "vue-router"
import "vue-loading-overlay/dist/vue-loading.css";
import HeaderTop from "../organisms/HeaderTop.vue";
import Footer from "../organisms/Footer.vue";

export default {
  components: {
    HeaderTop,
    Footer
  },

  setup() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const route = useRoute();
    const path = computed(() => route.name);
    const state = reactive({
      loading: true
    })
    return { path, state };
  },
};
</script>

<style lang="postcss" scoped>
.menu-list {
  @apply px-2 py-2 mb-2 text-base text-gray-600 hover:bg-gray-100;
}
.active {
  @apply border-b-2 py-1 border-gray-400;
}
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.settings-child {
  @apply flex justify-between p-1
}
</style>