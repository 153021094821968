<template>
  <div class="flex flex-col w-screen h-screen">
    <header-top
      @get-text="getFirstPage"
    />
    <div class="wrapper">
      <the-sidebar @show-alart="showAlart" class="sidebar" />
      <main class="h-full px-8 pt-4 overflow-x-hidden overflow-y-scroll main">
        <div v-if="states.text">
          <search-result
            :files="state.hits"
            :fileType="state.fileType"
            :searchText="states.text"
            :nbHits="state.nbHits"
            @changeType="changeSearchType"
            @filterQuery="filterFiles"
            class="mb-5"
          />
          <infinite-loading
            v-if="showSearchLoading"
            spinner="bubbles"
            @infinite="algoliaInfiniteHandler"
          >
            <template #no-more>全{{ state.nbHits }}件</template>
            <template #no-results>全{{ state.nbHits }}件</template>
          </infinite-loading>
        </div>
      </main>
    </div>
  </div>
  <success-alart
    class="z-20"
    :show="alartState.showAlart && alartState.alartMode == 'success'"
    :title="alartState.alartMsg.success.title || 'ボードにファイルを追加しました！'"
    :body="alartState.alartMsg.success.body || '詳細はマイボードから確認してください。'"
  />
  <failure-alart
    class="z-20"
    :show="alartState.showAlart && alartState.alartMode == 'failure'"
    :title="alartState.alartMsg.failure.title || 'ファイルを追加できませんでした！'"
    :body="alartState.alartMsg.failure.body || '詳細はマイボードから確認してください。'"
  />
  <warning-alart
    class="z-20"
    :show="alartState.showAlart && alartState.alartMode == 'warning'"
    :title="alartState.alartMsg.warning.title || '注意してください！'"
    :body="alartState.alartMsg.warning.body || '非公開ボードに属しているユーザが閲覧できるようになります。'"
  />
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import firebase from "firebase/app";
import infiniteLoading from "vue-infinite-loading";
import TheSidebar from "../organisms/TheNewSidebar.vue";
import _ from "lodash";
import HeaderTop from "../organisms/TheHeaderTop.vue";
import SearchResult from "./SearchResultTemplate.vue";
import useSearchRepository from "../organisms/composables/useSearchRepository";
import useUserRepository from "../organisms/composables/useUserRepository";
import alart from "../organisms/composables/alart";
import SuccessAlart from "../organisms/alarts/SuccessAlart.vue";
import FailureAlart from "../organisms/alarts/FailureAlart.vue";
import WarningAlart from "../organisms/alarts/WarningAlart.vue";

export default {
  components: {
    HeaderTop,
    SearchResult,
    infiniteLoading,
    TheSidebar,
    SuccessAlart,
    FailureAlart,
    WarningAlart
  },
  setup () {
    const user = firebase.auth().currentUser;
    const states = reactive({
      text: "",
      changedType: "",
      logType: "",
    });
    const {
      state,
      showSearchLoading,
      payload,
      searchPackagesFromAlgolia,
      searchSlidesFromAlgolia,
      searchBoardsFromAlgolia,
      searchUsersFromAlgolia,
      getPackageInfo,
      getSlideInfo,
      algoliaInfiniteHandler,
      addBoardsToFile,
      saveSearchAuditLog
    } = useSearchRepository();
    const { createSearchApiKey } = useUserRepository();
    const store = useStore();
    const route = useRoute();
    const routerParams = route.params;

    const intervalTime = 260;
    const retryErrorStatus = [400, 403];
    const getFirstPage = _.debounce(async (text, type) => {
      // 検索結果関連パラメータ初期化
      state.hits = [];
      states.text = text;
      state.fileType = state.changedType ? state.changedType : type ?? "packages";
      state.pageCnt = 0;
      state.nbHits = "-";
      const userInfo = store.state.user.userInfo;
      const filesApiKey = userInfo.algolia?.files_api_key ?? "";
      const boardsApiKey = userInfo.algolia?.boards_api_key ?? "";
      const usersApiKey = userInfo.algolia?.users_api_key ?? "";
      if (!text) {
        state.changedType = "";
        return;
      }

      Object.assign(payload, { text, filesApiKey, boardsApiKey, usersApiKey });
      if (state.fileType == "packages") {
        states.logType = "SEARCH_PACKAGES";
        await searchPackages();
        if (state.hits.length != 0) {
          await getPackageInfo(state.hits)
            .then(async () => {
              await addBoardsToFile();
            });
        }
      }
      if (state.fileType == "slides") {
        states.logType = "SEARCH_SLIDES";
        await searchSlides();
        if (state.hits.length != 0) {
          await getSlideInfo(state.hits)
            .then(async () => {
              await addBoardsToFile();
            });
        }
      }
      if (state.fileType == "boards") {
        states.logType = "SEARCH_BOARDS";
        await searchBoards();
      }
      if (state.fileType == "users") {
        states.logType = "SEARCH_USERS";
        await searchUsers();
      }
      // auditlog
      await saveSearchAuditLog({
        actionType: states.logType,
        word: states.text,
        nbHits: state.nbHits
      })
    }, intervalTime);
    const putApiKeyAfterCreated = async () => {
      store.state.user.userInfo.algolia = {
        files_api_key: payload.filesApiKey,
        boards_api_key: payload.boardsApiKey,
        users_api_key: payload.usersApiKey
      };
    };
    const searchPackages = async () => {
      await searchPackagesFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.filesApiKey = data.files_api_key;
          payload.usersApiKey = data.users_api_key;
          await searchPackagesFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          await putApiKeyAfterCreated();
        });
    };
    const searchSlides = async () => {
      await searchSlidesFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.filesApiKey = data.files_api_key;
          await searchSlidesFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          await putApiKeyAfterCreated();
        });
    };
    const searchBoards = async () => {
      await searchBoardsFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.boardsApiKey = data.boards_api_key;
          await searchBoardsFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          await putApiKeyAfterCreated();
        });
    };
    const searchUsers = async () => {
      await searchUsersFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.usersApiKey = data.users_api_key;
          await searchUsersFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          await putApiKeyAfterCreated();
        });
    };

    if (routerParams) {
      getFirstPage(routerParams.word, routerParams.type);
    }

    const filterFiles = async (queries) => {
      state.hits = [];
      state.pageCnt = 0;
      state.nbHits = "-";
      state.filters = "";
      queries.forEach((query, index) => {
        let convertedKey;
        let convertedValue;
        if (Object.keys(query)[0] == "format") {
          convertedKey = `extension.${Object.values(query)[0]}`;
          convertedValue = true;
        }

        const key = convertedKey ?? Object.keys(query)[0];
        const value = convertedValue ?? Object.values(query)[0];
        if (state.fileType == "packages") {
          if (index == 0) state.filters = `${key}:'${value}'`;
          else state.filters += ` AND ${key}:'${value}'`;
        } else {
          if (index == 0) state.filters = `${key}:'${value}'`;
          else state.filters += ` AND ${key}:'${value}'`;
        }
      });

      if (state.fileType == "packages") {
        await searchPackagesFromAlgolia(payload)
          .then((result) => {
            state.hits = result;
          });
        if (state.hits.length != 0) {
          await getPackageInfo(state.hits)
            .then(async () => {
              await addBoardsToFile();
            });
        }
      } else if (state.fileType == "slides") {
        await searchSlidesFromAlgolia(payload)
          .then((result) => {
            state.hits = result;
          });
        if (state.hits.length != 0) {
          await getSlideInfo(state.hits)
            .then(async () => {
              await addBoardsToFile();
            });
        }
      }
    };

    const changeSearchType = async (text, type) => {
      state.changedType = type;
      await getFirstPage(text, state.changedType);
    };

    const { alartState, showAlart } = alart(); 

    return {
      states,
      state,
      showSearchLoading,
      getFirstPage,
      algoliaInfiniteHandler,
      changeSearchType,
      filterFiles,
      showAlart,
      alartState
    };
  }
}
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: "areaa areab";
  height: calc(100% - 116px);
}

.sidebar {
  grid-area: areaa;
}

.main {
  grid-area: areab;
}
</style>