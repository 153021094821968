<template>
  <div class="bg-indigo-50">
    <div
      class="container flex flex-col items-center justify-center h-screen mx-auto"
    >
      <div
        class="px-8 py-6 bg-white rounded-lg shadow-lg"
        style="width: 640px;"
      >
        <header class="my-4">
          <router-link
            to="/signin"
          >
            <img
              class="mx-auto"
              src="../../assets/spitch-logo.svg"
              width="200"
              alt="logo"
            />
          </router-link>
        </header>
        <div class="my-4">
          <div class="my-8">
            <h1 class="text-center text-gray-600">
              パスワードをお忘れですか？
            </h1>
            <img
              class="mx-auto my-4"
              src="../../assets/undraw_forgot_password_gi2d.svg"
              width="250"
              alt=""
            />
          </div>
          <div class="mb-8 description">
            <p class="mb-2 text-gray-700 break-words">
              登録済みのメールアドレスを入力してください。パスワードリセット用のリンクをメールで送信します。
            </p>
          </div>
          <div class="mb-8 button">
            <Form
              v-slot="{ meta }"
              class="w-full bg-white"
              @submit="sendPasswordResetEmail"
              :initial-values="initialValues"
              :validation-schema="schema"
            >
              <div class="mb-4">
                <label class="block text-gray-700" for="email"
                  >メールアドレス
                </label>
                <Field
                  class="w-full p-2 mb-2 text-gray-700 border rounded shadow"
                  id="email"
                  name="email"
                  placeholder="sample@company.com"
                />
                <ErrorMessage
                  class="text-red-600"
                  name="email"
                />
              </div>
              <div class="text-center">
                <button
                  :disabled="!meta.valid"
                  class="p-2 mt-1 text-white transition-colors duration-300 bg-indigo-500 border border-indigo-600 border-solid rounded itext-center md:p-4 hover:bg-indigo-700"
                >
                  リセットメールを送信
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <success-modal :showModal="state.showModal" @closeModal="closeModal" />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { reactive } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import yup from "../../locale/custom-ja";
import SuccessModal from "../organisms/modals/SuccessMailModal.vue";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    SuccessModal
  },

  setup() {
    const state = reactive({
      showModal: false
    });

    const schema = yup.object({
      email: yup
        .string()
        .required()
        .email()
        .label("メールアドレス")
    });

    const initialValues = reactive({
      email: ""
    });

    const sendPasswordResetEmail = values => {
      firebase
        .auth()
        .sendPasswordResetEmail(values.email)
        .then(() => {
          state.showModal = true;
        })
        .catch(error => {
          console.error(error.message);
        });
    };

    const closeModal = () => {
      state.showModal = false;
    };

    return {
      state,
      schema,
      initialValues,
      sendPasswordResetEmail,
      closeModal
    };
  }
};
</script>