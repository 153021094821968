import SearchRepository from "./searchRepository";
import FileRepository from "./fileRepository";
import BoardRepository from "./boardRepository";
import UserRepository from "./userRepository";

const repositories = {
  search: SearchRepository,
  file: FileRepository,
  board: BoardRepository,
  user: UserRepository
};

export const RepositoryFactory = {
  get: name => repositories[name]
};