<template>
  <div
    v-for="(file, index) in files"
    :key="file.pin_id"
    class="bg-white rounded shadow-md"
    :class="{ sort: sortable }"
    :draggable="sortable ? 'false' : 'true'"
    @dragstart="onDragStartFromBoard($event, file)"
    @dragend="onDragEnd"
  >
    <div
      @click.prevent="$emit('openPreviewModal', file)"
      class="cursor-pointer"
    >
      <div
        @mouseover="onMouseOver(index)"
        @mouseleave="onMouseLeave"
        class="relative overflow-hidden rounded-t bg-gray-50 preview"
      >
        <div
          v-bind:style="{
            backgroundImage:
              'slideInfo' in file
                ? 'url(' + state.baseUrl + file.slideInfo.id + '.jpg)'
                : 'url(' + state.baseUrl + file.packageInfo.headerId + '.jpg)'
          }"
          class="absolute inset-0 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9 filter blur-xs contrast-50"
        ></div>
        <div
          v-bind:style="{
            backgroundImage:
              'slideInfo' in file
                ? 'url(' + state.baseUrl + file.slideInfo.id + '.jpg)'
                : 'url(' + state.baseUrl + file.packageInfo.headerId + '.jpg)'
          }"
          class="bg-center bg-no-repeat bg-contain preview-thumbnail aspect-w-16 aspect-h-9"
        ></div>
        <button
          v-show="
            isNotBoardViwer && state.hoverFlag && index === state.hoverIndex
          "
          v-on:click.stop="$emit('togglePinModal', file)"
          class="absolute outline-none top-2 right-2 focus:outline-none"
        >
          <div class="p-2 bg-white border rounded-full hover:bg-gray-50">
            <round-edit class="w-5 h-5" />
          </div>
        </button>
        <div
          class="absolute outline-none top-3 left-3 filter drop-shadow-xl focus:outline-none"
          v-show="file.packageInfo.permission === 'limited'"
        >
          <lock-icon class="w-5 h-5 text-yellow-500 stroke-current stroke-2" />
        </div>
      </div>
    </div>

    <div class="p-2 text-gray-600 meta">
      <div class="mb-1 meta-top">
        <p class="text-sm truncate">
          {{
            "slideInfo" in file
              ? file.slideInfo.name
              : file.packageInfo.fileTitle
          }}
        </p>
      </div>
      <div
        class="flex items-center justify-between mb-1.5 text-gray-400 meta-buttom"
      >
        <router-link
          class="flex items-center meta-user"
          :to="{
            name: 'user',
            path: `/users/${file.packageInfo?.user?.username}/profile`,
            params: { username: file.packageInfo?.user?.username, workspace: file.packageInfo?.user?.workSpace, id: file.packageInfo?.user?.id }
          }"
        >
          <img
            v-if="file.packageInfo.user.photoURL"
            :src="file.packageInfo.user.photoURL"
            class="object-cover w-5 h-5 border rounded-full"
          />
          <div v-else class="object-cover w-5 h-5 border rounded-full"></div>

          <p class="ml-2 text-xs truncate">
            {{
              file.packageInfo.user.displayName
                ? file.packageInfo.user.displayName
                : `${file.packageInfo.user.lastName} ${file.packageInfo.user.firstName}`
            }}
          </p>
        </router-link>
        <div class="flex items-center">
          <page-number-icon class="w-4 h-4" />
          <span class="ml-1 text-xs">
            {{ file.packageInfo.children_ids?.length }}
          </span>
          <eye-icon class="w-4 h-4 ml-2" />
          <span class="ml-1 text-xs">
            {{ file.packageInfo.pageview }}
          </span>
        </div>
      </div>
      <div class="flex justify-between overflow-x-auto">
        <div>
          <div
            v-for="board in file.boards"
            :key="board.id"
            class="inline-block mr-1 text-xs text-blue-400 truncate transition hover:text-blue-600 hover:underline"
            style="max-width: 170px"
          >
            <router-link
              v-if="board.visibility === 'public'"
              :to="{ path: `/boards/${board.id}` }"
            >
              #{{ board.name }}
            </router-link>
            <router-link
              v-if="
                board.visibility === 'limited' &&
                  [...board.owners, ...board.members].includes(
                    state.user.uid
                  )
              "
              :to="{ path: `/boards/${board.id}` }"
            >
              🔒{{ board.name }}
            </router-link>
          </div>
        </div>
        <button
          v-if="state.permission == 'owner' || file.packageInfo.created_by == user.uid"
          @click.stop="$emit('togglePinModal', file)"
          class="flex items-center justify-center transition hover:bg-red-200"
          type="button"
        >
          <trash-icon
            class="w-5 h-5 text-red-500"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { reactive } from "vue";
import RoundEdit from "../../icons/RoundEdit.vue";
import EyeIcon from "../../icons/EyeIcon.vue";
import LockIcon from "../../icons/LockIcon.vue";
import PageNumberIcon from "../../icons/PageNumberIcon.vue";
import utility from "../../organisms/composables/utility";
import TrashIcon from "../../icons/TrashIcon.vue";

export default {
  components: {
    RoundEdit,
    EyeIcon,
    PageNumberIcon,
    LockIcon,
    TrashIcon
  },

  emits: ["openPreviewModal", "togglePinModal"],

  props: {
    permission: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    isNotBoardViwer: {
      type: Boolean,
      required: true
    },
    sortable: {
      type: Boolean,
      required: false,
      defalut: false
    }
  },

  async setup(props) {
    const user = firebase.auth().currentUser;
    const state = reactive({
      baseUrl: location.origin + "/img/",
      hoverFlag: false,
      hoverIndex: null,
      user: user,
      permission: props.permission
    });

    const onMouseOver = index => {
      state.hoverFlag = true;
      state.hoverIndex = index;
    };

    const onMouseLeave = () => {
      state.hoverFlag = false;
    };

    const { formatTime, onDragStart, onDragEnd } = utility();

    const onDragStartFromBoard = (evt, file) => {
      const params = {
        fileType: file.pin_type,
        fileId: file.pin_id,
        permission: file.packageInfo.permission,
        createdBy: file.packageInfo.created_by,
        headerId: "slideInfo" in file ? file.pin_id : file.packageInfo.headerId,
        sortable: props.sortable
      };
      onDragStart(evt, params);
    };

    return {
      state,
      user,
      onMouseOver,
      onMouseLeave,
      formatTime,
      onDragStartFromBoard,
      onDragEnd
    };
  }
};
</script>

<style lang="postcss" scoped>
.sort {
  @apply border border-gray-300 border-dashed;
}
</style>