<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--fluent"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
  >
    <g fill="none">
      <path
        d="M11.414 3.636a.5.5 0 0 0 0-.707L9.293.808a.5.5 0 0 0-.707.707l.997.997a7.5 7.5 0 0 0-4.075 13.495a.5.5 0 0 0 .6-.8A6.5 6.5 0 0 1 10.066 3.5c.024 0 .05-.002.073-.005L8.586 5.05a.5.5 0 0 0 .707.707l2.121-2.121zM8.586 16.364a.5.5 0 0 0 0 .707l2.121 2.121a.5.5 0 0 0 .707-.707l-.997-.997a7.5 7.5 0 0 0 4.075-13.495a.5.5 0 1 0-.6.8A6.5 6.5 0 0 1 9.934 16.5a.516.516 0 0 0-.073.005l1.554-1.554a.5.5 0 1 0-.707-.707l-2.121 2.12z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
</template>