<template>
  <main>
    <div
      @click.self="toggleBoardModal"
      class="fixed inset-0 z-50 flex items-center justify-center py-6"
    >
      <div
        class="container overflow-y-auto bg-white rounded"
        style="width: 90%; height: 85%;"
      >
        <div class="relative mb-20">
          <div
            :style="{
              backgroundImage:
                'url(' + require('@/assets/landscape4enhance.jpeg') + ')',
            }"
            class="flex items-center justify-center w-full h-48 bg-center bg-no-repeat bg-cover preview"
          >
            <div class="p-2 text-white bg-gray-800 rounded bg-opacity-60">
              <p class="font-semibold">工事中...</p>
              <p>ボードにお好きな絵文字や画像を設定することができます。</p>
            </div>
          </div>
          <div class="absolute w-20 h-20 rounded-full bg-gray-50 top-40 left-8">
            <div class="flex items-center justify-center w-full h-full">
              <span class="text-5xl">🙏</span>
            </div>
          </div>
        </div>
        <div class="relative mx-10 vld-parent">
          <Form
            :validation-schema="schema"
            @submit="updateBoard"
            :initial-values="formValues"
          >
            <div class="absolute right-0 -top-10">
              <button
                @click="changeOperationModalFlag('delete')"
                class="flex items-center justify-center w-10 h-10 transition border border-red-400 rounded-full hover:bg-red-200"
                type="button"
              >
                <trash-icon
                  class="w-6 h-6 text-red-400 stroke-current stroke-2"
                />
              </button>
            </div>

            <div class="field">
              <label class="field-label" for="boardName">ボード名</label>
              <Field
                class="field-item"
                name="boardName"
                id="boardName"
                placeholder="プロジェクト計画集"
              />
              <ErrorMessage name="boardName" class="field-error" />
              <p class="text-xs text-gray-400">
                参考になるスライド、部内共有、プロジェクト名...など、わかりやすい名前で作成するのがおすすめです。
              </p>
            </div>
            <div class="field">
              <label class="field-label" for="description"
                >説明(オプション)</label
              >
              <Field
                class="field-item"
                name="description"
                id="description"
                rows="2"
                placeholder="このボードは参考にすべき資料を配置しています。"
                as="textarea"
              />
            </div>
            <div class="field">
              <p class="field-label">ボード公開</p>
              <div>
                <Field
                  id="create-board-permission-public"
                  class="w-4 h-4"
                  value="public"
                  name="visibility"
                  type="radio"
                ></Field>
                <label
                  for="create-board-permission-public"
                  class="mb-1 ml-1 text-base text-gray-500 cursor-pointer"
                >
                  公開
                </label>
              </div>
              <div>
                <Field
                  id="create-board-permission-limited"
                  class="w-4 h-4"
                  value="limited"
                  name="visibility"
                  type="radio"
                ></Field>
                <label
                  for="create-board-permission-limited"
                  class="mb-1 ml-1 text-base text-gray-500 cursor-pointer"
                >
                  非公開
                </label>
              </div>
            </div>
            <div class="mb-8">
              <p class="field-label">
                ボードメンバー {{ boardInfo.participants?.length }}人
              </p>
              <div class="flex items-center justify-between">
                <div class="flex overflow-x-scroll w-96">
                  <div
                    v-for="member in boardInfo.participants"
                    :key="member.id"
                    class="flex items-center justify-center w-10 h-10 border rounded-full"
                    style="min-width: 40px; min-height: 40px"
                  >
                    <img
                      v-if="member.photoURL"
                      class="object-cover w-full h-full rounded-full"
                      :src="member.photoURL"
                      alt=""
                    />
                    <div
                      v-else
                      class="flex items-center justify-center w-full h-full text-lg rounded-full bg-gray-50"
                    >
                      <span>{{ member.firstName.slice(0, 1) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full my-4 border"></div>
            <div class="flex justify-end field">
              <button
                @click="$emit('close-modal')"
                class="ml-2 bg-gray-100 button hover:bg-indigo-700"
              >
                キャンセル
              </button>
              <button
                class="ml-2 text-white bg-indigo-600 button hover:bg-indigo-700"
              >
                ボードを更新する
              </button>
            </div>
          </Form>
          <loading
            v-model:active="state.loading"
            :is-full-page="false"
            color="#818589"
          />
        </div>
      </div>
    </div>
    <div class="fixed inset-0 z-40 bg-black opacity-70"></div>
  </main>
</template>

<script>
import { reactive } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import yup from "../../../locale/custom-ja";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
import TrashIcon from "../../icons/TrashIcon.vue";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
    TrashIcon
  },

  props: {
    toggleBoardModal: {
      type: Function,
      required: true,
    },
    changeOperationModalFlag: {
      type: Function,
      required: true,
    },
    boardInfo: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      loading: false,
    });

    const schema = yup.object({
      boardName: yup.string().required().label("ボード名"),
      description: yup.string().label("説明"),
      visibility: yup.string().label("権限"),
    });

    const formValues = reactive({
      boardName: props.boardInfo.name,
      description: props.boardInfo.description,
      visibility: props.boardInfo.visibility,
      emoji: props.boardInfo.emoji ? props.boardInfo.emoji : "",
      coverURL: props.boardInfo.coverURL ? props.boardInfo.coverURL : ""
    });

    const updateBoard = async (values) => {
      state.loading = true;
      const payload = {
        name: values.boardName,
        description: values.description,
        visibility: values.visibility,
        emoji: values.emoji,
        coverURL: values.coverURL,
        target: props.boardInfo.id,
      };
      const result = await BoardRepository.update(payload).json();

      emit("updateBoard");
      state.loading = false;

      if (result.status === "200") {
        await new Promise(resolve => setTimeout(resolve, 100));
        props.toggleBoardModal();
      }
    };

    return {
      state,
      schema,
      formValues,
      updateBoard,
    };
  },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply border rounded px-1.5 py-1.5 focus:outline-none ease-linear transition-all duration-150;
}

.field {
  @apply mb-6;
}

.field-label {
  @apply block mb-1 text-base text-gray-500;
}

.field-item {
  @apply w-full p-2 text-gray-700 border rounded;
}

.field-error {
  @apply text-red-500 text-sm;
}
</style>