<template>
  <div class="mb-6">
    <h1 class="font-mono text-4xl font-bold">メンバー設定<span class="text-gray-400"></span></h1>
  </div>
  <div v-if="state.authUser.role != 'admin'">
    表示できる権限がありません。ワークスペースの管理者の方にお問い合せください。
  </div>
  <div v-else-if="state.authUser.role == 'admin'">
    <div class="grid grid-cols-3 h-1/2">
      <div class="mr-4 col-span-1">
        <h2 class="font-bold text-gray-700">メンバー</h2>
        <div class="mt-4">
          招待したいメンバーのメールアドレスと権限を設定して、招待URLを送ります。
        </div>
        <div class="mt-4">
          <button
            @click="inviteUsers"
            class="p-1.5 text-white bg-blue-600 rounded hover:bg-blue-800"
          >
            ユーザーを招待する
          </button>
        </div>
      </div>
      <div class="col-span-2 mr-16">
        <div class="flex flex-row justify-between">
          <h2 class="font-bold text-gray-700 mb-2">
            ユーザーリスト
          </h2>
          <div class="flex items-center justify-center h-7 mt-1 mb-2 border border-gray-200 rounded-lg focus:outline-none">
            <search-icon
              class="w-6 h-6 ml-2 text-gray-400 transition fill-current rounded-l-md "
            />
            <input
              class="w-full h-full pl-2 text-sm rounded-r-md focus:outline-none"
              type="search"
              placeholder="ユーザー検索"
              @input="searchUser"
            />
          </div>
        </div>
        <div class="flex flex-row justify-end mb-2">
          <input
            type="checkbox"
            id="deleted"
            class="mr-1 text-gray-500"
            @change="filterDeletedUser"
            v-model="state.showOnlyUndeletedUser"
          >
          <label
            for="deleted"
            class="text-sm text-gray-500"
          >
            削除済みユーザーを非表示
          </label>
        </div>
        <div
          v-if="state.allUsers.length != 0"
          style="height: 54vh;"
          class="border rounded-xl overflow-y-scroll bg-gray-50"
        >
          <div
            v-for="user in state.allUsers"
            :key="user"
          >
            <div
              class="grid grid-cols-12 border-b p-3"
            >
              <div class="col-span-1 ml-1">
                <img
                  v-if="user.photoURL"
                  :src="user.photoURL"
                  alt=""
                  class="object-cover w-11 h-11 border rounded-full bg-white"
                />
                <div
                  v-else
                  class="flex items-center justify-center object-cover w-11 h-11 border rounded-full bg-white"
                >
                  <span class="text-xl">
                    {{ user?.firstName?.slice(0, 1) }}
                  </span>
                </div>
              </div>
              <div class="col-span-6">
                <p>{{ user.lastName + " " + user.firstName }}</p>
                <p class="text-sm text-gray-600">{{ "@" + user.username }}</p>
              </div>
              <div class="col-span-4 flex items-center justify-center">
                {{ user.role == "admin" ? "管理者" : "メンバー" }}
              </div>
              <div
                v-if="state.authUser.id != user.id && !user.is_deleted"
                class="col-span-1 flex justify-center"
              >
                <button
                  class="rounded"
                  @click="deleteUser(user)"
                >
                  <delete-icon class="text-2xl text-red-500 hover:text-red-700" />
                </button>
              </div>
              <div
                v-else-if="user.is_deleted"
                class="col-span-1 flex items-center justify-center"
              >
                削除済み
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="h-2/6 mt-2 border-t">
      <div class="mr-4 mt-2 col-span-1">
        <h2 class="font-bold text-gray-700">招待中(工事中)</h2>
        <div class="mt-4">
          現在招待中のユーザーを表示します。
        </div>
      </div>
    </div> -->
  </div>
  <invite-users-modal
    v-if="state.showInviteModal"
    :showModal="state.showInviteModal ? true : false"
    :closeModal="closeInviteModal"
  />
  <user-delete-modal
    v-if="state.showDeleteModal"
    :userId="state.deleteTargetUser.id"
    :userFullName="state.deleteTargetUser.fullName"
    :showModal="state.showDeleteModal ? true : false"
    :closeModal="closeDeleteModal"
    @succeededDeleteUser="afterDeletedUser"
  />
</template>

<script>
import { onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import useUserRepository from "../../organisms/composables/useUserRepository";
import InviteUsersModal from "../modals/InviteUsersModal.vue";
import UserDeleteModal from "../modals/UserDeleteModal.vue";
import DeleteIcon from "../../icons/DeleteIcon.vue";
import SearchIcon from "../../icons/SearchIcon.vue";

export default {
  components: {
    InviteUsersModal,
    UserDeleteModal,
    DeleteIcon,
    SearchIcon,
  },
  emits: [
    "closeInviteModal"
  ],
  setup() {
    const store = useStore();
    const state = reactive({
      allUsers: [],
      filteredUsers: [],
      deleteTargetUser: {
        id: "",
        fullName: ""
      },
      searchText: "",
      showOnlyUndeletedUser: false,
      showInviteModal: false,
      showDeleteModal: false,
      isFilteredUser: false,
      authUser: store.state.user.userInfo
    });

    const { getUsers } = useUserRepository();
    const getAllUsers = async () => {
      const userInfo = store.state.user.userInfo;
      const users = await getUsers({ workSpace: userInfo.workSpace });
      state.allUsers = users;
      state.filteredUsers = users;
    };
    onBeforeMount(() => {
      getAllUsers();
    });

    const searchUser = (e) => {
      // TODO: スペースでsplitしての複数単語検索
      state.isFilteredUser = true;
      state.searchText = e.target.value;
      state.allUsers = state.filteredUsers.filter(user => {
        return user.username.toLowerCase().includes(state.searchText)
          || user.displayName.toLowerCase().includes(state.searchText)
          || user.firstName.toLowerCase().includes(state.searchText)
          || user.lastName.toLowerCase().includes(state.searchText);
      });
      if (state.showOnlyUndeletedUser)
        return state.allUsers = state.allUsers.filter(user => !user.is_deleted);
    };
    const filterDeletedUser = () => {
      if (!state.isFilteredUser || !state.searchText) {
        if (state.showOnlyUndeletedUser)
          return state.allUsers = state.filteredUsers.filter(user => !user.is_deleted);
        return state.allUsers = state.filteredUsers;
      } else {
        if (state.showOnlyUndeletedUser) {
          return state.allUsers = state.allUsers.filter(user => !user.is_deleted);
        } else {
          const deletedUsers = state.filteredUsers.filter(user => user.is_deleted);
          const target = deletedUsers.filter(user => {
            return user.username.toLowerCase().includes(state.searchText)
              || user.displayName.toLowerCase().includes(state.searchText)
              || user.firstName.toLowerCase().includes(state.searchText)
              || user.lastName.toLowerCase().includes(state.searchText);
          });
          return target.map(d => state.allUsers.push(d));
        }
      }
    };
    const inviteUsers = async () => {
      state.showInviteModal = true;
    };
    const deleteUser = async (target) => {
      state.deleteTargetUser.id = target.id;
      state.deleteTargetUser.fullName = target.lastName + " " + target.firstName;
      state.showDeleteModal = true;
    };
    const afterDeletedUser = async () => {
      state.allUsers.map((user) => {
        if (user.id == state.deleteTargetUser.id)
          user.is_deleted = true;
      });
      state.showDeleteModal = false;
    };

    const closeInviteModal = () => {
      state.showInviteModal = false;
    };
    const closeDeleteModal = () => {
      state.showDeleteModal = false;
    };

    return {
      state,
      searchUser,
      filterDeletedUser,
      inviteUsers,
      deleteUser,
      afterDeletedUser,
      closeInviteModal,
      closeDeleteModal
    };
  }
}
</script>