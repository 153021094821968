<template>
  <main>
    <div
      @click.self="$emit('closeModal')"
      class="fixed inset-0 z-50 flex items-center justify-center py-2"
    >
      <div
        class="container h-full overflow-y-auto bg-white rounded-lg"
        style="height: 300px; width: 300px;"
      >
        <div class="relative mb-20">
          <div
            :style="{
              backgroundImage:
                'url(' + require('@/assets/landscape4enhance.jpeg') + ')'
            }"
            class="flex items-center justify-center w-full h-48 bg-center bg-no-repeat bg-cover preview"
          >
            <div class="p-2 text-white bg-gray-800 rounded bg-opacity-60">
              <p class="font-semibold">工事中...</p>
              <p>お好きなボードイメージを設定することができます。</p>
            </div>
          </div>
          <div
            class="absolute w-20 h-20 rounded-full bg-gray-50 top-40 left-8"
          >
            <div class="flex items-center justify-center w-full h-full">
              <span class="text-4xl">🙏</span>
            </div>
          </div>
        </div>
        <div class="mx-4 vld-parent">
          <Form :validation-schema="schema" @submit="createBoard" :initial-values="formValues">
            <div class="field">
              <label class="field-label" for="boardName">ボード名</label>
              <Field
                class="field-item"
                name="boardName"
                id="boardName"
                placeholder="プロジェクト計画集"
              />
              <ErrorMessage name="boardName" class="field-error" />
            </div>
            <div class="field">
              <label class="field-label" for="description"
                >説明(オプション)</label
              >
              <Field
                class="field-item"
                name="description"
                id="description"
                rows="2"
                as="textarea"
              />
            </div>

            <div class="field">
              <p class="field-label">ボード公開</p>
              <div>
                <Field
                  id="create-board-permission-public"
                  class="w-4 h-4"
                  value="public"
                  name="visibility"
                  type="radio"
                  checked
                ></Field>
                <label
                  for="create-board-permission-public"
                  class="mb-1 ml-1 text-base text-gray-500 cursor-pointer"
                >
                  パブリック
                </label>
              </div>
              <div>
                <Field
                  id="create-board-permission-limited"
                  class="w-4 h-4"
                  value="limited"
                  name="visibility"
                  type="radio"
                ></Field>
                <label
                  for="create-board-permission-limited"
                  class="mb-1 ml-1 text-base text-gray-500 cursor-pointer"
                >
                  プライベート
                </label>
                <p class="my-1 font-bold text-xs text-gray-600">
                  ※プライベートボードはあとから公開に変更することができません。
                </p>
              </div>
            </div>
            <div class="field">
              <p class="field-label">ボードメンバー</p>
              <div class="flex items-center justify-center border rounded bg-gray-50">
                <Multiselect
                  class="border-0 border-r border-gray-300 multiselect-gray"
                  v-model="state.userIds"
                  mode="tags"
                  :searchable="true"
                  :options="options"
                  :closeOnSelect="false"
                />
                <select v-model="state.permission" class="px-1 mr-2 text-gray-500 bg-gray-50 focus:outline-none">
                  <option value="member">メンバー</option>
                  <option value="owner">管理者</option>
                </select>
              </div>
            </div>
            <div class="w-full my-4 border"></div>
            <div class="flex justify-between field">
              <button
                @click="$emit('closeModal')"
                class="text-black transition border rounded button hover:bg-gray-300"
              >
                キャンセル
              </button>
              <button
                class="ml-4 text-white transition bg-gray-700 rounded button hover:bg-gray-500"
              >
                ボードを作成する
              </button>
            </div>
          </Form>

          <loading
            v-model:active="state.loading"
            color="#818589"
            :is-full-page="false"
          />
        </div>
      </div>
    </div>
    <div class="fixed inset-0 z-40 bg-black opacity-70"></div>
  </main>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
import { Field, Form, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja";
import Multiselect from "@vueform/multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect,
    Loading
  },

  setup(_, { emit }) {
    const store = useStore();
    const userInfo = store.state.user.userInfo;

    const schema = yup.object({
      boardName: yup
        .string()
        .required()
        .label("ボード名"),
      description: yup.string().label("説明"),
      visibility: yup.string().label("権限")
    });

    const formValues = {
      boardName: "",
      description: "",
      visibility: "public",
      emoji: "",
      coverURL: ""
    };

    const state = reactive({ 
      loading: false,
      permission: 'member',
      userIds: []
    });

    const options = computed(() => {
      return store.state.user.users.filter(user => user.id != userInfo.id).map(user => {
        return { value: user.id, label: `${user.lastName} ${user.firstName}` };
      })
    })

    const createBoard = async values => {
      state.loading = true;
      const description = values.description ? values.description : ""
      const users = state.userIds.map(userId => {
        return { userId, permission: state.permission }
      });
      const payload = {
        boards: [
          {
            name: values.boardName,
            description: description,
            visibility: values.visibility,
            emoji: values.emoji,
            coverURL: values.coverURL,
            users: users
          }
        ],
        url_domain: location.origin
      };
      const result = await BoardRepository.create(payload).json();

      emit("updateBoard");
      state.loading = false;

      if (result.status === "200") {
        await new Promise(resolve => setTimeout(resolve, 100));
        emit("closeModal");
      }
    };

    return {
      createBoard,
      schema,
      formValues,
      state,
      options
    };
  }
};
</script>

<style lang="postcss" scoped>
.button {
  @apply text-sm border rounded px-3 py-1.5 focus:outline-none;
}

.field {
  @apply mb-6;
}

.field-label {
  @apply block mb-1 text-base text-gray-500;
}

.field-item {
  @apply w-full p-2 text-gray-700 border rounded;
}

.field-error {
  @apply text-red-500 text-sm;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-gray {
  --ms-tag-bg: #cecdca80;
  --ms-tag-color: #37352f;
  --ms-tag-font-weight: 400;
  --ms-ring-color: #ffffff00;
  --ms-radius: 0px;
}
</style>