import { render } from "./BoardCreateModal.vue?vue&type=template&id=6660cf62&scoped=true"
import script from "./BoardCreateModal.vue?vue&type=script&lang=js"
export * from "./BoardCreateModal.vue?vue&type=script&lang=js"

import "./BoardCreateModal.vue?vue&type=style&index=0&id=6660cf62&lang=postcss&scoped=true"
import "@vueform/multiselect/themes/default.css?vue&type=style&index=1&lang=css"
import "./BoardCreateModal.vue?vue&type=style&index=2&id=6660cf62&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-6660cf62"

export default script