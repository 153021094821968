<template>
  <transition name="sidebar">
    <aside v-if="showSidebar" class="z-50 flex flex-col overflow-y-scroll text-gray-600 w-60">
      <div class="py-4">
        <div class="px-4 mb-4 text-lg font-bold">
          <p>マイボード一覧</p>
        </div>

        <div class="mb-4">
          <p class="px-4 mb-2 text-sm font-bold text-gray-500 underline">Private</p>
          <ul>
            <li
              v-for="(board, index) in privateBoards" :key="board.id"
              class="px-4 py-1 text-sm leading-6 text-gray-600 truncate"
              :class="{enter: state.hoverPrivateIndex == index}"
              @drop="onDrop($event, board)"
              @dragenter.prevent="onPrivateDragEnter(index)"
              @dragleave.prevent="onDragLeave"
              @dragover.prevent
            >
              <span class="pr-2 text-base">{{ board.emoji ? board.emoji : '🐟' }}</span>{{ board.name }}
            </li>
          </ul>
        </div>

        <div>
          <p class="px-4 mb-2 text-sm font-bold text-gray-500 underline">Public</p>
          <ul>
            <li
              v-for="(board, index) in publicBoards" :key="board.id"
              class="px-4 py-1 text-sm leading-6 text-gray-600 truncate"
              :class="{enter: state.hoverSharedIndex == index}"
              @drop="onDrop($event, board)"
              @dragenter.prevent="onSharedDragEnter(index)"
              @dragleave.prevent="onDragLeave"
              @dragover.prevent
            >
              <span class="pr-2 text-base">{{ board.emoji ? board.emoji : '🐟' }}</span>{{ board.name }}
            </li>
          </ul>
        </div>
      </div>
    </aside>
  </transition>
</template>

<script>
import firebase from "firebase/app";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import useBoardRepository from "./composables/useBoardRepository"

export default {
  props: {
    showSidebar: Boolean,
  },

  setup(_, { emit }) {
    const user = firebase.auth().currentUser;
    const store = useStore();

    const state = reactive({
      showWarningModal: false,
      hoverSharedIndex: null,
      hoverPrivateIndex: null
    })

    const privateBoards = computed(() => store.getters['board/myPrivateBoards']);
    const publicBoards = computed(() => store.getters['board/myPublicBoards']);
    const userInfo = store.state.user.userInfo;

    const { getMyBoard } = useBoardRepository();

    const updateBoard = async () => {
      return await getMyBoard({ workSpace: userInfo.workSpace, userId: userInfo.id });
    }

    const onDrop = async (evt, board) => {
      evt.dataTransfer.dropEffect = "copy";
      evt.dataTransfer.effectAllowed = "copy";

      const fileType = evt.dataTransfer.getData("fileType");
      const fileId = evt.dataTransfer.getData("fileId");
      const permission = evt.dataTransfer.getData("permission");
      const createdBy = evt.dataTransfer.getData("createdBy");

      /**
       * アラート表示
       * 
       * ■ ファイルが重複している場合
       * - ファイルやボードの権限に関わらず失敗アラート
       * 
       * ■ 非公開ファイルの場合
       * - 作成者が自分以外の非公開ファイルを公開/非公開ボードに追加すると失敗アラート
       * - 非公開ファイルを公開ボードに追加すると失敗アラート
       * - 作成者が自分の非公開ファイルを非公開ボードに追加すると警告アラート
       * 
       * ■ 公開ファイルの場合
       * - ボードの権限に関わらず成功アラート
       */
      if (board.pins.some(pin => pin.pin_id === fileId && pin.pin_type === fileType)) {
        emit('showAlart', 'failure', { title: '', body: 'すでにこのファイルはボードに保存されています。' });
      } else if (permission === 'limited') {
        if (createdBy !== user.uid) {
          emit('showAlart', 'failure', { title: '', body: '別ユーザが作成した非公開ファイルはボードに追加できません。' });
        } else if (board.visibility === 'public') {
          emit('showAlart', 'failure', { title: '', body: '非公開ファイルは公開ボードに追加できません。' });
        } else if (board.visibility === 'limited') {
          const result = await useBoardRepository(board).addPin(fileId, fileType);
          if (result.status == 200) {
            updateBoard();
            emit('showAlart', 'warning', { title: '', body: '非公開ボードに属しているユーザが閲覧できるようになります。' });
          }
        }
      } else {
        const result = await useBoardRepository(board).addPin(fileId, fileType);
        if (result.status == 200) {
          updateBoard();
          emit("showAlart", 'success');
        }
      }

      onDragLeave();
    };

    let counter = 0;
    const onSharedDragEnter = (index) => {
      counter++
      state.hoverSharedIndex = index;
    }

    const onPrivateDragEnter = (index) => {
      counter++
      state.hoverPrivateIndex = index;
    }

    const onDragLeave = () => {
      counter--
      if (counter == 0) {
        state.hoverPrivateIndex = null;
        state.hoverSharedIndex = null;
      }
    }

    return {
      state,
      privateBoards,
      publicBoards,
      onDrop,
      onSharedDragEnter,
      onPrivateDragEnter,
      onDragLeave
    };
  }
};
</script>

<style scoped>
.sidebar-enter-from,
.sidebar-leave-to {
  transform: translate(-100%);
}
.sidebar-enter-active,
.sidebar-leave-active {
  transition: transform 0.1s ease;
}
.sidebar-enter-to,
.sidebar-leave {
  transform: translate(0);
}
</style>

<style lang="postcss" scoped>
.enter {
  @apply bg-gray-100;
}
</style>