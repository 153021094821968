<template>
  <div v-if="'ownersInfo' in boardInfo" class="flex items-center justify-center h-full mx-auto" >
    <div class="text-gray-600">
      <p class="text-xl font-bold text-center">あなたが参加していない非公開ボードです。<br>ボードに招待してもらうには以下のユーザに連絡してください。</p>
      <hr class="my-4">
      <div class="overflow-y-auto max-h-60">
        <p class="mb-4 text-sm text-gray-400">⚠リクエスト機能は未実装のため、別の手段で連絡してください。orz</p>
        <ul type="disc" class="list-disc list-inside">
          <li v-for="user in boardInfo.ownersInfo" :key="user.id" class="flex items-center justify-between">
            <p class="text-lg list-item">{{ `${user.lastName} ${user.firstName}さん (${user.email})` }}</p>
            <button @click="null" class="px-2 border border-gray-600 rounded cursor-not-allowed hover:text-white hover:bg-gray-600">リクエスト</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex justify-between mb-2">
      <div class="flex items-center justify-center">
        <h1 class="font-mono text-xl font-bold text-gray-600">
          {{ boardInfo.name }}
        </h1>

        <!-- ボードメンバーの招待/閲覧の切り替え -->
        <div 
          @click="isBoardEditable ? toggleBoardModal('invite') : toggleBoardModal('read-member')" 
          class="flex items-center justify-center p-0.5 ml-4 border border-gray-300 rounded hover:bg-gray-200 hover:cursor-pointer"
        >
          <div
            v-for="(member, index) in boardInfo.participants.slice(0, 3)" :key="member.id"
            class="border-r-2 border-gray-100 rounded w-7 h-7"
            :class="{'-ml-0.5': index > 0, 'z-30': index === 0, 'z-20': index === 1, 'z-10': index === 2}"
          >
            <img class="object-cover w-full h-full rounded" v-if="member.photoURL" :src="member.photoURL" />
            <div v-else class="flex items-center justify-center w-full h-full text-lg rounded bg-gray-50">
              <span>{{ member.firstName.slice(0, 1) }}</span>
            </div>
          </div>
          <p class="mx-2">{{ boardInfo.participants.length }}</p>
        </div>

        <div class="flex items-center justify-center ml-4">
          <p v-if="boardInfo.visibility !== 'public'" class="mr-2 text-xl">🔒</p>
          <button v-if="isBoardEditable" @click.stop="toggleBoardModal('edit')" class="focus:outline-none">
            <div class="p-1.5 bg-white border rounded-full hover:bg-gray-50">
              <round-edit class="w-5 h-5" />
            </div>
          </button>
          <button v-else @click.stop="toggleBoardModal('read')" class="focus:outline-none">
            <div class="p-1.5 bg-white border rounded-full hover:bg-gray-50">
              <info-icon class="w-5 h-5" />
            </div>
          </button>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <div class="flex items-center justify-center">
          <label 
            for="toogle"
            class="flex items-center cursor-pointer"
          >
            <div class="relative">
              <input v-model="state.toggle" id="toogle" type="checkbox" class="sr-only peer" />
              <div class="absolute w-6 h-6 transition bg-white rounded-full shadow dot -left-1 -top-1 peer-checked:bg-indigo-500 peer-checked:translate-x-full"></div>
              <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
            </div>
            <div class="ml-3 text-base text-gray-600">
              並び替え
            </div>
          </label>
        </div>
        <p class="text-2xl">📍 {{ boardInfo.allPinsCount }}</p>
      </div>
    </div>
    <div class="flex justify-between mb-4">
      <h1 class="font-mono text-base text-gray-400">
        {{ boardInfo.description ? boardInfo.description : "..." }}
      </h1>
      <div class="flex items-center justify-center">
        <div
          class="flex items-center justify-center h-8 mr-2 bg-white border border-gray-200 rounded-lg focus:outline-none"
        >
          <search-icon
            class="w-6 h-6 ml-2 text-gray-400 transition fill-current rounded-l-md"
          />
          <input
            class="w-56 h-full pl-2 text-sm bg-white rounded-r-md focus:outline-none"
            type="search"
            placeholder="ボードの絞り込み..."
            v-model="state.filterKeyword"
          />
        </div>
        <button
          v-if="!(boardInfo.name == 'お気に入り' && boardInfo.emoji == '👍')"
          v-on="isMemberOfBoard ? { click: leaveBoard } : { click: joinBoard }"
          class="h-full px-2 text-sm text-gray-700 transition border border-gray-700 rounded shadow hover:bg-gray-700 hover:text-white focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          :disabled="!isCreatedUserOfBoard && !isMemberOfBoard && boardInfo.visibility === 'limited'"
        >
          {{ isMemberOfBoard ? 'ボードから退出する' : 'ボードに参加する' }}
        </button>
      </div>
    </div>

    <draggable
      v-if="state.toggle"
      v-model="files"
      class="grid gap-6 row lg:grid-cols-4 md:grid-cols-3"
      animation="300"
      @change="onChange"
    >
      <board-detail-main-contents
        :files="files"
        :is-not-board-viwer="isNotBoardViwer"
        :sortable="true"
        @open-preview-modal="openPreviewModal"
        @toggle-pin-modal="togglePinModal"
      />
    </draggable>
    <div v-else class="grid gap-6 row lg:grid-cols-4 md:grid-cols-3">
      <board-detail-main-contents
        :files="files"
        :is-not-board-viwer="isNotBoardViwer"
        @open-preview-modal="openPreviewModal"
        @toggle-pin-modal="togglePinModal"
      />
    </div>

    <board-edit-modal
      v-if="state.boardModalFlag == 'edit' && state.showBoardModal"
      :toggle-board-modal="toggleBoardModal"
      :change-operation-modal-flag="changeBoardModalFlag"
      :board-info="boardInfo"
      @update-board="getBoard"
    />

    <board-read-modal
      v-else-if="state.boardModalFlag == 'read' && state.showBoardModal"
      :toggle-board-modal="toggleBoardModal"
      :change-operation-modal-flag="changeBoardModalFlag"
      :board-info="boardInfo"
      @update-board="getBoard"
    />

    <board-invite-member-modal
      v-else-if="state.boardModalFlag == 'invite' && state.showBoardModal"
      :toggle-board-modal="toggleBoardModal"
      :change-operation-modal-flag="changeBoardModalFlag"
      :board-info="boardInfo"
      @board-update="getBoard"
    />

    <board-read-member-modal
      v-else-if="state.boardModalFlag == 'read-member' && state.showBoardModal"
      :toggle-board-modal="toggleBoardModal"
      :board-info="boardInfo"
    />

    <board-delete-modal
      v-else-if="state.boardModalFlag == 'delete' && state.showBoardModal"
      :toggle-modal="toggleBoardModal"
      :change-operation-modal-flag="changeBoardModalFlag"
      :board-id="boardInfo.id"
      target="board"
      @close-modal="$router.push({ name: 'boardtop'})"
    />

    <operation-edit-pin-modal
      v-if="state.modalOperationFlag == 'edit'"
      :modal-visible-flag="state.modalVisibleFlag"
      :toggle-pin-modal="togglePinModal"
      :change-operation-modal-flag="changeOperationModalFlag"
      :selected-pin="state.selectedPin"
    />

    <operation-delete-modal
      v-else-if="state.modalOperationFlag == 'delete'"
      :modal-visible-flag="state.modalVisibleFlag"
      :toggle-modal="togglePinModal"
      :change-operation-modal-flag="changeOperationModalFlag"
      :pin-info="state.selectedPin"
      @pin-update="getBoard"
    />

    <div v-for="pin in files" :key="`${pin.pin_type}#${pin.packageInfo.sildeId}`">
      <preview-modal
        v-if="showPreviewModal(pin)"
        :anchor_point="state.anchorPoint"
        :package-info="state.packageInfo"
        :fade-mode="state.fadeMode"
        @update-package-info="getBoard"
        @toggle="closePreviewModal"
        @switch-package="switchPackage"
      />
    </div>


    <!-- <div class="fixed inset-x-0 flex items-center justify-center mx-auto bottom-4">
      <button 
        @click="state.showFileUploadModal = true"
        class="flex items-center justify-center w-12 p-2 font-bold bg-gray-600 rounded-full shadow-2xl text-gray-50 ounded-full hover:bg-gray-700"
      >
        <arrow-up-icon class="w-8 h-8" />
      </button>
    </div> -->

    <file-upload-modal 
     v-if="state.showFileUploadModal" 
     @close-modal="state.showFileUploadModal = false"
     :board-ids="[ boardId ]"
    />

    <loading
      v-model:active="state.loading"
      :is-full-page="false"
      color="#818589"
    />

    <infinite-loading
      v-if="showPinsLoading"
      spinner="circles"
      @infinite="infiniteHandler"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { reactive, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { VueDraggableNext } from "vue-draggable-next";
import util from "./composables/utility";
import BoardDetailMainContents from "./BoardDetailMainContents.vue"
import PreviewModal from "./modals/PreviewModal.vue";
import BoardReadModal from "./modals/BoardModalRead.vue";
import BoardEditModal from "./modals/BoardModalEdit.vue";
import BoardDeleteModal from "./modals/BoardModalDelete.vue";
import BoardInviteMemberModal from "./modals/BoardModalInviteMember.vue";
import BoardReadMemberModal from "./modals/BoardModalReadMember.vue";
import OperationEditPinModal from "./modals/PinModalEdit.vue";
import OperationDeleteModal from "./modals/PinModalDelete.vue";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepository = RepositoryFactoryForGae.get("board");
const FileRepositoryForGae = RepositoryFactoryForGae.get("file");
import useBoardRepository from "./composables/useBoardRepository";
import FileUploadModal from "./modals/FileUploadModal.vue";
// import ArrowUpIcon from "../icons/MdiArrowUpBoldIcon.vue";
import SearchIcon from "../icons/SearchIcon.vue";
import RoundEdit from "../icons/RoundEdit.vue";
import InfoIcon from "../icons/InfoIcon.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import infiniteLoading from "vue-infinite-loading";

export default {
  components: {
    draggable: VueDraggableNext,
    BoardReadModal,
    BoardEditModal,
    BoardDeleteModal,
    BoardInviteMemberModal,
    BoardReadMemberModal,
    OperationEditPinModal,
    OperationDeleteModal,
    PreviewModal,
    BoardDetailMainContents,
    FileUploadModal,
    // ArrowUpIcon,
    SearchIcon,
    RoundEdit,
    InfoIcon,
    Loading,
    infiniteLoading
  },

  async setup(_, { emit }) {
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      previewModalFlag: false,
      modalVisibleFlag: false,
      modalOperationFlag: 'edit',
      showBoardModal: false,
      boardModalFlag: 'edit',
      board: {},
      selectedPin: {},
      anchorPoint: "",
      packageInfo: {},
      previewId: null,
      fadeMode: "center",
      toggle: false,
      showFileUploadModal: false,
      filterKeyword: "",
      loading: false,
    });

    const boardId = route.params.id;
    const user = firebase.auth().currentUser;

    const isNotBoardViwer = computed(() => {
      const board = boardInfo.value;
      const members = [...board.owners, ...board.members];
      return !(members.includes(user.uid) && board.members.includes(user.uid));
    });

    const isMemberOfBoard = computed(() => {
      return [...boardInfo.value.owners, ...boardInfo.value.members].includes(user.uid)
    });
    const isCreatedUserOfBoard = computed(() => boardInfo.value.created_user === user.uid);

    const isBoardEditable = computed(() => {
      const board = boardInfo.value;
      const isBoardOwner = board?.owners.includes(user.uid);
      const isCreatedUser = board?.created_user === user.uid;
      return (isBoardOwner || isCreatedUser);
    });

    const { filterPinByKeyword } = util();

    const files = computed({
      get: () => {
        if (!boardInfo.value.pins) return [];
        return filterPinByKeyword(boardInfo.value.pins, state.filterKeyword);
      },
      set: (value) => {
        boardInfo.value.pins = value;
        const pins = value.map((pin) => {
          return { pin_id: pin.pin_id, pin_type: pin.pin_type };
        });
        const payload = { pins: pins, board_id: boardId };
        BoardRepository.updatePinOrder(payload);
      },
    });

    const attachBoardToPin = (board) => {
      const myBoard = store.state.board.myBoard;
      const pins = board.pins.map(pin => {
        const boards = myBoard.filter(x => x.pins.some(y => y.pin_id === pin.pin_id));
        return Object.assign(pin, { boards });
      });
      board.pins = pins;
      return board;
    }

    const boardInfo = computed(() => {
      const board = store.state.board.boardInfo;
      if ('ownersInfo'in board) return board;
      return attachBoardToPin(board);
    })

    const {
      addMembers,
      removeMembers,
      fetchPins,
      boardRepositoryState,
      getPinPayload,
      showPinsLoading,
      infiniteHandler
    } = useBoardRepository();

    const getBoard = async () => {
      const payload = { target: boardId, cursor: 0, currentPins: boardRepositoryState.pins };
      await fetchPins(payload).then((result) => {
        boardRepositoryState.pins = result;
      });
      getPinPayload.target = boardId;
      return boardRepositoryState.pins;
    };

    watchEffect(() => {
      const numPins = boardRepositoryState.pins.length;
      if (numPins > 0)
        getPinPayload.cursor = numPins;
    });

    await getBoard(); // Loading component will be removed if there is no 'await'.


    const joinBoard = async () => {
      state.loading = true;

      const users = [{ user_id: user.uid, permission: 'member' }]
      const payload = { users, board_id: boardId };
      const result = await addMembers(payload);
      if (result.status === "200") await getBoard();

      state.loading = false;
    };

    const leaveBoard = async () => {
      state.loading = true;

      const users = [{ user_id: user.uid, permission: user.permission }];
      const payload = { users, board_id: boardId };
      const result = await removeMembers(payload);
      if (result.status === "200") await getBoard();

      state.loading = false;
    };

    const switchPackage = (target) => {
      const currentIndex = files.value.findIndex((pin) => {
        if (pin.pin_type === "package") {
          return (
            `${pin.pin_type}#${pin.packageInfo.slideId}` == state.previewId
          );
        } else if (pin.pin_type === "slide") {
          return `${pin.pin_type}#${pin.slideInfo.id}` == state.previewId;
        }
      });
      const nextIndex = target == "next" ? currentIndex + 1 : currentIndex - 1;
      const numFiles = files.value.length;

      const isFirst = nextIndex < 0;
      const isLast = nextIndex > numFiles - 1;

      if (!(isFirst || isLast)) {
        const nextPackage = files.value[nextIndex];
        closePreviewModal();
        state.fadeMode = target == "next" ? "left" : "right";
        openPreviewModalReplaceUrl(nextPackage);
      }
    };

    const incrementPageview = (fileId, fileType) => {
      const payload = { target: fileId, type: fileType };
      return FileRepositoryForGae.incrementPageview(payload).json();
    };

    const openPreviewModal = (pin) => {
      const packageInfo = pin.packageInfo;
      const sorted_children_ids = packageInfo.children_ids_number.sort().map((x) => x.slice(5));
      packageInfo.sorted_children_ids = sorted_children_ids;

      const slideId =  pin.pin_type === "slide" ? pin.slideInfo.id : packageInfo.slideId;
      state.anchorPoint = slideId;
      state.packageInfo = packageInfo;
      state.packageInfo.boards = pin.boards;
      state.previewModalFlag = true;
      state.previewId = `${pin.pin_type}#${slideId}`;

      let url = `/package/${packageInfo.id}`
      if (pin.pin_type === 'slide') {
        url = `${url}#${slideId}`
        incrementPageview(packageInfo.slideId, "slide");
      } else {
        incrementPageview(packageInfo.packageId, "package");
      }

      // Change URL of preview page.
      history.pushState(null,null,url);

      emit('toggleShowPreviewModal');
    };

    const openPreviewModalReplaceUrl = (pin) => {
      const packageInfo = pin.packageInfo;
      const sorted_children_ids = packageInfo.children_ids_number.sort().map((x) => x.slice(5));
      packageInfo.sorted_children_ids = sorted_children_ids;

      const slideId =  pin.pin_type === "slide" ? pin.slideInfo.id : packageInfo.slideId;
      state.anchorPoint = slideId;
      state.packageInfo = packageInfo;
      state.previewModalFlag = true;
      state.previewId = `${pin.pin_type}#${slideId}`;

      let url = `/package/${packageInfo.id}`
      if (pin.pin_type === 'slide') {
        url = `${url}#${slideId}`
        incrementPageview(packageInfo.slideId, "slide");
      } else {
        incrementPageview(packageInfo.packageId, "package");
      }

      // Change URL of preview page.
      history.replaceState(null,null,url);
    };

    const closePreviewModal = () => {
      state.previewModalFlag = false;
      state.previewId = null;
      state.fadeMode = "center";
      state.packageInfo = {};
      emit('toggleShowPreviewModal');
    };

    const toggleBoardModal = (mode="edit") => {
      state.boardModalFlag = mode;
      state.showBoardModal = !state.showBoardModal;
    };

    const changeBoardModalFlag = (flag = "edit") => {
      state.boardModalFlag = flag;
    };

    const togglePinModal = (pin = {}) => {
      state.selectedPin = pin;
      state.modalVisibleFlag = !state.modalVisibleFlag;
    };

    const changeOperationModalFlag = (flag = "edit") => {
      state.modalOperationFlag = flag;
    };

    const onChange = (e) => {
      console.debug("event", e);
    };

    /**
     * Check if the preview modal should be display or not.
     */
    const showPreviewModal = (pin) => {
      const pinType = pin.pin_type;
      const slideId = pinType === 'package' ? pin.packageInfo.slideId : pin.slideInfo.id;
      const previewId = `${pinType}#${slideId}`;
      return state.previewId === previewId;
    }

    return {
      state,
      files,
      boardId,
      isNotBoardViwer,
      isMemberOfBoard,
      isCreatedUserOfBoard,
      isBoardEditable,
      boardInfo,
      getBoard,
      switchPackage,
      joinBoard,
      leaveBoard,
      openPreviewModal,
      closePreviewModal,
      toggleBoardModal,
      togglePinModal,
      changeBoardModalFlag,
      changeOperationModalFlag,
      onChange,
      showPreviewModal,
      showPinsLoading,
      infiniteHandler
    };
  },
};
</script>