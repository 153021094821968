<template>
  <!-- <div>
    <stack-icon class="text-white stroke-current h-7 w-7"/>
  </div> -->
  <div>
    <div
      v-if="state.hasNewNotifications"
      class="absolute z-20 w-3 h-3 bg-red-500 rounded-full left-4"
    >
    </div>
    <notification-icon
      class="relative z-10 text-white rounded-full cursor-pointer stroke-current w-7 h-7"
      @click="openNotificationDropdown"
    />
  </div>
  <div
    v-show="state.notificationDropdown"
    @click="closeNotificationDropdown"
    class="fixed inset-0 z-10 w-full h-full"
  ></div>
  <notification-dropdown
    v-if="state.notificationDropdown"
    :showDropdown="state.notificationDropdown"
    class="right-12"
  />
  <button
    class="flex items-center justify-center p-1 ml-5 rounded hover:bg-gray-100 focus:outline-none"
    @click="clickUserSettingsDropdown"
  >
    <img
      v-if="userInfo.photoURL"
      :src="userInfo.photoURL"
      alt="user-icon"
      class="object-cover w-12 h-12 p-1 border rounded-full"
    />
    <div v-else class="w-12 h-12 p-2 border rounded-full">
      <span class="text-2xl text-gray-500">{{
        userInfo?.lastName ? userInfo.lastName.substring(0, 1) : ""
      }}</span>
    </div>
    <arrow-down />
  </button>
  <div
    v-show="state.userSettingsDropdown"
    @click="state.userSettingsDropdown = false"
    class="fixed inset-0 z-10 w-full h-full"
  ></div>
  <div
    v-show="state.userSettingsDropdown"
    class="absolute right-0 z-20 py-2 mt-2 bg-white rounded-md shadow-xl dropdown-menu top-12 w-52"
  >
    <div
      @click="transition('profile')"
      class="flex items-center p-2 mb-4 cursor-pointer user-item hover:bg-gray-100"
    >
      <img
        v-if="userInfo.photoURL"
        :src="userInfo.photoURL"
        alt="user-icon"
        class="object-cover p-1 bg-white border rounded-full h-14 w-14"
      />
      <div
        v-else
        class="flex items-center justify-center p-2 border rounded-full h-14 w-14"
      >
        <span class="text-4xl text-gray-500">{{
          userInfo?.lastName ? userInfo.lastName.substring(0, 1) : ""
        }}</span>
      </div>
      <p class="mx-2 text-gray-600 truncate">
        {{ userInfo.displayName != ""
          ? userInfo.displayName
          : userInfo.lastName + " " + userInfo.firstName
        }}
      </p>
    </div>
    <hr />
    <div class="my-2 menu-item">
      <router-link to="/settings/notification" class="menu-list">通知</router-link>
      <router-link to="#" class="menu-list">ウォッチ</router-link>
      <router-link to="/boards" class="menu-list">マイボード</router-link>
      <router-link to="#" class="menu-list">ユーザ一覧</router-link>
      <router-link :to="{ name: 'Settings' }" class="menu-list">設定</router-link>
    </div>
    <hr />
    <div class="my-2 logout">
      <button
        @click="signout"
        type="button"
        class="w-full text-left text-red-500 menu-list focus:outline-none"
      >
        ログアウト
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import firebase from "firebase/app";

import NotificationIcon from "../../icons/NotificationIcon.vue";
import ArrowDown from "../../icons/ArrowDown.vue";
import NotificationDropdown from "./NotificationDropdown.vue";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const NotificationRepository = RepositoryFactoryForGae.get("notification");

export default {
  components: {
    ArrowDown,
    NotificationIcon,
    NotificationDropdown,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const userInfo = computed(() => {
      return store.state.user.userInfo;
    });

    const state = reactive({
      userSettingsDropdown: false,
      notificationDropdown: false,
      hasNewNotifications: userInfo.value.has_new_notification,
      firstTimeOpenNotificationDropdown: false,
      notificationList: []
    });

    const clickUserSettingsDropdown = () => {
      state.userSettingsDropdown = !state.userSettingsDropdown;
    };

    const openNotificationDropdown = async () => {
      state.notificationDropdown = true;
      state.hasNewNotifications = false;
      if (!state.firstTimeOpenNotificationDropdown) {
        await NotificationRepository.updateNotificationReadFlg({ is_read: true })
          .then(() => {
            state.firstTimeOpenNotificationDropdown = true;
          });
      }
    };
    const closeNotificationDropdown = async () => {
      state.notificationDropdown = false;
    };

    const transition = async path => {
      switch (path) {
        case "profile":
          return router.push({
            path: `/users/${store.state.user.userInfo.username}/profile`,
            name: "user",
            params: { username: store.state.user.userInfo.username }
          });
        default:
          return router.push({ path: "/" });
      }
    };

    const signout = () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push("/signin");
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return {
      state,
      userInfo,
      clickUserSettingsDropdown,
      openNotificationDropdown,
      closeNotificationDropdown,
      transition,
      signout,
    };
  },
};
</script>

<style lang="postcss" scoped>
.menu-list {
  @apply block px-4 py-2 text-gray-600 hover:bg-gray-100;
}
</style>