
<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M10 1.6a8.4 8.4 0 1 0 0 16.8a8.4 8.4 0 0 0 0-16.8zm4.789 11.461L13.06 14.79L10 11.729l-3.061 3.06L5.21 13.06L8.272 10L5.211 6.939L6.94 5.211L10 8.271l3.061-3.061l1.729 1.729L11.728 10l3.061 3.061z"
    >
    </path>
  </svg>
</template>