<template>
  <base-modal
    :showModal="true"
    @toggleModal="closeModal"
  >
    <template v-slot:header>ユーザーを招待する</template>

    <template v-slot:body>
      <div
        v-if="state.validationType == 'success'"
        class="mb-2 text-green-400"
      >
        招待用メールが正常に送信されました！
      </div>
      <div
        v-else-if="state.validationType == 'invalid-pattern'"
        class="mb-2 text-red-600"
      >
        無効なメールアドレスです。
      </div>
      <div
        v-else-if="state.validationType == 'not-company-email'"
        class="mb-2 text-red-600"
      >
        許可されていないドメインが含まれています。
      </div>
      <div class="mb-8" style="">
        <p class="mb-1 text-gray-500">メールアドレス</p>
        <div class="flex p-1 border rounded">
          <input
            id="mail-form"
            type="email"
            class="h-10 pl-3 w-full border-none focus:outline-none"
            v-model="state.email"
          />
          <select
            id="permission"
            v-model="state.role"
            class="h-8 px-1 py-1 my-1 text-gray-400 bg-transparent rounded right-20 focus:outline-none"
          >
            <option value="member">メンバー</option>
            <option value="admin">管理者</option>
          </select>
          <button
            @click="addUserToList"
            :disabled="!state.email"
            class="w-24 h-8 px-4 py-1 mx-2 my-1 text-white bg-gray-500 rounded shadow hover:bg-gray-700 focus:outline-none disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-not-allowed"
          >
            追加
          </button>
        </div>
      </div>
      <div>
        <p class="block mb-1 text-gray-500">招待者リスト</p>
        <div
          v-for="user in state.inviteUserList"
          :key="'A' + user.id"
          class="mb-2 last:mb-0"
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="ml-2">
                <span>{{ user.email }}</span>
              </div>
            </div>
            <div class="flex items-center justify-center w-44">
              <Multiselect
                class="w-full text-gray-500 h-7"
                style="min-height: 0px; --ms-option-font-size: text-sm;--ms-border-width: 0px;"
                v-model="user.role"
                :options="[{ label: '管理者', value: 'admin' },{ label: 'メンバー', value: 'member' }]"
                :canClear="false"
                :caret="true"
                @change="changePermission(user)"
              />
              <button
                @click="removeUserFromList(user)"
                class="w-28 p-1 ml-1 text-sm text-white bg-red-500 border-none rounded hover:bg-red-600"
              >
                削除する
              </button>
            </div>
          </div>
        </div>
      </div>
      <loading
        v-model:active="state.loading"
        :is-full-page="false"
        color="#818589"
        blur=""
      />
    </template>

    <template v-slot:footer>
      <div class="mx-10">
        <div class="w-full mt-2 mb-4 border"></div>
        <div class="flex justify-end mb-6">
          <button
            @click="closeModal"
            class="p-2 rounded mr-4 text-black bg-gray-200 button hover:bg-gray-300"
          >
            キャンセル
          </button>
          <button
            @click="sendInviteMail"
            :disabled="state.disableInviteButton || state.inviteUserList.length == 0"
            class="p-2 text-white bg-blue-600 rounded hover:bg-blue-800 disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-not-allowed"
          >
            招待する
          </button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { reactive, computed } from "vue";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const userRepository = RepositoryFactoryForGae.get("user");
import BaseModal from "./BaseModal.vue";
import Multiselect from "@vueform/multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    BaseModal,
    Multiselect,
    Loading
  },
  emits: [
    "closeModal"
  ],
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      email: "",
      role: "member",
      inviteUserList: [],
      disableInviteButton: false,
      isSuccessfulInvite: false,
      validationType: "",
      loading: false
    });

    const isSuccessfulInvite = computed(() => {
      return state.isSuccessfulInvite;
    });

    const addUserToList = async () => {
      state.validationType = 0;
      const pattern = new RegExp(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/);
      if (!pattern.test(state.email)) {
        return state.validationType = "invalid-pattern";
      }
      state.inviteUserList.push({ email: state.email, role: state.role });
      state.email = "";
      state.role = "member";
    };
    const removeUserFromList = async (target) => {
      state.inviteUserList = state.inviteUserList.filter((user) => user.email != target.email);
    };
    const changePermission = async (target) => {};

    const sendInviteMail = async () => {
      state.disableInviteButton = true;
      state.loading = true;
      const payload = {
        users: state.inviteUserList
      };
      await userRepository.sendInviteMail(payload)
        .json()
        .then(({ status }) => {
          if (status) {
            state.loading = false;
            state.disableInviteButton = false;
            state.validationType = "success";
          }
        })
        .catch((e) => {
          state.loading = false;
          state.disableInviteButton = false;
          if (e.message.includes("401"))
            state.validationType = "not-company-email";
        });
    };

    return {
      state,
      isSuccessfulInvite,
      addUserToList,
      removeUserFromList,
      changePermission,
      sendInviteMail
    }
  }
};
</script>