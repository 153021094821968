<template>
  <success-modal :show-modal="showModal" @close-modal="closeModal">
    <template v-slot:header>
      メールの送信が完了しました！
    </template>

    <template v-slot:body>
      メールボックスを確認して以降の処理を完了してください。
    </template>

    <template v-slot:footer>
      <button
        @click="closeModal"
        class="w-32 p-2 text-center text-white bg-indigo-500 border rounded hover:bg-indigo-600 focus:outline-none"
      >
        OK
      </button>
    </template>
  </success-modal>
</template>

<script>
import SuccessModal from "./BaseWithoutLineModal.vue";
export default {
  components: { SuccessModal },

  props: {
    showModal: Boolean
  },

  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    };

    return { closeModal };
  }
};
</script>