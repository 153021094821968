<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M215.996 52h-176a4 4 0 0 0 0 8h12v148a12.014 12.014 0 0 0 12 12h128a12.014 12.014 0 0 0 12-12V60h12a4 4 0 0 0 0-8zm-20 156a4.005 4.005 0 0 1-4 4h-128a4.005 4.005 0 0 1-4-4V60h136zM84 24a4 4 0 0 1 4-4h80a4 4 0 0 1 0 8H88a4 4 0 0 1-4-4z"
      fill="currentColor"
    ></path>
  </svg>
</template>