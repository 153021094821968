<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M111.137 158.977a56 56 0 1 0-46.273 0a92.233 92.233 0 0 0-52.138 36.117a4 4 0 1 0 6.54 4.605a84.017 84.017 0 0 1 137.464-.005a4 4 0 0 0 6.541-4.606a92.232 92.232 0 0 0-52.134-36.111zM40 108a48 48 0 1 1 48 48a48.054 48.054 0 0 1-48-48zm203.826 92.662a4 4 0 0 1-5.574-.968A84.174 84.174 0 0 0 169.522 164a4 4 0 0 1 0-8a48 48 0 1 0-13.026-94.212a4 4 0 1 1-2.166-7.702a55.997 55.997 0 0 1 38.328 104.89a92.235 92.235 0 0 1 52.135 36.112a4 4 0 0 1-.967 5.574z"
      fill="currentColor"
    ></path>
  </svg>
</template>