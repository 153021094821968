<template>
  <operation-modal :showModal="modalVisibleFlag" @toggleModal="togglePinModal">
    <template v-slot:header>{{ boardInfo.name }} から削除する</template>

    <template v-slot:body>
      <div v-if="'slideInfo' in selectedPin">
        <p class="block mb-2 font-bold text-gray-700 text-md">
          ファイル名: {{ selectedPin.name }}
        </p>
        <div
          v-bind:style="{
            backgroundImage:
              'url(' + state.baseUrl + selectedPin.slideId + '.jpg)',
          }"
          class="bg-gray-100 bg-center bg-no-repeat bg-contain border rounded preview-thumbnail aspect-w-16 aspect-h-9"
        ></div>
      </div>
      <div v-else>
        <p class="block mb-2 font-bold text-gray-700 text-md">
          ファイル名: {{ selectedPin.fileTitle }}
        </p>
        <div
          v-bind:style="{
            backgroundImage: 'url(' + state.baseUrl + selectedPin.headerId + '.jpg)',
          }"
          class="bg-gray-100 bg-center bg-no-repeat bg-contain border rounded preview-thumbnail aspect-w-16 aspect-h-9"
        ></div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="mx-10">
        <div class="w-full my-4 border"></div>
        <div class="flex justify-end mb-6">
          <button
            @click="closeModal"
            class="text-black bg-gray-200 button hover:bg-gray-300"
          >
            キャンセル
          </button>
          <button
            @click="removePinFromBoard"
            class="ml-4 text-white bg-indigo-600 button hover:bg-indigo-700"
          >
            ボードから削除する
          </button>
        </div>
      </div>
      <loading v-model:active="state.loading" color="#818589" />
    </template>
  </operation-modal>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import OperationModal from "./BaseModal.vue";
import useBoardRepository from "../../organisms/composables/useBoardRepository";

export default {
  components: {
    OperationModal,
    Loading,
  },

  props: {
    modalVisibleFlag: {
      type: Boolean,
      required: true,
    },
    togglePinModal: {
      type: Function,
      required: true,
    },
    selectedPin: {
      type: Object,
      required: true,
    },
    boardInfo: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      baseUrl: location.origin + "/img/",
      userInfo: store.state.user.userInfo,
      pinInfo: props.selectedPin,
      boardInfo: props.boardInfo,
      loading: false
    });

    const {
      getMyBoard,
      getMyBoardToSideBar,
      getPublicBoard,
      removePin
    } = useBoardRepository(state.boardInfo);

    const updateBoard = async () => {
      await getMyBoard({
        workSpace: state.userInfo.workSpace,
        userId: state.userInfo.id
      });
      await getMyBoardToSideBar({
        workSpace: state.userInfo.workSpace,
        userId: state.userInfo.id
      });
      await getPublicBoard({ workSpace: state.userInfo.workSpace });
    };

    const removePinFromBoard = async () => {
      state.loading = true;
      const fileType = state.pinInfo.packageId ? "package" : "slide";
      await removePin(state.pinInfo.id, fileType).catch(error =>
        console.error("failed to remove pin", error)
      );
      await updateBoard();
      state.loading = false;
      emit("closeModal");
    };

    const closeModal = () => {
      emit("closeModal");
    };

    return {
      state,
      removePinFromBoard,
      closeModal,
    };
  },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply border rounded px-3 py-1.5 focus:outline-none;
}
</style>