import firebase from 'firebase/app';
import { RepositoryFactoryForGae } from '@/api/gae/RepositoryFactory';
const FileRepository = RepositoryFactoryForGae.get('file');
const BoardRepository = RepositoryFactoryForGae.get('board');

export default {
  namespaced: true,
  state: {
    showMyBoardLoading: false,
    myBoard: [],
    showPublicBoardLoading: false,
    publicBoard: [],
    myPublicBoard: [],
    myPrivateBoard: [],
    pins: [],
    showSidebar: false,
    boardInfo: {}
  },
  getters: {
    publicBoards (state) {
      return state.publicBoard
    },
    myPrivateBoards (state) {
      return state.myPrivateBoard;
    },
    myPublicBoards (state) {
      return state.myPublicBoard;
    },
    defaultBoards (state) {
      return state.myBoard.filter(board => board?.type === 'default');
    },
    customBoards (state) {
      return state.myBoard.filter(board => board?.type !== 'default');
    }
  },
  mutations: {
    setMyBoardLoading(state, status) {
      state.showMyBoardLoading = status;
    },
    setMyBoard(state, data) {
      state.myBoard = data;
    },
    setPublicBoardLoading(state, status) {
      state.showPublicBoardLoading = status;
    },
    setBoardInfo(state, boardInfo) {
      state.boardInfo = boardInfo;
    },
    setPublicBoard(state, data) {
      state.publicBoard = data;
    },
    setMyPrivateBoard(state, data) {
      state.myPrivateBoard = data;
    },
    setMyPublicBoard(state, data) {
      state.myPublicBoard = data;
    },
    setPins(state, data) {
      state.pins = data;
    },
    clearPins(state) {
      state.pins = [];
    },
    updatePins(state, data) {
      state.pins = data;
    },
    updateSidebar(state, flag) {
      state.showSidebar = flag;
    },
    updatePrivateBoardOrder(state, data) {
      state.myPrivateBoard = data;
    },
    updatePublicBoardOrder(state, data) {
      state.myPublicBoard = data;
    },
    addPinsToBoard(state, data) {
      state.boardInfo.pins = data;
    }
  },
  actions: {
    // 自分が所属する全てのボード
    async getMyBoard({ commit }, data) {
      commit("setMyBoardLoading", true);
      const db = firebase.firestore();
      const ownerBoard = await db.collection("dataStore").doc(data.workSpace).collection("boards")
        .where('owners', 'array-contains', data.userId).get()
        .then(snapshot => snapshot.docs.map(doc => doc.data()));
      const memberBoard = await db.collection("dataStore").doc(data.workSpace).collection("boards")
        .where('members', 'array-contains', data.userId).get()
        .then(snapshot => snapshot.docs.map(doc => doc.data()));
      const myBoard = [...ownerBoard, ...memberBoard];
      commit("setMyBoard", myBoard);
      commit("setMyBoardLoading", false);
      return myBoard;
    },

    // サイドバー用にパブリックとプライベートでボードを管理
    async getMyBoardToSideBar({ commit }, data) {
      commit("setMyBoardLoading", true);
      const db = firebase.firestore();
      const user = await db
        .collection("dataStore")
        .doc(data.workSpace)
        .collection("users")
        .doc(data.userId)
        .get()
        .then((doc) => {
          return doc.data();
        });
      const privateBoardIds = user.boards.private;
      const publicBoardIds = user.boards.public;
      const privateBoards = await Promise.all(
        privateBoardIds.map(async (id) => {
          return await db
            .collection("dataStore")
            .doc(data.workSpace)
            .collection("boards")
            .doc(id)
            .get()
            .then((doc) => {
              return doc.data();
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
      const publicBoards = await Promise.all(
        publicBoardIds.map(async (id) => {
          return await db
            .collection("dataStore")
            .doc(data.workSpace)
            .collection("boards")
            .doc(id)
            .get()
            .then((doc) => {
              return doc.data();
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
      commit("setMyPrivateBoard", privateBoards);
      commit("setMyPublicBoard", publicBoards);
      commit("setMyBoardLoading", false);
      return [...privateBoards, ...publicBoards];
    },

    async getPublicBoard({ commit }, data) {
      commit("setPublicBoardLoading", true);
      const db = firebase.firestore();
      const publicBoard = await db.collection("dataStore").doc(data.workSpace).collection("boards")
        .where('visibility', '==', 'public').get()
        .then(snapshot => snapshot.docs.map(doc => doc.data()));
      commit("setPublicBoard", publicBoard);
      commit("setPublicBoardLoading", false);
      return publicBoard;
    },

    async getBoardInfo({ commit }, data) {
      commit("setPublicBoardLoading", true);
      const boardInfo = await BoardRepository.getPin(data).json() 
      commit("setBoardInfo", boardInfo)
      commit("setPublicBoardLoading", false);
      return boardInfo;
    },

    async addPinsToBoard({ commit }, data) {
      commit("setPublicBoardLoading", true);
      commit("addPinsToBoard", data);
      commit("setPublicBoardLoading", false);
    },

    async getPins({ commit }, pins) {
      const files = await Promise.all(pins.map(async (pin) => {
        let file;
        const pinId = pin.pin_id
        const pinType = pin.pin_type
        const payload = { "target": pinId };

        if (pinType === "package") {
          file = await FileRepository.getPackageInfo(payload).json()
          file.slideId = file.headerId

        } else if (pinType === "slide") {
          file = await FileRepository.getSlideInfo(payload).json()
        }

        return file;
      }));
      commit("setPins", files)
    },

    updatePins({ commit }, newPins) {
      commit("updatePins", newPins);
    },

    updatePrivateBoardOrder({ commit }, newBoard) {
      commit("updatePrivateBoardOrder", newBoard);
    },

    updatePublicBoardOrder({ commit }, newBoard) {
      commit("updatePublicBoardOrder", newBoard);
    },

    openSidebar({ commit }) {
      commit("updateSidebar", true);
    },

    closeSidebar({ commit }) {
      commit("updateSidebar", false);
    }
  }
};