<template>
  <operation-modal :showModal="modalVisibleFlag" @toggleModal="toggleModal">
    <template v-slot:header>ピンを削除する</template>

    <template v-slot:body>
      <p class="mb-8">
        再度ボードに追加することで復元できます。
      </p>
      <form-button
        title="キャンセル"
        className="button button-gray"
        @click="cancel"
      />
      <form-button
        title="完全に削除する"
        className="button button-red"
        @click="removePin"
      />
      <loading
        v-model:active="state.loading"
        color="#818589"
        :is-full-page="false"
      />
    </template>
  </operation-modal>
</template>

<script>
import { reactive } from "vue"
import { useRoute } from "vue-router"
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const BoardRepositoryForGae = RepositoryFactoryForGae.get("board");
import OperationModal from "./BaseModal.vue";
import FormButton from "../../atoms/FormButton.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    OperationModal,
    FormButton,
    Loading
  },

  props: {
    modalVisibleFlag: {
      type: Boolean,
      required: true,
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    changeOperationModalFlag: {
      type: Function,
      required: true,
    },
    pinInfo: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      loading: false
    })

    const cancel = () => {
      props.changeOperationModalFlag('edit')
    }

    const route = useRoute()

    const removePin = async () => {
      state.loading = true;
      const payload = {
        pins: [{ pin_id: props.pinInfo.pin_id, pin_type: props.pinInfo.pin_type }],
        board_id: route.params.id,
      };
      const result = await BoardRepositoryForGae.removePin(payload).json();
      state.loading = false;
      if (result.status === "200") {
        emit("pin-update");
        await new Promise(resolve => setTimeout(resolve, 100));

        props.toggleModal();
      }
    }

    return {
      state,
      cancel,
      removePin
    }
  }
};
</script>

<style lang="postcss" scoped>
.button {
  @apply w-full border-none font-semibold px-6 py-3 rounded outline-none focus:outline-none mb-2 ease-linear transition-all duration-150;
}

.button-red {
  @apply bg-red-500 hover:bg-red-600 text-white
}

.button-gray {
  @apply bg-gray-100 hover:bg-gray-200
}
</style>