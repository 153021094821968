import { useStore } from "vuex";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const UserRepositoryForGae = RepositoryFactoryForGae.get("user");

export default function() {
  const store = useStore();

  const checkEmail = async (payload) => {
    const res = await UserRepositoryForGae.checkEmail(payload).json();
    return res.data.isValidEmail;
  };

  const checkUserId = async (payload) => {
    const res = await UserRepositoryForGae.checkUserId(payload).json();
    return res.data.isValidUsername;
  };

  const checkSignup = async (payload) => {
    const res = await UserRepositoryForGae.checkSignup(payload).json();
    return res.data;
  };

  const getUsers = async (data) => {
    return await store.dispatch("user/getUsers", data);
  };

  const getDecryptionKey = async (data) => {
    return await store.dispatch("user/getSignupParamsDecryptionKey", data);
  };

  /**
   * Create following documents after signup.
   *
   * - UserMapping
   * - UserProfile
   * - DefaultBoard
   *
   * @param {Object} payload
   */
  const createUserAfterSignup = async (payload) => {
    return await UserRepositoryForGae.createUserAfterSignup(payload).json();
  };

  const createSearchApiKey = async (payload) => {
    return await UserRepositoryForGae.createSearchApiKey(payload).json();
  };

  const updateFirstLogin = async (payload) => {
    return await UserRepositoryForGae.updateFirstLogin(payload).json();
  };

  return {
    checkEmail,
    checkUserId,
    checkSignup,
    getUsers,
    getDecryptionKey,
    createUserAfterSignup,
    createSearchApiKey,
    updateFirstLogin,
  };
}
