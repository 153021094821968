<template>
  <div class="mx-16">
    <div class="flex items-center justify-between mb-4">
      <h1 class="text-xl font-bold text-gray-600">🗂️ マイボード</h1>
      <div class="flex items-center justify-end">
        <div class="flex items-center justify-center">
          <div
            class="flex items-center justify-center w-full h-8 mr-2 bg-white border border-gray-200 rounded-lg focus:outline-none"
          >
            <search-icon
              class="w-6 h-6 ml-2 text-gray-400 transition fill-current rounded-l-md"
            />
            <input
              class="w-56 w-full h-full pl-2 text-sm bg-white rounded-r-md focus:outline-none"
              type="search"
              placeholder="ボードの絞り込み..."
              v-model="state.filterMyBoardKeyword"
              v-focus
            />
          </div>
          <div class="flex">
            <button
              @click="state.showBoardCreateModal = true"
              class="h-8 text-sm text-gray-700 transition border border-gray-700 rounded shadow w-28 hover:bg-gray-700 hover:text-white focus:outline-none"
            >
              ボードを作成
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- デフォルトボードが存在するためコメントアウト -->
    <!-- <div v-if="boards.length <= 0" class="flex justify-center mx-16 mb-4">
      <p class="text-xl text-gray-400">
        ボードはパッケージやスライドを目的ごとに保存できる機能です！
        <br />お気に入りの資料や、プロジェクト単位など、まとめたいカテゴリに応じてボードを作成します。<br />自分だけのボードや、メンバーと共有できるボードを作成して資料のナレッジを貯めましょう🙌
      </p>
    </div> -->

    <!-- マイボードセクション -->
    <div v-if="!showMyBoardLoading" class="grid gap-6 row lg:grid-cols-3 md:grid-cols-2">
      <div
        class="transition transform bg-white border rounded shadow-md hover:shadow-xl hover:-translate-y-px"
        v-for="(item, index) in boards"
        :key="item.id"
      >
        <router-link :to="{ name: 'boardDetail', params: { id: item.id } }">
          <div
            v-on:mouseover="onMouseOverMyBoard(index)"
            v-on:mouseleave="onMouseLeaveMyBoard"
            class="flex overflow-hidden rounded bg-gray-50 preview"
          >
            <div v-if="item.coverURL">
              <div
                v-bind:style="{ backgroundImage: 'url(' + item.coverURL + ')'}"
                class="w-40 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9"
              ></div>
            </div>
            <div v-else>
              <div
                v-bind:style="{ backgroundImage: item.pins.length > 0 ? 'url(' + state.baseUrl + item.thumbnail + '.jpg)': 'url(' + require('@/assets/board-thumbnail.png') + ')'}"
                class="inset-0 w-40 bg-center bg-no-repeat bg-cover border-r preview-background aspect-w-16 aspect-h-9"
              ></div>
            </div>
            <div class="flex justify-between w-full px-2 py-1">
              <div>
                <p class="mb-1 font-bold text-gray-600 text">
                  {{ item.visibility === "limited" ? "🔒" : "#"}}{{ item.name }}
                </p>
                <p class="text-xs text-gray-500">
                  {{ item.description }}
                </p>
              </div>
              <div class="text-sm">
                <div class="flex text-gray-400">
                  <pins-icon class="w-5 h-5 current-color" />
                  <span>{{item.pins.length}}</span>
                </div>
                <div class="flex text-gray-400">
                  <users-icon class="w-5 h-5 current-color" />
                  <span>{{item.owners.length + item.members.length}}</span>
                </div>
              </div>
            </div>
            <button
              v-show="state.hoverMyBoardFlag && index === state.hoverMyBoardIndex"
              v-on:click.prevent="
                isEditable
                  ? toggleBoardModal(item, 'edit')
                  : toggleBoardModal(item, 'read')
              "
              class="absolute outline-none top-2 right-2 focus:outline-none"
            >
              <div class="p-2 bg-white border rounded-full hover:bg-gray-50">
                <round-edit v-if="isEditable" class="w-5 h-5" />
                <info-icon v-else class="w-5 h-5" />
              </div>
            </button>
          </div>
        </router-link>
      </div>
    </div>
    <loader-board v-else />
  </div>
  <board-modal-create
    v-if="state.showBoardCreateModal"
    @close-modal="state.showBoardCreateModal = false"
    @update-board="updateBoard"
  />

  <operation-edit-board-modal
    v-if="state.modalOperationFlag == 'edit' && state.modalVisibleFlag"
    :toggle-board-modal="toggleBoardModal"
    :change-operation-modal-flag="changeOperationModalFlag"
    :board-info="state.board"
    @update-board="updateBoard"
  />

  <operation-read-board-modal
    v-else-if="state.modalOperationFlag == 'read' && state.modalVisibleFlag"
    :toggle-board-modal="toggleBoardModal"
    :change-operation-modal-flag="changeOperationModalFlag"
    :board-info="state.board"
    @update-board="updateBoard"
  />

  <operation-invite-user-modal
    v-else-if="state.modalOperationFlag == 'invite'"
    :modal-visible-flag="state.modalVisibleFlag"
    :toggle-board-modal="toggleBoardModal"
    :change-operation-modal-flag="changeOperationModalFlag"
    :board-info="state.board"
    @board-update="updateBoard"
  />

  <operation-delete-modal
    v-else-if="state.modalOperationFlag == 'delete'"
    :toggle-modal="toggleBoardModal"
    :change-operation-modal-flag="changeOperationModalFlag"
    :board-id="state.board.id"
    target="board"
    @close-modal="closeDeleteModal"
  />
</template>

<script>
import firebase from "firebase/app";
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import util from "./composables/utility";
import useBoardRepository from "./composables/useBoardRepository";
import BoardModalCreate from "../organisms/modals/BoardModalCreate.vue";
import OperationReadBoardModal from "./modals/BoardModalRead.vue";
import OperationEditBoardModal from "./modals/BoardModalEdit.vue";
import OperationDeleteModal from "./modals/BoardModalDelete.vue";
import OperationInviteUserModal from "./modals/BoardModalInviteMember.vue";
import LoaderBoard from "./utilities/LoaderBoard.vue"
import RoundEdit from "../icons/RoundEdit.vue";
import SearchIcon from "../icons/SearchIcon.vue";
import UsersIcon from "../icons/UsersIcon.vue";
import InfoIcon from "../icons/InfoIcon.vue";
import PinsIcon from "../icons/PinsIcon.vue";

export default {
  components: {
    RoundEdit,
    SearchIcon,
    UsersIcon,
    InfoIcon,
    PinsIcon,
    BoardModalCreate,
    OperationReadBoardModal,
    OperationEditBoardModal,
    OperationDeleteModal,
    OperationInviteUserModal,
    LoaderBoard
  },

  async setup() {
    const user = firebase.auth().currentUser;
    const store = useStore();

    const state = reactive({
      baseUrl: `${location.origin}/img/`,
      board: {},
      hoverMyBoardFlag: false,
      hoverMyBoardIndex: null,
      hoverPublicBoardFlag: false,
      hoverPublicBoardIndex: null,
      showBoardCreateModal: false,
      modalVisibleFlag: false,
      modalOperationFlag: "edit",
      filterMyBoardKeyword: "",
      filterPublicBoardKeyword: ""
    });

    const showPublicBoardLoading = computed(() => store.state.board.showPublicBoardLoading);
    const showMyBoardLoading = computed(() => store.state.board.showMyBoardLoading);

    const { filterBoardByKeyword } = util();

    const boards = computed(() => {
      const boards = [...store.getters['board/defaultBoards'], ...store.getters['board/customBoards']];
      const users = store.state.user.users;
      const boardWithMember = mergeBoardAndMember(boards, users);
      return filterBoardByKeyword(boardWithMember, state.filterMyBoardKeyword);
    });

    const publicBoard = computed(() => { 
      const boards = store.state.board.publicBoard.filter(x => {
        const isOwner = x.owners.includes(user.uid);
        const isMember = x.members.includes(user.uid);
        return !isOwner && !isMember;
      });
      const users = store.state.user.users;
      const boardWithMember = mergeBoardAndMember(boards, users);
      return filterBoardByKeyword(boardWithMember, state.filterMyBoardKeyword);
    });

    const isEditable = computed(() => {
      if (typeof state.hoverMyBoardIndex !== "number") return false;
      const board = boards.value[state.hoverMyBoardIndex];
      return (board?.owners.includes(user.uid) || board?.created_user === user.uid);
    });

    const userInfo = store.state.user.userInfo;
    const { getMyBoard, getPublicBoard, mergeBoardAndMember } = useBoardRepository();

    // Update boards with members when page loading.
    const updateBoard = async () => {
      await getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid });
      await getPublicBoard({ workSpace: userInfo.workSpace });

      state.board = boards.value[state.hoverMyBoardIndex];
    };

    const toggleBoardModal = (board = {}, mode = "edit") => {
      state.board = board;
      state.modalOperationFlag = mode;
      state.modalVisibleFlag = !state.modalVisibleFlag;
    };

    const closeDeleteModal = async () => {
      toggleBoardModal();
      updateBoard()
    };

    const changeOperationModalFlag = (flag = "edit") => {
      state.modalOperationFlag = flag;
    };

    const onMouseOverMyBoard = index => {
      state.hoverMyBoardFlag = true;
      state.hoverMyBoardIndex = index;
    };

    const onMouseLeaveMyBoard = () => {
      state.hoverMyBoardFlag = false;
    };

    const onMouseOverPublicBoard = index => {
      state.hoverPublicBoardFlag = true;
      state.hoverPublicBoardIndex = index;
    };

    const onMouseLeavePublicBoard = () => {
      state.hoverPublicBoardFlag = false;
    };

    return {
      state,
      boards,
      showMyBoardLoading,
      showPublicBoardLoading,
      publicBoard,
      isEditable,
      updateBoard,
      toggleBoardModal,
      closeDeleteModal,
      changeOperationModalFlag,
      onMouseOverMyBoard,
      onMouseLeaveMyBoard,
      onMouseOverPublicBoard,
      onMouseLeavePublicBoard
    };
  }
};
</script>