<template>
  <div class="w-full px-8 pt-6 mb-4 bg-white sm:w-2/3">
    <div class="field">
      <failure-alart
        :show="state.showAlart"
        :title="state.alartTitle"
        :body="state.alartBody"
      />
    </div>
    <Form
      v-slot="{ meta }"
      @submit="signup"
      :initial-values="initialValues"
      :validation-schema="schema"
    >
      <div class="field">
        <div v-if="!state.decryptedEmail">
          <label class="field-label" for="email">メールアドレス※</label>
          <Field
            class="field-item"
            id="email"
            name="email"
            placeholder="ns_taro@example.com"
            v-model="state.email"
            aria-readonly="true"
          />
          <ErrorMessage class="text-red-600" name="email" />
          <span v-if="!state.isValidEmail" class="text-red-600">
            このメールアドレスは許可されていません
          </span>
        </div>
        <div v-else>
          <label class="field-label" for="email">メールアドレス※(変更不可)</label>
          <Field
            class="field-item"
            id="email"
            name="email"
            v-model="state.email"
            :placeholder="state.decryptedEmail"
            readonly
          />
        </div>
      </div>
      <div class="field">
        <label class="field-label" for="password">パスワード※</label>
        <Field
          class="field-item"
          id="password"
          name="password"
          type="password"
          placeholder="********"
          autocomplete="on"
        />
        <ErrorMessage class="text-red-600" name="password" />
      </div>
      <div class="field">
        <div>
          <div class="flex-1">
            <label class="field-label" for="lastName">お名前※</label>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1">
            <Field
              class="field-item"
              id="lastName"
              name="lastName"
              placeholder="北砂"
            />
            <ErrorMessage class="text-red-600" name="lastName" />
          </div>
          <div class="flex-1 ml-4">
            <Field
              class="field-item"
              id="firstName"
              name="firstName"
              placeholder="太郎"
            />
            <ErrorMessage class="text-red-600" name="firstName" />
          </div>
        </div>
      </div>
      <div class="field">
        <label class="field-label" for="username">ユーザーID※ (自動)</label>
        <Field
          class="field-item text-gray-600"
          id="username"
          name="username"
          placeholder="ns_taro"
          v-model="userId"
        />
        <ErrorMessage class="text-red-600" name="username" />
        <span v-if="!state.isValidUserId" class="text-red-600">
          このユーザーIDはすでに使われています
        </span>
      </div>
      <div>
        <button
          :disabled="!meta.valid"
          class="w-full p-4 font-bold text-white bg-blue-500 rounded shadow hover:bg-blue-700 focus:outline-none disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          新規登録
        </button>
      </div>
    </Form>
    <loading
      v-model:active="state.loading"
      :is-full-page="false"
      color="#818589"
      blur=""
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { onBeforeMount, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import crypto from "crypto-js";
import { Field, Form, ErrorMessage } from "vee-validate";
import yup from "../../locale/custom-ja";
import useUserRepository from "./composables/useUserRepository"
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FailureAlart from "./alarts/FailureAlartBase.vue";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
    FailureAlart,
  },

  setup() {
    const router = useRouter();

    const state = reactive({
      isValidUserId: true,
      isValidEmail: true,
      loading: false,
      showAlart: false,
      alartTitle: "アカウントの作成に失敗しました",
      alartBody: "",
      decryptedEmail: "",
      email: "",
      role: ""
    });

    const { getDecryptionKey } = useUserRepository();

    if (document.location.search) {
      const encryptedRole = document.location.search.split("?role=")[1].split("?mail=")[0];
      const encryptedMail = document.location.search.split("?mail=")[1];
      const mailSalt = "LNfHIUMEalbyRMzQoLMLU";
      onBeforeMount(async () => {
        state.decryptedEmail = crypto.AES.decrypt(encryptedMail, mailSalt).toString(crypto.enc.Utf8);
        const salt = await getDecryptionKey({ email: state.decryptedEmail });
        state.role = crypto.AES.decrypt(encryptedRole, salt).toString(crypto.enc.Utf8);
        state.email = state.decryptedEmail;
      });
    }

    const initialValues = reactive({
      lastName: "",
      firstName: "",
      username: "",
      email: "",
      password: "",
    });

    const schema = yup.object().shape({
      lastName: yup.string().required().max(32).label("姓"),
      firstName: yup.string().required().max(32).label("名"),
      username: yup
        .string()
        .required()
        .max(32)
        .matches(
          /^[a-zA-Z0-9_-]+$/,
          "半角英数字・ﾊｲﾌﾝ・ｱﾝﾀﾞｰﾊﾞｰのみ使用できます"
        )
        .label("ユーザ名"),
      email: yup.string().required().email().label("メールアドレス"),
      password: yup.string().required().min(8).label("パスワード"),
    });

    const userId = computed({
      get: () => {
        const userId = state.email.split("@")[0];
        return userId;
      },
      set: (newValue) => {
        const userId = newValue;
        return userId;
      }
    });

    const { checkSignup, createUserAfterSignup } = useUserRepository();

    const createAccount = async (values) => {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
        .then(async () => {

          const payload = {
            username: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            displayName: values.displayName,
            role: state.role == "admin" ? "admin" : "member"
          };

          await createUserAfterSignup(payload);

          // Update Authentication
          firebase.auth().currentUser.updateProfile({
            displayName: values.displayName,
          });

          return;
        })
        .catch((error) => {
          /**
           * Error Handling for createUserWithEmailAndPassword method.
           * https://firebase.google.com/docs/reference/js/firebase.auth.Auth#createuserwithemailandpassword
           */
          switch (error.code) {
            case "auth/email-already-in-use":
              state.alartBody = "すでに使用されているメールアドレスです";
              break;
            case "auth/invalid-email":
              state.alartBody = "無効なメールアドレスの形式です";
              break;
            case "auth/operation-not-allowed":
              state.alartBody = "メール/パスワード認証は無効になっています";
              break;
            case "auth/weak-password":
              state.alartBody = "パスワードの強度が十分ではありません";
              break;
            default:
              state.alartBody = "サービス管理者へお問い合わせください";
              break;
          }

          showAlart();
        });
      return;
    };

    const signup = async (values) => {
      state.loading = true;

      try {
        // Check sign-up parameter which is email and userId.
        const status = await checkSignup({ 
          email: values.email, 
          username: values.username 
        });

        const { isValidEmail, isValidUserId } = status;
        state.isValidEmail = isValidEmail;
        state.isValidUserId = isValidUserId;

        values.displayName = values.lastName + " " + values.firstName;
        // Register user in firebase authentication and create docs in firestore.
        if (isValidEmail && isValidUserId) {
          await createAccount(values).then(_ => {
            router.push({ name: "afterSignUp" });
          });
        }

        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.alartBody = "サインアップに失敗しました。";
        return showAlart();
      }
    };

    const showAlart = () => {
      state.showAlart = true;
      setTimeout(() => (state.showAlart = false), 5000);
    };

    return {
      state,
      initialValues,
      schema,
      signup,
      userId
    };
  },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply shadow appearance-none border rounded w-full p-4 bg-blue-500 hover:bg-blue-700 text-white font-bold focus:outline-none;
}

.field {
  @apply mb-6;
}

.field-label {
  @apply block mb-1 text-sm text-gray-500;
}

.field-item {
  @apply w-full p-3 text-gray-700 shadow border rounded;
}
</style>