<template>
  <div class="flex bg-gray-100">
    <!-- <the-sidebar :show-sidebar="showSidebar" @toggle-sidebar="toggleSidebar" /> -->
    <div class="flex flex-col w-screen h-screen">
      <header-top />
      <main class="pt-4 mx-16 overflow-y-scroll">
        <h1 class="mb-4 font-mono text-xl font-bold text-gray-700">
          タグ<span class="text-gray-400">(工事中)</span>
        </h1>
      </main>
    </div>
  </div>

  <the-hamburger :show-sidebar="showSidebar" @toggle-sidebar="toggleSidebar" />
</template>

<script>
import { ref } from "vue";
import TheHamburger from "../organisms/utilities/TheHamburger.vue";
// import TheSidebar from "../organisms/TheSidebar.vue"
import HeaderTop from "../organisms/TheHeaderTop.vue";

export default {
  components: {
    TheHamburger,
    // TheSidebar,
    HeaderTop,
  },

  setup() {
    let showSidebar = ref(true);

    const toggleSidebar = () => {
      showSidebar.value = !showSidebar.value;
    };

    return {
      showSidebar,
      toggleSidebar,
    };
  }
};
</script>