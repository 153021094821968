import { createLogger, createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'

import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import search from "@/store/modules/search";
import board from "@/store/modules/board";
import upload from "@/store/modules/upload";

const persitedStates = createPersistedState({
  key: "",
  paths: "",
  storage: window.sessionStorage
});

export default createStore({
  modules: {
    auth,
    user,
    search,
    board,
    upload
  },
  plugins: process.env.VUE_APP_NODE_ENV === 'development' ? [
    createLogger({
      actionFilter(action, state) {
        // same as `filter` but for actions
        // `action` is a `{ type, payload }`
        return false
      },
    }),
    persitedStates
  ] : [
    // persitedStates
  ]
});