<template>
  <mail-verify-signup-template :email="state.email" />
</template>

<script>
import firebase from "firebase/app";
import { reactive } from "vue"
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const UserRepository = RepositoryFactoryForGae.get("user");
import MailVerifySignupTemplate from "../templates/MailVerifySignupTemplate.vue";

export default {
  components: {
    MailVerifySignupTemplate,
  },

  setup() {
    const currentUser = firebase.auth().currentUser

    const state = reactive({
      email: currentUser.email
    })

    const sendVerifyMail = async () => {
      const redirectUrl = location.origin;
      await UserRepository.sendVerificationEmail({
        email: state.email,
        redirect_url: redirectUrl
      })
      .then(() => {
        firebase.auth().signOut();
      })
      .catch((e) => {
        console.error(e.message)
      });
    }

    if (!currentUser.emailVerified) {
      sendVerifyMail()
    }

    return {
      state
    }
  }
};
</script>