<template>
  <div class="flex bg-gray-100">
    <div class="flex flex-col w-screen h-screen">
      <header-top />
      <div class="wrapper">
        <the-sidebar @show-alart="showAlart" class="sidebar" />
        <main class="px-8 pt-4 overflow-y-scroll main">
          <!-- tab section -->
          <div class="mb-4">
            <ul class="flex border-b cursor-pointer list-reset">
              <li
                @click="state.selectedTab = 'package'; $router.push({name: 'trend', query: {q: 'package'}})"
                class="mr-1 -mb-px"
              >
                <div
                  :class="state.selectedTab == 'package' ?
                    'bg-white border-l border-t border-r text-black' : 'bg-gray-200'
                  "
                  class="inline-block px-4 py-2 font-semibold text-gray-400 rounded-t"
                >
                  Packages
                </div>
              </li>
              <li
                @click="state.selectedTab = 'slide'; $router.push({name: 'trend', query: {q: 'slide'}})"
                class="mr-1 -mb-px"
              >
                <div
                  :class="state.selectedTab == 'slide' ?
                    'bg-white border-l border-t border-r text-black' : 'bg-gray-200'
                  "
                  class="inline-block px-4 py-2 font-semibold text-gray-400 rounded-t"
                >
                  Slides
                </div>
              </li>
            </ul>
          </div>
          <div style="min-height: 620px">
            <the-loader v-if="loading" />
            <top-main-content v-show="!loading" :files="files" />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useRoute } from "vue-router"
import { useStore } from "vuex";
import alart from "../organisms/composables/alart";
import HeaderTop from "../organisms/TheHeaderTop.vue";
import TheSidebar from "../organisms/TheNewSidebar.vue";
import TopMainContent from "../organisms/TopMainContent.vue";
import TheLoader from "../organisms/utilities/LoaderFiles.vue";

export default {
  components: {
    HeaderTop,
    TopMainContent,
    TheLoader,
    TheSidebar
  },

  props: {
    files: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      selectedTab: route.query.q === 'slide' ? 'slide' : 'package',
    })

    const { alartState, showAlart } = alart(); 

    return {
      state,
      alartState,
      showAlart
    };
  }
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: "areaA areaB";
  height: calc(100% - 116px);
}

.sidebar {
  grid-area: areaA;
}

.main {
  grid-area: areaB;
}
</style>