<template>
  <base-simple-modal
    :show-modal="true"
    @close-modal="$emit('closeModal')"
  >
    <template v-slot:content>
      <div
        class="px-4 py-3 text-yellow-600 bg-white border-t-4 border-yellow-500 rounded shadow-lg"
        role="alert"
      >
        <div class="flex px-4">
          <div class="py-1">
            <svg
              class="w-6 h-6 mr-4 text-yellow-500 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
              />
            </svg>
          </div>
          <div>
            <p class="font-bold">非公開ボードにファイルを追加してよろしいですか？</p>
            <p class="text-sm">非公開ボードに属しているユーザが閲覧できるようになります。</p>
          </div>
        </div>
        <div class="flex items-center justify-center pt-2">
          <button @click="$emit('addPin')" class="rounded mx-4 py-0.5 w-28 bg-yellow-500 text-white hover:bg-yellow-600">はい</button>
          <button @click="$emit('closeModal')" class="rounded mx-4  py-0.5 text-white w-28 bg-gray-500 hover:bg-gray-600">キャンセル</button>
        </div>
      </div>
    </template>
  </base-simple-modal>
</template>

<script>
import BaseSimpleModal from "./BaseSimpleModal.vue";

export default {
  components: {
    BaseSimpleModal,
  },

  emits: [
    'closeModal',
    'addPin'
  ]
};
</script>