<template>
  <div class="max-mobile-height">
    <div class="fixed inset-0 z-40">
      <!-- ヘッダー -->
      <div class="top-0 flex items-center justify-between w-full h-12 bg-gray-100 bg-opacity-100 items-between filter drop-shadow-xl">
        <div class="mx-1">
          <div v-if="packageInfo.extension.pptx" class="header-extention">PPTX</div>
          <div v-if="packageInfo.extension.pdf" class="header-extention">PDF</div>
        </div>
        <p class="left-0 right-0 m-auto max-w-xs text-md font-bold text-center text-gray-700"> {{ packageInfo.fileTitle }} </p>
        <button
          @click="closeModal($event)"
          class="flex items-center justify-center mx-4 transition duration-100 transform bg-white border border-gray-400 rounded-full shadow-2xl w-7 h-7 hover:scale-105 hover:rotate-90"
        >
          <close-icon class="w-7 h-7" />
        </button>
      </div>
      <div
        class="relative flex flex-col items-center max-h-screen"
        id="modal-content"
      >
        <!-- スライド画像 -->
        <div
          id="scrollable"
          class="w-screen overflow-y-auto"
          :class="{
            fadecenter: fadeMode == 'center',
            fadeleft: fadeMode == 'left',
            faderight: fadeMode == 'right',
          }"
          v-on:click.self="closeModal($event)"
        >
          <div class="mx-2">
            <div
              class="border-b-2 border-l-2 border-r-2 rounded-b vh-heiht"
            ></div>

            <div
              class="relative mt-7 preview-image"
              v-for="(children_id, index) in state.packageInfo.sorted_children_ids"
              :key="children_id"
              :id="children_id"
              @mouseenter="state.showSlideMenu = true; state.slideMenuIndex = index"
              @mouseleave="!(state.mode === 'favorite' && state.pinInfo.target === 'slide') ? state.showSlideMenu = false : state.showSlideMenu = true"
            >
              <div class="relative">
                <img
                  id="modal-slide-image"
                  class="object-contain mx-auto border border-gray-300 border-opacity-25"
                  width="1"
                  height="1"
                  v-lazy="`${state.baseUrl}${children_id}.jpg`"
                  alt="slide"
                  style="width: 100%; height: auto; max-height: 85vh;"
                />
                <div class="absolute top-0 right-0" v-show="state.showSlideMenu && state.slideMenuIndex === index">
                  <div class="flex items-center justify-center text-white bg-black bg-opacity-20 backdrop-filter backdrop-blur">
                    <!-- サムネイル設定 -->
                    <button 
                      @click.stop="setPackageHeader(children_id)"
                      :disabled="!isCreatedUser"
                      class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <pin-icon class="w-8 h-8" />
                    </button>

                    <!-- ボードに追加 -->
                    <button 
                      @click.stop="state.pinInfo.target === 'package' ? state.mode = 'favorite' : state.mode == 'favorite' ? (state.mode = '') : (state.mode = 'favorite'); setPinInfo(children_id, 'slide')"
                      :disabled="!hasAddPinPermission"
                      class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <add-icon class="w-8 h-8" />
                    </button>
                    <pin-add-dropdown 
                      :show-download-menu="state.mode === 'favorite' && state.pinInfo.target === 'slide'"
                      :pin-info="state.pinInfo"
                      placement="bottom"
                    />

                    <!-- スライドダウンロード -->
                    <button 
                      @click.stop="downloadSlide(children_id)" 
                      class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white disabled:cursor-not-allowed" 
                      :disabled="!canDownload"
                    >
                      <download-icon class="w-8 h-8" />
                    </button>

                    <!-- URLコピー -->
                    <button @click.stop="copyToClipboard(children_id)" class="flex items-center justify-center w-12 h-12 hover:text-black hover:bg-white">
                      <clip-icon class="w-8 h-8" />
                    </button>
                  </div>
                </div>

                <!-- スタック -->
                <div v-show="state.showSlideMenu && state.slideMenuIndex === index" class="absolute bottom-2 right-2">
                  <button class="px-2 py-2 text-white bg-gray-600 rounded cursor-not-allowed">スタックに追加</button>
                </div>

                <!-- ラベル -->
                <div class="absolute bottom-0 ml-2 left-full" v-if="hasShowLabelPermission">
                  <div v-for="(label, index) in state.slideLables[children_id]" :key="`${children_id}#${index}`" class="mt-2">
                    <router-link 
                      :to="{ path: '/search/slides', query: { sort: 'created_timestamp', labels: label }}" 
                      class="px-2 py-1 text-sm bg-white rounded"
                    >
                      {{ label }}
                    </router-link>
                  </div>
                </div>
              </div>

              <!-- 紐づくボード -->
              <div class="flex items-center justify-start my-2">
                <div v-for="board in attachedBoardsWithSlide[children_id]" :key="board.id" class="mr-2">
                  <router-link :to="{ path: `/boards/${board.id}` }" class="px-2 py-1 text-xs bg-white rounded-full">
                    <span v-if="board.visibility === 'limited'">🔒 </span>#{{ board.name }}
                  </router-link>
                </div>
              </div>

              <loading
                v-model:active="state.showLoading"
                :is-full-page="false"
                color="#818589"
              />
            </div>
            <div class="pb-6"></div>
            <div
              class="border-t-2 border-l-2 border-r-2 rounded-t vh-margin-top vh-heiht"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed inset-0 z-30 bg-black bg-clip-padding backdrop-filter backdrop-blur bg-opacity-80"></div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { useStore } from "vuex";
import useFileRepository from "../../organisms/composables/useFileRepository";
import PinAddDropdown from "../../organisms/dropdowns/PinAddDropdown.vue";
import { reactive, onMounted, onBeforeMount, onBeforeUnmount, computed } from "vue";
import AddIcon from "../../icons/AddIcon.vue";
import CloseIcon from "../../icons/CloseIcon.vue";
import DownloadIcon from "../../icons/DownloadIcon.vue";
import PinIcon from "../../icons/MdiPinOutline.vue";
import ClipIcon from "../../icons/PhPaperclip.vue";
import utility from "../../organisms/composables/utility";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    PinAddDropdown,
    AddIcon,
    CloseIcon,
    DownloadIcon,
    PinIcon,
    ClipIcon,
    Loading
  },

  props: {
    anchor_point: {
      type: String,
      required: true,
    },
    packageInfo: {
      type: Object,
      required: true,
    },
    fadeMode: {
      type: String,
      default: "center",
    },
  },

  emits: ["toggle", "updatePackageInfo", "switchPackage", "deletePackageInfo"],

  setup(props, { emit }) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const store = useStore();
    const user = firebase.auth().currentUser;

    const state = reactive({
      baseUrl: location.origin + "/img/",
      loadedCount: 0,
      showLoading: false,
      showDownloadMenu: false,
      packageInfo: props.packageInfo,
      pinInfo: { id: '', permission: props.packageInfo.permission, target: '' },
      mode: "", // 'download', 'package', 'chart', 'commend', 'favorite'
      showSlideMenu: false,
      slideMenuIndex: null,
      slideLables: {}
    });

    const attachedBoardsWithSlide = computed(() => {
      const boards = store.state.board.myBoard.filter(board => {
        const memberIds = [...board.owners, ...board.members]
        return board.visibility === 'public' || (board.visibility === 'limited' && memberIds.includes(user.uid));
      });

      const data = props.packageInfo.children_ids.reduce((a, c) => {
        if (!(c in a)) {
          a[c] = boards.filter(board => board.pins.some(pin => pin.pin_id === c && pin.pin_type === 'slide'));
        }
        return a;
      }, {});

      return data;
    })

    const isCreatedUser = computed(() => {
      return props.packageInfo.created_by === user.uid;
    })

    const hasAddPinPermission = computed(() => { 
      return !(props.packageInfo.permission === 'limited' && props.packageInfo.created_by !== user.uid)
    });

    const hasShowLabelPermission = computed(() => {
      const isCreatedUser = props.packageInfo.created_by === user.uid;
      const isVisibleUser = props.packageInfo.visible_user.includes(user.uid);
      const isLimitedPackage = props.packageInfo.permission === 'limited';
      return !(!isCreatedUser && !isVisibleUser && isLimitedPackage);
    })

    const closeModal = () => {
      history.back();
      emit("toggle");
    };

    let actEle, scFlag = true;

    // shrotcut trigger
    const handleKeydown = (event) => {
      scFlag = false;
      switch (event.key) {
        case "Escape":
          closeModal();
          break;
        case "ArrowUp":
          if (actEle.previousElementSibling.id) {
            actEle = actEle.previousElementSibling;
            actEle.scrollIntoView({ block: "center", behavior: "auto" });
          }
          break;
        case "ArrowDown":
          if (actEle.nextElementSibling.id) {
            actEle = actEle.nextElementSibling;
            actEle.scrollIntoView({ block: "center", behavior: "auto" });
          }
          break;
        case "ArrowLeft":
          emit("switchPackage", "previous");
          break;
        case "ArrowRight":
          emit("switchPackage", "next");
          break;
      }
      setTimeout(() => { scFlag = true }, 1000);
    };

    /**
     * スクロール終了時に処理を実行する
     * 
     * スクロールイベントが連続して発火しても
     * 最後以外はクリアする。
     * 
     * Credits: https://lab.syncer.jp/Web/JavaScript/Snippet/46/
     */
    let timeoutId;
    const handleScroll = () => {
      if (scFlag) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          const st = document.getElementById('scrollable').scrollTop;
          const targets = Array.from(document.querySelectorAll('.preview-image'));
          actEle = targets.find(e => e.offsetTop > st).previousElementSibling;
        }, 500);
      }
    } 

    const handlePopstate = (e) => {
      emit('toggle');
    }

    onBeforeMount(() => {
      window.addEventListener("keydown", handleKeydown);
      window.addEventListener("popstate", handlePopstate);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("popstate", handlePopstate);
      window.removeEventListener("scroll", handleScroll);
    });

    const enableKeydown = () => {
      window.addEventListener("keydown", handleKeydown);
    }

    const disableKeydown = () => {
      window.removeEventListener("keydown", handleKeydown);
    }

    onMounted(async () => {
      actEle = document.getElementById(props.anchor_point);

      setTimeout(() => {
        actEle.scrollIntoView({ block: "center", behavior: "auto" });
      }, 10)

      setTimeout(() => {
        document.getElementById('scrollable').addEventListener("scroll", handleScroll);
      }, 30);
    });

    const getSlideLabels = async (slideId) => {
      const slideInfo = await getSlideInfo({ target: slideId });
      state.slideLables[slideId] = slideInfo.labels;
    };

    const updatePackageInfo = (newValue) => {
      Object.assign(state.packageInfo, newValue);
      const data = { newValue, packageId: state.packageInfo.packageId };
      emit("updatePackageInfo", data);
    };

    const deletePackageInfo = (packageId) => {
      emit("deletePackageInfo", packageId);
      closeModal();
    }

    const { getSlideInfo, downloadFile, updatePackageHeader } = useFileRepository();

    const canDownload = computed(() => {
      const permission = props.packageInfo?.permission;
      // return true if the permission is public.
      if (permission === "public") return true; 

      // permission is any other 'public' and the user is one of visible users.
      return props.packageInfo?.visible_user.includes(user.uid);
    })

    /// Slide Section ///
    const downloadSlide = async (slideId) => {
      // Before download
      state.showLoading = true;
      try {
        const slideInfo = await getSlideInfo({ target: slideId });
        const target = slideInfo.extension.pdf ? slideInfo.pdf_path : slideInfo.extension.pptx ? slideInfo.pptx_path : '';
        await downloadFile({ target, type: 'slide' });
      } finally {
        // After download
        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;
      }
    };

    const setPackageHeader = async (slideId) => {
      // Before update package header
      state.showLoading = true;
      try {
        await updatePackageHeader({ packageId: props.packageInfo.id, headerId: slideId });
        // After update package header
        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;

        await new Promise(resolve => setTimeout(resolve, 500));
        alert('パッケージのサムネイルを更新しました。')
      } catch(error) {
        console.error(error);

        await new Promise(resolve => setTimeout(resolve, 100));
        state.showLoading = false;
      }
    }

    const { copyToClipboard } = utility();

    const setPinInfo = (id, target) => {
      state.pinInfo.id = id;
      state.pinInfo.target = target;
    }

    return {
      state,
      attachedBoardsWithSlide,
      isCreatedUser,
      hasAddPinPermission,
      hasShowLabelPermission,
      closeModal,
      getSlideLabels,
      updatePackageInfo,
      enableKeydown,
      disableKeydown,
      deletePackageInfo,
      canDownload,
      downloadSlide,
      setPackageHeader,
      copyToClipboard,
      setPinInfo
    };
  },
};
</script>

<style scoped>
*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 13px;
  background-color: #fff;
  box-shadow: inset 0 0 10px 10px #909090;
  border: solid 4px transparent;
}

.fadecenter {
  animation-name: fadecenter;
  animation-duration: 0.5s;
}

.fadeleft {
  animation-name: fadeleft;
  animation-duration: 0.5s;
}

.faderight {
  animation-name: faderight;
  animation-duration: 0.5s;
}

.vh-margin-top {
  margin-top: 4vh;
}

.vh-heiht {
  height: 8vh;
}

@keyframes fadecenter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeleft {
  from {
    opacity: 0;
    transform: translateX(150px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes faderight {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

<style lang="postcss" scoped>
.header-extention {
  @apply inline-flex items-center px-3 py-1 mx-1 text-xs font-bold text-gray-700 bg-gray-200 rounded-lg;
}
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>