<template>
  <div class="grid gap-6 row lg:grid-cols-3 md:grid-cols-2">
    <div class="bg-white border rounded animate-pulse " v-for="item in [1, 2, 3, 4, 5, 6, 7]" :key="item">
      <a>
        <div class="flex rounded">
          <div>
            <div class="w-40 bg-gray-300 aspect-w-16 aspect-h-9"></div>
          </div>
          <div class="flex justify-between w-full px-2 py-1">
            <div class="w-4/5">
              <div class="w-2/5 h-4 mb-1 bg-gray-300 rounded title"></div>
              <div class="h-4 mb-1 bg-gray-300 rounded description w-5/5"></div>
              <div class="w-3/5 h-4 bg-gray-300 rounded description"></div>
            </div>
            <div class="1/5">
              <div class="w-10 h-4 mb-1 bg-gray-300 rounded"></div>
              <div class="w-10 h-4 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>