<template>
  <div class="flex flex-col w-screen max-mobile-height bg-gray-100">
    <header-top
      @get-text="getFirstPage"
    />
    <main class="pt-3 overflow-y-scroll h-screen">
      <div v-if="states.text">
        <search-result
          :files="state.hits"
          :fileType="state.fileType"
          :searchText="states.text"
          :nbHits="state.nbHits"
          @changeType="changeSearchType"
          class="mb-5"
        />
        <infinite-loading
          v-if="showSearchLoading"
          spinner="bubbles"
          @infinite="algoliaInfiniteHandler"
        >
          <template #no-more>全{{ state.nbHits }}件</template>
          <template #no-results>全{{ state.nbHits }}件</template>
        </infinite-loading>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import firebase from "firebase/app";
import _ from "lodash";
import HeaderTop from "../organisms/HeaderTop.vue";
import Footer from "../organisms/Footer.vue";
import SearchResult from "../organisms/SearchResult.vue";
import infiniteLoading from "vue-infinite-loading";
import useSearchRepository from "../../organisms/composables/useSearchRepository";
import useUserRepository from "../../organisms/composables/useUserRepository";

export default {
  components: {
    HeaderTop,
    Footer,
    SearchResult,
    infiniteLoading
  },
  setup () {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const user = firebase.auth().currentUser;
    const states = reactive({
      text: "",
      changedType: ""
    });
    const {
      state,
      showSearchLoading,
      payload,
      searchPackagesFromAlgolia,
      searchSlidesFromAlgolia,
      searchBoardsFromAlgolia,
      searchUsersFromAlgolia,
      getPackageInfo,
      getSlideInfo,
      algoliaInfiniteHandler
    } = useSearchRepository();
    const { createSearchApiKey } = useUserRepository();
    const store = useStore();

    const intervalTime = 270;
    const retryErrorStatus = [400, 403];
    const getFirstPage = _.debounce(async (text, type) => {
      state.hits = [];
      states.text = text;
      state.fileType = state.changedType ? state.changedType : type ?? "packages";
      state.pageCnt = 0;
      state.nbHits = "-";
      const userInfo = store.state.user.userInfo;
      const filesApiKey = userInfo.algolia?.files_api_key ?? "";
      const boardsApiKey = userInfo.algolia?.boards_api_key ?? "";
      const usersApiKey = userInfo.algolia?.users_api_key ?? "";
      if (!text) {
        state.changedType = "";
        return;
      }

      Object.assign(payload, { text, filesApiKey, boardsApiKey, usersApiKey });
      if (state.fileType == "packages") {
        await searchPackages();
        if (state.hits.length != 0) {
          await getPackageInfo(state.hits);
        }
      }
      if (state.fileType == "slides") {
        await searchSlides();
        if (state.hits.length != 0) {
          await getSlideInfo(state.hits);
        }
      }
      if (state.fileType == "boards") {
        await searchBoards();
      }
      if (state.fileType == "users") {
        await searchUsers();
      }
    }, intervalTime);
    const searchPackages = async () => {
      await searchPackagesFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.filesApiKey = data.files_api_key;
          payload.usersApiKey = data.users_api_key;
          await searchPackagesFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          store.state.user.userInfo.algolia = {
            files_api_key: payload.apiKey
          };
        });
    };
    const searchSlides = async () => {
      await searchSlidesFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.filesApiKey = data.files_api_key;
          await searchSlidesFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          store.state.user.userInfo.algolia = {
            files_api_key: payload.filesApiKey
          };
        });
    };
    const searchBoards = async () => {
      await searchBoardsFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.boardsApiKey = data.boards_api_key;
          await searchBoardsFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          store.state.user.userInfo.algolia = {
            boards_api_key: payload.boardsApiKey
          };
        });
    };
    const searchUsers = async () => {
      await searchUsersFromAlgolia(payload)
        .then(res => {
          state.hits = res;
        })
        .catch(async e => {
          state.pageCnt = 0;
          if (!retryErrorStatus.find(status => status == e.status))
            return;
          const { data } = await createSearchApiKey({
            user_id: user.uid
          });
          payload.usersApiKey = data.users_api_key;
          await searchUsersFromAlgolia(payload)
            .then(res => {
              state.hits = res;
            })
            .catch(e => {
              throw new Error(e);
            });
          store.state.user.userInfo.algolia = {
            users_api_key: payload.usersApiKey
          };
        });
    };

    const changeSearchType = async (text, type) => {
      state.changedType = type;
      await getFirstPage(text, state.changedType);
    };

    return {
      states,
      state,
      showSearchLoading,
      getFirstPage,
      algoliaInfiniteHandler,
      changeSearchType
    };
  }
}
</script>

<style lang="postcss" scoped>
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
li {
  @apply inline-block mx-2
}
</style>
