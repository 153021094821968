<template>
  <board-top-template />
</template>

<script>
import firebase from "firebase/app";
import { useStore } from "vuex";
import useUserRepository from "../organisms/composables/useUserRepository";
import useBoardRepository from "../organisms/composables/useBoardRepository";
import BoardTopTemplate from '@/components/templates/BoardTopTemplate'

export default {
  components: {
    BoardTopTemplate
  },

  setup() {
    const store = useStore();
    const user = firebase.auth().currentUser;

    // Set board data in global state(vuex).
    const userInfo = store.state.user.userInfo;

    const { getMyBoard, getPublicBoard } = useBoardRepository();
    getMyBoard({ workSpace: userInfo.workSpace, userId: user.uid });
    getPublicBoard({ workSpace: userInfo.workSpace });

    const { getUsers } = useUserRepository();
    getUsers({ workSpace: userInfo.workSpace });
  }
};
</script>