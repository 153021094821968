<template>
  <signup-template />
</template>

<script>
import SignupTemplate from '@/components/templates/SignupTemplate'

export default {
  components: { 
    SignupTemplate 
  },
};
</script>