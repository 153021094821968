<template>
  <div class="flex-auto search-form">
    <div
      class="flex h-10 bg-gray-100 border rounded-lg hover:shadow-lg"
      v-bind:class="{ 'search-focus': state.focus }"
    >
      <div
        v-if="state.isInstantSearch"
        class="flex items-center h-full px-2 overflow-hidden transition rounded-lg"
      >
        <search-icon class="transition fill-current w-7 h-7 group-hover:text-blue-500" />
      </div>
      <div class="flex items-center w-full">
        <input
          :placeholder="state.isInstantSearch ? 'リアルタイムで検索' : '検索したいワードを入力...'"
          id="search-form"
          class="w-full h-full pl-3 bg-gray-100 rounded-lg focus:outline-none"
          type="text"
          autocomplete="off"
          v-model="state.text"
          v-on:keypress.enter="!state.isInstantSearch ? search() : ''"
          v-on:focus="focused"
          v-on:blur="state.focus = false"
          @input="inputText"
        />
        <button
          v-if="state.text"
          @click="clearText"
          class="mr-2"
        >
          <clear-text-icon />
        </button>
        <div
          class="flex items-center h-full overflow-hidden transition bg-gray-200 rounded-r-lg group"
        >
          <button
            v-if="!state.isInstantSearch"
            v-on:click="search()"
            class="h-full mx-2"
          >
            <search-icon class="transition fill-current w-7 h-7 group-hover:text-blue-500" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch, nextTick, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import SearchIcon from "../icons/SearchIcon.vue";
import ClearTextIcon from "../icons/ClearTextIcon.vue";
import useSearchRepository from "../organisms/composables/useSearchRepository";

export default {
  components: {
    SearchIcon,
    ClearTextIcon,
  },

  setup(_, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      type: route.path.includes("/slides") ? "slides" : "packages",
      text: route.query?.q ?? "",
      focus: false,
      isInstantSearch: route.path == "/search" ? true : false
    });
    const {
      setKeyPressWord
    } = useSearchRepository();

    onBeforeMount(() => {
      state.text = store.state.search.keyPressWord;
    });

    watch(() => route.path, () => {
      nextTick(() => {
        setKeyPressWord("");
        state.text = "";
      });
    });

    const changeSearchType = async type => {
      state.type = type;
      if (!state.text) return;
      emit("get-text", state.text, state.type);
    };
    const focused = async () => {
      state.focus = true;
    };
    const search = async () => {
      const path = "/search";
      setKeyPressWord(state.text);
      // full space chage to half space
      router.push({
        path: path,
        name: "SearchTop",
        params: {
          type: state.type ?? "packages",
          word: state?.text?.replace(/\u3000/g, " ")
        }
      });
    };
    const inputText = target => {
      // IME対応
      if (target.inputType == "insertCompositionText") {
        state.text = target.data;
        return;
      }
      if (state.isInstantSearch && target.data != "　" && target.data != " ") {
        emit("get-text", state.text, state.type);
      }
    };
    const clearText = () => {
      state.text = "";
      setKeyPressWord("");
      emit("get-text", state.text);
    };

    return {
      state,
      changeSearchType,
      focused,
      search,
      inputText,
      clearText
    };
  },
};
</script>

<style lang="postcss" scoped>
.search-focus {
  @apply ring-2 ring-indigo-400;
}
</style>