<template>
  <top-ranking-template />
</template>

<script>
import TopRankingTemplate from "../templates/TopRankingTemplate.vue"

export default {
  components: { 
    TopRankingTemplate
  },
};
</script>