<template>
  <button
    :class="buttonClass"
    type="button"
  >
    {{ buttonTitle }}
  </button>
</template>

<script>
import { toRefs, computed } from 'vue'

export default {
  props: {
    title: { 
      type: String, 
      required: true 
    },
    className: {
      type: String, 
      required: true 
    }
  },

  setup(props) {
    const { title, className } = toRefs(props)
    const buttonTitle = computed(() => title.value);
    const buttonClass = computed(() => className.value);

    return {
      buttonTitle,
      buttonClass
    }
  }
};
</script>