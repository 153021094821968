export default {
  namespaced: true,
  state: {
    showModal: false
  },

  mutations: {
    setModalFlag(state, flag) {
      state.showModal = flag;
    }
  },

  actions: {
    openModal({ commit }) {
      commit('setModalFlag', true);
    },
    closeModal({ commit }) {
      commit('setModalFlag', false);
    },
  }
};
