import { computed, reactive } from "vue";
import { useStore } from "vuex"
import { RepositoryFactory } from "@/api/functions/RepositoryFactory";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
import algolia from "algoliasearch";
const SearchRepository = RepositoryFactory.get("search");
const SearchRepositoryForGae = RepositoryFactoryForGae.get("search");
const FileRepositoryForGae = RepositoryFactoryForGae.get("file");

export default function () {
  const store = useStore();
  const state = reactive({
    algoliaClient: {},
    files: [],
    hits: [],
    fileType: 'package',
    boards: [],
    limit: 0,
    pageCnt: 0,
    nbHitsPerPage: 0,
    nbHits: 0,
    filters: ""
  });

  const setKeyPressWord = word => {
    return store.dispatch("search/setKeyPressWord", word);
  };

  const payload = reactive({});

  const showInfiniteLoading = computed(() => {
    return state.files.length >= state.limit
  });
  const showSearchLoading = computed(() => {
    return state.hits.length >= 1
  });

  const tokenize = async (payload) => {
    const res = await SearchRepository.tokenize(payload).json();
    return res.data.tokens;
  }

  /// Package ///
  const searchPackagePayload = reactive({ sort: 'created_timestamp', text: '', permission: '', format: '', createdBy: '', deleted: false, limit: 24, cursor: '', tokens: [] });
  const searchPackage = async (payload) => {
    const res = await SearchRepositoryForGae.package(payload).json();
    return res.data;
  };
  const searchPackagesFromAlgolia = async (payload) => {
    state.algoliaClient = algolia(process.env.VUE_APP_ALGOLIA_APP_ID, payload.filesApiKey);
    const index = state.algoliaClient.initIndex("packages");
    const results = await index.search(payload.text, {
      filters: state.filters,
      page: state.pageCnt
    }).catch(e => {
      throw e;
    });
    state.nbHitsPerPage = results.hitsPerPage;
    state.nbHits = results.nbHits;
    return results.hits;
  };
  const getPackageInfo = async hits => {
    return hits.map(async hit => {
      const packageInfo = await FileRepositoryForGae.getPackageInfo({
        target: hit.id
      }).json();
      Object.assign(hit, {
        type: "package",
        user: packageInfo.user,
        packageId: packageInfo.packageId,
        slideId: packageInfo.slideId,
        pageview: packageInfo.pageview,
        children_ids: packageInfo.children_ids,
        children_ids_number: packageInfo.children_ids_number
      });
    });
  };
  const deletePackageInfo = (packageId) => {
    state.files = state.files.filter(file => file.packageId != packageId);
  };
  const updatePackageInfo = (data) => {
    state.files = state.files.map((file) => {
      if (file.packageId !== data.packageId) return file;
      return Object.assign(file, data.newValue);
    });
  };

  /// Slide ///
  const searchSlidePayload = reactive({ sort: 'created_timestamp', text: '', permission: '', format: '', createdBy: '', deleted: false, limit: 24, cursor: '', labels: [], tokens: [] });
  const searchSlide = async (payload) => {
    if (typeof payload.labels === "string" && payload.labels) payload.labels = [payload.labels];
    const res = await SearchRepositoryForGae.slide(payload).json();
    const result = res.data.map(slide => {
      return Object.assign(slide.packageInfo, { 
        packageId: slide.parentId,
        slideId: slide.slideId,
        user: slide.user,
        slide_number: slide.slide_number
      });
    });
    return result;
  };
  const searchSlidesFromAlgolia = async (payload) => {
    state.algoliaClient = algolia(process.env.VUE_APP_ALGOLIA_APP_ID, payload.filesApiKey);
    const index = state.algoliaClient.initIndex("slides");
    const results = await index.search(payload.text, {
      filters: state.filters,
      page: state.pageCnt
    });
    state.nbHitsPerPage = results.hitsPerPage;
    state.nbHits = results.nbHits;
    return results.hits;
  };
  const getSlideInfo = async hits => {
    return hits.map(async hit => {
      const slideInfo = await FileRepositoryForGae.getSlideInfo({
        target: hit.id
      }).json();
      Object.assign(hit, {
        type: "slide",
        user: slideInfo.user,
        packageId: slideInfo.packageId,
        slideId: slideInfo.slideId,
        children_ids: slideInfo.children_ids,
        children_ids_number: slideInfo.children_ids_number,
      });
    });
  };

  /// Boards ///
  const searchBoardsFromAlgolia = async payload => {
    state.algoliaClient = algolia(process.env.VUE_APP_ALGOLIA_APP_ID, payload.boardsApiKey);
    const index = state.algoliaClient.initIndex("boards");
    const results = await index.search(payload.text, {
      page: state.pageCnt
    });
    state.nbHitsPerPage = results.hitsPerPage;
    state.nbHits = results.nbHits;
    return results.hits;
  };

  /// Users ///
  const searchUsersFromAlgolia = async payload => {
    state.algoliaClient = algolia(process.env.VUE_APP_ALGOLIA_APP_ID, payload.usersApiKey);
    const index = state.algoliaClient.initIndex("users");
    const results = await index.search(payload.text, {
      page: state.pageCnt
    });
    state.nbHitsPerPage = results.hitsPerPage;
    state.nbHits = results.nbHits;
    return results.hits;
  };

  /// Infinite Loading ///
  const infiniteHandler = async ($state) => {
    let result;
    if (state.fileType === "package") {
      result = await searchPackage(payload)
      state.limit = payload.limit;
    } else if (state.fileType === "slide") {
      result = await searchSlide(payload)
      state.limit = payload.limit;
    }

    state.files = [...state.files, ...result]
    if (result.length == state.limit) {
      $state.loaded();
    } else {
      $state.complete();
    }
  };
  const algoliaInfiniteHandler = async ($state) => {
    state.pageCnt += 1;
    let hits;
    if (state.fileType === "packages") {
      hits = await searchPackagesFromAlgolia(payload);
      state.hits = [...state.hits, ...hits];
      await getPackageInfo(state.hits)
        .then(async () => {
          await addBoardsToFile();
        });
    } else if (state.fileType === "slides") {
      hits = await searchSlidesFromAlgolia(payload);
      state.hits = [...state.hits, ...hits];
      await getSlideInfo(state.hits)
        .then(async () => {
          await addBoardsToFile();
        });
    } else if (state.fileType === "boards") {
      hits = await searchBoardsFromAlgolia(payload);
      state.hits = [...state.hits, ...hits];
    } else if (state.fileType === "users") {
      hits = await searchUsersFromAlgolia(payload);
      state.hits = [...state.hits, ...hits];
    }
    if (hits.length == state.nbHitsPerPage) {
      $state.loaded();
    } else {
      $state.complete();
    }
  };
  const addBoardsToFile = async () => {
    return state.hits.map(hit => {
      const boards = store.state.board.myBoard.filter(board =>
        board.pins.some(pin =>
          pin.pin_id === hit.id
        )
      );
      return Object.assign(hit, { boards });
    });
  };

  /// Audit Log ///
  const saveSearchAuditLog = async (payload) => {
    await SearchRepositoryForGae.saveSearchAuditLog(payload);
  };

  return {
    state,
    payload,
    showInfiniteLoading,
    showSearchLoading,
    searchPackagePayload,
    searchSlidePayload,
    setKeyPressWord,
    tokenize,
    searchPackage,
    searchPackagesFromAlgolia,
    getPackageInfo,
    searchSlide,
    searchSlidesFromAlgolia,
    getSlideInfo,
    searchBoardsFromAlgolia,
    searchUsersFromAlgolia,
    deletePackageInfo,
    updatePackageInfo,
    infiniteHandler,
    algoliaInfiniteHandler,
    addBoardsToFile,
    saveSearchAuditLog
  }
}