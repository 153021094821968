import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepositoryForGae = RepositoryFactoryForGae.get("file");

export default function () {
  const getSlideInfo = async (payload) => {
    return await FileRepositoryForGae.getSlideInfo(payload).json();
  }

  const getDownloadUrl = async (payload) => {
    const res = await FileRepositoryForGae.getDownloadUrl(payload).json();
    return res.url;
  }
  
  const downloadFile = async (payload) => {
    return await getDownloadUrl(payload)
      .then(url => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
      });
  }

  const updatePackageHeader = async (payload) => {
    return await FileRepositoryForGae.updatePackageHeader(payload).json();
  }

  const incrementPageview = async (payload) => {
    return await FileRepositoryForGae.incrementPageview(payload).json();
  }

  return {
    getSlideInfo,
    getDownloadUrl,
    downloadFile,
    updatePackageHeader,
    incrementPageview
  }
}