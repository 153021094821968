<template>
  <div class="bg-indigo-50">
    <div
      class="container flex flex-col items-center justify-center h-screen mx-auto"
    >
      <div
        class="px-8 py-6 bg-white rounded-lg shadow-lg"
        style="width: 640px;"
      >
        <header class="my-4">
          <router-link
            to="/"
          >
            <img
              class="mx-auto"
              src="../../assets/spitch-logo.svg"
              width="200"
              alt="logo"
            />
          </router-link>
        </header>
        <div class="my-4">
          <div class="my-8">
            <h1 class="text-center text-gray-600">
              メールアドレスの確認がお済みでありません！
            </h1>
            <img
              class="mx-auto my-4"
              src="../../assets/undraw_subscribe_vspl.svg"
              width="250"
              alt=""
            />
          </div>
          <div class="mb-8 description">
            <p class="mb-2 text-gray-700 break-words">
              ご登録いただいた
              <span class="font-bold">
                「{{ state.email }}」
              </span>
              宛に確認用のご案内をお送りします。
            </p>
            <p class="mb-2 text-gray-700 break-words">
              <b>アカウント登録後1分以上経過してから</b>、下記のボタンよりメールを再送信してください。
            </p>
            <ul class="text-sm text-gray-400">
              <li>
                ※上記の確認作業が完了しないと、本サービスをご利用できません。
              </li>
              <li>
                ※受信箱にメールがない場合は迷惑メールをご確認ください。
              </li>
            </ul>
          </div>
          <div class="mb-8 text-center button">
            <button
              class="p-2 mt-1 text-center text-white transition-colors duration-300 bg-indigo-500 border border-indigo-600 border-solid rounded md:mx-2 md:p-4 hover:bg-indigo-700"
              @click="resendVerifyMail"
            >
              メールを送信する
            </button>
          </div>
        </div>
      </div>
    </div>
    <success-modal :showModal="state.showModal" @closeModal="closeModal" />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { reactive } from "vue";
import SuccessModal from "../organisms/modals/SuccessMailModal.vue";

export default {
  components: {
    SuccessModal
  },

  props: {
    resend: {
      type: Function,
      required: true
    }
  },

  setup(props) {
    const currentUser = firebase.auth().currentUser;
    const state = reactive({
      showModal: false,
      email: currentUser.email
    });

    const closeModal = () => {
      state.showModal = false;
    };

    const resendVerifyMail = async () => {
      await props.resend().then(() => (state.showModal = true));
    };

    return {
      state,
      resendVerifyMail,
      closeModal
    };
  }
};
</script>