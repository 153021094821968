import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
const resource = "trend";
export default {
  package() {
    return Repository.get(`${version}/${resource}/package`);
  },

  slide() {
    return Repository.get(`${version}/${resource}/slide`);
  },
};