<template>
  <div class="flex bg-gray-100">
    <div
      v-if="deviceType == 'pc'"
      class="flex flex-col w-screen h-screen"
    >
      <header-top />
      <div class="wrapper">
        <the-sidebar @show-alart="showAlart" class="sidebar" />
        <main
          class="h-full px-8 pt-4 overflow-x-hidden overflow-y-scroll scrollbar main"
          :class="{
            close: !state.showPreviewModal,
            open: state.showPreviewModal
          }"
        >
          <Suspense>
            <template #default>
              <board-detail-contents
                @toggle-show-preview-modal="
                  state.showPreviewModal = !state.showPreviewModal
                "
              />
            </template>
            <template #fallback>
              <loader-pin />
            </template>
          </Suspense>
        </main>
      </div>
    </div>
    <div
      v-if="deviceType == 'mobile'"
      class="flex flex-col w-screen max-mobile-height"
    >
      <mobile-header-top />
      <main
        class="py-4 bg-gray-100 max-mobile-height"
        :class="{
          close: !state.showPreviewModal,
          open: state.showPreviewModal
        }"
      >
        <the-sidebar-board
          :show-sidebar="showSidebar"
          @show-alart="showAlart"
          class="fixed left-0 bg-white rounded shadow-lg top-32 h-4/5"
        />
        <Suspense>
          <template #default>
            <mobile-board-detail
              @toggle-show-preview-modal="
                state.showPreviewModal = !state.showPreviewModal
              "
            />
          </template>
          <template #fallback>
            <loader-pin />
          </template>
        </Suspense>
      </main>
      <Footer />
    </div>
    <success-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'success'"
      :title="alartState.alartMsg.success.title || 'ボードにファイルを追加しました！'"
      :body="alartState.alartMsg.success.body || '詳細はマイボードから確認してください。'"
    />
    <failure-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'failure'"
      :title="alartState.alartMsg.failure.title || 'ファイルを追加できませんでした！'"
      :body="alartState.alartMsg.failure.body || '詳細はマイボードから確認してください。'"
    />
    <warning-alart
      class="z-20"
      :show="alartState.showAlart && alartState.alartMode == 'warning'"
      :title="alartState.alartMsg.warning.title || '注意してください！'"
      :body="alartState.alartMsg.warning.body || '非公開ボードに属しているユーザが閲覧できるようになります。'"
    />
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import HeaderTop from "../organisms/TheHeaderTop.vue";
import BoardDetailContents from "../organisms/BoardDetailContents.vue";
import LoaderPin from "../organisms/utilities/LoaderPin.vue";
import TheSidebarBoard from "../organisms/TheSidebarBoard.vue";
import TheSidebar from "../organisms/TheNewSidebar.vue";
import SuccessAlart from "../organisms/alarts/SuccessAlart.vue";
import FailureAlart from "../organisms/alarts/FailureAlart.vue";
import WarningAlart from "../organisms/alarts/WarningAlart.vue";
import alart from "../organisms/composables/alart";
import MobileHeaderTop from "../mobile/organisms/HeaderTop.vue";
import MobileBoardDetail from "../mobile/templates/BoardDetail.vue";
import Footer from "../mobile/organisms/Footer.vue";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    HeaderTop,
    BoardDetailContents,
    LoaderPin,
    TheSidebarBoard,
    TheSidebar,
    MobileHeaderTop,
    MobileBoardDetail,
    Footer,
    SuccessAlart,
    FailureAlart,
    WarningAlart
  },

  setup() {
    const deviceType = calcBreakpoint(window.innerWidth);

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const store = useStore();

    const state = reactive({
      showPreviewModal: false
    });

    const showSidebar = computed(() => store.state.board.showSidebar);

    const openSidebar = () => {
      store.dispatch('board/openSidebar');
    }

    const { alartState, showAlart } = alart(); 

    return {
      deviceType,
      state,
      alartState,
      showSidebar,
      showAlart,
      openSidebar
    };
  }
};
</script>

<style scoped>
.scrollbar::-webkit-scrollbar-track {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 13px;
  background-color: #777;
  box-shadow: inset 0 0 10px 10px #909090;
  border: solid 4px transparent;
}

.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: "areaA areaB";
  height: calc(100% - 116px);
}

.sidebar {
  grid-area: areaA;
}

.main {
  grid-area: areaB;
}
</style>

<style lang="postcss" scoped>
.open {
  @apply overflow-y-hidden;
}
.close {
  @apply overflow-y-scroll;
}
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>