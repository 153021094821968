<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--ph"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 256"
  >
    <path
      d="M160 60H64a12.013 12.013 0 0 0-12 12v152a4 4 0 0 0 6.325 3.255l53.668-38.339l53.682 38.339A4 4 0 0 0 172 224V72a12.013 12.013 0 0 0-12-12zm4 156.228l-49.683-35.483a4 4 0 0 0-4.65 0L60 216.227V72a4.004 4.004 0 0 1 4-4h96a4.004 4.004 0 0 1 4 4zM204 40v152a4 4 0 0 1-8 0V40a4.004 4.004 0 0 0-4-4H88a4 4 0 0 1 0-8h104a12.013 12.013 0 0 1 12 12z"
      fill="currentColor"
    ></path>
  </svg>
</template>