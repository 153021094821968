import { reactive } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import sanitizeHtml from "sanitize-html";

export default function () {
  const store = useStore();
  const state = reactive({})

  const formatTime = x => {
    if (moment(x).isBetween(moment().subtract(1, "days"), moment())) {
      const diffTime = moment.duration(moment().diff(moment(x)));
      return diffTime.hours() < 1
        ? `${diffTime.minutes()} 分前`
        : `${diffTime.hours()} 時間前`;
    } else {
      return moment(x).format("M月D日 HH:mm");
    }
  };

  const filterBoardByKeyword = (boards, keyword) => {
    return boards.filter(x => {
      const isNameMatch = x.name.toLowerCase().includes(keyword.toLowerCase());
      const isDescMatch = x.description.toLowerCase().includes(keyword.toLowerCase());
      return isNameMatch || isDescMatch;
    });
  }

  const filterSlackChannelsByKeyword = (channels, keyword) => {
    return channels.filter(x => {
      const isNameMatch = x.name.toLowerCase().includes(keyword.toLowerCase());
      return isNameMatch;
    });
  }

  const filterPinByKeyword = (pins, keyword) => {
    return pins.filter(x => {
      /**
       * カタカナ濁音処理で正準等価のケースが存在した。
       * そのため、フィルターが正しく結果を返さないことがあり、
       * 'String.prototype.normalize()'で正規化する必要がある。
       * 
       * [参考]
       * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
       * https://qiita.com/jkr_2255/items/e0c039c438d3ebfd1a6a
       * 
       */
      const isNameMatch = x.packageInfo.fileTitle.normalize('NFC').toLowerCase().includes(keyword.toLowerCase());
      const isDescMatch = x.packageInfo.description.normalize('NFC').toLowerCase().includes(keyword.toLowerCase());
      return isNameMatch || isDescMatch;
    });
  }

  const onDragStart = (e, params) => {
    if (params.sortable) return;
    // store.dispatch("board/openSidebar");

    e.dataTransfer.dropEffect = "copy";
    e.dataTransfer.effectAllowed = "copy";

    e.dataTransfer.setData("fileType", params.fileType);
    e.dataTransfer.setData("fileId", params.fileId);
    e.dataTransfer.setData("permission", params.permission);
    e.dataTransfer.setData("createdBy", params.createdBy);

    // Crate Ghost image
    const img = document.createElement("img");
    img.src = `${location.origin}/img/${params.headerId}.jpg`;
    img.style.width = '100px';
    document.body.appendChild(img);
    e.dataTransfer.setDragImage(img, 0, 0);
  }

  const onDragEnd = () => {
    // store.dispatch("board/closeSidebar");
  }

  /**
   * https環境のみ動作するためローカルで検証する際は
   * 'yarn serve-{ env | dev or prod } --https'で立ち上げる
   */
  const copyToClipboard = async (slideId) => {
    const url = location.origin + location.pathname;
    const text = `${url}#${slideId}`;
    await navigator.clipboard.writeText(text);
    alert('Copied!');
    console.debug('Copied!', text);
  }

  /**
   * Don't allow any tags for 'description' which is user input.
   * Discard if any tags included, escape if special symbols included.
   */
  const sanitize = (dirty) => {
    const clean = sanitizeHtml(dirty, { 
      allowedTags: [], 
      allowedAttributes: {} 
    });
    return clean;
  }

  const linkify = (dirty) => {
    // Sanitizing
    const clean = sanitize(dirty);

    let html;
    // Replace '\n' to ' <br>'
    const newlineCharExp = /\r?\n/g
    html = clean.replace(newlineCharExp, ' <br>');

    // Add link strings to a tag.
    const linkExp = /(https?:\/\/[^\s]*)/g
    html = html.replace(linkExp, "<a href='$1' class='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'>$1</a>")

    return html;
  }

  return {
    state,
    formatTime,
    filterBoardByKeyword,
    filterSlackChannelsByKeyword,
    filterPinByKeyword,
    onDragStart,
    onDragEnd,
    copyToClipboard,
    linkify
  }
}