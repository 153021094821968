<template>
  <div class="text-gray-700">
    <div class="mb-8 title">
      <h1 class="mb-4 font-mono text-4xl font-bold">アカウント設定</h1>
      <p>アカウント情報に関する変更はこちらから行えます。</p>
    </div>
    <div class="mb-8 infomation">
      <h2 class="font-semibold">アカウント情報</h2>
      <div class="w-full py-4 bg-white">
        <div class="mb-6">
          <label class="block mb-2" for="email">
            <p>メールアドレス</p>
            <span class="text-sm text-gray-400"
              >ログインメールアドレスをこちらから変更できます</span
            >
          </label>
          <div class="flex items-center">
            <p class="text-lg text-gray-600">{{ email }}</p>
            <button @click="showChangeEmailModal = true" class="mx-4 btn">
              メールアドレス変更
            </button>
          </div>
        </div>

        <div class="mb-6">
          <label class="block mb-2" for="email">
            <p>パスワード</p>
            <span class="text-sm text-gray-400"
              >ログインパスワードをこちらから変更できます</span
            >
          </label>
          <button class="btn" @click="showResetPasswordModal = true">
            パスワード変更
          </button>
        </div>
      </div>
    </div>
    <div class="mb-8 danger-zone">
      <div class="">
        <button
          @click="showDeleteAccountModal = true"
          class="px-4 py-2 text-white bg-red-500 rounded shadow focus:outline-none hover:bg-red-600"
        >
          アカウントを削除
        </button>
      </div>
    </div>
    <change-email-modal
      :showModal="showChangeEmailModal"
      @showSuccessModal="
        showChangeEmailModal = false;
        showSuccessModal = true;
      "
      @closeModal="showChangeEmailModal = false"
    />
    <reset-password-modal
      :showModal="showResetPasswordModal"
      @showSuccessModal="
        showResetPasswordModal = false;
        showSuccessModal = true;
      "
      @closeModal="showResetPasswordModal = false"
    />
    <success-modal
      :showModal="showSuccessModal"
      @closeModal="showSuccessModal = false"
    />
    <account-delete-modal
      :showModal="showDeleteAccountModal"
      :deleteAccount="deleteAccount"
      @closeModal="showDeleteAccountModal = false"
    />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import firebase from "firebase/app";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import ChangeEmailModal from "../modals/EmailModalChange.vue";
import ResetPasswordModal from "../modals/AccountModalResetPassword.vue";
import SuccessModal from "../modals/SuccessMailModal.vue";
import AccountDeleteModal from "../modals/AccountModalDelete.vue";

import { RepositoryFactoryForGae } from "../../../api/gae/RepositoryFactory.js";
const UserRepository = RepositoryFactoryForGae.get("user");

export default {
  components: {
    ChangeEmailModal,
    AccountDeleteModal,
    SuccessModal,
    ResetPasswordModal,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const showChangeEmailModal = ref(false);
    const showResetPasswordModal = ref(false);
    const showSuccessModal = ref(false);
    const showDeleteAccountModal = ref(false);
    const email = ref(firebase.auth().currentUser.email);

    const userId = store.state.auth.userUid;
    const signout = () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push("/signin");
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const deleteAccount = async () => {
      const payload = { userId: userId };
      await UserRepository.deletableUser(payload).json();
      signout();
    };

    return {
      email,
      showChangeEmailModal,
      showResetPasswordModal,
      showSuccessModal,
      showDeleteAccountModal,
      deleteAccount,
    };
  },
};
</script>

<style lang="postcss" scoped>
.field-item {
  @apply w-96 px-4 py-2 text-gray-700 shadow border rounded;
}

.btn {
  @apply rounded shadow text-sm text-gray-500 hover:bg-gray-100 p-2 focus:outline-none;
  transition: background 20ms ease-in 0s;
}
</style>