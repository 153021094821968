<template>
  <div>
    <div
      v-if="showModal"
      @click.self="callParentToggleModal"
      class="fixed inset-0 z-50 flex items-center justify-center outline-none focus:outline-none"
    >
      <div
        class="flex my-8 rounded"
        style="width: 520px; max-height: 90%"
      >
        <!--content-->
        <div
          class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
        >
          <!--header-->
          <div
            class="flex items-center justify-between p-5 text-gray-700 bg-gray-100 border-b border-solid rounded-t border-blueGray-200"
          >
            <h3 class="text-xl font-semibold">
              <slot name="header"></slot>
            </h3>
          <button
            @click="callParentToggleModal"
            class="transition duration-100 transform shadow-2xl w-7 h-7 hover:scale-105 hover:rotate-90"
          >
            <close-icon class="w-7 h-7" />
          </button>
          </div>
          <!--body-->
          <div class="relative flex-auto px-6 py-4 overflow-y-auto">
            <div
              class="flex items-center h-8 mb-2 border border-gray-200 rounded-lg focus:outline-none"
            >
              <search-icon
                class="w-6 h-6 ml-2 text-gray-400 transition fill-current rounded-l-md"
              />
              <input
                class="w-full h-full pl-2 text-sm bg-white rounded-r-md focus:outline-none"
                type="search"
                placeholder="チャンネルの絞り込み..."
                @input="inputFilterText"
              />
            </div>
            <slot name="body"></slot>
          </div>
          <!--footer-->
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-70"></div>
  </div>
</template>

<script>
import CloseIcon from "../../icons/CloseIcon.vue";
import SearchIcon from "../../icons/SearchIcon.vue";

export default {
  components: {
    CloseIcon,
    SearchIcon,
  },
  props: {
    showModal: Boolean
  },
  methods: {
    callParentToggleModal() {
      this.$emit("toggleModal");
    },
    inputFilterText(e) {
      this.$emit("filterChannels", e.target.value);
    }
  }
};
</script>
