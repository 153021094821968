<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1100 221"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-70.0367,-99.8153)">
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.73155,-2.12007e-18,-2.12007e-18,2.71424,-3250.81,-1754.82)"
        >
          <path
            d="M945.096,1472.89C945.096,1474.85 944.323,1476.73 942.948,1478.11C941.574,1479.49 939.709,1480.27 937.766,1480.26C626.895,1480.13 531.026,1471.64 447.893,1419.25C372.105,1371.5 323.914,1261.61 322.621,1082.42C322.605,1080.46 323.368,1078.57 324.742,1077.18C326.115,1075.79 327.985,1075 329.936,1075C354.966,1074.99 436.782,1074.99 461.739,1074.99C465.763,1075 469.033,1078.26 469.06,1082.32C469.711,1167.65 482.207,1247.71 535.893,1281.34C604.532,1324.32 673.034,1332.74 937.786,1332.89C941.825,1332.9 945.095,1336.19 945.095,1340.26C945.096,1365.41 945.096,1447.74 945.096,1472.89Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.787,-9.80728e-18,-9.80728e-18,2.70692,-3611.56,-1746.09)"
        >
          <path
            d="M1207.68,1480.11C1205.77,1480.11 1203.95,1479.33 1202.6,1477.94C1201.26,1476.56 1200.5,1474.68 1200.5,1472.72C1200.5,1447.61 1200.5,1365.76 1200.5,1340.66C1200.5,1336.58 1203.71,1333.27 1207.68,1333.27C1488.36,1333.25 1593.73,1331.43 1593.73,1203.97C1593.73,1076.51 1487.27,1074.69 1206.3,1074.67C1204.4,1074.67 1202.57,1073.89 1201.22,1072.5C1199.88,1071.11 1199.12,1069.23 1199.13,1067.27C1199.24,1016.96 1199.87,725.877 1199.98,675.678C1199.99,671.604 1203.2,668.306 1207.16,668.306C1231.53,668.306 1311.07,668.306 1335.46,668.306C1339.42,668.306 1342.63,671.614 1342.63,675.694C1342.63,713.193 1342.63,882.132 1342.63,919.617C1342.63,923.694 1345.84,927 1349.8,927.006C1463.63,927.207 1769.62,934.813 1769.62,1203.97C1769.62,1476.97 1529.86,1480.08 1207.68,1480.11Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.82682,-9.10502e-18,-9.10502e-18,2.75247,-4797.28,-1807.2)"
        >
          <path
            d="M2316.25,1478.64C2312.35,1478.64 2309.18,1475.39 2309.18,1471.38C2309.18,1396.26 2309.18,762.138 2309.18,686.762C2309.18,684.826 2309.93,682.971 2311.27,681.607C2312.61,680.243 2314.42,679.483 2316.3,679.496C2340.44,679.658 2418.99,680.185 2442.98,680.346C2446.86,680.372 2450,683.614 2450.01,687.606C2450.07,762.461 2450.61,1396.28 2450.68,1471.45C2450.68,1473.38 2449.93,1475.23 2448.61,1476.6C2447.28,1477.96 2445.47,1478.73 2443.6,1478.72C2419.43,1478.71 2340.4,1478.66 2316.25,1478.64Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.82682,-9.10502e-18,-9.10502e-18,2.75247,302.724,-1807.2)"
        >
          <path
            d="M2316.25,1478.64C2312.35,1478.64 2309.18,1475.39 2309.18,1471.38C2309.18,1396.26 2309.18,762.138 2309.18,686.762C2309.18,684.826 2309.93,682.971 2311.27,681.607C2312.61,680.243 2314.42,679.483 2316.3,679.496C2340.44,679.658 2418.99,680.185 2442.98,680.346C2446.86,680.372 2450,683.614 2450.01,687.606C2450.07,762.461 2450.61,1396.28 2450.68,1471.45C2450.68,1473.38 2449.93,1475.23 2448.61,1476.6C2447.28,1477.96 2445.47,1478.73 2443.6,1478.72C2419.43,1478.71 2340.4,1478.66 2316.25,1478.64Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.8138,5.96238e-18,5.96238e-18,2.86248,-5690.51,-1979.46)"
        >
          <path
            d="M2928.75,1482.08C2924.83,1482.08 2921.64,1478.95 2921.64,1475.09C2921.64,1451.24 2921.64,1373.18 2921.63,1349.33C2921.63,1347.47 2922.38,1345.7 2923.72,1344.39C2925.05,1343.08 2926.86,1342.34 2928.74,1342.34C2994.05,1342.34 3488.9,1342.34 3554.23,1342.34C3556.11,1342.34 3557.92,1343.08 3559.25,1344.39C3560.59,1345.7 3561.34,1347.48 3561.34,1349.33C3561.32,1373.19 3561.28,1451.25 3561.27,1475.1C3561.27,1478.95 3558.09,1482.08 3554.16,1482.08C3488.87,1482.08 2994.06,1482.08 2928.75,1482.08Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.68764,2.17628e-18,2.17628e-18,2.70541,-5456.56,-1755.35)"
        >
          <path
            d="M4422.82,1477.9C4422.82,1481.98 4419.49,1485.29 4415.38,1485.29C4196.62,1485.32 4036.55,1486.21 3939.12,1412.08C3839.91,1336.58 3790.29,1251.4 3790.29,1078.7C3790.29,921.355 3833.38,809.495 3939.12,742.866C4031.34,684.765 4160.63,670.437 4415.42,672.053C4419.52,672.077 4422.82,675.379 4422.82,679.445C4422.82,704.622 4422.82,787.307 4422.82,812.563C4422.82,814.524 4422.04,816.404 4420.64,817.79C4419.24,819.177 4417.35,819.956 4415.38,819.956C4086.25,819.922 3939.12,818.611 3939.12,1078.7C3939.12,1338.53 4123.69,1337.48 4415.38,1337.44C4417.35,1337.44 4419.24,1338.22 4420.64,1339.6C4422.04,1340.99 4422.82,1342.87 4422.82,1344.83C4422.82,1370.07 4422.82,1452.66 4422.82,1477.9Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.70887,-2.39248e-19,-2.39248e-19,2.70692,-5756.66,-1748.33)"
        >
          <path
            d="M5170.8,1481.87C5166.72,1481.87 5163.41,1478.56 5163.41,1474.48C5163.41,1430.25 5163.41,1201 5163.41,1156.78C5163.41,1152.69 5160.11,1149.39 5156.03,1149.39C5122.3,1149.39 4983.03,1149.39 4949.3,1149.39C4945.22,1149.39 4941.92,1146.08 4941.92,1142C4941.92,1116.77 4941.92,1034.23 4941.92,1009C4941.92,1007.05 4942.7,1005.17 4944.08,1003.78C4945.47,1002.39 4947.34,1001.62 4949.3,1001.62C4983.09,1001.62 5122.75,1001.62 5156.56,1001.62C5158.52,1001.62 5160.4,1000.84 5161.79,999.449C5163.17,998.06 5163.95,996.178 5163.94,994.216C5163.87,949.965 5163.5,720.788 5163.43,676.536C5163.42,674.575 5164.2,672.692 5165.58,671.304C5166.97,669.916 5168.85,669.136 5170.81,669.136C5196.03,669.136 5278.49,669.136 5303.69,669.136C5307.77,669.136 5311.07,672.444 5311.07,676.524C5311.07,752.948 5311.07,1398.06 5311.07,1474.48C5311.07,1478.56 5307.77,1481.87 5303.69,1481.87C5278.49,1481.87 5196,1481.87 5170.8,1481.87Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.85682,-1.90269e-17,-1.90269e-17,2.70146,-5859.58,-1784.6)"
        >
          <path
            d="M3188.85,1202.14C3186.99,1202.15 3185.21,1201.37 3183.89,1199.98C3182.58,1198.59 3181.84,1196.71 3181.84,1194.74C3181.85,1136.25 3181.88,749.775 3181.88,691.315C3181.88,687.227 3185.02,683.913 3188.88,683.913C3212.78,683.913 3290.96,683.913 3314.85,683.913C3316.71,683.913 3318.49,684.693 3319.8,686.081C3321.12,687.47 3321.85,689.353 3321.85,691.317C3321.85,749.778 3321.81,1136.2 3321.8,1194.63C3321.8,1198.71 3318.67,1202.03 3314.81,1202.03C3290.92,1202.05 3212.75,1202.12 3188.85,1202.14Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(0.1,0,0,-0.1,307,326.113)">
        <g
          transform="matrix(2.71793,-1.34784e-18,-1.34784e-18,2.70692,-3267.24,-1771.98)"
        >
          <path
            d="M815.906,1084.24C811.852,1084.24 808.562,1080.95 808.547,1076.88C808.12,961.531 797.169,900.484 740.783,867.435C670.235,826.085 551.324,825.647 337.613,825.642C333.549,825.642 330.255,822.334 330.255,818.254C330.255,793.03 330.255,710.489 330.255,685.263C330.255,683.301 331.032,681.42 332.414,680.034C333.797,678.648 335.671,677.871 337.624,677.874C693.986,678.064 792.092,692.213 863.716,756.021C934.866,819.407 955.399,895.626 955.936,1076.81C955.946,1078.78 955.176,1080.66 953.795,1082.06C952.415,1083.45 950.538,1084.24 948.58,1084.24C923.416,1084.24 841.036,1084.24 815.906,1084.24Z"
            style="fill: rgb(15, 27, 49); fill-rule: nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
</template>