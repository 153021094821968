<template>
    <main
      v-if="!states.loading"
      class="pt-5 overflow-y-scroll h-screen"
    >
      <!-- avater section -->
      <div class="flex flex-col items-center">
        <label for="file" class="relative">
          <div
            v-if="isEditable"
            @change="setImage"
            class="absolute top-4 left-8 border rounded-full p-1.5 bg-gray-800 text-gray-50 text-md z-10"
          >
            <round-edit />
          </div>
        </label>
        <label for="file" class="relative p-1 rounded">
          <img
            v-if="initialValues.photoURL"
            :src="initialValues.photoURL"
            @change="setImage"
            alt="avater"
            class="object-cover p-1 border rounded-full cursor-pointer"
            style="width: 150px; height: 150px"
          />
          <div
            v-else
            class="flex items-center justify-center p-2 border rounded-full cursor-pointer"
            style="width: 150px; height: 150px"
            @change="setImage"
          >
            <span class="text-8xl text-gray-500">
              {{ initialValues.lastName ? initialValues.lastName.substring(0, 1) : "" }}
            </span>
          </div>
        </label>
        <div
          v-if="editProfile.isEdittingPhotoURL"
          class="flex justify-center form-group inline-block"
        >
          <button
            @click="cancelImage"
            :disabled="states.isDisabled"
          >
            キャンセル
          </button>
          <button
            :disabled="states.isDisabled"
            @click="uploadImage"
            :class="[states.isDisabled ? 'bg-gray-300' : 'bg-gray-700']"
            class="px-2 py-1 m-3 rounded text-white"
          >
            更新する
          </button>
        </div>
        <input type="file" id="file" class="hidden" @change="setImage" />
      </div>
      <div class="flex flex-col">
        <Form
          class="w-full bg-white"
          @submit="updateProfile"
          :initial-values="initialValues"
          :validation-schema="reqSchema"
        >
          <div class="relative flex items-center justify-center">
            <span
              v-if="!editProfile.isEdittingDisplayName"
              class="flext text-3xl font-bold leading-10 text-gray-600 justify-center"
            >
              {{ initialValues.displayName ? initialValues.displayName : initialValues.lastName + " " + initialValues.firstName }}
            </span>
            <div
              v-if="editProfile.isEdittingDisplayName"
            >
              <Field
                class="field-half-item"
                id="displayName"
                name="displayName"
                placeholder=""
              />
              <ErrorMessage class="text-red-600" name="displayName" />
            </div>
            <round-edit
              v-if="isEditable && !editProfile.isEdittingDisplayName"
              @click="onEditMode('isEdittingDisplayName')"
              class="border rounded-full p-1.5 mt-1 ml-2 bg-gray-800 text-gray-50 text-xl inline-block"
            />
          </div>
          <div
            v-if="editProfile.isEdittingDisplayName"
            class="flex justify-center form-group inline-block"
          >
            <button
              @click="editProfile.isEdittingDisplayName = false"
              :disabled="states.isDisabled"
            >
              キャンセル
            </button>
            <button
              class="px-2 py-1 m-3 rounded text-white"
              :class="[states.isDisabled ? 'bg-gray-300' : 'bg-gray-700']"
              :disabled="states.isDisabled"
            >
              更新する
            </button>
          </div>
        </Form>
        <div class="flex items-center justify-center">
          <div class="flex mb-5 text-gray-400 items-center font-semibold">
            {{ "@" + initialValues.username }}
          </div>
        </div>
        <!-- tab section -->
        <div class="flex flex-col items-center">
          <div class="tabs mb-2">
            <span
              @click="states.selectedTab = 'profile'"
              class="tab-item mr-2 p-2 rounded font-semibold"
              :class="states.selectedTab == 'profile' ? 'border-b-4 border-gray-700' : ''"
            >
              プロフィール
            </span>
            <span
              @click="states.selectedTab = 'board'"
              class="tab-item mr-2 p-2 rounded font-semibold"
              :class="states.selectedTab == 'board' ? 'border-b-4 border-gray-700' : ''"
            >
              ボード
            </span>
            <span
              @click="states.selectedTab = 'file'"
              class="tab-item mr-2 p-2 rounded font-semibold"
              :class="states.selectedTab == 'file' ? 'border-b-4 border-gray-700' : ''"
            >
              ファイル
            </span>
            <span
              @click="states.selectedTab = 'like'"
              class="tab-item mr-2 p-2 rounded font-semibold"
              :class="states.selectedTab == 'like' ? 'border-b-4 border-gray-700' : ''"
            >
              いいね
            </span>
          </div>
        </div>
        <!-- profile section -->
        <hr>
        <div
          v-if="states.selectedTab == 'profile'"
        >
          <Form
            class="w-full bg-white"
            @submit="updateProfile"
            :initial-values="initialValues"
            :validation-schema="reqSchema"
          >
            <div class="relative flex justify-between mt-4 mx-4 font-bold">
              名前
              <round-edit
                v-if="isEditable && !editProfile.isEdittingName"
                @click="onEditMode('isEdittingName')"
                class="absolute right-2 border rounded-full p-1.5 ml-2 bg-gray-800 text-gray-50 text-xl inline-block"
              />
            </div>
            <div
              v-if="!editProfile.isEdittingName"
              class="m-4 text-base text-gray-800"
            >
              {{ initialValues.lastName + " " + initialValues.firstName }}
            </div>
            <div
              v-if="editProfile.isEdittingName"
              class="flex form-group mt-2 ml-4"
            >
              <div class="lastname">
                <label class="block mb-2" for="lastName">姓</label>
                <Field
                  class="field-half-item"
                  id="lastName"
                  name="lastName"
                  :placeholder="initialValues.lastName"
                />
                <ErrorMessage class="text-red-600" name="lastName" />
              </div>
              <div class="ml-8 firstname">
                <label class="block mb-2" for="firstName">名</label>
                <Field
                  class="field-half-item"
                  id="firstName"
                  name="firstName"
                  :placeholder="initialValues.firstName"
                />
                <ErrorMessage class="text-red-600" name="firstName" />
              </div>
            </div>
            <div
              v-if="editProfile.isEdittingName"
              class="flex justify-start ml-4 inline-block"
            >
              <button
                @click="editProfile.isEdittingName = false"
                :disabled="states.isDisabled"
              >
                キャンセル
              </button>
              <button
                class="px-2 py-1 m-4 rounded text-white"
                :class="[states.isDisabled ? 'bg-gray-300' : 'bg-gray-700']"
                :disabled="states.isDisabled"
              >
                更新する
              </button>
            </div>
          </Form>
          <hr>
          <Form
            class="w-full bg-white"
            @submit="updateProfile"
            :initial-values="initialValues"
            :validation-schema="reqSchema"
          >
            <div>
              <div class="relative mt-4 mx-4 font-bold">
                自己紹介
                <round-edit
                  v-if="isEditable && !editProfile.isEdittingBiography"
                  @click="onEditMode('isEdittingBiography')"
                  class="absolute right-2 border rounded-full p-1.5 ml-2 bg-gray-800 text-gray-50 text-xl inline-block"
                />
              </div>
              <div
                v-if="!editProfile.isEdittingBiography"
                class="m-4 text-base text-gray-800 whitespace-pre-line"
              >
                {{ initialValues.biography }}
              </div>
            </div>
            <div
              v-if="editProfile.isEdittingBiography"
              class="flex form-group mt-2 ml-4"
            >
              <div class="biography">
                <Field
                  as="textarea"
                  class="field-item"
                  id="biography"
                  name="biography"
                  rows="6"
                  placeholder="..."
                />
              </div>
            </div>
            <div
              v-if="editProfile.isEdittingBiography"
              class="flex justify-start ml-4 form-group inline-block"
            >
              <button
                @click="editProfile.isEdittingBiography = false"
                :disabled="states.isDisabled"
              >
                キャンセル
              </button>
              <button
                class="px-2 py-1 m-4 rounded text-white"
                :class="[states.isDisabled ? 'bg-gray-300' : 'bg-gray-700']"
                :disabled="states.isDisabled"
              >
                更新する
              </button>
            </div>
          </Form>
          <hr>
          <Form
            class="w-full bg-white"
            @submit="updateProfile"
            :initial-values="initialValues"
            :validation-schema="reqSchema"
          >
            <div class="relative mt-4 mx-4 font-bold">
              所属
              <round-edit
                v-if="isEditable && !editProfile.isEdittingDepartment"
                @click="onEditMode('isEdittingDepartment')"
                class="absolute right-2 border rounded-full p-1.5 ml-2 bg-gray-800 text-gray-50 text-xl inline-block"
              />
            </div>
            <div
              v-if="!editProfile.isEdittingDepartment"
              class="m-4 text-base text-gray-800"
            >
              {{ initialValues.department }}
            </div>
            <div
              v-if="editProfile.isEdittingDepartment"
              class="department mt-2 ml-4"
            >
              <Field
                class="field-item"
                id="department"
                name="department"
                placeholder=""
              />
              <ErrorMessage class="text-red-600" name="department" />
            </div>
            <div
              v-if="editProfile.isEdittingDepartment"
              class="flex justify-start ml-4 inline-block"
            >
              <button
                @click="editProfile.isEdittingDepartment = false"
                :disabled="states.isDisabled"
              >
                キャンセル
              </button>
              <button
                class="px-2 py-1 m-4 rounded text-white"
                :class="[states.isDisabled ? 'bg-gray-300' : 'bg-gray-700']"
                :disabled="states.isDisabled"
              >
                更新する
              </button>
            </div>
          </Form>
          <hr>
          <Form
            class="w-full bg-white"
            @submit="updateProfile"
            :initial-values="initialValues"
            :validation-schema="reqSchema"
          >
            <div class="relative mt-4 mx-4 font-bold">
              職種・役職
              <round-edit
                v-if="isEditable && !editProfile.isEdittingPosition"
                @click="onEditMode('isEdittingPosition')"
                class="absolute right-2 border rounded-full p-1.5 ml-2 bg-gray-800 text-gray-50 text-xl inline-block"
              />
            </div>
            <div
              v-if="!editProfile.isEdittingPosition"
              class="m-4 text-base text-gray-800"
            >
              {{ initialValues.position }}
            </div>
            <div
              v-if="editProfile.isEdittingPosition"
              class="mt-2 ml-4 position"
            >
              <Field
                class="field-item"
                id="position"
                name="position"
                placeholder=""
              />
              <ErrorMessage class="text-red-600" name="position" />
            </div>
            <div
              v-if="editProfile.isEdittingPosition"
              class="flex justify-start ml-4 inline-block"
            >
              <button
                @click="editProfile.isEdittingPosition = false"
                :disabled="states.isDisabled"
              >
                キャンセル
              </button>
              <button
                class="px-2 py-1 m-4 rounded text-white"
                :class="[states.isDisabled ? 'bg-gray-300' : 'bg-gray-700']"
                :disabled="states.isDisabled"
              >
                更新する
              </button>
            </div>
          </Form>
          <hr>
          <Form
            class="w-full bg-white mb-5"
            @submit="updateProfile"
            :initial-values="initialValues"
            :validation-schema="reqSchema"
          >
            <div class="relative mt-4 mx-4 font-bold">
              ウェブサイト
              <round-edit
                v-if="isEditable && !editProfile.isEdittingWebsite"
                @click="onEditMode('isEdittingWebsite')"
                class="absolute right-2 border rounded-full p-1.5 ml-2 bg-gray-800 text-gray-50 text-xl inline-block"
              />
            </div>
            <div
              v-if="!editProfile.isEdittingWebsite"
              class="m-4 break-words"
            >
              <a
                :href="initialValues.website ? initialValues.website : ''"
                target="_blank"
                class="underline text-gray-800"
              >
                {{ initialValues.website }}
              </a>
            </div>
            <div
              v-if="editProfile.isEdittingWebsite"
              class="mt-2 ml-4"
            >
              <Field
                class="field-item"
                id="website"
                name="website"
                type="url"
                placeholder="https://example.com"
              />
              <ErrorMessage class="text-red-600" name="website" />
            </div>
            <div
              v-if="editProfile.isEdittingWebsite"
              class="flex justify-start ml-4 inline-block"
            >
              <button
                @click="editProfile.isEdittingWebsite = false"
                :disabled="states.isDisabled"
              >
                キャンセル
              </button>
              <button
                class="px-2 py-1 m-4 rounded text-white"
                :class="[states.isDisabled ? 'bg-gray-300' : 'bg-gray-700']"
                :disabled="states.isDisabled"
              >
                更新する
              </button>
            </div>
          </Form>
          <hr>
        </div>
        <!-- board section -->
        <div v-if="states.selectedTab == 'board'">
          <div
            v-if="favBoard"
            class="flex m-4 text-sm text-gray-400"
          >
            閲覧者には公開ボードのみ表示されます
          </div>
          <div
            v-if="allBoards.length"
            class="grid gap-6 row m-4"
          >
            <div
              class="transition transform bg-white border rounded shadow-md hover:shadow-xl hover:-translate-y-px"
              v-for="(item) in allBoards"
              :key="item.id"
            >
              <router-link
                :to="{ name: 'boardDetail', params: { id: item.id } }"
              >
                <div
                  class="flex overflow-hidden rounded bg-gray-50 preview"
                >
                  <div v-if="item.coverURL">
                    <div
                      v-bind:style="{ backgroundImage: 'url(' + item.coverURL + ')' }"
                      class="w-40 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9"
                    ></div>
                  </div>
                  <div v-else>
                    <div
                      v-bind:style="{ backgroundImage: item.pins.length > 0 ? 'url(' + states.baseUrl + item.thumbnail + '.jpg)': 'url(' + require('@/assets/board-thumbnail.png') + ')'}"
                      class="inset-0 w-40 bg-center bg-no-repeat bg-cover border-r preview-background aspect-w-16 aspect-h-9"
                    ></div>
                  </div>
                  <div class="flex justify-between w-full px-2 py-1">
                    <div>
                      <p class="mb-1 font-bold text-gray-600 text">
                        {{ item.visibility === "limited" ? "🔒" : "#"}}{{ item.name }}
                      </p>
                      <p class="text-xs text-gray-500">
                        {{ item.description }}
                      </p>
                    </div>
                    <div class="text-sm">
                      <div class="flex text-gray-400">
                        <pins-icon class="w-5 h-5 current-color" />
                        <span>{{ item.pins.length }}</span>
                      </div>
                      <div class="flex text-gray-400">
                        <users-icon class="w-5 h-5 current-color" />
                        <span>{{ item.membersNumber }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div
            v-else
            class="m-4 text-gray-600"
          >
            参加済みのボード情報はありません。
          </div>
        </div>
        <!-- file section -->
        <file-page
          v-if="states.selectedTab == 'file'"
          :files="files"
          class="mt-4"
        />
        <infinite-loading
          v-if="states.selectedTab == 'file' && showInfiniteLoading"
          spinner="circles"
          @infinite="infiniteHandler"
        />
        <!-- like section -->
        <like-file-page
          v-if="states.selectedTab == 'like'"
          :files="likeFiles"
          class="mt-4"
        />
        <infinite-loading
          v-if="states.selectedTab == 'like' && showInfiniteLoading"
          spinner="circles"
          @infinite="infiniteHandler"
        />
      </div>
    </main>
    <loading
      v-model:active="states.loading"
      :is-full-page="false"
      color="#818589"
      blur=""
    />
    <success-alart
      :show="states.showAlart"
      :title="states.alartTitle"
      :body="states.alartBody"
    />
</template>

<script>
import { onBeforeMount, watchEffect, computed, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import infiniteLoading from "vue-infinite-loading";
import { Form, Field, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja"
import firebase from "firebase/app";
import RoundEdit from "../../icons/RoundEdit.vue";
import SuccessAlart from "../organisms/SuccessAlart.vue";
import FilePage from "../templates/Home.vue";
import LikeFilePage from "../organisms/LikeFiles.vue";
import UsersIcon from "../../icons/UsersIcon.vue";
import PinsIcon from "../../icons/PinsIcon.vue";
import { RepositoryFactoryForGae } from "../../../api/gae/RepositoryFactory";
import useSearchRepository from "../../organisms/composables/useSearchRepository";
const UserRepository = RepositoryFactoryForGae.get("user");
const BoardRepository = RepositoryFactoryForGae.get("board");

export default {
  components: {
    Loading,
    FilePage,
    LikeFilePage,
    Form,
    Field,
    ErrorMessage,
    RoundEdit,
    SuccessAlart,
    UsersIcon,
    PinsIcon,
    infiniteLoading
  },
  props: {
    profileInfo: {
      type: Object,
      required: true
    }
  },
  async setup (props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const {
      state,
      showInfiniteLoading,
      searchPackagePayload,
      payload,
      infiniteHandler
    } = useSearchRepository();
    const states = reactive({
      loading: false,
      user: [],
      editMode: false,
      isDisabled: false,
      selectedTab: "profile",
      profileInfo: props.profileInfo,
      showAlart: false,
      alartTitle: "プロフィールを更新しました",
      alartBody: "反映されない場合はリロードしてください",
      baseUrl: `${location.origin}/img/`
    });
    const reqSchema = yup.object({
      firstName: yup
        .string()
        .trim()
        .required()
        .label("名"),
      lastName: yup
        .string()
        .trim()
        .required()
        .label("姓"),
      displayName: yup.string().trim(),
      biography: yup.string(),
      department: yup.string().trim(),
      position: yup.string().trim(),
      website: yup
        .string()
        .label("Website")
        .url()
    });
    const initialValues = reactive({});
    const setInitialValues = userInfo => {
      initialValues.photoURL = userInfo.photoURL;
      initialValues.prevPhotoURL = userInfo.photoURL;
      initialValues.firstName = userInfo.firstName;
      initialValues.lastName = userInfo.lastName;
      initialValues.displayName = userInfo.displayName;
      initialValues.username = userInfo.username;
      initialValues.biography = userInfo.biography;
      initialValues.department = userInfo.department;
      initialValues.position = userInfo.position;
      initialValues.website = userInfo.website;
    };
    const editProfile = reactive({
      isEdittingPhotoURL: false,
      isEdittingDisplayName: false,
      isEdittingName: false,
      isEdittingBiography: false,
      isEdittingDepartment: false,
      isEdittingPosition: false,
      isEdittingWebsite: false
    });
    const initEditMode = async () => {
      editProfile.isEdittingPhotoURL = false;
      editProfile.isEdittingDisplayName = false;
      editProfile.isEdittingName = false;
      editProfile.isEdittingBiography = false;
      editProfile.isEdittingDepartment = false;
      editProfile.isEdittingPosition = false;
      editProfile.isEdittingWebsite = false;
      states.isDisabled = false;
    };
    const onEditMode = async (item) => {
      await initEditMode();
      editProfile[item] = true;
    };
    const getUserInfoPayload = {
      workspace: route.params.workspace,
      username: route.params.username
    };
    const showAlart = () => {
      states.showAlart = true;
      setTimeout(() => (states.showAlart = false), 5000);
    };
    const isEditable = computed (() => {
      return store.state.user.userInfo.username == initialValues.username;
    });

    // ファイル取得
    const files = computed (() => {
      return state.files.filter((file) => {
        const boards = store.state.board.myBoard.filter(
            (board) => board.pins.some(pin => pin.pin_id === file.id)
          );
        Object.assign(file, { boards });
        return file.created_by == states.user[0].id;
      });
    });
    onBeforeMount (async () => {
      emit("start-loading");
      states.user = await store.state.user.users.filter(user => user.username == route.params.username);
      Object.assign(payload, { ...searchPackagePayload, ...route.query });
      emit("end-loading");
    });
    watchEffect (() => {
      const numFiles = state.files.length;
      if (numFiles > 0) {
        switch (route.params.type) {
          case "packages":
            payload.cursor = state.files[numFiles - 1].packageId;
            break;
          case "slides":
            state.fileType = "slide";
            payload.cursor = state.files[numFiles - 1].slideId;
            break;
          default:
            payload.cursor = state.files[numFiles - 1].packageId;
        }
      }
    });

    // ボード取得
    const getUserIdbyUsers = async () => {
      const user = await store.state.user.users.filter(user => user.username == route.params.username);
      return { user_id: user[0].id, workspace: user[0].workSpace };
    };
    const getBoardPayload = await getUserIdbyUsers();
    let allBoards = ref();
    let createdBoards = ref();
    let favBoard = ref();
    const fetchBoards = async () => {
      const { data } = await BoardRepository.fetchBoardById(getBoardPayload).json();
      if (data.all.find(board => 
        board.name == "お気に入り" &&
        board.created_user == store.state.user.userInfo.id
      )) {
        allBoards.value = data.all;
        favBoard.value = data.all.filter(board => board.name == "お気に入り");
      } else {
        allBoards.value = data.all.filter(board => board.name != "お気に入り");
      }
      createdBoards.value = data.created_board;
    };
    await fetchBoards();

    // プロフィール情報取得
    const getUserInfo = async (params) => {
      return await UserRepository.getUserInfoById({
        workspace: params.workspace,
        user_id: params.username
      }).json();
    };
    const setUserInfo = async (params) => {
      params.workspace = getBoardPayload.workspace;
      const { data } = await getUserInfo(params);
      setInitialValues(data[0]);
      states.loading = false;
    };
    await setUserInfo(getUserInfoPayload);
    
     // プロフィール各項目(画像以外)更新
    const updateProfile = async (values) => {
      states.isDisabled = true;
      const payload = values;
      const user = firebase.auth().currentUser;
      payload.userId = user.uid;
      await UserRepository.updateProfile(payload)
        .then(async () => {
          await setUserInfo(getUserInfoPayload);
          store.dispatch("user/setUser");
          console.log("Successfully updated profile");
        })
        .catch(e => {
          console.error(e.message);
        });
      await initEditMode();
      showAlart();
    };

    // プロフィール画像CRUD
    let file;
    const setImage = e => {
      file = e.target.files[0];
      initialValues.photoURL = URL.createObjectURL(file);
      initEditMode();
      editProfile.isEdittingPhotoURL = true;
    };
    const cancelImage = () => {
      initialValues.photoURL = initialValues.prevPhotoURL;
      editProfile.isEdittingPhotoURL = false;
    };
    const uploadImage = async () => {
      states.isDisabled = true;
      const fileName = file.name;
      const user = firebase.auth().currentUser;
      const path = `profile-image/${user.uid}/${fileName}`;
      const profileImageRef = firebase.storage().ref().child(path);
      const snapshot = await profileImageRef.put(file);
      const photoURL = await snapshot.ref.getDownloadURL();
      const payload = {
        photoURL: photoURL,
        userId: user.uid
      };
      await UserRepository.updateProfile(payload)
        .then(async () => {
          await setUserInfo(getUserInfoPayload);
          store.dispatch("user/setUser");
        })
        .catch(e => {
          console.error(e.message);
        });
      await initEditMode();
      showAlart();
    };

    // いいね取得
    const likeFiles = computed(() => {
      return state.files.filter(
        file => file.liked_users.some(
          user => user == states.user[0].username
        )
      );
    });

    return {
      showInfiniteLoading,
      states,
      initialValues,
      reqSchema,
      isEditable,
      editProfile,
      files,
      likeFiles,
      allBoards,
      favBoard,
      infiniteHandler,
      onEditMode,
      setImage,
      uploadImage,
      cancelImage,
      updateProfile
    }
  }
};
</script>
<style lang="postcss" scoped>
.field-item {
  @apply block w-[320px] px-4 py-2 text-gray-700 shadow border rounded;
}
.field-half-item {
  @apply w-36 block px-4 py-2 text-gray-700 shadow border rounded;
}
</style>
