<template>
  <div class="flex bg-gray-100">
    <div
      v-if="deviceType == 'pc'"
      class="flex flex-col w-screen h-screen"
    >
      <header-top />
      <main class="py-4 overflow-y-scroll bg-gray-100 scrollbar">
        <Suspense>
          <template #default>
            <board-top-main-contents />
          </template>
          <template #fallback>
            <the-loader />
          </template>
        </Suspense>
      </main>
    </div>
    <div
      v-if="deviceType == 'mobile'"
      class="flex flex-col w-screen max-mobile-height"
    >
      <mobile-header-top />
      <main class="py-4 overflow-y-scroll bg-gray-100 scrollbar">
        <Suspense>
          <template #default>
            <mobile-board-top />
          </template>
          <template #fallback>
            <the-loader />
          </template>
        </Suspense>
      </main>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderTop from "../organisms/TheHeaderTop.vue";
import BoardTopMainContents from "../organisms/BoardTopContents.vue";
import MobileHeaderTop from "../mobile/organisms/HeaderTop.vue";
import MobileBoardTop from "../mobile/templates/BoardTop.vue";
import TheLoader from "../organisms/utilities/LoaderFiles.vue";
import Footer from "../mobile/organisms/Footer.vue";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    HeaderTop,
    BoardTopMainContents,
    MobileHeaderTop,
    MobileBoardTop,
    TheLoader,
    Footer
  },
  setup () {
    const deviceType = calcBreakpoint(window.innerWidth);

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    return { 
      deviceType
    };
  }
};
</script>

<style scoped>
.max-mobile-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>