<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcSharpFilterList"
};
</script>