import ky from "ky";
import firebase from 'firebase/app'

// You can use your own logic to set your local or production domain
// const baseDomain = "https://spitch.jp";
const baseDomain = location.origin;
const baseURL = `${baseDomain}/api`;

const getToken = () => {
    return firebase.auth().currentUser?.getIdToken()
}

export default ky.create({
    prefixUrl: baseURL,
    retry: {
        methods: ['post'],
        statusCodes: [401]
    },
    timeout:15000,
    hooks: {
        beforeRequest: [
            async (request) => {
                const token = await getToken()
                request.headers.set('Authorization', `Bearer ${token}`);
            }
        ],
        beforeRetry: [
            async ({ request, options, error, retryCount }) => {
                console.debug('Error', error)
                const token = await getToken() 
                request.headers.set('Authorization', `Bearer ${token}`)
            }
        ]
    }
})