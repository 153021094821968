<template>
  <div class="vld-parent">
    <div class="mb-8 title">
      <h1 class="font-mono text-4xl font-bold">プロフィール設定</h1>
      <p class="my-4">
        プロフィールにアクセスしたユーザには以下の情報が表示されます。
      </p>
    </div>
    <div class="flex items-center form-group">
      <label for="file" class="relative">
        <img
          v-if="state.url"
          @change="setImage"
          :src="state.url"
          alt="avatar"
          class="object-cover p-2 border rounded-full cursor-pointer"
          style="width: 150px; height: 150px"
        />
        <div
          v-else
          class="flex items-center justify-center p-2 border rounded-full cursor-pointer"
          style="width: 150px; height: 150px;"
          @change="setImage"
        >
          <span class="text-gray-500 text-8xl">{{
            state.lastName.substring(0, 1)
          }}</span>
        </div>
        <div
          class="absolute top-20 -right-2 border rounded-full p-1.5 bg-gray-800 text-gray-50"
        >
          <round-edit />
        </div>
      </label>
      <input type="file" id="file" class="hidden" @change="setImage" />
      <div class="ml-8">
        <p class="text-xl font-semibold leading-10 text-gray-600">
          {{ initialValues.lastName + " " + initialValues.firstName }}
        </p>
        <p class="text-sm text-gray-600">
          {{ initialValues.department + " > " + initialValues.position }}
        </p>
      </div>
    </div>
    <div class="mb-8 infomation">
      <h2 class="font-semibold">プロフィール情報</h2>
      <Form
        class="w-full py-4 bg-white"
        @submit="updateProfile"
        :initial-values="initialValues"
        :validation-schema="schema"
      >
        <div class="flex form-group">
          <div class="lastname">
            <label class="block mb-2" for="lastName">姓</label>
            <Field
              class="field-half-item"
              id="lastName"
              name="lastName"
              placeholder="Rokuyama"
            />
            <ErrorMessage class="text-red-600" name="lastName" />
          </div>
          <div class="ml-8 firstname">
            <label class="block mb-2" for="firstName">名</label>
            <Field
              class="field-half-item"
              id="firstName"
              name="firstName"
              placeholder="Daiki"
            />
            <ErrorMessage class="text-red-600" name="firstName" />
          </div>
        </div>

        <div class="form-group">
          <label class="block mb-2" for="displayName">表示名</label>
          <Field
            class="field-item"
            id="displayName"
            name="displayName"
            placeholder="Your dispaly name"
          />
          <ErrorMessage class="text-red-600" name="displayName" />
        </div>

        <div class="form-group">
          <label class="block mb-2" for="biography">自己紹介</label>
          <Field
            as="textarea"
            class="field-item"
            id="biography"
            name="biography"
            rows="3"
            placeholder="..."
          />
        </div>

        <div class="flex form-group">
          <div class="department">
            <label class="block mb-2" for="department">部署</label>
            <Field
              class="field-half-item"
              id="department"
              name="department"
              placeholder="コンサル事業部"
            />
            <ErrorMessage class="text-red-600" name="department" />
          </div>
          <div class="ml-8 position">
            <label class="block mb-2" for="position">役職</label>
            <Field
              class="field-half-item"
              id="position"
              name="position"
              placeholder="シニアコンサルタント"
            />
            <ErrorMessage class="text-red-600" name="position" />
          </div>
        </div>

        <div class="form-group">
          <label class="block mb-2" for="website">Website</label>
          <Field
            class="field-item"
            id="website"
            name="website"
            type="url"
            placeholder="https://example.com"
          />
          <ErrorMessage class="text-red-600" name="website" />
        </div>

        <div class="form-group">
          <button
            class="px-4 py-2 text-white bg-gray-600 rounded shadow focus:outline-none hover:bg-gray-700"
          >
            プロフィールを更新する
          </button>
        </div>
      </Form>
    </div>
    <loading
      v-model:active="state.loading"
      :is-full-page="false"
      color="#818589"
      blur=""
    />
    <success-alart
      :show="state.showAlart"
      :title="state.alartTitle"
      :body="state.alartBody"
    />
  </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";
import yup from "../../../locale/custom-ja";
import RoundEdit from "../../icons/RoundEdit.vue";
import firebase from "firebase/app";
import { RepositoryFactoryForGae } from "../../../api/gae/RepositoryFactory";
const UserRepository = RepositoryFactoryForGae.get("user");
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SuccessAlart from "../alarts/SuccessAlart.vue";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    RoundEdit,
    Loading,
    SuccessAlart
  },

  async setup() {
    const store = useStore();

    let file;
    const state = reactive({
      loading: false,
      photoUrl: "",
      lastName: "",
      showAlart: false,
      alartTitle: "プロフィールを更新しました",
      alartBody: "反映されない場合はリロードしてください"
    });
    const initialValues = reactive({});

    const schema = yup.object({
      firstName: yup
        .string()
        .trim()
        .required()
        .label("名"),
      lastName: yup
        .string()
        .trim()
        .required()
        .label("姓"),
      displayName: yup.string().trim(),
      biography: yup.string(),
      department: yup.string().trim(),
      position: yup.string().trim(),
      website: yup
        .string()
        .label("Website")
        .url()
    });

    const setInitialValues = userInfo => {
      initialValues.firstName = userInfo.firstName;
      initialValues.lastName = userInfo.lastName;
      initialValues.displayName = userInfo.displayName;
      initialValues.biography = userInfo.biography;
      initialValues.department = userInfo.department;
      initialValues.position = userInfo.position;
      initialValues.website = userInfo.website;
    };

    const getUserInfo = async () => {
      return await UserRepository.getUserInfo().json();
    };

    // Setting userInfo
    const setUserInfo = async () => {
      const userInfo = await getUserInfo();
      state.url = userInfo.photoURL;
      state.lastName = userInfo.lastName;
      setInitialValues(userInfo);
    };

    await setUserInfo();

    const setImage = e => {
      file = e.target.files[0];
      state.url = URL.createObjectURL(file);
    };

    const upload = async ({ uid }) => {
      // Setting variables
      const fileName = file.name;
      const path = `profile-image/${uid}/${fileName}`;
      const storageRef = firebase.storage().ref();
      const profileImageRef = storageRef.child(path);

      // Upload profile image
      const snapshot = await profileImageRef.put(file);
      return await snapshot.ref.getDownloadURL();
    };

    const toggleLoading = () => {
      state.loading = !state.loading;
    };

    const showAlart = () => {
      state.showAlart = true;
      setTimeout(() => (state.showAlart = false), 5000);
    };

    const updateProfile = async values => {
      toggleLoading();
      const user = firebase.auth().currentUser;
      const payload = values;
      payload.userId = user.uid;

      // Set photoURL if there is new profile image
      if (file) payload.photoURL = await upload(user);

      // Update firestore and authentication
      await UserRepository.updateProfile(payload).then(() => {
        if (payload?.photoURL) {
          user
            .updateProfile({
              displayName: payload.displayName,
              photoURL: payload.photoURL
            })
            .then(async () => {
              await setUserInfo();
              store.dispatch("user/setUser");
              console.log("Successfuly updated profile");
            })
            .catch(error => {
              console.error(error.message);
            });
        } else {
          user
            .updateProfile({
              displayName: payload.displayName
            })
            .then(async () => {
              await setUserInfo();
              store.dispatch("user/setUser");
              console.log("Successfuly updated profile");
            })
            .catch(error => {
              console.error(error.message);
            });
        }
      });
      toggleLoading();
      showAlart();
    };

    return {
      state,
      schema,
      initialValues,
      setImage,
      updateProfile
    };
  }
};
</script>

<style lang="postcss" scoped>
.form-group {
  @apply mb-8;
}

.field-item {
  @apply block w-120 px-4 py-2 text-gray-700 shadow border rounded;
}

.field-half-item {
  @apply block w-56 px-4 py-2 text-gray-700 shadow border rounded;
}
</style>