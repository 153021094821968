<template>
  <Form
    v-slot="{ meta }"
    :initial-values="initialValues"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <div class="form-item">
      <div
        v-if="state.files.length == 0"
        class="border-2 border-dashed rounded-lg h-44 border-light-gray-500"
        @drop.prevent="onDrop"
        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
      >
        <label for="file">
          <div
            class="flex flex-col items-center justify-center h-full cursor-pointer hover:bg-white"
          >
            <file-upload-icon class="w-10 h-10 mb-2 color-gray-500" />
            <p class="text-sm text-gray-400">ファイルを選択してください</p>
          </div>
        </label>
        <input
          name="file"
          type="file"
          id="file"
          class="hidden"
          accept=".pptx, application/pdf"
          multiple
          @change="onSelect"
        />
      </div>
      <div
        v-else
        class="overflow-x-hidden overflow-y-auto border-2 h-44 border-light-gray-200"
        @drop.prevent="onDrop"
        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
      >
        <div
          v-for="file in state.files"
          :key="`${file.name}_${file.size}`"
          class="flex w-full bg-white border-b-2 border-light-gray-500"
        >
          <div class="flex justify-center w-12 p-1">
            <pdf-icon v-if="file.name.endsWith('pdf')" />
            <ppt-icon v-else />
          </div>
          <div class="flex items-center justify-between w-full p-2">
            <div class="max-w-sm">
              <p class="flex-initial truncate">{{ file.name }}</p>
            </div>
            <button
              class="p-1 ml-4 bg-gray-100 rounded-full hover:bg-gray-200"
              @click="unselect(file.name, file.size)"
            >
              <close-icon class="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-item">
      <label class="formItem-label">公開権限</label>
      <div class="flex items-center my-1">
        <Field
          name="permission"
          type="radio"
          id="public"
          value="public"
        ></Field>
        <label for="public" class="ml-2">
          <span class="font-semibold">🌏 公開 : </span>
          <span class="text-sm text-gray-400">
            ワークスペースに所属する全てのユーザに表示されます。
          </span>
        </label>
      </div>
      <div class="flex items-center my-1">
        <Field
          name="permission"
          type="radio"
          id="limited"
          value="limited"
        ></Field>
        <label for="limited" class="ml-2">
          <span class="font-semibold">🔒 限定 : </span>
          <span class="text-sm text-gray-400">
            資料は公開されず、ボード登録時にのみ他ユーザに表示されます。
          </span>
        </label>
      </div>
    </div>

    <div class="form-item">
      <details open>
        <summary class="formItem-label"
          >ボード設定<span class="ml-2 text-sm font-light text-gray-400"
            >任意で保存先ボードを選択できます。</span
          ></summary
        >
        <div
          class="px-6 py-3 my-1 overflow-y-auto divide-y rounded-sm shadow-inner max-h-64"
        >
          <div
            class="flex items-center h-8 mb-2 border border-gray-200 rounded-lg focus:outline-none"
          >
            <search-icon
              class="w-6 h-6 ml-2 text-gray-400 transition fill-current rounded-l-md"
            />
            <input
              class="w-full h-full pl-2 text-sm bg-white rounded-r-md focus:outline-none"
              type="search"
              placeholder="ボードの絞り込み..."
              v-model="state.filterKeyword"
            />
          </div>
          <div
            v-for="board in boards"
            :key="board.id"
            class="flex items-center w-full p-2"
          >
            <Field
              class="w-4 h-4"
              :value="board.id"
              :id="board.id"
              name="boards"
              type="checkbox"
            ></Field>
            <div :for="board.id" class="flex items-center justify-start ml-2">
              <div
                class="flex items-center justify-center w-6 h-6 mr-2 border rounded-full"
              >
                {{ board.emoji ? board.emoji : "✋" }}‍
              </div>
              <label
                :for="board.id"
                class="transition cursor-pointer hover:underline"
                >{{board.visibility === "limited" ? "🔒" : ""}} {{ board.name }}</label
              >
            </div>
          </div>
        </div>
      </details>
    </div>

    <button
      class="w-full px-4 py-2 text-white bg-gray-600 rounded shadow focus:outline-none hover:bg-gray-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
      :disabled="!(meta.valid && exists)"
    >
      アップロードを開始する
    </button>
  </Form>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import yup from "../../locale/custom-ja";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepository = RepositoryFactoryForGae.get("file");
import utility from "./composables/utility"
import FileUploadIcon from "../icons/FileUploadIcon.vue";
import PdfIcon from "../icons/pdf.vue";
import PptIcon from "../icons/ppt.vue";
import SearchIcon from "../icons/SearchIcon.vue";
import CloseIcon from "../icons/CloseIcon.vue";

export default {
  components: {
    Form,
    Field,
    SearchIcon,
    FileUploadIcon,
    PdfIcon,
    PptIcon,
    CloseIcon
  },

  props: {
    droppedFiles: {
      type: Array,
      required: false
    },
    boardIds: {
      type: Array,
      required: false
    }
  },

  emits: ["beforeUpload", "afterUpload"],

  setup(props, { emit }) {
    const store = useStore();

    const initialValues = reactive({
      permission: "public",
      boards: props.boardIds
    });

    const schema = yup.object({
      permission: yup
        .string()
        .required()
        .label("公開権限")
    });

    const state = reactive({
      files: [],
      loading: false,
      filterKeyword : ""
    });

    const { filterBoardByKeyword } = utility();
    const boards = computed(() => { 
      const myBoard = [...store.getters['board/defaultBoards'], ...store.getters['board/customBoards']];
      return filterBoardByKeyword(myBoard, state.filterKeyword);
    });

    const exists = computed(() => {
      return state.files.length > 0;
    });

    const getUploadUrl = async (fileName, values) => {
      const payload = {
        name: fileName,
        permission: values.permission,
        boards: values.boards ? values.boards : [],
        canPublicNotify: values.canPublicNotify
      };
      const res = await FileRepository.getUploadUrl(payload).json();
      return res.url;
    };

    const onSubmit = async (values, { resetForm }) => {
      emit("beforeUpload");
      // Check board permission for notify to public.
      const boardPermission = [];
      boards.value.map((board) => {
        values.boards?.forEach((b) => {
          if (board.id == b) boardPermission.push(board.visibility);
        })
      });
      if (boardPermission.includes("limited"))
        values.canPublicNotify = true;
      else
        values.canPublicNotify = true;
      // boardsにnullが入ってしまう事象を確認
      // https://www.notion.so/northsand/10-3-97a0385a0f4945aab62c630cdf39881d
      console.debug('before filter boards', values.boards);
      if (typeof values.boards === 'string' || values.boards instanceof String) {
        console.debug('boards is string', values.boards);
        values.boards = values.boards.split();
        console.debug('converted string boards', values.boards);
      } else if (!values.boards) {
        values.boards = []; // Set empty array if 'values.boards' is null or undefined
      }
      values.boards = Array.from(values.boards).filter(x => !!x );
      console.debug('after filter boards', values.boards);

      for (const file of state.files) {
        const uploadUrl = await getUploadUrl(file.name, values);
        console.debug("uploadUrl", uploadUrl);
        const uploadFileOptions = {
          headers: { "Content-Type": "application/octet-stream" },
          body: file
        };
        await FileRepository.uploadFile(
          uploadUrl,
          uploadFileOptions
        ).catch(error => console.error(error));
      }
      state.files = [];
      resetForm();
      emit("afterUpload");
    };

    const validNumFiles = files => {
      // Validate number of files
      const maxNumFiles = 10;
      const allFiles = [...state.files, ...files];
      if (allFiles.length > maxNumFiles) {
        alert(`最大${maxNumFiles}つのファイルのみアップロードできます。`);
        return false;
      }
      return true;
    };

    const validFileSize = file => {
      // Validate each file size
      const mb = 70; 
      const maxFileSize = mb * 1024 * 1024;
      return file.size < maxFileSize;
    };

    const isEncrypt = async file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileName = file.name;
          const contents = event.target.result;
          if (contents.indexOf("/Encrypt") !== -1) {
            console.debug(`${fileName} is encrypted`);
            resolve(true);
          } else {
            console.debug(`${fileName} is not encrypted`);
            resolve(false);
          }
        };

        reader.onerror = (event) => {
          console.error(
            "File could not be read! Code " + event.target.error.code
          );
          reject();
        };
        reader.readAsText(file);
      });
    };

    const getTargetFiles = async (files) => {
      // Validate each file size
      const msg = [];
      const excludeOverSizeFiles = files.filter(file => {
        const isValidSize = validFileSize(file);
        if (isValidSize) {
          return true;
        } else {
          const errMsg = `${file.name}(${Math.ceil(
            file.size / 1024 / 1024
          )}MB)はファイルサイズ制限を超過しています。`;
          msg.push(errMsg);
          return false;
        }
      });

      if (msg.length > 0) alert(msg.join("\n"));
      return excludeOverSizeFiles;
    };

    const selectFiles = async (files, e = null) => {
      const isValidNumFiles = validNumFiles(files);
      if (!isValidNumFiles) return false;
      const targetFiles = await getTargetFiles(files);
      state.files = [...state.files, ...targetFiles];
    };

    if (props.droppedFiles && props.droppedFiles.length > 0)
      selectFiles(props.droppedFiles);

    const onDrop = evt => {
      const files = Array.from(evt.dataTransfer.files).filter(
        file => file.name.endsWith(".pdf") || file.name.endsWith(".pptx")
      );
      selectFiles(files);
    };

    const onSelect = e => {
      const files = Array.from(e.target.files);
      selectFiles(files);
    };

    const unselect = (name, size) => {
      state.files = state.files.filter(
        file => !(file.name == name && file.size == size)
      );
    };

    return {
      boards,
      initialValues,
      state,
      schema,
      exists,
      onDrop,
      onSelect,
      onSubmit,
      unselect
    };
  }
};
</script>

<style lang="postcss" scoped>
.form-item {
  @apply text-gray-600 mb-4;
}

.formItem-label {
  @apply text-base font-semibold cursor-pointer;
}

.formItem-field {
  @apply block px-2 pb-4 rounded w-full shadow border;
}
</style>