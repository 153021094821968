<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--system-uicons"
    width="32"
    height="32"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 21 21"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M1.5 10.5l9-9l9 9"></path>
      <path d="M3.5 11.5v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4"></path>
    </g>
  </svg>
</template>