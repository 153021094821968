<template>
  <operation-modal
    :showModal="true"
    @toggleModal="toggleBoardModal"
  >
    <template v-slot:header>
      参加者リスト
    </template>

    <template v-slot:body>
      <!-- メンバーのフィルタリング -->
      <div class="mx-1 mb-6">
        <div class="flex border rounded" :class="{ 'search-focus': state.focus }">
          <div class="flex items-center justify-center px-2 border-r">
            <search-icon class="w-6 h-6 text-gray-500" />
          </div>
          <input type="text" placeholder="メンバーを検索" @focus="state.focus = true" @blur="state.focus = false" v-model="state.word" class="px-2 py-1.5 w-full focus:outline-none">
        </div>
      </div>

      <!-- メンバー一覧 -->
      <div v-for="user in members" :key="user.id" class="mb-2 last:mb-0">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <div class="flex items-center justify-center w-8 h-8 border rounded-full">
              <img v-if="user.photoURL" class="object-cover w-full h-full border-none rounded-full" :src="user.photoURL" />
              <div v-else class="flex items-center justify-center w-full h-full text-lg rounded-full bg-gray-50">
                <span>{{ user?.firstName ? user.firstName.slice(0, 1) : '?' }}</span>
              </div>
            </div>
            <div class="ml-2">
              <p>{{ `${user.lastName} ${user.firstName}` }}<span class="mx-2 text-gray-500">{{ user.displayName }}</span></p>
            </div>
          </div>
          <div class="text-gray-500">
            <p v-if="user.permission === 'owner'">⚙️ 管理者</p>
            <p v-if="user.permission === 'member'">🔍 メンバー</p>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="mx-6">
        <hr>
        <div class="flex justify-end my-4">
          <button @click="toggleBoardModal" class="text-black bg-gray-200 button hover:bg-gray-300">
            キャンセル
          </button>
        </div>
      </div>
    </template>
  </operation-modal>
</template>

<script>
import { reactive, computed } from "vue";
import OperationModal from "./BaseModal.vue";
import SearchIcon from "../../icons/SearchIcon.vue"

export default {
  components: {
    OperationModal,
    SearchIcon
  },

  props: {
    toggleBoardModal: {
      type: Function,
      required: true
    },
    boardInfo: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const state = reactive({
      focus: false,
      word: ""
    })

    const members = computed(() => {
      return props.boardInfo.participants.filter(user => {
        const isMatchFullName = `${user.firstName} ${user.lastName}`.includes(state.word);
        const isMatchDisplayName = user.displayName.includes(state.word);
        return isMatchFullName || isMatchDisplayName;
      })
    })

    return { 
      state,
      members
    }
  }
};
</script>

<style lang="postcss" scoped>
.button {
  @apply shadow rounded px-3 py-1.5 focus:outline-none;
}
.search-focus {
  @apply ring-2 ring-indigo-400;
}
</style>