<template>
  <div
    v-if="showModal"
    @click.self="closeModal"
    class="fixed inset-0 z-40 flex items-center overflow-y-auto justify-center outline-none focus:outline-none"
  >
    <div class="w-auto max-w-lg max-h-screen mx-auto">
      <div class="w-full overflow-y-auto bg-white rounded-lg">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="fixed inset-0 z-30 bg-black opacity-70"></div>
</template>

<script>
export default {
  props: {
    showModal: Boolean
  },

  emits: [
    'closeModal'
  ],

  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    };

    return { closeModal };
  }
};
</script>