<template>
  <div>
    <div class="grid gap-6 row lg:grid-cols-4 md:grid-cols-3 ranking-wrapper">
      <div
        v-for="(file, index) in files"
        :key="`ga${file.slideId}`"
        class="bg-white rounded shadow-md"
        draggable="true"
        @dragstart="onDragStartFromTop($event, file)"
        @dragend="onDragEnd"
      >
        <div @click.prevent="openPreviewModal(file)" class="cursor-pointer">
          <div class="relative rounded-t preview bg-gray-50">
            <div
              v-bind:style="{
                backgroundImage: 'url(' + state.baseUrl + file.slideId + '.jpg)'
              }"
              class="absolute inset-0 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9 filter contrast-50"
            ></div>
            <div
              v-bind:style="{
                backgroundImage: 'url(' + state.baseUrl + file.slideId + '.jpg)'
              }"
              class="bg-center bg-no-repeat bg-contain preview-thumbnail aspect-w-16 aspect-h-9"
              :class="{ranking: $route.name === 'trend'}"
              v-on:mouseover.self="
                state.hoverFlag = true;
                state.hoverIndex = index;
              "
            ></div>
            <div
              v-show="state.hoverFlag && index === state.hoverIndex"
              class="absolute inset-0 transition hover:bg-black hover:bg-opacity-30 "
              v-on:mouseleave.self="state.hoverFlag = false"
            >
              <div class="relative">
                <!-- v-if="userInfo.id != file.created_by" -->
                <div class="absolute top-0 flex justify-end w-full p-2">
                  <button
                    v-if="isLikedPackage(file)"
                    @click.stop="unlikePackage(file)"
                    class="px-2 text-sm text-white transition-all duration-150 ease-linear rounded-full outline-none focus:outline-none"
                  >
                    <liked-icon class="text-3xl" />
                  </button>
                  <button
                    v-else
                    @click.stop="likePackage(file)"
                    class="px-2 text-sm text-white transition-all duration-150 ease-linear rounded-full outline-none focus:outline-none"
                  >
                    <unlike-icon class="text-3xl" />
                  </button>
                </div>
              </div>
            </div>
            <div
              class="absolute outline-none top-3 left-3 filter drop-shadow-xl focus:outline-none"
              v-show="file.permission === 'limited'"
            >
              <lock-icon
                class="w-5 h-5 text-yellow-500 stroke-current stroke-2"
              />
            </div>
          </div>
        </div>
        <div class="px-2 py-1 text-gray-600 meta">
          <div class="flex items-center justify-between mb-1 meta-top">
            <p
              class="text-sm truncate"
              style="max-width: 20vh"
            >
              {{
                $route.params?.type === "slides"
                  ? `[${file.slide_number}]` + file.fileTitle
                  : file.fileTitle
              }}
            </p>
            <p class="ml-2 text-xs text-gray-400 truncate">
              {{ formatTime(file.created_timestamp) }}
            </p>
          </div>

          <div
            class="flex items-center justify-between mb-1.5 text-gray-400 meta-buttom"
          >
            <router-link
              class="flex items-center transition meta-user hover:text-gray-600 group"
              tabindex="-1"
              :to="{ name: 'user', path: `/users/${file.user.username}/profile`, params: { username: file.user.username, workspace: file.user.workSpace, id: file.user.id } }"
            >
              <img
                v-if="file.user.photoURL"
                :src="file.user.photoURL"
                alt=""
                class="object-cover w-5 h-5 border rounded-full"
              />
              <div
                v-else
                class="flex items-center justify-center object-cover w-5 h-5 border rounded-full"
              >
                <span class="text-xs">{{
                  file.user.firstName.slice(0, 1)
                }}</span>
              </div>

              <p
                v-if="file.user.displayName"
                class="ml-1 text-xs truncate group-hover:underline"
              >
                {{ file.user.displayName }}
              </p>
              <p v-else class="ml-2 text-xs truncate">
                {{ `${file.user.lastName} ${file.user.firstName}` }}
              </p>
            </router-link>
            <div class="flex items-center">
              <tooltip-text text="いいね数" placement="buttom">
                <div class="flex">
                  <liked-icon
                    v-if="isLikedPackage(file)"
                    class="w-4 h-4 ml-2"
                  />
                  <like-count-icon
                    v-else
                    class="w-4 h-4 ml-2"
                  />
                  <span
                    class="ml-0.5 text-xs"
                    :class="isLikedPackage(file) ? 'text-red-400' : ''"
                  >
                    {{ file.liked_users?.length ?? 0 }}
                  </span>
                </div>
              </tooltip-text>
              <tooltip-text text="コメント数" placement="buttom">
                <div class="flex">
                  <comment-icon class="w-4 h-4 ml-2" />
                  <span class="ml-0.5 text-xs">{{ file.comment?.length ?? 0 }}</span>
                </div>
              </tooltip-text>
              <tooltip-text text="閲覧数" placement="buttom">
                <div class="flex">
                  <eye-icon class="w-4 h-4 ml-2" />
                  <span class="ml-0.5 text-xs">{{ file.pageview }}</span>
                </div>
              </tooltip-text>
            </div>
          </div>
          <div class="flex items-center mb-0.5">
            <div
              v-if="state.addPinFlag && state.selectedPin == file"
            >
              <button
                v-if="boards.length > 0 && hasAddPinPermission(file)"
                @click.stop="toggleOperationModal(file)"
                class="flex mr-1.5 border border-gray-400 rounded transition hover:text-white hover:border-blue-400 hover:bg-blue-400 focus:outline-none "
                type="button"
              >
                <cancel-add-pin-icon />
              </button>
            </div>
            <tooltip-text
              v-else
              text="ボードに追加"
              placement="bottom"
            >
              <button
                v-if="boards.length > 0 && hasAddPinPermission(file)"
                @click.stop="toggleOperationModal(file)"
                class="flex mr-1.5 border border-gray-400 rounded transition hover:text-white hover:border-blue-400 hover:bg-blue-400 focus:outline-none "
                type="button"
              >
                <plus class="w-4 h-4 p-0.5" />
              </button>
            </tooltip-text>
            <tooltip-text text="他人の非公開資料はボードに追加できません" placement="right">
              <button
                v-if="!(boards.length > 0 && hasAddPinPermission(file))"
                class="flex mr-1.5 border border-gray-300 rounded cursor-not-allowed "
              >
                <plus class="w-4 h-4 p-0.5" />
              </button>
            </tooltip-text>
            <div
              v-if="state.addPinFlag && state.selectedPin == file"
              class="flex flex-row"
            >
              <div class="h-5 mr-1">
                <input
                  id="search-form"
                  class="flex justify-center items-center w-44 h-full pl-2 text-xs border border-gray-400 bg-gray-100 rounded-lg focus:outline-none"
                  type="text"
                  autocomplete="off"
                  @focus="onFocus"
                  v-model="state.keyword"
                  v-focus
                />
                <div
                  v-if="state.displayBoardSuggestion"
                  class="flex overflow-y-scroll rounded"
                >
                  <ul class="z-40 text-sm h-32 w-44">
                    <li
                      v-for="board in allBoardsList"
                      :key="board"
                      class="pl-2 py-1 bg-white hover:bg-gray-100 hover:cursor-pointer"
                      @click="selectedBoardfromSuggestion(board)"
                    >
                      <div class="flex flex-row">
                        <hash-tag-icon
                          v-if="board.visibility == 'public'"
                          class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600 text-base"
                        />
                        <private-icon
                          v-else
                          class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600 text-base"
                        />
                        <div class="w-full">{{ board.name }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <button
                type="button"
                class="text-xs px-1 rounded border border-gray-300 hover:bg-blue-500 hover:text-white disabled:bg-gray-300 disabled:text-white"
                :class="[state.keyword ? 'hover:border-white' : 'hover:cursor-auto']"
                @click="addPinToBoard(file)"
                :disabled="!state.keyword"
              >
                追加
              </button>
            </div>
            <div
              v-else
              class="flex overflow-x-scroll"
            >
              <div
                v-for="board in file.boards"
                :key="board.id"
                class="flex-none mr-0.5 text-xs p-0.5 rounded-lg truncate transition"
                style="max-width: 170px"
                @mouseover="hoverBoard(file, board, 'hover')"
                @mouseleave="hoverBoard(file, board, 'leave')"
              >
                <div class="flex flex-row">
                  <div
                    v-if="board.visibility === 'public'"
                    class="flex flex-row"
                  >
                    <button
                      v-if="state.displayDeleteIcon
                        && state.targetFile.id == file.id
                        && state.targetBoard.id == board.id
                      "
                      type="button"
                      @click="togglePinModal(file, board)"
                    >
                      <cancel-icon class="mr-0.5 font-bold" />
                    </button>
                    <hash-tag-icon
                      v-else
                      class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600"
                    />
                    <router-link
                      :to="{ path: `/boards/${board.id}` }"
                      class="hover:underline"
                      @mouseover="hoverBoard(file, board, 'hover')"
                      @mouseleave="hoverBoard(file, board, 'leave')"
                    >
                      <span class="hover:underline">{{ board.name }}</span>
                    </router-link>
                  </div>
                  <div
                    v-if="board.visibility === 'limited' &&
                        [
                          ...board.owners,
                          ...board.members
                        ].includes(state.user.uid)
                    "
                    class="flex flex-row"
                  >
                    <button
                      v-if="state.displayDeleteIcon
                        && state.targetFile.id == file.id
                        && state.targetBoard.id == board.id
                      "
                      type="button"
                      @click="togglePinModal(file, board)"
                      @mouseover="hoverBoard(file, board, 'hover')"
                      @mouseleave="hoverBoard(file, board, 'leave')"
                    >
                      <cancel-icon class="mr-0.5 font-bold" />
                    </button>
                    <private-icon
                      v-else
                      class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600"
                    />
                    <router-link
                      :to="{ path: `/boards/${board.id}` }"
                      class="flex flex-row hover:underline"
                      @mouseover="hoverBoard(file, board, 'hover')"
                      @mouseleave="hoverBoard(file, board, 'leave')"
                    >
                      <div>{{ board.name }}</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <operation-add-pin-modal
      :show-pin-modal="state.addPinModalFlag"
      :close-pin-modal="toggleOperationModal"
      :file="state.selectedPin"
      @update-boards="updateBoard"
    />

    <operation-delete-pin-modal
      v-if="state.modalVisibleFlag"
      :modal-visible-flag="state.modalVisibleFlag"
      :toggle-pin-modal="togglePinModal"
      :selected-pin="state.selectedPin"
      :board-info="state.targetBoard"
      @remove-pin="removePinFromDeletePinModal"
      @close-modal="closeDeletePinModal"
      class="fadecenter"
    />

    <div v-for="file in files" :key="file.sildeId">
      <preview-modal
        v-if="state.packageInfo.slideId == file.slideId"
        :anchor_point="state.anchorPoint"
        :package-info="state.packageInfo"
        :fade-mode="state.fadeMode"
        @update-package-info="updatePackageInfo"
        @delete-package-info="deletePackageInfo"
        @toggle="closePreviewModal"
        @switch-package="switchPackage"
      ></preview-modal>
    </div>
    <invite-users-modal
      v-if="state.showInviteUsersModal"
      :showModal="state.showInviteUsersModal ? true : false"
      :closeModal="closeInviteUsersModal"
    >
    </invite-users-modal>

    <loading
      v-model:active="state.loading"
      color="#818589"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import { onBeforeMount, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepositoryForGae = RepositoryFactoryForGae.get("file");
const BoardRepositoryForGae = RepositoryFactoryForGae.get("board");
const UserRepository = RepositoryFactoryForGae.get("user");
import useBoardRepository from "./composables/useBoardRepository";
import useUserRepository from "./composables/useUserRepository";
import PreviewModal from "./modals/PreviewModal.vue";
import InviteUsersModal from "./modals/InviteUsersModal.vue";
import OperationAddPinModal from "./modals/PinModalAdd.vue";
import OperationDeletePinModal from "./modals/PinDeleteModal.vue";
import TooltipText from "./utilities/TooltipContent.vue";
import utility from "./composables/utility";

import EyeIcon from "../icons/EyeIcon.vue";
import Plus from "../icons/Plus.vue";
import UnlikeIcon from "../icons/UnlikeIcon.vue";
import LikedIcon from "../icons/LikedIcon.vue";
import LikeCountIcon from "../icons/LikeCountIcon.vue";
import CommentIcon from "../icons/CommentIcon.vue";
import LockIcon from "../icons/LockIcon.vue";
import CancelAddPinIcon from "../icons/CancelIcon.vue";
import CancelIcon from "../icons/CancelBaselineIcon.vue";
import PrivateIcon from "../icons/PrivateIcon.vue";
import HashTagIcon from "../icons/HashTagIcon.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    PreviewModal,
    OperationAddPinModal,
    OperationDeletePinModal,
    InviteUsersModal,
    TooltipText,
    EyeIcon,
    Plus,
    LockIcon,
    UnlikeIcon,
    LikedIcon,
    LikeCountIcon,
    CommentIcon,
    CancelAddPinIcon,
    CancelIcon,
    PrivateIcon,
    HashTagIcon,
    Loading
  },

  props: {
    files: Array
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const user = firebase.auth().currentUser;

    const state = reactive({
      baseUrl: `${location.origin}/img/`,
      hoverFlag: false,
      hoverIndex: null,
      addPinModalFlag: false,
      addPinFlag: false,
      selectedPin: {},
      anchorPoint: "",
      previewModalFlag: false,
      packageInfo: {},
      previewId: null,
      fadeMode: "center",
      boards: [],
      user: user,
      showInviteUsersModal: false,
      loading: false,
      modalVisibleFlag: false,
      text: "",
      keyword: "",
      modalOperationFlag: "",
      targetBoard: {},
      targetFile: {},
      addPinTargetBoard: {},
      displayDeleteIcon: false,
      displayBoardSuggestion: false,
    });

    const { getUsers } = useUserRepository();
    const checkFirstLogin = async () => {
      if (!store.state.user.userInfo.first_login) {
        const users = await getUsers({ workSpace: store.state.user.userInfo.workSpace });
        if (users.length <= 2)
          state.showInviteUsersModal = true;
        await UserRepository.updateFirstLogin();
      }
    };
    onBeforeMount(() => {
      checkFirstLogin();
    });

    const boards = computed(() => store.state.board.myBoard);
    const favBoard = computed(() =>
      store.getters["board/defaultBoards"].find(x => x.name === "お気に入り")
    );
    const allBoardsList = computed(() => {
      let mergedBoards = Array.from(
        new Set(
          [...store.state.board.myPrivateBoard, ...store.state.board.publicBoard]
        )
      );
      if (state.keyword) {
        mergedBoards = mergedBoards.filter((board) => {
          return board.name.includes(state.keyword);
        });
      }
      return mergedBoards;
    });

    const checkAddedBoard = (targetBoard, addedBoards) => {
      const isAddedBoard = addedBoards.some((board) => board.id == targetBoard.id);
      return isAddedBoard;
    };

    const { getMyBoard, getMyBoardToSideBar, getPublicBoard } = useBoardRepository();
    const userInfo = computed(() => {
      return store.state.user.userInfo;
    });
    const updateBoard = async () => {
      await getMyBoard({ workSpace: userInfo.value.workSpace, userId: user.uid });
      await getPublicBoard({ workSpace: userInfo.value.workSpace });
      await getMyBoardToSideBar({ workSpace: userInfo.value.workSpace, userId: user.uid });
    };

    const closePreviewModal = () => {
      state.previewModalFlag = false;
      state.fadeMode = "center";
      state.packageInfo = {};
    };

    const hasAddPinPermission = file => {
      if (file.permission === "limited") {
        return file.created_by === user.uid;
      } else {
        return true;
      }
    };

    const toggleOperationModal = (file = {}) => {
      state.selectedPin = file;
      state.addPinFlag = !state.addPinFlag;
      if (!state.addPinFlag) state.keyword = "";
    };

    const hasFavPin = file => {
      const pins = favBoard.value.pins;
      const field = route.params.type === "slides" ? "slideId" : "packageId";
      return pins.some(pin => pin.pin_id === file[field]);
    };

    const incrementPageview = (fileId, fileType) => {
      const payload = { target: fileId, type: fileType };
      return FileRepositoryForGae.incrementPageview(payload).json();
    };

    const openPreviewModal = async (file) => {
      const url = await getContent(file);
      history.pushState(history.state, "", url);
    }

    const getSortedChildrenIds = (childrenIds) => {
      return childrenIds.sort().map(packageId => packageId.slice(5));
    }

    const getContent = packageInfo => {
      const sorted_children_ids = getSortedChildrenIds(packageInfo.children_ids_number);
      packageInfo.sorted_children_ids = sorted_children_ids;

      state.packageInfo = packageInfo;
      state.previewModalFlag = true;

      let url;
      if (route.name === 'trend') {
        state.anchorPoint = route.query?.q === "slide" ? packageInfo.slideId : sorted_children_ids[0];
        url = `/package/${packageInfo.packageId}`

        if (route.query?.q === "slide") {
          url = `${url}#${state.anchorPoint}`;
          incrementPageview(packageInfo.slideId, "slide");
        } else {
          incrementPageview(packageInfo.packageId, "package");
        }
      } else {
        state.anchorPoint = route.params?.type === "slides" ? packageInfo.slideId : sorted_children_ids[0];
        url = `/package/${packageInfo.packageId}`

        if (route.params?.type === "slides") {
          url = `${url}#${state.anchorPoint}`;
          incrementPageview(packageInfo.slideId, "slide");
        } else {
          incrementPageview(packageInfo.packageId, "package");
        }
      }

      return url;
    };

    const switchPackage = target => {
      const currentIndex = props.files.findIndex(
        file => file.slideId == state.packageInfo.slideId
      );
      const nextIndex = target == "next" ? currentIndex + 1 : currentIndex - 1;
      const numFiles = props.files.length;

      const isFirst = nextIndex < 0;
      const isLast = nextIndex > numFiles - 1;

      if (!(isFirst || isLast)) {
        const nextPackage = props.files[nextIndex];
        closePreviewModal();
        state.fadeMode = target == "next" ? "left" : "right";
        const url = getContent(nextPackage);
        history.replaceState(history.state, "", url);
      }
    };

    /** Should be in composables file coz this func can be used several files. */
    const fileType = router.currentRoute.value.params.type;
    const addPin = async file => {
      state.loading = true;
      const boardId = state.addPinTargetBoard.id;
      const pin_id = fileType == "slides" ? file.slideId : file.packageId;
      const pin_type = fileType == "slides" ? "slide" : "package";
      const payload = { pins: [{ pin_id, pin_type }], board_id: boardId };
      await BoardRepositoryForGae.addPin(payload);
      await updateBoard();
      state.loading = false;
      state.addPinFlag = false;
      state.keyword = "";
    };
    const removePin = async (file) => {
      const boardId = favBoard.value.id;
      const pinId = fileType == "slides" ? file.slideId : file.packageId;
      const pinType = fileType == "slides" ? "slide" : "package";
      const payload = { board_id: boardId, pins: [{ pin_id: pinId, pin_type: pinType }] };
      await BoardRepositoryForGae.removePin(payload);
      await updateBoard();
    };
    const createBoard = async values => {
      state.loading = true;
      const description = values.description ? values.description : ""
      const users = [];
      const payload = {
        boards: [
          {
            name: values.boardName,
            description: description,
            visibility: values.visibility,
            emoji: values.emoji,
            coverURL: values.coverURL,
            users: users
          }
        ],
        url_domain: location.origin
      };
      const result = await BoardRepositoryForGae.create(payload).json();

      state.loading = false;
      return result;
    };

    const addPinToBoard = async (file) => {
      const mergedBoards = Array.from(
        new Set(
          [...store.state.board.myPrivateBoard, ...store.state.board.publicBoard]
        )
      );
      const existedBoard = mergedBoards.filter((board) => board.name == state.keyword);
      if (existedBoard.length > 0) return await addPin(file);
      else {
        const createBoardValue = {
          boardName: state.keyword,
          description: "",
          visibility: "public",
          emoji: "",
          coverURL: "",
        };
        const result = await createBoard(createBoardValue);
        if (result.status === "200") {
          state.addPinTargetBoard.id = result.id;
          return await addPin(file);
        }
      }
    };

    const isLikedPackage = file => {
      const likeList = userInfo.value.like_packages_list || [];
      return likeList.some(like => like === file.id);
    };
    const likePackage = async (file) => {
      const payload = {
        target: file.packageId,
        event_type: "like",
        url: {
          domain: location.origin,
          endpoint: `/package/${file.packageId}`
        }
      };
      store.state.user.userInfo.like_packages_list.push(file.packageId);
      file.liked_users.push(store.state.user.userInfo.username);
      await FileRepositoryForGae.likePackage(payload);
      await store.dispatch("user/setUser");
    };
    const unlikePackage = async (file) => {
      const payload = { target: file.packageId };
      store.state.user.userInfo.like_packages_list = store.state.user.userInfo.like_packages_list.filter(x => x != file.packageId);
      file.liked_users = file.liked_users.filter(x => x != store.state.user.userInfo.username);
      await FileRepositoryForGae.unlikePackage(payload);
      await store.dispatch("user/setUser");
    };

    const { formatTime, onDragStart, onDragEnd } = utility();

    const onDragStartFromTop = (evt, file) => {
      const fileType = route.params.type === "slides" ? "slide" : "package";
      const params = {
        fileType: fileType,
        fileId: fileType === "package" ? file.packageId : file.slideId,
        permission: file.permission,
        createdBy: file.created_by,
        headerId: file.slideId,
        sortable: false
      };

      onDragStart(evt, params);
    };

    // Update packageInfo which is changed some fields in child components.
    const updatePackageInfo = data => {
      emit("updatePackageInfo", data);
    };

    const deletePackageInfo = packageId => {
      emit("deletePackageInfo", packageId);
    };

    const closeInviteUsersModal = () => {
      state.showInviteUsersModal = false;
    };

    const togglePinModal = (pin, board) => {
      state.selectedPin = pin;
      state.modalVisibleFlag = !state.modalVisibleFlag;
      state.targetBoard = board;
    };

    const removePinFromDeletePinModal = async () => {
      state.modalVisibleFlag = !state.modalVisibleFlag;
    };

    const closeDeletePinModal = async () => {
      state.modalVisibleFlag = !state.modalVisibleFlag;
    };

    const hoverBoard = (file, board, type) => {
      if (type == "hover") state.displayDeleteIcon = true;
      else state.displayDeleteIcon = false;
      state.targetFile = file;
      state.targetBoard = board;
    };

    const onFocus = () => {
      state.displayBoardSuggestion = true;
    };

    const onBlur = () => {
      state.displayBoardSuggestion = false;
    };

    const selectedBoardfromSuggestion = (target) => {
      state.addPinTargetBoard = target;
      state.keyword = target.name;
      state.displayBoardSuggestion = false;
    };

    return {
      state,
      boards,
      userInfo,
      allBoardsList,
      updateBoard,
      closePreviewModal,
      hasAddPinPermission,
      toggleOperationModal,
      hasFavPin,
      openPreviewModal,
      switchPackage,
      addPin,
      removePin,
      addPinToBoard,
      isLikedPackage,
      likePackage,
      unlikePackage,
      onDragStartFromTop,
      onDragEnd,
      formatTime,
      updatePackageInfo,
      deletePackageInfo,
      closeInviteUsersModal,
      togglePinModal,
      removePinFromDeletePinModal,
      closeDeletePinModal,
      hoverBoard,
      onFocus,
      onBlur,
      selectedBoardfromSuggestion,
      checkAddedBoard
    };
  }
};
</script>


<style scoped>
::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

.ranking::before {
  content: "";
  position: absolute;
  border-bottom: 80px solid transparent;
  border-left: 80px solid rgba(0,0,0,0.5)
}

.ranking-wrapper {
  counter-reset: ranking;
}

.ranking::after {
  counter-increment: ranking;
  content: counter(ranking) " 位";
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 600;
  color: #fff
}

/* .fadecenter {
  animation-name: fadecenter;
  animation-duration: 0.3s;
}

@keyframes fadecenter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
</style>