<template>
  <div style="min-height: 620px">
    <top-main-content
      :files="files"
      v-if="deviceType == 'pc'"
      @delete-package-info="deletePackageInfo"
      @update-package-info="updatePackageInfo"
    />
    <mobile-home
      :files="files"
      v-if="deviceType == 'mobile'"
      @delete-package-info="deletePackageInfo"
      @update-package-info="updatePackageInfo"
    />
    <infinite-loading
      v-if="showInfiniteLoading"
      spinner="circles"
      @infinite="infiniteHandler"
    />
  </div>
</template>

<script>
import { onBeforeMount, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import TopMainContent from "../organisms/TopMainContent.vue";
import infiniteLoading from "vue-infinite-loading";
import useSearchRepository from "./composables/useSearchRepository";
import MobileHome from "../mobile/templates/Home.vue";
import calcBreakpoint from "../organisms/composables/calcBreakpoint";

export default {
  components: {
    TopMainContent,
    infiniteLoading,
    MobileHome
  },

  setup(_, { emit }) {
    const deviceType = calcBreakpoint(window.innerWidth);
    const route = useRoute();
    const store = useStore();

    const {
      state,
      showInfiniteLoading,
      searchPackagePayload,
      payload,
      searchPackage,
      deletePackageInfo,
      updatePackageInfo,
      infiniteHandler
    } = useSearchRepository();

    const files = computed(() => {
      return state.files.map(file => {
        const privateBoards = store.state.board.myPrivateBoard.filter(board => board.pins.some(pin => pin.pin_id === file.id));
        const publicBoards = store.state.board.publicBoard.filter(board => board.pins.some(pin => pin.pin_id === file.id));
        const mergedBoards = [...privateBoards, ...publicBoards];
        return Object.assign(file, { boards: mergedBoards });
      })
    })

    onBeforeMount(async () => {
      emit("start-loading");
      Object.assign(payload, { ...searchPackagePayload, ...route.query })
      state.files = await searchPackage(payload);
      emit("end-loading");
    });

    watchEffect(() => {
      const numFiles = state.files.length;
      if (numFiles > 0) {
        switch (route.params.type) {
          case "packages":
            payload.cursor = state.files[numFiles - 1].packageId;
            break;
          case "slides":
            state.fileType = "slide";
            payload.cursor = state.files[numFiles - 1].slideId;
            break;
          default:
            payload.cursor = state.files[numFiles - 1].packageId;
        }
      }
    });

    return {
      deviceType,
      state,
      files,
      showInfiniteLoading,
      deletePackageInfo,
      updatePackageInfo,
      infiniteHandler
    };
  }
};
</script>