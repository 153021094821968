<template>
  <div
    class="relative right-32 p-1 z-50 w-44 origin-top-right bg-white border rounded-md shadow-lg max-h-60"
  >
    <button
      class="px-2 py-1 hover:bg-blue-600 hover:text-white"
      @click="editComment"
    >
      コメントを編集する
    </button>
    <button
      class="px-2 py-1 text-red-600 hover:bg-red-600 hover:text-white"
      @click="deleteComment"
    >
      コメントを削除する
    </button>
  </div>
</template>

<script>
import { reactive } from "vue";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepository = RepositoryFactoryForGae.get("file");

export default {
  props: {
    commentInfo: {
      type: Object,
      required: true
    },
    fileInfo: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      comment: props.commentInfo,
      file: props.fileInfo,
    });

    const editComment = () => {
      emit("editComment", state.comment.id);
    };

    const deleteComment = async () => {
      const payload = {
        type: "packages",
        file_id: state.file.id,
        comment_id: state.comment.id
      };
      await FileRepository.deleteComment(payload)
        .json()
        .then(() => {
          emit("deleteComment", state.comment.id)
        });
    };

    return {
      editComment,
      deleteComment
    };
  },
};
</script>

<style scoped>
</style>