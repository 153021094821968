
<template>
  <svg width="1em" height="1em" viewBox="0 0 256 256">
    <path fill="currentColor" d="M236 92c0 30.6-17.7 62-52.6 93.4a314.3 314.3 0 0 1-51.5 37.6a8.1 8.1 0 0 1-7.8 0C119.8 220.6 20 163.9 20 92a60 60 0 0 1 108-36a60 60 0 0 1 108 36Z">
    </path>
  </svg>
</template>

<script>
export default {
  name: 'PhHeartFill'
}
</script>